import { RouterProvider, createBrowserRouter } from 'react-router-dom'
//--------------------------------------------------------------------------------------------------------------------------------------
//ALL CSS IMPORTS-
import './assets/css/style.css'
import './assets/css/style.css.map'
import './assets/css/fontawesome-all.min.css'
import './assets/css/animate.min.css'
import './assets/css/fontawesome-all.min.css'
import './assets/css/flaticon.css'
import './assets/css/meanmenu.css'
import './assets/css/icofont.min.css'
import './assets/css/datepicker.min.css'
import './assets/css/magnific-popup.css'
import './assets/css/nice-select.css'
import './App.css'

//--------------------------------------------------------------------------------------------------------------------------------------
//ALL COMPONENTS IMPORT-
// import AppLayout from './pages/AppLayout'
// import Homepage1 from './Components/Homepage1'
// import Aboutsection from './Components/Aboutsection'
// import Causebreadcums from './Components/Causebreadcums'
// import Causedetails1 from './Components/Causedetails1'
// import Eventbreadcums from './Components/Eventbreadcums'
// import Faqpage from './Components/Faqpage'
// import Donation from './Components/Donation'
// import Contact from './Components/Contact'
// import Login from './Components/Login'
// import Loginorganization from './Components/Loginorganization';
// import Organizationform from './Components/Organizationfrom';
// import Organizationsubmit from './Components/Organizationsubmit';
// import Signup from './Components/Signup'
// import Register from './Components/Register'
// import Formsubmit from './Components/Nav_Form/Formsubmit'
// import Organizationprofile from './Components/Organizationprofile'
import MainRouter from './MainRouter/MainRouter'
import { useTranslation } from 'react-i18next'
import Chat from './pages/Chat'
//----------------------------------------------------------------------------------------------------------------------------------------

export default function App () {
  //AppLayout Component is main main componenet, displaying Header component i.e Nav-Bar
  // const router = createBrowserRouter([
  //   {
  //     path: '/',
  //     element: <AppLayout />,
  //     children: [
  //       { path: '/', element: <Homepage1 /> },
  //       { path: '/about', element: <Aboutsection /> },
  //       { path: '/causes-details', element: <Causebreadcums /> },
  //       { path: '/causes', element: <Causedetails1 /> },
  //       { path: '/event', element: <Eventbreadcums /> },
  //       { path: '/faq', element: <Faqpage /> },
  //       { path: '/donation', element: <Donation /> },
  //       { path: '/contact', element: <Contact /> },

        
  //     ]
  //   },
  //   { path: '/login', element: <Login /> },
  //   { path: '/signin-by-org', element: <Signup /> },
  //   { path: '/register', element: <Register /> },
  //   {path:'/register-by-org', element:<Organizationform/>},
  //   { path: '/formsubmit', element: <Formsubmit /> },
  //   {path:'/organizationsubmit', element:<Organizationsubmit/>},
  //   {path:'/organization-profile', element:<Organizationprofile/>}

  // ])

  
const {i18n} = useTranslation()
  return (
    <div dir={i18n.language === 'ar'?'rtl':'ltr'} className={`${i18n.language === 'ar' ? 'cairo' : 'manrope'}`} 
    style={{
      fontFamily:i18n.language === 'ar' ? '"Cairo", sans-serif !important' : '"Manrope", sans-serif !important'
    }}
    >
      {/* <RouterProvider router={router}></RouterProvider> */}
      <MainRouter/>
      {/* <Chat/> */}
    </div>
  )
}
