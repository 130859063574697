import React from 'react';
import { useTranslation } from 'react-i18next';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import pdf1 from "../../../src/assets/PG-doc1.pdf";
import pdf2 from "../../../src/assets/PG-doc2.pdf";
import pdf3 from "../../../src/assets/PG-doc3.pdf";
import pdf4 from "../../../src/assets/PG-doc4.pdf";
import pdf5 from "../../../src/assets/PG-doc5.pdf";
import pdf6 from "../../../src/assets/PG-doc6.pdf";


import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const ProjectGuide2Card = ({ page, setpage }) => {
    const { t } = useTranslation();

    const pdfData = [
        { pdfUrl: pdf1, labelKey: "Education and training field" },
        { pdfUrl: pdf2, labelKey: "The social and awareness field" },
        { pdfUrl: pdf3, labelKey: "Projects of the Haram and visitors to the city" },
        { pdfUrl: pdf4, labelKey: "Forum Guide" },
        { pdfUrl: pdf5, labelKey: "Health and environmental field" },
        { pdfUrl: pdf6, labelKey: "Development field" },
    ];

    // Create the default layout plugin instance with custom toolbar
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        toolbarPlugin: {
            toolbar: (props) => {
                const { ToolbarSlot } = props;
                return (
                    <div className="custom-toolbar">
                        <ToolbarSlot.PreviousPageButton />
                        <ToolbarSlot.NextPageButton />
                    </div>
                );
            },
        },
    });

    return (
        <div className="w-full py-12 lg:py-32 bg-[#EEECE1] rounded-lg overflow-hidden" style={{ zIndex: '999' }}>
            <div className="w-11/12 mx-auto flex flex-col items-center gap-10 lg:gap-16">
                <h3 className="text-4xl text-center text-[#0A4E68] tracking-wide z-100" style={{ zIndex: "100" }}>
                    {t(`${page}.FIRST EDITION OF ALMOULTQA GUIDE`)}
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8" style={{width:'100%'}}>
                    {pdfData.map((pdfItem, index) => (
                        <div key={index} className="flex flex-col justify-center items-center p-2.5 bg-white rounded-3xl text-sky-900 text-opacity-60 max-w-[499px] w-full">
                            <a href={pdfItem.pdfUrl} target="_blank" rel="noopener noreferrer" className="text-lg font-semibold underline">
                                {t(`${page}.${pdfItem.labelKey}`)}
                            </a>
                            <div className="mt-4 w-full h-64 overflow-hidden">
                                <Worker workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`}>
                                    <Viewer fileUrl={pdfItem.pdfUrl}  />
                                </Worker>
                            </div>
                            <a href={pdfItem.pdfUrl} download className="mt-4 px-4 py-2 text-white bg-sky-900 rounded-[40px]">
                                {t(`Download`)}
                            </a>
                        </div>
                    ))}
                </div>
                <div onClick={() => setpage(page === 'ProjectGuide2' ? 'ProjectGuide' : 'ProjectGuide2')} className="justify-center px-4 py-2 text-lg font-semibold text-white bg-sky-900 rounded-[40px] cursor-pointer">
                {page === 'ProjectGuide' ? 'SECOND':'FIRST'} PAGE

                </div>
            </div>
        </div>
    );
};

export default ProjectGuide2Card;
