import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Navbar from '../Components/Nav_Form/Navbar';
import BasicDetailsForm from '../Components/CreateProject/BasicDetailsForm';
import TeamMemberForm from '../Components/CreateProject/TeamMemberForm';
import FinancialDetailForm from '../Components/CreateProject/FinancialDetailForm';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { creatProjectApi } from '../Api';
import CreateProjectModal from '../Modal/CreateProjectModal';
import { fetchlocalstorage } from '../HelperFunctions';
import DemoBasicDetailsForm from '../Components/CreateProject/DemoBasicDetailsForm';
import Header from '../Components/UI/Header';
import Footer from '../Components/Footer/Footer';

const DemoProject = () => {
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [isAllValueSet,setIsAllValueSet] = useState(false)
  const [step, setStep] = useState(1);
  const [step1Data, setStep1Data] = useState({});
//   const [step2Data, setStep2Data] = useState({});
//   const [step3Data, setStep3Data] = useState({});
  const { t , i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()


  const handleStep1Submit = async data => {
    // setStep1Data(data);
    console.log('data',data);
    let payload = { ...step1Data, ...data }
   
    try {
      setLoading(false)
      const res = await creatProjectApi(payload);
      // console.log(res)
      // console.log(res)
      if (res.status === 200 || res.status === 201) {

        toast.success(t(res?.data?.message), { id: "001" });
        //if donar created successfully update isProfile true for furter validation
        setLoading(true)
        navigate("/list-all-projects");

      } else {
        // toast.error(res?.response?.data?.message, { id: "err501" });

        setLoading(true)
      }
    } catch (error) {
      // console.log(error)
      toast.error((t(error?.response?.data?.message)), { id: "err501" });
    }


  };
  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
 };

 function submitForm() {

  
 }

  return (
    <div>

      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>
      {/* <Navbar /> */}
      <div className="bg-white py-8 px-4 shadow-md rounded-lg my-5 mx-auto max-w-4xl">
        {/* Header Section */}
        <div className="text-center pb-8">
          <p className="text-2xl font-semibold text-[#3b5299]">{t(`Create Project`)}</p>
          <p className="text-gray-600">{t(`Enter the details to get going`)}</p>
        </div>
        <DemoBasicDetailsForm onSubmit={handleStep1Submit} setStep={setStep} step={step} />  
      </div>

      <Footer />
    </div>
  );
}

export default DemoProject