import React from "react";
import { useTranslation } from "react-i18next";

const AboutContent = () => {
  const { t , i18n} = useTranslation();
  return (
    <section className="flex flex-col max-w-[80%]">
      <header className="flex gap-2.5 pr-5 text-4xl leading-8 text-[#0A4E68] uppercase whitespace-nowrap max-md:flex-wrap">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d36eeca3d87f81c8394bd3a1129796e3ff74b4e1864bfeb49f32002f9eb7db26?apiKey=8d89067347d641b99b038352d46b6e1d&"
          className="shrink-0 aspect-square w-[52px]"
          alt=""
        />
        <h1 className={`my-auto font-semibold max-md:max-w-full ${i18n.language === 'ar' ? 'cairo' : 'manrope'}`}>{t("About1.EXCELLENCE")}</h1>
      </header>
      <article className="mt-8 w-full text-xl font-light leading-10 text-[#084F679C] text-opacity-60 max-md:max-w-full">
        <p>
        {t("About1.EXCELLENCE_DESCRIPTION")}
        </p>
      </article>
    </section>
  );
};

export default AboutContent;
