import * as yup from "yup";

export const signinValidationForDonor = yup.object({
  email: yup.string().email('Invalid email').required("يجب إدخال البريد الإلكتروني"),
  password: yup.string().min(6, "كلمة المرور يجب الا تقل عن ٦ حروف و ارقام").required("هذا هو الحقل المطلوب")  
});


export const signupValidationForDonor = yup.object({
  email: yup.string().email('Invalid email').required("يجب إدخال البريد الإلكتروني"),
  password: yup.string().min(6, "كلمة المرور يجب الا تقل عن ٦ حروف و ارقام").required("هذا هو الحقل المطلوب")  
});
 

export const donorDetailsValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
  domain: yup.string().required('Domain is required'),
  mobileNumber: yup
    .number()
    .typeError('Mobile number must be a number')
    .required('Mobile number is required'),
  landLineNumber: yup.number().typeError('Land number must be a number'),
  faxNumber: yup.number().typeError('Fax number must be a number'),
  email: yup.string().email('Invalid email').required('Email is required'),
  // status: yup.string().oneOf(['PENDING', 'APPROVED', 'REJECTED']),
  entityType: yup.string(),
  licenseNumber: yup.string().required("License Number is required"),
  licenseAuthority: yup.string(),
  address: yup.string().required('Address is required'),
  googleMapLink: yup.string(),
  mission: yup.string(),
  vision: yup.string(),
  objectives: yup.string(),
  executiveManagerName: yup.string(),
  executiveManagerNumber: yup.number().typeError('Executive manager number must be a number'),
   
});

export const donotAgentDetailsValidationSchema=yup.object({
  agentName: yup.string(),
  agentMobileNumber: yup.number().typeError('Agent mobile number must be a number'),
  agentEmail: yup.string().email('Invalid agent email'),
});