import React from "react";
import { useTranslation } from "react-i18next";

const Achievements = () => {
  const {i18n , t} = useTranslation()
  return (
    <div className="w-full my-32">
      <div className="w-[90%] mx-auto flex flex-col items-center gap-28">
        <h3 className={`text-[#0A4E68] text-4xl text-center font-medium ${i18n.language == 'ar' ? "cairo" : "manrope"}`}>
          {t("Home1.ACHIEVEMENTS")}
        </h3>
        <div className="w-full text-black grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-8 sm:gap-16 md:gap-24">
          <div className="flex justify-between flex-col items-center gap-2">
            <img src="/images/chain.svg" className="w-1/5 md:w-1/10" />
            <p className="text-xl font-normal text-center">
              {t("Home1.Participating Charitable Organization")}
            </p>
            <p className="text-lg md:text-4xl font-bold text-[#134560]">83</p>
          </div>
          <div className="flex justify-between flex-col items-center gap-2">
            <img src="/images/bookmark.svg" className="w-1/5 md:w-1/10" />
            <p className="text-xl font-normal text-center">{t("Home1.Submitted Projects")}</p>
            <p className="text-lg md:text-4xl font-bold text-[#134560]">83</p>
          </div>
          <div className="flex justify-between flex-col items-center gap-2">
            <img src="/images/hamburger.svg" className="w-1/5 md:w-1/10" />
            <p className="text-xl font-normal text-center">
              {t("Home1.Matured and Well-studied Projects")}
            </p>
            <p className="text-lg md:text-4xl  font-bold text-[#134560]">79</p>
          </div>
          <div className="flex justify-between flex-col items-center gap-2">
            <img src="/images/people.svg" className="w-1/5 md:w-1/10" />
            <p className="text-xl font-normal text-center">{t("Home1.Experts and Evaluators")}</p>
            <p className="text-lg md:text-4xl font-bold text-[#134560]">22</p>
          </div>
          <div className="flex justify-between flex-col items-center gap-2">
            <img src="/images/bookmark.svg" className="w-1/5 md:w-1/10" />
            <p className="text-xl font-normal text-center">{t("Home1.Workshops")}</p>
            <p className="text-lg md:text-4xl font-bold text-[#134560]">12</p>
          </div>
          <div className="flex justify-between flex-col items-center gap-2">
            <img src="/images/people.svg" className="w-1/5 md:w-1/10" />
            <p className="text-xl font-normal text-center">{t("Home1.Beneficiaries")}</p>
            <p className="text-lg md:text-4xl font-bold text-[#134560]">1,120,000</p>
          </div>
          <div className="flex justify-between flex-col items-center gap-2">
            <img src="/images/dollarBitcoin.svg" className="w-1/5 md:w-1/10" />
            <p className="text-xl font-normal text-center">
              {t("Home1.Cost of Well-studied Projects")}
            </p>
            <p className="text-lg md:text-4xl font-bold text-[#134560]">96,076,982 SAR</p>
          </div>
          <div className="flex justify-between flex-col items-center gap-2">
            <img src="/images/worker.svg" className="w-1/5 md:w-1/10" />
            <p className="text-xl font-normal text-center">{t("Home1.Workers")}</p>
            <p className="text-lg md:text-4xl font-bold text-[#134560]">1,153</p>
          </div>
          <div className="flex justify-between flex-col items-center gap-2">
            <img src="/images/dollarBitcoin.svg" className="w-1/5 md:w-1/10" />
            <p className="text-xl font-normal text-center">{t("Home1.Cost of Signed Projects")}</p>
            <p className="text-lg md:text-4xl font-bold text-[#134560]">20,640,017 SAR</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
