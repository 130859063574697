export const createProjectBasicDetailInitialValues = {
    projectName: "",
    domain: "",//
    region:"",
    // projectType:"",
    projectJustification: "",
    projectOverview: "",
    projectObjectives: "",
    targetedAudience: "",
    startDate: "",
    closeDate: "", 
    remark: "",//
    rating: 0,//
    status:"DRAFT",
    expectedOutcomes: "",
    expectedImpact: "",
    creativeInnovativeAspect: "",
    projectManagerName: "",
    projectManagerSignature:"",
    projectImage:'',
    teamMembersFulltime: "", //
    teamMembersVolunteer: "",//
    teamMembersPartnerships: "",//
    partnershipstype: "",//
    teamMembersPercentageOfEachPartner: "",//
    teamMembersPerformanceMeasurement: "",//
    teamMembersProjectImplementation: true,//
    teamMembersFundingSource:true,
    teamMembersLastProjectsReports: "",
    teamMembersSupportingEntityStrategy: "",
    teamMembersFundingPercentage: "",
    financialDetails: [
      {
        item: '',
        quantity: '',
        price: '',
        beforeVat:0,
        vat:0,
        totalprice:0
      }
    ],
  financialDetailsItem: "",
  financialDetailsQuantity: "",
  financialDetailsPrice: "",
  financialDetailsBeforeTotal:0,
  financialDetailsTotal:0,
  // Financial:- Second   
  plansActivity: "",
  plansPerformance: "",
  plansTimeline: ""

}
export const createProjectTeamMemberInitialValues = {

    //Team form
    teamMembersFulltime: "", //
    teamMembersVolunteer: "",//
    teamMembersPartnerships: "",//
    partnershipstype: "",//
    teamMembersPercentageOfEachPartner: "",//
    teamMembersPerformanceMeasurement: "",//
    teamMembersProjectImplementation: "",//
    teamMembersLastProjectsReports: "",
    teamMembersSupportingEntityStrategy: "",
    teamMembersFundingPercentage: ""


}

export const createProjectFinancialDetailInitialValues = {

    //Financial form
    financialDetails: [
        {
          item: '',
          quantity: '',
          price: '',
          beforeVat:0,
          vat:0,
          totalprice:0
        }
      ],
    financialDetailsItem: "",
    financialDetailsQuantity: "",
    financialDetailsPrice: "",
    financialDetailsBeforeTotal:0,
    financialDetailsTotal:0,
    // Financial:- Second   
    plansActivity: "",
    plansPerformance: "",
    plansTimeline: ""


}
