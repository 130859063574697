import React,{useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ProfileSectionOne from './ProfileSectionOne'
import ProfileSectionTwo from './ProfileSectionTwo'
import { fetchlocalstorage } from '../../HelperFunctions'

const ProfileInfo = ({userInfo}) => {
console.log(userInfo)
const {t} = useTranslation()
const [step, setStep] = useState(1);
const handleClick1 =()=>{
    if(step===2)
    {
        setStep(1);
    }
   
}
const handleClick2=()=>{
    if(step===1)
    {
        setStep(2);
    }
}
    return (
        <div className='px-[15%] flex justify-between flex-col '> 

            {/* <div className='mt-4 p-4 sm:p-1 p-2 grid grid-cols-1 md:grid-cols-3 sm:gap-0 gap-4'>
                <div className=''>
                    <div>
                        <span className='text-black mr-2'>Name: </span>
                        <span>{userInfo?.name}</span>
                    </div>
                    <div>
                        <span className='text-black mr-2'>License Number: </span>
                        <span>{userInfo?.licenseNumber}</span>
                    </div>
                    <div>
                        <span className='text-black mr-2'>Donation Field: </span>
                        <span>{userInfo?.domain?.name}</span>
                    </div>
                    <div>
                        <span className='text-black mr-2'>Address: </span>
                        <span>{userInfo?.address}</span>
                    </div>
                     

                </div>
                <div className=''>
                    <div>
                        <span className='text-black mr-2'>Contact:  </span>
                        <span>{userInfo?.mobileNumber}</span>
                    </div>
                    <div>
                        <span className='text-black mr-2'>Email Id:  </span>
                        <span>{userInfo?.email}</span>
                    </div>
                </div>
                <div className=''>
                    <div>
                        <span className='text-black mr-2'>Status:  </span>
                        <span className='font-bold	 text-black px-2'>{userInfo?.status} </span>
                    </div>
                    
                </div>
            </div> */}
             <div className="flex justify-between text-center mb-6 mt-12 px-3">
                    <div className="w-1/2" onClick={handleClick1} >
                        <div className={`inline-block w-8 h-8 ${step==1 ?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"}  rounded-full text-lg leading-loose  ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>1</div>
                        <span className="text-sm font-semibold text-gray-700 ml-2">{t(`Organisation Details`)}</span>
                    </div>
                    <div className="w-1/2" onClick={handleClick2}>
                        <div className={`inline-block w-8 h-8 ${step==2?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"} text-gray-600 rounded-full text-lg leading-loose ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>2</div>
                        <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Agent Details`)}</span>
                    </div>

                </div>
                {step === 1 && <ProfileSectionOne userInfo={userInfo}/>}
                {step === 2 && <ProfileSectionTwo userInfo={userInfo}/>}
            {/* <div className='mt-4 xs:p-2 sm:p-4 flex flex-wrap'>
                

                
            </div> */}
        </div>
    )
}

export default ProfileInfo