
import React, { useEffect, useState } from 'react';
import Navbar from '../Nav_Form/Navbar';
import { FaPen } from "react-icons/fa";
import ProfileInfo from './ProfileInfo';
import { getProfile } from '../../Api';
import { FaPhoneAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { fetchlocalstorage } from '../../HelperFunctions';

function DonorProfile() {
    const [userInfo, setUserInfo] = useState({})
    const {t} = useTranslation()
    const navigate = useNavigate()

    async function getProfileData() {
        let data = await getProfile()
        // console.log(data)
        setUserInfo(data?.data?.donor)
        // console.log(data.data.charity)
    }
    useEffect(() => {
        getProfileData()
    }, [])

    return (
        // <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div  >
            <Navbar />
            <div className="bg-white    xs:p-1 sm:p-2 shadow   sm:rounded-lg xs:m-[1rem] sm:m-[5rem] md:m-[7rem] " >
                <div className="  px-2    py-5 xs:p2 sm:p-6">
                    <div className=" ">
                        <h4 className="leading-6 mb-4 font-medium text-gray-900">
                        {t(`Profile Details`)}
                        </h4>
                        <div className='flex  '>
                            <div className='w-24 p-2 rounded  bg-project_bg_image_color text-white text-lg xs:hidden sm:block' style={{
                                width: "80.556px",
                                height: "80.556px",
                                marginRight: '26px'
                                // flex-shrink: 0;

                            }}>{ <div className=' border-2 w-full h-full bg-project_image_color text-white text-lg flex justify-center item-center' style={{
                                borderRadius: "6px",
                                border: "none"
                            }}><h3 className='uppercase text-[3rem] text-white'>{userInfo?.name?.charAt(0).toUpperCase()}</h3></div>}</div>
                            <div className='w-3/4'>
                                <div className=' text-black   flex'><p className={` mr-2 text-black font-bold ${fetchlocalstorage("language")=="ar"?"ml-4":""}`}>{t(`Name`)} : </p><p>{userInfo?.name}</p></div>
                                <div className='flex item-center '><span className={`mr-2 mt-1  text-black font-bold ${fetchlocalstorage("language")=="ar"?"ml-6":""}`}><FaPhoneAlt /></span> {userInfo?.mobileNumber}</div>
                                <div className='flex item-center mt-2 'flex><p className={`mr-2 text-black font-bold ${fetchlocalstorage("language")=="ar"?"ml-4":""}`}>{t(`Status`)}: </p> <p>{userInfo?.status}</p></div>
                            </div>
                           {userInfo?.status !=="APPROVED"? <div className='flex items-center justify-end flex-grow '  onClick={()=>navigate("/update-donor",{state:{userInfo}})}> {/* Edit button at end */}
                                <span className='flex bg-gray-300 px-4 py-2 text-black	justify-center align-center   rounded  cursor-pointer'>
                                    <button className='ml-auto mr-3'>{t(`Edit`)}</button> {/* ml-auto for margin-left: auto */}
                                    <span><FaPen /></span>
                                </span>
                            </div>:""}
                        </div>
                    </div>
                    <hr />
                    {/* <div className="mt-5 border-t border-gray-200 pt-5">
                        <dl>
                            <dt className="text-sm font-medium text-gray-500">
                                Organization
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                Appllore Technologies
                            </dd>
                            <dt className="text-sm font-medium text-gray-500">
                                Phone
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">


                            </dd>
                        </dl>
                    </div> */}
                    {/* <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4"> */}
                    {/* Placeholder for the participation letters
            {[1, 2, 3, 4].map((item) => (
              <div key={item} className="bg-gray-100 p-4 rounded-lg flex justify-center items-center">
                <span className="text-sm text-gray-500">Participation Letter</span>
              </div>
            ))} */}
                    <ProfileInfo userInfo={userInfo} />
                    {/* </div> */}
                </div>
            </div>
        </div >
    );
}

export default DonorProfile;



