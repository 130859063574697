import React, {useState, useEffect} from 'react'
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MyTable from './MyTable';
import { fetchlocalstorage } from '../../HelperFunctions';
import SupportModal from './ResolvedDesktop';
import Navbar from '../Nav_Form/Navbar';
import { backendUrl } from '../../axios';
// import { fetchlocalstorage } from '../HelperFunctions';

const TicketSupport= () => {
    const {t} = useTranslation()
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [nextPage, setNextPage] = useState(1)
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
      };
  const getTable = async () => {
    const token = localStorage.getItem('token');
     
    try {
      const response = await fetch(`${backendUrl}/ticket/getAllTicketsOfDonor?page=${nextPage}&limit=${limit}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
     

        setLimit(data?.limit)
        setPage(data?.page)
        setTotal(data?.total)
        setTotalPages(data?.totalPages)
        // setProject(data?.data)

      setData(data);
    } catch (error) {
      console.error('There was a problem fetching the data:', error);
    }
  };

  useEffect(() => {
    getTable();
  }, [nextPage]);
  const columns = useMemo(
    () => [
      {
        Header: t(`Category`),
        accessor: 'category',
      },
      {
        Header: t('SubCategory'),
        accessor: 'subCategory',
      },
      {
        Header: t('Description'),
        accessor: 'description',
      },
      {
        Header: t('Status'),
        accessor: 'status',
      },
      {
        Header: t('Admin Remarks'),
        accessor: 'adminRemarks',
      },
    ],
    );

    

    const transformedData = useMemo(() => {
        if (data?.data && Array.isArray(data.data)) {
          return data?.data.map(item => ({
            category: item?.category,
            subCategory: item?.subCategory,
            description: item?.description,
            status: item?.status,
            adminRemarks: item?.adminRemarks,
          }));
        }
        return [];
      }, [data]);


      let paginationButton = () => {
        let arr = []
        for (let i = 1; i <= totalPages; i++) {
          arr.push(
    
            <button
              key={i}
              onClick={() => setNextPage(i)}
              className={`relative z-10 inline-flex items-center ${nextPage === i ? 'bg-indigo-600' : ''} px-4 py-2 text-sm font-semibold ${nextPage === i ? 'text-white ' : 'text-black'} focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              {i}
            </button>
    
          )
    
        }
        // console.log(arr)
        return arr
      }

  return (
    <>

    <Navbar/>
  <section
    className="breadcrumb_area breadcrumb_overlay"
    style={{ background: "url(/img/bg/breadcrum_bg_2.jpg)" }}
  >
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="breadcrumb_section">
            <ul className="breadcrumb-list volunteer_breadcrumb text-black">
              <li className="bhas_border ">
                <Link to="/">{t(`Home`)}</Link>
              </li>
              <li>
                <span className="active">{t(`Support`)}</span>
              </li>
            </ul>
            <div className="breadcrumb_title">
              <h2>{t(`Support Ticket`)}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div style={{ position: 'relative' }}>
  <button
        variant="contained"
        color="primary"
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          backgroundColor: "blue",
          color: "white",
          padding: "10px 15px",
          borderRadius: "5px",
          border: "none",
          
        }}
        onClick={() => setShowModal(true)}
      >
        {t(`Add Ticket`)}
      </button>
    <section
      className="support_ticket_area"
      style={{
        paddingTop: "80px",
        paddingBottom: "80px",
        paddingLeft: "50px",
        paddingRight: "50px",
      }}
    >
      <div>
        <MyTable columns={columns} data={transformedData} />
      </div>
      <div className='bg-whit   '>

            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
              <div className="flex flex-1 justify-between sm:hidden">
                <a
                  href="#"
                  
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  onClick={() => nextPage!==1&&setNextPage((prev) => prev - 1)}
                >
                  {t('Previous')}
                </a>
                <a
                  href="#"
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  disabled={nextPage == totalPages ? true : false}
                  // disabled={true}
                  onClick={() => nextPage!==totalPages&&setNextPage((prev) => prev + 1)}

                >
                  {t('Next')}
                </a>
              </div>
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    {t('Showing')} <span className="font-medium">{limit * (page - 1) + 1}</span> {t('to')} <span className="font-medium">{limit * (page - 1) + data?.data?.length}</span> {t('of')}{' '}
                    <span className="font-medium">{total}</span> {t('results')}
                  </p>
                </div>
                <div>
                  <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    <Link  
                      to="#"
                      className={`relative inline-flex items-center ${fetchlocalstorage("language")=="ar"?"rounded-r-md":"rounded-l-md"} px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                      onClick={() => nextPage!==1&&setNextPage((prev) => prev - 1)}

                    >
                      <span className="sr-only">{t('Previous')}</span>
                      <span className="h-5 w-5" aria-hidden="true" >{"<"}</span>
                    </Link>
                     {paginationButton().map(item => item)
                    }
 

                    <a
                      href="#"
                      className={`relative inline-flex items-center ${fetchlocalstorage("language")=="ar"?"rounded-l-md":"rounded-r-md"} px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                      onClick={() => nextPage!==totalPages&&setNextPage((prev) => prev + 1)}

                    >
                      <span className="sr-only">{t(`Next`)}</span>
                      <span className="h-5 w-5" aria-hidden="true" >{">"}</span>
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
    </section>
  </div>
  <SupportModal showModal={showModal} onClose={() => setShowModal(false)} />
</>
  )
}

export default TicketSupport