import React from "react";
import { useTranslation } from "react-i18next";
const ThankYou = () => {
  const {t}=useTranslation()
  return (
    <div class="bg-gray-100 flex items-center justify-center h-screen">
      <div class="bg-white p-8 rounded-lg shadow-lg">
        <h1 class="text-3xl font-semibold text-center mb-4">{('Thank You')}!</h1>
        <p class="text-lg text-gray-700 text-center">
       {t('Your registration is completed.')}
        </p>
        {/* <div class="flex justify-center mt-8">
          <a
            href="#"
            class="px-4 py-2 bg-blue-500 text-white rounded-md text-lg hover:bg-blue-600"
          >
            Go to Dashboard
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default ThankYou;
