import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import { backendUrl } from "../../axios";
// import { fetchlocalstorage } from "../HelperFunctions";
const OpportunityList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [domains, setDomains] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/domain/getAllDomains`
        );
        setDomains([{ _id: "", name: "ALL" }, ...response.data.domains]);
        setSelectedTab(""); // Default to "ALL"
      } catch (error) {
        console.error("Error fetching domains:", error);
      }
    };
    fetchDomains();
  }, []);

  useEffect(() => {
    const getAllApprovedEvents = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/project/getAllLiveProjects?domain=${selectedTab}&page=&limit=`
        );
        setEvents(response.data.data); // Adjust based on actual response structure
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    getAllApprovedEvents();
  }, [selectedTab]);
  

  return (
    <div className="w-[100%] absolute top-[50vh] bg-[#EEEDE5]">
      <div
        className="px-5 md:container mx-auto p-4 rounded-lg"
        style={{ zIndex: "999" }}
      >
        <div className="flex flex-wrap items-center  justify-center gap-3 mb-8">
          {domains.map((domain) => (
            <button
              key={domain._id}
              onClick={() => setSelectedTab(domain._id)}
              className={`p-2 h-12 lg:p-3 flex justify-center items-center lg:text-lg leading-8 text-center capitalize rounded-lg border border-sky-900 border-solid ${
                selectedTab === domain._id
                  ? "bg-sky-900 text-white"
                  : "text-sky-900"
              }`}
            >
              {t(domain.name)}
            </button>
          ))}
        </div>

        <div className=" md:container mx-auto p-2 lg:p-4">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 w-full">
            {events.length > 0 ? (
              events.map((project, index) => (
                <div key={index} className="op_card w-full bg-white p-5 rounded cursor-pointer" onClick={() =>
                  navigate("/causes-details", { state: { item:project } })
                }>
              
              <div className="relative w-full">
                  <img
                    style={{
                      height: "200px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={project.projectImage}
                    alt="Project"
                  />

                  <div className="absolute h-32 -bottom-0 flex flex-col justify-between py-2.5 text-sm text-center text-white bg-emerald-300 w-[68px] rounded-[32px_32px_0px_0px]">
                    <div className="justify-center self-center px-2 py-2 whitespace-nowrap rounded-3xl border text-xs border-white border-solid leading-[143%]">
                    {
                      ((project?.raised/project?.financialDetailsTotal
                      )*100).toFixed(1)
                    }%
                    </div>
                    <h2 className="mt-3 w-full leading-5 text-white whitespace-break-spaces">
                    {project?.domain?.name}
                    </h2>
                  </div>
                </div>

                  <div className="flex flex-col self-stretch lg:px-5 mt-4 py-5 bg-white rounded-none max-w-[509px]">
                    {/* <div className="text-xs font-medium leading-6 text-sky-900 max-md:max-w-full">
                      Social Services
                    </div> */}
                    <div className="mt-2 text-xl font-semibold leading-7 text-gray-900 max-md:max-w-full">
                      {project?.projectName}
                    </div>
                    <div className="flex gap-2 justify-between mt-10 max-md:flex-wrap">
                      <div className="flex flex-col justify-center">
                        <div className="flex gap-3.5 text-base font-semibold leading-6 whitespace-nowrap text-slate-500">
                          <div className="shrink-0 w-6 h-6" />
                          <div>{t('Goal')}</div>
                        </div>
                        <div className="mt-5 text-sm leading-6 text-center text-black">
                          <span className="text-2xl font-semibold">
                            {project?.financialDetailsTotal.toFixed(2)?.slice(0,4)}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="flex flex-col font-semibold">
                        <div className="flex gap-0.5 text-base leading-6 whitespace-nowrap text-slate-500">
                          <div className="shrink-0 w-6 h-6" />
                          <div>{t('Raised')}</div>
                        </div>
                        <div className="mt-5 text-xl leading-6 text-black">
                          <span className="text-2xl">{project?.raised}</span>{" "}
                          <span className="text-sm ">Sar</span>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center font-semibold">
                        <div className="flex gap-0.5 text-base leading-6 text-slate-500">
                          <div className="shrink-0 w-6 h-6" />
                          <div>{t('To Go')}</div>
                        </div>
                        <div className="mt-5 text-xl leading-6 text-black">
                          <span className="text-2xl">
                          {String(project?.financialDetailsTotal - project?.raised).slice(0, 4)}{" "}
                          </span>
                          <span className="text-sm ">Sar</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="justify-center self-start p-2.5 mt-10 text-lg font-semibold text-white bg-sky-900 rounded-[40px] max-md:px-5 cursor-pointer "
                      
                    >
                      {t("Project Details")}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-2xl text-gray-500 w-full flex justify-center items-center">
                <p className="">

               {t("opportunities_not_found")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OpportunityList;
