import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function Footer2 () {
  const {t} = useTranslation()
  return (
    // <!-- Footer Area Start -->

    <footer>
      <div className='footer_top_area footer_top_2'>
        <div className='footer_top_wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-7 col-sm-7'>
                <div className='footer_widget footer_about mb-50'>
                  <div className='footer_logo mb-35'>
                    <Link href='/' className='animation flex items-center'>
                      <img src='/login_image/login.svg' alt='img' />
                      <div style={{width:200}}>{t('Al-moultqa')}</div>
                    </Link>
                  </div>
                  <p className='mb-30'>{t('The Non-Profit and Donor Support Forum is a unique event in Saudi Arabia, bringing together charitable organizations and donors, including grant-making institutions, companies, and socially responsible banks. Our mission is to support impactful projects that provide significant benefits to recipients.')}</p>
                  {/* <div className='footer_social_2'>
                    <Link href='#' className='facebook'>
                      <i className='fab fa-facebook-f'></i>
                    </Link>
                    <Link href='#' className='twitter'>
                      <i className='fab fa-twitter'></i>
                    </Link>
                    <Link href='#' className='behance'>
                      <i className='fab fa-behance'></i>
                    </Link>
                    <Link href='#' className='youtube'>
                      <i className='fab fa-youtube'></i>
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-5 col-sm-5'>
                <div className='footer_widget mb-50 pl_15'>
                  <div className='footer_widget_title mb-25'>
                    <h4 className='footer_title footer_title_2'>
                      {t(`Useful links`)}
                    </h4>
                  </div>
                  <div className='footer_links footer_links_2'>
                    <ul>
                      <li>
                        <Link href='/about'>{t(`About`)}</Link>
                      </li>
                      <li>
                        <Link href='/volunteer'>{t(`Meet The Team`)}</Link>
                      </li>
                      <li>
                        <Link href='/volunteer'>{t(`Volenteers`)}</Link>
                      </li>
                      <li>
                        <Link href='/about'>{t(`Service Provided`)}</Link>
                      </li>
                      {/* <li>
                        <Link href='/blog'>{t(`News`)}</Link>
                      </li> */}
                      <li>
                        <Link href='/contact'>{t(`Contact`)}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-7 col-sm-7'>
                <div className='footer_widget mb-50 pl_25'>
                  <div className='footer_widget_title mb-25'>
                    <h4 className='footer_title footer_title_2'>{t('Donation')}</h4>
                  </div>
                  <div className='footer_links footer_links_2'>
                    <ul>
                      <li>
                        <Link href='/donation'>{t(`How to Donate`)}</Link>
                      </li>
                      <li>
                        <Link href='/donation'>{t(`Donation List`)}</Link>
                      </li>
                      <li>
                        <Link href='/cause'>{t(`Recent Causes`)}</Link>
                      </li>
                      <li>
                        <Link href='/faq'>{t(`FAQ`)}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className='footer_btn'>
                    <Link
                     to='/ticket-support'
                      className='g_btn fbtn_2 to_right1  rad-50'
                    >
                      {t(`Get Support`)}<span></span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-5 col-sm-5'>
                <div className='footer_widget footer_news mb-50'>
                  <div className='footer_widget_title mb-25'>
                    <h4 className='footer_title footer_title_2'>
                      {t(`Keep In Touch`)}
                    </h4>
                  </div>
                  <div className='footer_info_content'>
                    <p>
                      <span>
                        <strong>{t('Location')} : </strong>{t('Prince Abdul Mohsen Bin Abdulaziz Road, Friday, 42316')}
                      </span>
                    </p>
                    {/* <p>
                      <Link href='/tel:800433633'>
                        <span>
                          <strong>Phone :</strong>(01) 800 433 633
                        </span>
                      </Link>
                    </p> */}
                    <p>
                      <Link href='/mailto:info@almoultqa.com'>
                        <span>
                          <strong>{t('Email')} :</strong>info@almoultqa.com
                        </span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xxl-6 col-xl-6 col-lg-6 col-sm-12'>
                <div className='fcta_sigle has_bg mb-30'>
                  {/* <img src='/img/footer/fcta2_1.png' alt='img' style={{height:"100%",padding:10}}/> */}
                  <div className='fcta_text'>
                    <h4>{t(`Help & Support Now`)}</h4>
                    <span>
                      {t('Support us by donating')}
                    </span>
                  </div>
                  <div className='fcta_button'>
                    <Link
                      to='/donation'
                      className='g_btn fca_btn1 to_right2  rad-50'
                    >
                      {t(`Donate`)} <span></span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-6 col-sm-12'>
                <div className='fcta_sigle has_bg mb-30'>
                  {/* <img src='/img/footer/fcta2_2.png' alt='img' style={{height:"100%",padding:10}}/> */}
                  <div className='fcta_text'>
                    <h4>{t(`Join as Charity Organization`)}</h4>
                    <span>
                    {t('Support us by donating')}
                    </span>
                  </div>
                  <div className='fcta_button'>
                  <Link
                      to='/about'
                      className='g_btn fca_btn1 to_right2  rad-50'
                    >
                      {t(`Join`)} <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='footer_copyright_area'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 text-center'>
              <p>
                Copyright © 2024 All Rights Reserved by{' '}
                <strong>
                  <Link href='#'>Al-moultqa</Link>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </footer>

    //<!-- Footer Area End -->
  )
}
