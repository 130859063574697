"use client";

import React from 'react'


// Swiper components, modules and styles
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";



import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const HomeImageBanner = () => {
    const { t , i18n } = useTranslation();
    const slides = [
        {
            image: "/images/bannerImg2.svg",
            text: `"${t("Home1.bannerDescription1")}"`
        },
        {
            image: "/images/homeBanner.svg",
            text: `"${t("Home1.bannerDescription2")}"`
        },
    ]

    const swiperRef = React.useRef(null);

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };


    return (
        <div className='mb-8'>
            <Swiper
                navigation={{
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                }}
                pagination={{ type: "bullets", clickable: true }}
                autoplay={true}
                loop={true}

                modules={[Autoplay, Navigation, Pagination]}
                className='h-[40vh] md:h-[70vh] lg:h-[80vh]'
            >
                {slides?.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <div className="relative h-[100%] flex items-center justify-center">
                            <img src={slide?.image} alt={`Slide ${index}`} className='object-contain' />
                            <div className="absolute inset-0 flex items-center justify-center text-white">
                            <h1 className={`text-xl md:text-4xl lg:text-4xl font-extrabold w-[90%] md:w-[60%] lg:w-[80%] text-center leading-snug md:leading-normal lg:leading-relaxed italic px-4 text-white md:px-0 ${i18n.language == 'ar' ? "cairo" : "manrope"}`}>{slide?.text}</h1>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
                {/* <button
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-black rounded-full p-2 shadow-lg z-10 "
                    onClick={handlePrev}
                >
                    <IoIosArrowBack size={24} />
                </button>
                <button
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-black rounded-full p-2 shadow-lg z-10"
                    onClick={handleNext}
                >
                    <IoIosArrowForward size={24} />
                </button> */}
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </Swiper>
        </div>
    )
}

export default HomeImageBanner
