import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Navbar from '../Components/Nav_Form/Navbar';
import BasicDetailsForm from '../Components/CreateProject/BasicDetailsForm';
import TeamMemberForm from '../Components/CreateProject/TeamMemberForm';
import FinancialDetailForm from '../Components/CreateProject/FinancialDetailForm';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { creatProjectApi,updateReportApi } from '../Api';
import CreateProjectModal from '../Modal/CreateProjectModal';
import { fetchlocalstorage } from '../HelperFunctions';
import DemoBasicDetailsForm from '../Components/CreateProject/DemoBasicDetailsForm';
import Header from '../Components/UI/Header';
import Footer from '../Components/Footer/Footer';

const DemoUpdateProject = () => {
    const [isModalOpen,setIsModalOpen] = useState(false)

    const [step, setStep] = useState(1);
    const [step1Data, setStep1Data] = useState({});
    const [step2Data, setStep2Data] = useState({});
    const [step3Data, setStep3Data] = useState({});
    const navigate=useNavigate();

    // const {state} = useLocation()
    // const {projectDetail} = state
    const location = useLocation();
    const state = location.state || {};
    const { projectDetail } = state;
    console.log(state);
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()
//   const handleStep1Submit = data => {
//     setStep1Data(data);

//     setStep(2);
//   };
//   const handleStep2Submit = data => {
//     setStep2Data(data);

//     setStep(3);
//   };

  const handleStep1Submit = async data => {
    // setStep1Data(data);
    // debugger;
    console.log('data',data);
    let payload = { ...step1Data, ...data }

    let s=payload.projectObjectives.filter((item)=>item!=="");
    payload.projectObjectives=s;

    try {
      setLoading(false)
      const res = await updateReportApi(payload,projectDetail._id);
     // const res = await updateReportApi(payload,projectDetail._id);
      // console.log(res)
      // console.log(res)
      if (res.status === 200 || res.status === 201) {

        toast.success(t(res?.data?.message), { id: "001" });
        //if donar created successfully update isProfile true for furter validation
        setLoading(true)
        navigate("/list-all-projects");

      } else {
        // toast.error(res?.response?.data?.message, { id: "err501" });

        setLoading(true)
      }
    } catch (error) {
      // console.log(error)
      toast.error(t(error?.response?.data?.message), { id: "err501" });
    }


  };
  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
 };

 function submitForm() {

  
 }

  return (
    <div>
      {/* <Navbar /> */}

      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>


      <div className="bg-white py-8 px-4 shadow-md rounded-lg my-5 mx-auto max-w-4xl">
        {/* Header Section */}
        <div className="text-center pb-8">
          <h1 className="text-2xl font-semibold">{t(`Update Project`)}</h1>
          <p className="text-gray-600">{t(`Enter the details to get going`)}</p>
        </div>

        {/* {JSON.stringify(projectDetail)} */}
        <DemoBasicDetailsForm onSubmit={handleStep1Submit} projectDetail={projectDetail } />
        {/* {step == 2 ? <TeamMemberForm onSubmit={handleStep2Submit} setStep={setStep} step={step} /> : ""}
        {step == 3 ? <FinancialDetailForm setIsModalOpen = {setIsModalOpen} onSubmit={handleStep3Submit} setStep3Data={setStep3Data} setStep={setStep} step={step} loading={loading} /> : ""} */}
        {/* {isModalOpen && (
        <CreateProjectModal submitForm={()=>handleStep3Submit(step3Data)} onClose={handleCloseModal} loading={loading}/>
      )} */}
      </div>

      <Footer />


    </div>
  );
}

export default DemoUpdateProject