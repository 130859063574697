import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";
import { useNavigate } from "react-router";
import { callAxios } from "../axios";

const CreateProjectModal = ({ onClose }) => {
  const [rememberMe, setRememberMe] = useState(false);
  const { t, i18n } = useTranslation();
  const [tc, seTtc] = useState(null);
  const navigate = useNavigate();

  const getTCs = async () => {
    try {
      const { data } = await callAxios(
        "get",
        "termsAndConditon/getTermsAndConditionByUser"
      );

      console.log(data);
      seTtc(data?.data);
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message);
    }
  };

  useEffect(() => {
    getTCs();
  }, []);

  return (
    <div className="overflow-y-scroll h-96">
      <div className="fixed  inset-0 z-50 overflow-auto  bg-gray-800 bg-opacity-50 flex items-center justify-center">
        <div
          className={`${
            i18n.language === "ar" ? "cairo" : "manrope"
          } bg-white w-full mx-2 sm:w-[50%] p-6  rounded-lg h-2/3 overflow-scroll`}
        >
          {/* <form onSubmit={formik.handleSubmit}> */}
          <div className="flex justify-end w-full ">
            <button
              onClick={() => navigate("/list-all-projects")}
              className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
              aria-label="Close modal"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>

          <div className="flex flex-col gap-8">
            <h5
              className={`text-center text-2xl mb-6 ${
                i18n.language === "ar" ? "cairo" : "manrope"
              } `}
            >
              {t(
                "Conditions for submitting a charitable organization’s project"
              )}
            </h5>

            <div className="w-full">
              <h3
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === "ar"
                      ? tc?.contentArabic
                      : tc?.contentEnglish,
                }}
                className={`text-2xl mb-6 ${
                  i18n.language === "ar" ? "cairo" : "manrope"
                } `}
              ></h3>
            </div>
          </div>

          <ul className="text-lg	">
            <li className="flex items-center my-2 mt-4">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="mr-2 ml-2"
              />
              <label htmlFor="remember-me" className="text-lg my-2">
                {t('Please check "I agree to the terms".')}
              </label>
            </li>
          </ul>
          <button
            type="button"
            className="bg-green-500 text-white px-4 py-2 rounded-md "
            onClick={() =>
              rememberMe
                ? onClose()
                : toast(t('Please check "I agree to the terms".'))
            }
          >
            {t("Submitting")}
          </button>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default CreateProjectModal;
