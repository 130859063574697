import { useEffect, useRef, useState } from "react";
import Navbar from "../Components/Nav_Form/Navbar";
import { useLocation } from "react-router";
import { createPaymentApi } from "../Api";

function MakePaymentByReserve() {
   
    const formRef = useRef();
    const {state} = useLocation()
    const baseUrl = "https://al-moulqta-fe.vercel.app"
    async function createPayment(data) {
        let res = await createPaymentApi(data)

        console.log(res)


        
    }

    useEffect(() => {
        window.Moyasar.init({
            element: formRef.current,
            amount: 100*parseInt(state?.amount),
            currency: "SAR",
            description: state?.paymentDetails?.projectName ,
            publishable_api_key: "pk_test_eLUwVUWBownQV6cmDsr825hcK5bMj8fiBS59sbZj",
            callback_url: `${baseUrl}/payment-success`,
            methods: ["creditcard", "stcpay", "applepay"],
            on_completed: function (payment) {
                console.log(payment)
                // setPaymentId(payment?.id)
    //             let data = {
    //                 donor: donorId,
    //   project: projectId,
    //   amount: amount,
    //   currency: currency,
    //   description: description,
    //   status: status,
    //   paymentId: paymentId
    //             }
    let data = {
        donorId:state?.donorId,
        projectId:state?.paymentDetails?.projectId?._id,
        amount:state.amount,
        currency:"SAR",
        description:state?.paymentDetails?.projectId?.projectName,
        status:payment?.status,
        paymentId:payment?.id
    }
    console.log(data)
    // createPaymentApi(data)
                localStorage.setItem("payment",JSON.stringify(payment))
              },
                apple_pay: {
                    country: "SA",
                    label: "Awesome Cookie Store",
                    validate_merchant_url: "https://api.moyasar.com/v1/applepay/initiate"
                }
        });

    }, []);

    return (
        <div>
            <Navbar/>
            <div className="mb-5">


            <div className="mysr-form" ref={formRef} />
            </div>
        </div>
    );
}

export default MakePaymentByReserve;
