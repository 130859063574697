
import React, { useEffect, useState } from 'react';
import Navbar from '../Nav_Form/Navbar';
import { FaPen } from "react-icons/fa";
import { getProfile } from '../../Api';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdDocument } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Loader from "react-js-loader";
import ProfileInfo from './ProfileInfo';
import DemoProfileInfo from './DemoProfileInfo'
import { fetchlocalstorage } from '../../HelperFunctions';
import Thankyou from './Thankyou';
import i18n from 'i18next';
import Header from '../UI/Header';
import Footer from '../Footer/Footer';


function DemoOrganizationprofile() {
  console.log(i18n.language)
  const [userInfo, setUserInfo] = useState({})
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showThankYou, setShowThankYou] = useState(true);
  async function getProfileData() {
    let data = await getProfile()
    setUserInfo(data?.data?.charity)
    setLoading(true)
    // console.log(data.data.charity)
  }
  useEffect(() => {
    getProfileData()
  }, [])
  const openLink = (link) => {
    console.log(link)
    window.open(link, '_blank');
  };


  useEffect(() => {
    // console.log(location.pathname)
    // console.log(location.pathname)
    // console.log(location.state?.previousPath)
    // const shouldShowThankYou = location.pathname === '/organization-profile' && location.state?.previousPath === '/charity';

    // if (shouldShowThankYou) 
    //   setShowThankYou(true)
    //  else
    //  setShowThankYou(false)


    if (userInfo?.status === 'PENDING' || userInfo?.status === 'RESEND') {
      setShowThankYou(true);
    }
    else {
      setShowThankYou(false)
    }



    const timer = setTimeout(() => {
      setShowThankYou(false);
    }, 5000);

    return () => clearTimeout(timer);

  }, [userInfo]);

  return (
    // <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
    <div  >
      {/* <Navbar /> */}

      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>
      {/* <div className="ml-16 mt-8"><span className=''>Sync Products</span>/Product Listing</div> */}

      <div className={`flex justify-center items-center ${showThankYou ? "mt-10" : "mt-0"}`}>
        {showThankYou && <Thankyou />}
      </div>

      <div className="bg-white p-2 shadow sm:rounded-lg xs:m-[1rem] sm:m-[5rem] md:m-[7rem] md:mt-16"  >
        {loading ? <div className="  px-2 py-5 pb-20 sm:p-6">
          <div className=" ">
            <p className="leading-6 mb-[4rem] font-medium text-gray-900">
              {t('Profile Details')}
            </p>
            <div className='flex  '>
              <div className='w-24 rounded text-white text-lg xs:hidden sm:block' style={{
                width: "80.556px",
                height: "80.556px",
                marginRight: '26px'
              }}>
              <img src={userInfo.companyProfileImage} alt='Profile picture' className='object-cover w-full h-full'/>
              </div>
              <div className='w-3/4'>
                <div className='uppercase  flex'><p className={`text-black  font-bold mr-2  ${fetchlocalstorage("language") == "ar" ? "ml-2" : ""}`} >{t(`Name`)} : </p><p>{userInfo?.name}</p></div>
                <div className='flex item-center  '><p className={`mr-2 text-black  font-bold ${fetchlocalstorage("language") == "ar" ? "ml-4" : ""}`}><FaPhoneAlt /></p>     <p> {userInfo?.mobileNumber}</p></div>
                <div className='flex item-center  '><p className={`text-black  font-bold  mr-2 ${fetchlocalstorage("language") == "ar" ? "ml-2" : ""}`}>{t(`Status`)}: </p>      <p className={`${userInfo?.status === 'PENDING' ? 'text-orange-500' : userInfo?.status === 'APPROVED' ? 'text-green-500' : 'text-red-500'}`}>{t(userInfo?.status)}</p></div>
               {userInfo?.status!="APPROVED" && userInfo?.remark && <div className='flex item-center  '><p className={`text-black  font-bold  mr-2 ${fetchlocalstorage("language") == "ar" ? "ml-2" : ""}`}>{t(`Remark`)}: </p>      <p >{t(userInfo?.remark)}</p></div>}

              </div>
              {(userInfo?.status === "APPROVED" || userInfo?.status === "RESEND") && <div className='flex items-center justify-end flex-grow ' onClick={() => navigate("/update-charity", { state: { userInfo } })}> {/* Edit button at end */}
                <span className='flex bg-gray-300 px-4 py-2 text-black	justify-center align-center   rounded  cursor-pointer'>
                  {/* <button className='ml-auto mr-3' >{t(`Edit`)}</button> */}
                  <button className='ml-auto mr-3' >{t(`Edit`)}</button>

                  <span><FaPen /></span>
                </span>
              </div>}
            </div>
          </div>
          {/* <div className="mt-5 border-t border-gray-200 pt-5">
            <dl>
              <dt className="text-sm font-medium text-gray-500">
                Organization
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                Appllore Technologies
              </dd>
              <dt className="text-sm font-medium text-gray-500">
                Phone
              </dt>
              <dd className="mt-1 text-sm text-gray-900">


              </dd>
            </dl>
          </div> */}

          <DemoProfileInfo userInfo={userInfo} />


          {/* <hr /> */}



        </div> : <span> <Loader type="spinner-circle" bgColor={'black'} color={'black'} size={100} /></span>}
      </div>

      <Footer />

    </div >
  );
}

export default DemoOrganizationprofile;



