import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import { backendUrl } from '../../axios';
const CharityDashboard = () => {
    const {t}=useTranslation();
    const [data, setData] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (id) => {
        setOpenDropdown(openDropdown === id ? null : id);
    };

    useEffect(() => {
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NjNkZmE4MGI0YTI4ZjViMGEyMjJmZTMiLCJpYXQiOjE3MTk4MzY5ODksImV4cCI6MTc1MTM3Mjk4OX0.xDGAZYMKAGX_rt5bhQYESf7JFa4DQ-IXTS5ViOqAAD4';

        axios
            .get(`${backendUrl}/project/getAllProjects?page=1&limit=10&status=`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log({ res });
                setData(res.data.data);
            })
            .catch((error) => {
                console.error('Error fetching projects:', error);
            });
    }, []);

    return (
        <section className="w-full bg-[#eeece1]">
            <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
                <Header />
            </section>
            <table className="w-11/12 mx-auto text-center my-6 rounded-lg align-center divide-x text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 bg-white uppercase border-b">
                    <tr>
                        <th scope="col" className="px-6 py-3">{t('ID')}</th>
                        <th scope="col" className="px-6 py-3">{t('PROJECT NAME')}</th>
                        <th scope="col" className="px-6 py-3">{t('DOMAIN TYPE')}</th>
                        <th scope="col" className="px-6 py-3">{t('PROJECT GOAL')}</th>
                        <th scope="col" className="px-6 py-3">{t('PROJECT STATUS')}</th>
                        <th scope="col" className="px-6 py-3">{t('DONATION STATUS')}</th>
                        <th scope="col" className="px-6 py-3">{t('ACTIONS')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length ? data.map((item, id) => (
                        <tr key={`project-${id}`} className="bg-white">
                            <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap">{id + 1}</td>
                            <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">{item.projectName}</td>
                            <td className="px-6 py-4">{item?.domain?.name}</td>
                            <td className="px-6 py-4">{item.financialDetailsTotal}</td>
                            <td className="px-6 py-4">{t(item.status)}</td>
                            <td className="px-6 py-4">{item.status !== "LIVE" ? "" : t(item?.donationStatus)}</td>
                            <td className="px-6 py-4 cursor-pointer relative">
                                <div className="px-4 py-4 list-none w-4 h-4 text-center cursor-pointer" onClick={() => toggleDropdown(item._id)}>
                                    <div className="w-6 h-6 text-gray-500" >...</div>
                                </div>
                                {/* {openDropdown === item._id && (
                                    <div className="absolute z-50 mt-2 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg right-0">
                                        <div className="py-2 w-40">
                                            <Link
                                                to={{ pathname: "/project-detail" }}
                                                state={{ id: item._id, name: item?.domain?.name }}
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                View
                                            </Link>
                                            {!item?.domain?.isProjectBlocked && (
                                                (item.status === "DRAFT" || item.status === "INITIAL_NOTES" || item.status === "EXPERT_COMMENTED" || item.status === "WORKSHOP_COMPLETED") && (
                                                    <Link
                                                        to="/update-project"
                                                        state={{ projectDetail: item }}
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                        Edit
                                                    </Link>
                                                )
                                            )}
                                            <Link
                                                to="#"
                                                onClick={() => handleOpenModal(item._id, "Report")}
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                Upload Report
                                            </Link>
                                            {(item.status === "DRAFT" || item.status === "INITIAL_NOTES" || item.status === "EXPERT_COMMENTED" || item.status === "UNDER_MATURATION" || item.status === "WORKSHOP_COMPLETED") && (
                                                <Link
                                                    to="#"
                                                    onClick={() => sendForApproval(item._id,
                                                        item.status === "DRAFT" ? "NEW_PROJECT" :
                                                            item.status === "EXPERT_COMMENTED" ? "UNDER_MATURATION" :
                                                                item.status === "WORKSHOP_COMPLETED" ? "READY_FOR_EVALUATION" : "UNDER_REVIEW")}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Send For Approval
                                                </Link>
                                            )}
                                            {item.status === "LIVE" && (
                                                <Link
                                                    to="#"
                                                    onClick={() => sendForApproval(item._id, "COMPLETED")}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Complete donation
                                                </Link>
                                            )}
                                            {item.status === "COMPLETED" && (
                                                <Link
                                                    to="#"
                                                    onClick={() => handleOpenModal(item._id, "Attachment")}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Upload Attachment
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                )} */}

                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan="7" className="px-6 py-4 text-center">No data available</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <Footer />
        </section>
    );
};


export default CharityDashboard