import React from "react";
import { useTranslation } from "react-i18next";

const Itinerary = () => {
  const { t , i18n } = useTranslation();

  return (
    <section className="flex flex-col gap-8 w-full max-w-[80%]">
      <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
        <img
          loading="lazy"
          src="/images/icons/itinerary.svg"
          className="shrink-0 aspect-square w-[52px]"
          alt=""
        />
        <h1 className={`${i18n.language === 'ar' ? 'cairo' : 'manrope'} my-auto uppercase  font-semibold max-md:max-w-full`}>
          {t("About1.itinerary")}
        </h1>
      </div>
      <div className="w-full grid lg:grid-cols-19 gap-4 mt-4" dir="rtl" >
        {/* Replace col-span-4 with col-span-full for mobile */}
        <div className="w-full py-8 px-4 col-span-full md:col-span-4 justify-center text-lg leading-9 text-white bg-[#0A4D69] rounded-lgshadow">
          <p className="text-lg text-white font-normal leading-9 text-center ">
            {t("About1.card0")}
          </p>
        </div>
        {/* Arrow and content for card1 */}
        <div className="w-full flex flex-col items-center justify-center py-4">
          <img src="/images/icons/arrow-backward.svg" alt="" className="" />
        </div>
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className="text-[#0A4D69] text-center leading-9text-lg font-normal">
            {t("About1.card1")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            01
          </div>
          <div className="absolute bg-[#0A4D69] py-1 text-center px-2  right-0 bottom-0 rounded-br-lg text-white text-sm  ">
            {t("About1.ce")}
          </div>
        </div>
        {/* Arrow and content for card2 */}
        <div className="w-full flex flex-col items-center justify-center py-4 ">
          <img src="/images/icons/arrow-backward.svg" alt="" className="" />
        </div>
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className={` ${i18n.language === 'ar' ? 'cairo' : 'manrope'} text-[#0A4D69]  leading-9text-lg text-center font-normal`}>
            {t("About1.card2")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            02
          </div>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 bottom-0 text-center rounded-br-lg text-white text-sm  ">
            {t("About1.ce")}
          </div>
        </div>
        {/* Arrow and content for card3 */}
        <div className="w-full flex flex-col items-center justify-center py-4">
          <img src="/images/icons/arrow-backward.svg" alt="" className="" />
        </div>
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className="text-[#0A4D69] text-center leading-9text-lg font-normal">
            {t("About1.card3")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            03
          </div>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 text-center bottom-0 rounded-br-lg text-white text-sm  ">
            {t("About1.ce")}
          </div>
        </div>
      </div>
      <div className="w-full grid lg:grid-cols-19 mt-4" dir="rtl">
        <div className="w-full col-span-8"></div>
        <div className="w-full col-span-7"></div>
        <div className="w-full col-span-4 flex justify-center">
          <img src="/images/icons/arrow-down.svg" alt="" className="" />
        </div>
      </div>
      <div className="w-full grid lg:grid-cols-19 mt-4" dir="ltr">
        {/* Arrow and content for card4 */}
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className="text-[#0A4D69] text-center leading-9text-lg font-normal">
            {t("About1.card4")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            04
          </div>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 bottom-0 rounded-br-lg text-white text-sm  ">
            {t("About1.ce")}
          </div>
        </div>
        {/* Arrow and content for card5 */}
        <div className="w-full flex flex-col items-center justify-center py-4">
          <img src="/images/icons/arrow-forward.svg" alt="" className="" />
        </div>
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className="text-[#0A4D69] text-center leading-9text-lg font-normal">
            {t("About1.card5")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            05
          </div>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 bottom-0 rounded-br-lg text-white text-sm  ">
            {t("About1.ac")}
          </div>
        </div>
        {/* Arrow and content for card6 */}
        <div className="w-full flex flex-col items-center justify-center py-4">
          <img src="/images/icons/arrow-forward.svg" alt="" className="" />
        </div>
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className="text-[#0A4D69] text-center leading-9text-lg font-normal">
            {t("About1.card6")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            06
          </div>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 bottom-0 rounded-br-lg text-white text-sm  ">
            {t("About1.experts")}
          </div>
        </div>
        {/* Arrow and content for card7 */}
        <div className="w-full flex flex-col items-center justify-center py-4">
          <img src="/images/icons/arrow-forward.svg" alt="" className="" />
        </div>
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className="text-[#0A4D69] text-center leading-9text-lg font-normal">
            {t("About1.card7")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            07
          </div>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 bottom-0 rounded-br-lg text-white text-sm  ">
            {t("About1.ac")}
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-19 mt-4" dir="rtl">
        <div className="w-full col-span-4 flex justify-center py-4">
          <img src="/images/icons/arrow-down.svg" alt="" className="" />
        </div>
      </div>
      <div className="w-full grid grid-cols-19 mt-4" dir="rtl">
        {/* Arrow and content for card8 */}
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className="text-[#0A4D69] text-center leading-9text-lg font-normal">
            {t("About1.card8")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            08
          </div>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 bottom-0 rounded-br-lg text-white text-sm  ">
            {t("About1.ac")}
          </div>
        </div>
        {/* Arrow and content for card9 */}
        <div className="w-full flex flex-col items-center justify-center py-4">
          <img src="/images/icons/arrow-backward.svg" alt="" className="" />
        </div>
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className="text-[#0A4D69] text-center leading-9text-lg font-normal">
            {t("About1.card9")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            09
          </div>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 bottom-0 rounded-br-lg text-white text-sm  ">
            {t("About1.ac")}
          </div>
        </div>
        {/* Arrow and content for card10 */}
        <div className="w-full flex flex-col items-center justify-center py-4">
          <img src="/images/icons/arrow-backward.svg" alt="" className="" />
        </div>
        <div className="w-full py-8 col-span-full md:col-span-4 px-4 relative flex items-center justify-center bg-white rounded-lg shadow">
          <p className="text-[#0A4D69] text-center leading-9text-lg font-normal">
            {t("About1.card10")}
          </p>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 top-0 rounded-tr-lg text-white text-sm  ">
            10
          </div>
          <div className="absolute bg-[#0A4D69] py-1 px-2 right-0 bottom-0 rounded-br-lg text-white text-sm  ">
            {t("About1.ac")}
          </div>
        </div>
        {/* Arrow and content for card11 */}
        <div className="w-full flex flex-col items-center justify-center py-4">
          <img src="/images/icons/arrow-backward.svg" alt="" className="" />
        </div>
        <div className="w-full py-8 px-4 col-span-full md:col-span-4 justify-center text-lg leading-9 text-white bg-[#0A4D69] rounded-lgshadow">
          <p
            style={{
              fontFeatureSettings: "salt on",
            }}
            className="  text-lg text-white text-center font-normal leading-9"
          >
            {t("About1.card11")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Itinerary;
