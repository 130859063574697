import React from 'react'

import { Outlet } from 'react-router';
import Header from '../Components/UI/Header';

export default function AppLayout() {
  return (
    <div>
      {/* <Header/> */}
      <Outlet/>
    </div>
  )
}
