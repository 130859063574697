import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { updateReportApi } from '../Api';
import { useFormik } from 'formik';
import * as yup from "yup";
import toast from 'react-hot-toast';
import axios from 'axios';

const UploadProjectAttatchment = ({ onClose ,id}) => {
    const [reportFile, setReportFile] = useState(null);

    const {t} = useTranslation()
    const formik = useFormik({
        initialValues: {
            completedDocument:''
        },
        validationSchema: yup.object({
            completedDocument: yup.string().required(t('Completed document is required')),
         }),
        onSubmit: async (values) => {
            try {
                // console.log(values)
                let res = await updateReportApi(values,id)
                if (res.status === 200 || res.status === 201) {
          
                    toast.success(t(res?.data?.message), { id: "001" });
                      //if donar created successfully update isProfile true for furter validation
                      onClose()
                      formik.resetForm()
                     
                }
            } catch (error) {
                
            }
            // Handle form submission here
        },
    });
    const uploadImage =async (e,fieldType)=>{
        // Logic to upload the selected file
        if (e.target.files[0]) {
          const formData = new FormData();
          formData.append('file', e.target.files[0]);
          
          // Send formData to your backend API for file upload
          let data = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`, formData, {
              headers: {
                "Content-Type": 'multipart/form-data',
                 
              },
            });
           
          
            // Handle response
            formik.setFieldValue(fieldType,data.data.fileUrl)
             
            
          
        }
          }

  return (
    <div>
          <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white w-full max-w-md p-6 rounded-lg">
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                        aria-label="Close modal"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </button>
                </div>
                <h5>Upload any attachment</h5>
                <form onSubmit={formik.handleSubmit}>

                <div className="mt-4">
                    
                    <div className="mb-4">
                        <label htmlFor="completedDocument" className="block text-sm font-medium text-gray-700">Upload Attachment</label>
                        <input type="file" name="completedDocument" id="completedDocument" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Select file"
                            // {...formik.getFieldProps('completedDocument')}
                            onChange={(e) => uploadImage(e, "completedDocument")}


                        />

                        {formik.touched.completedDocument && formik.errors.completedDocument ? (
                            <p className="text-red-500">{formik.errors.completedDocument}</p>
                        ) : null}

                    </div>

                    <div className="mt-6">
                    <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        
                    >
                        Next
                    </button>
                </div>
                </div>
                </form>
            </div>
        </div>

    </div>
  )
}

export default UploadProjectAttatchment