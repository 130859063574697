import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../Nav_Form/Navbar';
import { useFormik } from 'formik';
import {  signinByOrgInitialValue } from '../../../Validations/Organization/initialValues';
import { signinValidationForOrg } from '../../../Validations/Organization';
import toast from 'react-hot-toast';
import {  loginConsultantApi, loginOrgApi } from '../../../Api';
import { useTranslation } from 'react-i18next';
import * as yup from "yup";

// import Navbar from './Nav_Form/Navbar';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading,setloading] = useState()
  const {t} = useTranslation()



   const location = useLocation()
   const {pathname} = location
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: signinByOrgInitialValue,
    validationSchema: yup.object({
      email: yup.string().email('Invalid email').required(<>{t(`Email is a required field`)}</>),
      password: yup.string().min(6, <>{t(`Password must be at least 6 characters`)}</>).required(<>{t(`Password`)}</>)  
    }),  
    onSubmit: async (values) => {
       
      const { email, password } = values;
      try {
        
        const res = await loginConsultantApi({ email, password ,role:"CONSULTANT"});
        // console.log(res)
        if (res.status === 200 || res.status === 201) {
          if (res?.data?.token === false) { 
            toast(t(res?.data?.message), { icon: "⚠️", id: "1102" });
          } else { 
            toast.success(t(res?.data?.message), { id: "001" });
            localStorage.setItem("token", res?.data?.token);
          localStorage.setItem("userDetails",JSON.stringify(res?.data?.user));


         
            
              navigate("/my-availability", { replace: true });
  
       
 

          }
        }
      } catch (error) { 
        toast.error(t(error?.response?.data?.message), { id: "err501" });
      }
 
    },
  });

// Because of the time boundation we are creating the same component for both Donor and charity

  return (
    <div className='relative'>
      <Navbar />
      <div className='flex justify-center items-center h-screen '>
        <form onSubmit={formik.handleSubmit}>

          <div className='flex flex-col border w-[500px] h-[650px] shadow-lg rounded-md items- px-8 py-20'>
            <div className='flex items-center mb-4'>
              <Link><img src='/login_image/login.svg' alt='' className='mb-4 mr-2' /> </Link>
              <span className='text-xl text-green-900 '>{t("Al-moultqa")}</span>
            </div>

            <h1 className='text-xl font-normal mb-4 my-3'>{t(`Welcome to Al-moultqa!`)} 👋</h1>
            <p className='mb-4'> {t(`Please sign in to your consultant account and start the adventure`)}</p>
            <div className='flex flex-col mb-4 w-full'>
              <input
                type='email'
                placeholder={t(`Please enter your email`)}
                {...formik.getFieldProps('email')}
                                // onChange={e => setEmail(e.target.value)}
                className='border border-gray-300 rounded-md p-2 mb-2 w-full'
              />
              {formik.touched.email && formik.errors.email ? (
                                    <p className="text-red-500">{formik.errors.email}</p>
                                ) : null}
              <input
              name='password'
                type='password'
                placeholder={t(`Please enter your password`)}
                // value={password}
                // onChange={e => setPassword(e.target.value)}
                {...formik.getFieldProps('password')}
                className='border border-gray-300 rounded-md p-2 mb-2 w-full'
              />
              {formik.touched.password && formik.errors.password ? (
                                    <p className="text-red-500">{formik.errors.password}</p>
                                ) : null}
              <div className='flex justify-between'>
              <div className='flex items-center mb-2'>
                <input
                  type='checkbox'
                  checked={rememberMe}
                  onChange={e => setRememberMe(e.target.checked)}
                  className='mr-2'
                />
                <label htmlFor='remember-me'>{t(`Remember Me`)}</label>
              </div>
              <p className='text-sm text-green-500 mr-2 cursor-pointer' onClick={()=>navigate('/forget-password')}>{t('Forget Password')}</p>
              </div>
             
              {/* {error && <p className='text-red-500'>{error}</p>} */}
            </div>
            <button
              className='bg-green-500 text-white px-4 py-2 rounded-md w-full'
              type='submit'
              // onClick={handleLogin}
            >
               {t(`Submit`)}
            </button>
            
            <p className='py-4'>
            {t(`New on our platform?`)} <Link to={"/signup-consultant"}>{t(`Create an account`)}</Link>
            </p>
          </div>
        </form>
      </div>
      <img
        src='/login_image/login_image2.svg'
        alt=''
        className='absolute top-6 right-[30px] mt-8'
      />
    </div>
  );
}
