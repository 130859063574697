import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { loginOrgApi } from "../../Api";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import LoginNavbar from "../LoginPage/LoginNavbar";
import { LiaQuestionCircle } from "react-icons/lia";

// import Navbar from './Nav_Form/Navbar';

export default function LoginPage() {
  const { t, i18n } = useTranslation();

  const currentLanguage = localStorage.getItem("language") || "en";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setloading] = useState();
  const [signinType, setSigninType] = useState("organization");

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email")
        .required(<>{t(`Email is a required field`)}</>),
      password: yup
        .string()
        .min(6, <>{t(`Password must be at least 6 characters`)}</>)
        .required(<>{t(`Password`)}</>),
    }),
    onSubmit: async (values) => {
      let role = pathname == "/login" ? "DONOR" : "CHARITY";
      const { email, password } = values;
      try {
        const res = await loginOrgApi({ email, password, role });
        console.log("logedIn User", res?.data?.user);
        if (res.status === 200 || res.status === 201) {
          if (res?.data?.token === false) {
            toast(t(res?.data?.message), { icon: "⚠️", id: "1102" });
          } else {
            toast.success(t(res?.data?.message), { id: "001" });
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem(
              "userDetails",
              JSON.stringify(res?.data?.user)
            );
            // console.log(location)
            if (location?.state?.donate) {
              navigate("/causes-details", {
                state: { item: location?.state?.item },
              });
              return;
            }
            if (res?.data?.user?.role == "DONOR") {
              if (!res?.data?.user.isProfileCreated) {
                navigate("/donor", { token: res?.data?.token });
              } else {
                navigate("/my-bookings", { replace: true });
              }
            } else {
              if (!res?.data?.user.isProfileCreated) {
                navigate("/charity", { token: res?.data?.token });
              } else {
                // navigate("/my-analytics", { replace: true });
                navigate(`/my-analytics`, { replace: true });
              }
            }
          }
        }
      } catch (error) {
        // console.log("this is toast message please fix this",)
        toast.error(t(error?.response?.data?.message), { id: "err501" });
      }
    },
  });

  return (
    <section
      className={`w-full h-[100vh]  bg-cover ${
        i18n.language === "ar" ? "cairo" : "manrope"
      } `}
      style={{
        backgroundImage: "url('/images/loginImg.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section className="h-[11vh] flex flex-col z-40 relative">
        <LoginNavbar />
      </section>

      <div className="w-[100%] px-4 sm:px-24 mt-16">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-[500px] min-h-[600px]">
          <div className="mb-8">
            <h1
              className={`text-2xl font-normal mt-4 text-[#101828] ${
                i18n.language === "ar" ? "cairo" : "manrope"
              }`}
            >
              {t("Login1.Welcome to Al-Moultqa")}
            </h1>
            <p className="text-[#084F6799] mt-2">
              {/* {t(`Login1.Please sign in to your  account`)} */}
              {t(`Please sign in to your ${signinType} account`)}
            </p>
          </div>
          <form className="text-[#084F6799]" onSubmit={formik.handleSubmit}>
            <p className="text-[#084F6799] mb-4">{t("Login1.Login As")}</p>
            <div className="flex items-center gap-4 mb-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="loginAs"
                  value="donor"
                  className="form-radio"
                  onChange={() => setSigninType("donor")}
                />
                <span className="mx-2">{t("Login1.Donor")}</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="loginAs"
                  value="organization"
                  className="form-radio"
                  defaultChecked
                  onChange={() => setSigninType("organization")}
                />
                <span className="mx-2">{t("Login1.Organization")}</span>
              </label>
            </div>
            <div className="mb-4 space-y-1">
              <label className="" htmlFor="email">
                {t("Login1.Enter Email")}
              </label>
              <input
                type="email"
                {...formik.getFieldProps("email")}
                placeholder={t("Enter the email")}
                name="email"
                className="w-full p-3 border border-gray-300 placeholder:text-[#084F6799] rounded-lg"
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-500">{formik.errors.email}</p>
              ) : null}
            </div>
            <div className="mb-4 space-y-1">
              <label className="" htmlFor="password">
                {t("Login1.Enter Password")}
              </label>
              <input
                type="password"
                {...formik.getFieldProps("password")}
                placeholder={t("Enter the password")}
                name="password"
                className="w-full p-3 border border-gray-300 rounded-lg placeholder:text-[#084F6799]"
              />
              {formik.touched.password && formik.errors.password ? (
                <p className="text-red-500">{formik.errors.password}</p>
              ) : null}
            </div>
            <div className="flex items-center justify-between mb-6">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <span className="mx-2 text-[#0A4D69]">
                  {t("Login1.Remember Me")}
                </span>
              </label>
              <a
                href="#"
                className="text-sm text-[#0A4D69]"
                onClick={() => navigate("/forget-password")}
              >
                {t("Login1.Forget Password?")}
              </a>
            </div>
            <button
              type="submit"
              className="w-full p-3 bg-[#0A4D69] text-white rounded-lg"
            >
              {t("Login1.Login")}
            </button>
          </form>
          <p className="text-center mt-4 text-[#084F6799]">
            {t("Login1.Don't have an account?")}{" "}
            {signinType === "organization" ? (
              <a
                onClick={() => navigate("/register-by-org")}
                className="underline cursor-pointer"
              >
                {t("Login1.Create an account")}
              </a>
            ) : (
              <a
                onClick={() => navigate("/register")}
                className="underline cursor-pointer"
              >
                {t("Login1.Create an account")}
              </a>
            )}
          </p>
        </div>
        <button
          className={`bg-white rounded-3xl shadow-lg flex items-center gap-2 p-[10px] ${
            currentLanguage === "en" ? "ml-auto" : "mr-auto"
          } text-[#0A4D69] text-sm `}
        >
          <LiaQuestionCircle className="text-[#0A4D69]" />
          {t("Login1.Support")}
        </button>
      </div>
    </section>
  );
}
