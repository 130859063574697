import React, { useState, useEffect } from "react";
import { isAuth } from "../../HelperFunctions";
import { t } from "i18next";

const ChatSidebar = ({ search, setSearch, users, setSelectedChat }) => {
  let getAuth = isAuth();
  let getUserInfo = localStorage.getItem("userDetails");
  getUserInfo = JSON.parse(getUserInfo);

  const id = getUserInfo._id;
  const [userId, setUserId] = useState(id);

  const handleChatClick = (type, user) => {
    console.log(user, "user");

    setSelectedChat(user);
    // dispatch(selectChat(id))
    // setActive({ type, id })
    // if (!mdAbove) {
    //     handleLeftSidebarToggle()
    // }
  };


  return (
    <div className="  flex flex-col w-2/5 border-r-2 overflow-y-scroll h-[80vh] ">
      {/* search compt */}
      <div className="border-b-2 py-4 px-2">
        <input
          type="text"
          placeholder="search chatting"
          className="py-2 px-2 border-2 border-gray-200 rounded-2xl w-full"
          onChange={(e) => setSearch(e.target.value)}s
        />
      </div>
      {users.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full">
          <div className="text-gray-500 text-2xl font-semibold">
            {t('No users found')}
          </div>
        </div>
      ) : (
        <>
          {users
            .filter((user) => user?._id !== userId)
            .map((user) => {
              return (
                <>
               
                  <div key={user?._id} 
                   onClick={() => handleChatClick('chat', user)}
                  className={`flex cursor-pointer flex-row py-4 px-2 items-center border-b-2 border-l-4
                  ${user?.newMessage && "border-blue-400"} `}>
                    {/* <div className="w-1/4">
                      <img
                        src={user?.avatar}
                        className="object-cover h-12 w-12 rounded-full"
                        alt=""
                      />
                    </div> */}
                    <div className="w-full">
                      <div className="text-lg font-semibold">{user?.name}</div>
                      <span className="text-gray-500">
                       {user?.role}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
        </>
      )}
      {/* end search compt */}
      {/* user list */}

      {/* end user list */}
    </div>
  );
};

export default ChatSidebar;
