import React, { useState } from "react";
import { Link } from "react-router-dom";
import Causeareanav from "./Causeareanav";
import Aboutfootertoparea2 from "./Aboutfootertoparea2";
import { useTranslation } from "react-i18next";
import { fetchlocalstorage } from "../HelperFunctions";

export default function CauseBreadCrumb() {
  const { t } = useTranslation();

  return (
    //breadcrumb area start
    <>
      <section className="breadcrumb_area breadcrumb_overlay">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="breadcrumb_section">
                <ul className="breadcrumb-list volunteer_breadcrumb">
                  <li className="bhas_border text-black">
                    <Link href="/" className="text-black">
                      {t("Home")}
                    </Link>
                  </li>
                  <li
                    className={`${
                      fetchlocalstorage("language") == "ar"
                        ? "mr-4 !important"
                        : ""
                    }`}
                  >
                    <span className="active">{t(`Causes`)}</span>
                  </li>
                </ul>
                <div className="breadcrumb_title">
                  <h2 className="text-black">{t(`Recent Causes`)}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Causeareanav />

      <Aboutfootertoparea2 />
    </>

    // breadcrumb area end
  );
}
// {/* <Causeareanav/> */}
