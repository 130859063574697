import React from 'react'
import Header from './Header';
import Footer from '../Footer/Footer';
import AboutNumberAchivements from '../AboutPage/aboutNumberAchivements';
import { useTranslation } from 'react-i18next';

export const AboutNumbers = () => {
    const { t , i18n } = useTranslation();

  return (
    <section className='w-full bg-[#eeece1]'>
    <section className="bg-[#0A4D69] h-[50vh] flex flex-col  relative">
        <Header />
    </section>
    <div className="w-[100%]  bg-[#EEEDE5]  " style={{marginTop:'-250px'}}>
        <div className="w-[80%] mx-auto flex flex-col gap-14 relative z-80">
            <h3
                className={`${i18n.language === 'ar' ? 'cairo' : 'manrope'} text-6xl uppercase text-center text-white tracking-wide z-100 max-sm:text-4xl`}
                style={{
                    zIndex: "100",
                }}
            >
                {t("Navbar.aboutNumbers")}
            </h3>
            <AboutNumberAchivements />
        </div>
        <div className="w-full flex flex-col items-center mt-12 gap-24">
           
            <Footer />
        </div>
    </div>

</section>
  )
}
