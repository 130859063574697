import React from 'react'
import { useNavigate } from 'react-router'
import { fetchlocalstorage } from '../HelperFunctions'
import { createPaymentApi } from '../Api'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const ViewPdfModal = ({onClose,causeDetail,amount,donationStatus}) => {
    
     const navigate = useNavigate()
     console.log(causeDetail)
    const {t} = useTranslation()
   async  function reserveDonation(causeDetail,id,amount) {
        let data = {
            donorId:id,
            projectId:causeDetail?._id,
            amount:amount,
            currency:"SAR",
            description:causeDetail?.projectName,
            status:"reserve"
        }
        try {
            
    let res = await createPaymentApi(data)
            console.log(res)
            if(res.status == 200){
                toast.success(t(res?.data?.message))
                onClose(false)
            }
        } catch (error) {
            console.log(error)
        }

     }
   return (
    <div>
         <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white w-full max-w-md p-6 rounded-lg">
                <div className="flex justify-end">
                    <button
                        onClick={()=>onClose(false)}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                        aria-label="Close modal"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </button>
                </div>
                <h5 className='text-[1.5rem] mb-2'>{t('Donation Details')}</h5>
                {/* <div>
        <embed src='https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf' type="application/pdf" width="100%" height="600px" />
      </div> */}
        <div>
      <div className='flex  items-center'>
        
        <h4 className='mr-2'>{t(`Donation amount`)} : </h4>
        <p>{amount}</p>
      </div>
      <div className='flex  items-center'>
        <h4 className='mr-2'>{t(`Cause`)} : </h4>
        <p>{causeDetail?.projectName}</p>
      </div>

        </div>
              <div>
               {
                donationStatus=="Donation"?
               <button onClick={()=>navigate("/make-payment",{state:{paymentDetails:causeDetail,donorId:JSON.parse(fetchlocalstorage('userDetails'))._id,amount}})} className='bg-project_bg_image_color p-2 shadow  mt-2 rounded-lg text-white '>{t(`Next`)}</button>
               :
               <button onClick={()=> reserveDonation(causeDetail,JSON.parse(fetchlocalstorage('userDetails'))._id,amount) } className='bg-project_bg_image_color p-2 shadow  mt-2 rounded-lg text-white '>{t(`Reserve Donation`)}</button>
            }

              </div>
            </div>
        </div>
    </div>
  )
}

export default ViewPdfModal