import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { json, useLocation, useNavigate } from "react-router";
import { organizationDetailsInitialValues } from "../../../Validations/Organization/initialValues";
import { organizationDetailsValidation } from "../../../Validations/Organization";
import { callDomainForOrg, getCities, getRegions } from "../../../Api";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import axios from "axios";
import { fetchlocalstorage } from "../../../HelperFunctions";
import DatePicker from "@deskpro/react-datepicker-hijri";
import moment from "moment-hijri";
import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";
import CreatableSelect from "react-select/creatable";
import InputValue from "../../../pages/InputValue";
import toast from "react-hot-toast";
import i18n from 'i18next';
import HijriDatePicker from "../../HijriDatePicker";
const customComponents = {
  DropdownIndicator: null,
  Menu: () => null,
};

// export default () => ;

moment.locale("ar");

const DemoOrganizationDetails = ({
  onSubmit,
  CharityProfile,
  setEstablishDiff,
  establishDiff,
}) => {
  const hijriDate = moment().format("iYYYY/iM/iD");
  const initialDate = moment(hijriDate, "iYYYY/iM/iD");
  const [checked, setChecked] = useState();
  const [saudiRegions, setSaudiRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [errorState, setErrorstate] = useState(false);
  const [newinput, setInput] = useState("");
  const [item, setItems] = useState();
  console.log("items", item);
  console.log({ CharityProfile });
  const [domain, setDomain] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const handleUpdate = (newItems) => {
    setItems(newItems);
  };

  //  const saudiRegions = [
  //   "Al Riyadh",
  //   "Eastern Region",
  //   "Mecca Al Mokarama",
  //   "Al Madina Al Moawra",
  //   "Al Qassim",
  //   "Assir",
  //   "Tabouk",
  //   "Hail",
  //   "Northern Borders",
  //   "Jazan",
  //   "Najran",
  //   "Al Baha",
  //   "Al Jouf",
  // ];
  //  const cities = [
  //   "Riyadh",
  //   "Jeddah",
  //   "Mecca",
  //   "Medina",
  //   "Dammam",
  //   "Ta'if",
  //   "Tabuk",
  //   "Buraydah",
  //   "Khobar",
  //   "Khamis Mushait",
  //   "Najran",
  //   "Hail",
  //   "Yanbu",
  //   "Abha",
  //   "Jizan",
  // ];

  const licensingAuthorities = [
    "National Center for Non-Profit Sector Support",
    "General Authority for Endowments",
    "Ministry of Human Resources and Social Development",
    "Ministry of Transport",
    "Ministry of Justice",
    "Ministry of Energy",
    "Ministry of Industry and Mineral Resources",
    "Ministry of Health",
    "Ministry of Municipal and Rural Affairs and Housing",
    "Ministry of Tourism",
    "Ministry of Sports",
    "Ministry of Interior",
    "Ministry of Education",
    "Ministry of Commerce and Investment",
    "Ministry of Environment, Water and Agriculture",
    "Ministry of Economy and Planning",
    "Ministry of Media",
    "Ministry of Communications and Information Technology",
    "Saudi Data and Artificial Intelligence Authority",
    "General Authority for Exhibitions and Conferences",
    "Others",
  ];
  const saudiBanks = [
    "Saudi National Bank",
    "Al Rajhi Bank",
    "Riyad Bank",
    "Saudi Awwal Bank",
    "Arab National Bank",
    "alinma bank",
    "Banque Saudi Fransi",
    "Saudi Investment Bank",
    "Bank AlJazira",
    "Bank AlBilad",
    "Gulf International Bank Saudi Arabia (GIB-SA)",
    "STC bank",
    "Vision Bank",
    "Emirates NBD",
    "National Bank of Bahrain (NBB)",
    "National Bank of Kuwait (NBK)",
    "Muscat Bank",
    "Deutsche Bank",
    "BNP Paribas",
    "J.P. Morgan Chase N.A",
    "National Bank Of Pakistan (NBP)",
    "T.C.ZIRAAT BANKASI A.S.",
    "Industrial and Commercial Bank of China (ICBC)",
    "Qatar National Bank",
    "MUFG Bank, Ltd.",
    "First Abu Dhabi Bank",
    "UBS AG Bank (Previously Credit Suisse)",
    "Standard Chartered Bank",
    "National Bank of Iraq",
    "Trade bank of Iraq",
    "Bank of China Limited",
    "Banque Misr",
    "National Bank of Egypt",
    "Sohar International Bank",
    "D360 Bank",
    "Bank of Jordan",
    "Abu Dhabi Commercial Bank",
  ];
  // const saudiRegionsOptions = saudiRegions.map((region) => ({
  //   value: region,
  //   label: region,
  // }));
  // console.log(saudiRegions)
  const nineDigitRegex = /^\d{9}$/;
  const wordCount = (str) => {
    return str.trim().split(/\s+/).length;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      domain: [],
      countryCode: "+966",
      mobileNumber: "",
      landLineNumber: "",
      faxNumber: "",
      ibanNumber: "",
      email: "",
      website: "",
      numberOfBoardDirector: "",
      bankName: "",
      address: "",
      googleMapLink: "",
      dateOfEstablishment: initialDate,
      organizationLicense: "",
      actualStartDateofOrganizationActivity: initialDate,
      mission: "",
      vision: "",
      objectives: [""],
      targetedAudience: "",
      executiveManagerName: "",
      executiveManagerNumber: "",
      licenseNumber: "",
      licenseAuthority: "",
      licenseDate: initialDate,
      licenseExpiryDate: initialDate,
      region: "",
      city: "",
      // accountNumber: "",
      //registrationCertificateNumber: "",
      // city: "",
      district: "",
      street: "",
      copyOfTheOrganizationalStructure: "",
      copyOfTheBoardOfDirectorsAppointment: "",
      agentName: "",
      agentMobileNumber: "",
      agentEmail: "",
      letterOfAuthorization: "",
      updatedBankStatement: "",
      copyOfBudgets: "",
      participationLetter: "",
      copyOfTheBoardOfDirectorsDegree: "",
      companyProfileImage: "",
      establishDiff: ""
    },
    validationSchema: yup.object({
      name: yup.string().required(<>{t("Name is required")}</>),
      domain: yup
        .array()
        // Specify that the array contains strings
        .min(1, <>{t("Select at least one option")}</>) // Ensu/re at least one item is selected
        .required(<>{t("Organization Field is required")}</>),
      mobileNumber: yup
        .string()
        .matches(nineDigitRegex, t("Mobile Number Must be nine digits"))
        .required(<>{t("Mobile number is required")}</>),
      landLineNumber: yup
        .string()
        .matches(nineDigitRegex, t("Landline Number Must be nine digits")),
      faxNumber: yup.number().positive().integer(),
      ibanNumber: yup
        .number()
        .positive()
        .integer()
        .required(<>{t("IBAN number is required")}</>),
      email: yup.string().email(<>{t("Invalid email")}</>),
      region: yup.mixed().test(
        'is-string-or-object',
        'Region must be a string or a stringify-able object',
        function (value) {
          if (typeof value === 'string') return true; // Allow string type
          if (typeof value === 'object' && value !== null) {
            try {
              JSON.stringify(value); // Ensure object can be stringified
              return true;
            } catch (e) {
              return false;
            }
          }
          return false;
        }
      ).required(<>{t("Region is required")}</>),
      city: yup.string().required(t("City is required")) || yup.object().required(t("City is required")),
      copyOfBudgets: yup.string()
        .test(
          'is-required-based-on-establishDiff',
          "Copy of Budget required",
          function (value) {
            // const { establishDiff } = this.parent; // Access establishDiff from the form values
            if (establishDiff >= 2) {
              return !!value; // value must be present (non-empty)
            }
            return true; // value is not required
          }
        ),
      website: yup
        .string()
        .matches(
          /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
          t("Invalid URL")
        )
        .required(<>{t("Website URL is required")}</>),

      numberOfBoardDirector: yup
        .number()
        .positive()
        .integer()
        .required(<>{t("No of Board of Director member is required")}</>),
      bankName: yup.string().required(<>{t("Bank name is required")}</>),
      address: yup.string().required(<>{t("Address is required")}</>),
      googleMapLink: yup
        .string()
        .required(<>{t("Google map link is required")}</>),
      dateOfEstablishment: yup
        .date()
        .required(t("Date of establishment is required ")),
      actualStartDateofOrganizationActivity: yup.date(),
      organizationLicense: yup
        .string()
        .required(t("Organization license is required")),
      //accountNumber :yup.string().required("Account Number is required"),
      //registrationCertificateNumber :yup.string().required(t("Certificate Number is required")),

      district: yup.string().required(t("District is required")),
      street: yup.string().required(t("Street is required")),

      mission: yup
        .string()
        .required(<>{t("Mission is required")}</>)
        .max(300, <>{t("The entity's message should not exceed 300 characters")}</>),

      vision: yup
        .string()
        .required(<>{t("Vision is required")}</>)
        .max(300, <>{t("The entity's vision should not exceed 300 characters")}</>),
      objectives: yup.array()
        .of(
          yup.string()
            .required(<>{t("Objectives cannot be empty")}</>)
            .min(1, <>{t("Objectives cannot be empty")}</>)
        )
        .max(5, <>{t("Maximum 5 objectives allowed")}</>),
      targetedAudience: yup
        .string()
        .required(<>{t("Target audience is required")}</>),
      executiveManagerName: yup
        .string()
        .required(<>{t("Executive manager name is required")}</>),
      executiveManagerNumber: yup
        .string()
        .matches(
          nineDigitRegex,
          t("Executive manager number must be nine digits")
        )
        .required(<>{t("Executive manager no is required")}</>),
      licenseAuthority: yup
        .string()
        .required(<>{t("License Authority is required")}</>),
      licenseNumber: yup.string().required(t("License number is required")),
      licenseDate: yup.date().required(t("License established date")),
      licenseExpiryDate: yup
        .date()
        .required(t("License expiry date is required")),
      copyOfTheOrganizationalStructure: yup.string().required(t("Copy of Organizational structure is required")),
      copyOfTheBoardOfDirectorsAppointment: yup.string().required(t("Copy of Board of Director Appointment is required")),
      agentName: yup.string().required(t("Agent Name is required")),
      agentMobileNumber: yup.number().positive().integer().required(t("Agent Mobile Number is required")),
      agentEmail: yup.string().email("Invalid email").required(t("Agent Email is required")),
      letterOfAuthorization: yup.string().required(t('Letter of Authorization is required')),
      updatedBankStatement: yup.string().required(t("Bank Statement is required")),
      participationLetter: yup.string().required(t("Participation letter from the organization is required")),
      copyOfTheBoardOfDirectorsDegree: yup.string().required(t('Copy of the Director,s Appointment Degree is required')),
      companyProfileImage: yup.string(),
    }),
    onSubmit: async (values) => {
      console.log("clicked");
      if (!checked) {
        toast.error(<>{t("Please check I confirm all information is accurate")}</>);
        // toast.error(<>{t("Please check the Information")}</>);
        return;
      }
      if (newinput.length > 0) {
        formik.values.licenseAuthority = newinput;
      }


      // formik.values.dateOfEstablishment =
      //   values.dateOfEstablishment?.format("YYYY-MM-DD");
      // formik.values.actualStartDateofOrganizationActivity =
      //   values.actualStartDateofOrganizationActivity?.format("YYYY-MM-DD");
      // formik.values.licenseDate = values.licenseDate?.format("YYYY-MM-DD");
      // formik.values.licenseExpiryDate =
      //   values.licenseExpiryDate?.format("YYYY-MM-DD");

      console.log("values in form 1", values);

      onSubmit(values);
    },
  });

  console.log(formik);

  function checkdiff() {
    const date2 = moment(formik.values.actualStartDateofOrganizationActivity);
    const date1 = moment(formik.values.dateOfEstablishment);
    const differenceInYears = date2.diff(date1, "years", true);
    console.log('date1', 'date2', date1, date2.diff(date1, "years", true));
    setEstablishDiff(differenceInYears);
    console.log("establishDiff", establishDiff)
  }
  async function callDomainType(params) {
    try {
      const data = await callDomainForOrg();
      const newData = data?.data?.domains.map((domain) => ({
        value: domain?._id,
        label: domain?.name,
      }));
      setDomain(newData);
    } catch (error) {
      console.log(error);
    }
  }
  // console.log(REACT_APP_API_BASE_URL)
  useEffect(() => {
    async function getAllRegions() {
      try {
        let data = await getRegions();
        console.log(data?.data?.data)
        setSaudiRegions(data?.data?.data);
      } catch (error) {
        console.error('Failed to fetch regions:', error);
      }
    }
    getAllRegions();
  }, []);

  useEffect(() => {
    callDomainType();
    let user = JSON.parse(localStorage.getItem("userDetails"));
    formik.setFieldValue("email", user.email);
    // formik.setFieldValue("region",CharityProfile.region.name)
    // console.log(CharityProfile)
    if (Object.keys(CharityProfile).length !== 0) {
      const filteredData = {};

      Object.keys(organizationDetailsInitialValues).forEach((key) => {
        if (CharityProfile.hasOwnProperty(key)) {
          filteredData[key] = CharityProfile[key];
          if (CharityProfile[key] == null) {
            filteredData[key] = "";
          }
        }
      });
      //  console.log(filteredData.region.name)
      //  console.log(filteredData.city.cityName)
      //  console.log("path",location.pathname)
      //  console.log(CharityProfile.region.name)
      // Set filtered data as form values
      // console.log(filteredData)
      formik.setValues(filteredData);
      const objectivedata = filteredData.objectives;
      formik.setFieldValue("objectives", objectivedata);

      formik.setFieldValue(
        "dateOfEstablishment",
        moment(filteredData?.dateOfEstablishment)
      );
      formik.setFieldValue(
        "actualStartDateofOrganizationActivity",
        moment(filteredData?.actualStartDateofOrganizationActivity)
      );
      formik.setFieldValue("licenseDate", moment(filteredData?.licenseDate));
      formik.setFieldValue(
        "licenseExpiryDate",
        moment(filteredData?.licenseExpiryDate)
      );
      formik.setFieldValue('ibanNumber', filteredData.ibanNumber.slice(2))
      formik.setFieldValue("domain", filteredData?.domain?.map(el => el?._id));
      // formik.setFieldValue("city",CharityProfile?.city.cityName);
      // setCities([{_id:CharityProfile?.city?._id,cityName:CharityProfile?.city?.cityName}])
      formik.setFieldValue("city", filteredData?.city?._id);
      // formik.setFieldValue("region",filteredData?.region.name);
      // console.log('city',filteredData?.region?.name);


    }
  }, []);

  const handleAddItem = () => {
    if (formik.values.objectives) {
      const s = formik.values.objectives?.filter((item) =>
        item !== ''
      );

      formik.setFieldValue("objectives", ["", ...s]);
    }


  };
  const handleRemoveItem = (index) => {
    const objectives = formik.values.objectives.filter((_, i) => i !== index);
    formik.setFieldValue("objectives", objectives);
  };

  // useEffect(()=>{
  //   if(formik.values.domain.length){

  //   }
  // console.log(formik)
  // },[formik.values.domain])
  const handleFileChange = async (event, fieldType) => {
    const inputElement = event.target;
    const selectedFile = inputElement.files[0];
  
    if (selectedFile) {
      const isPdf = selectedFile.type === 'application/pdf';
      const isLessThan5MB = selectedFile.size < 5 * 1024 * 1024; // 5MB in bytes
  
      // Validate file type
      if (!isPdf) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldType]: 'Only PDF files are allowed.',
        }));
        setFiles((prevFiles) => ({
          ...prevFiles,
          [fieldType]: null,
        }));
  
        // Clear the file input
        inputElement.value = null;
        return;
      }
  
      // Validate file size
      if (!isLessThan5MB) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldType]: 'File size should be less than 5MB.',
        }));
        setFiles((prevFiles) => ({
          ...prevFiles,
          [fieldType]: null,
        }));
  
        // Clear the file input
        inputElement.value = null;
        return;
      }
  
      // Clear errors and set the file in state
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldType]: null,
      }));
      setFiles((prevFiles) => ({
        ...prevFiles,
        [fieldType]: selectedFile,
      }));
  
      // Create FormData and make the API request
      const formData = new FormData();
      formData.append("file", selectedFile);
  
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        // Update the field value with the uploaded file URL
        formik.setFieldValue(fieldType, response?.data?.fileUrl);
  
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldType]: 'File upload failed. Please try again.',
        }));
        // Clear the file input if the upload fails
        inputElement.value = null;
      }
    }
  };
  
  

  const uploadImage = async (e, fieldType) => {
    const inputElement = e.target;
    const selectedFile = inputElement.files[0];
  
    if (selectedFile) {
      const isJpgOrPng =
        selectedFile.type === 'image/jpeg' ||
        selectedFile.type === 'image/png';
      const isLessThan5MB = selectedFile.size < 5 * 1024 * 1024; // 5MB in bytes
  
      if (!isJpgOrPng) {
        alert('Only JPG and PNG files are allowed.');
        // Clear the file input
        inputElement.value = null;
        return;
      }
  
      if (!isLessThan5MB) {
        alert('File size should be less than 5MB.');
        // Clear the file input
        inputElement.value = null;
        return;
      }
  
      const image = new Image();
      image.src = URL.createObjectURL(selectedFile);
  
      image.onload = async () => {
        const { width, height } = image;
        const isAspectRatioValid = width === height;
  
        if (!isAspectRatioValid) {
          alert('The image must have a 1:1 aspect ratio (equal width and height).');
          // Clear the file input
          inputElement.value = null;
          return;
        }
  
        // If all validations pass, proceed with the upload
        const formData = new FormData();
        formData.append("file", selectedFile);
  
        try {
          let data = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
  
          // Handle response and update the form field
          formik.setFieldValue(fieldType, data?.data?.fileUrl);
        } catch (error) {
          alert('File upload failed. Please try again.');
          // Clear the file input if the upload fails
          inputElement.value = null;
        }
      };
  
      image.onerror = () => {
        alert('Invalid image file.');
        // Clear the file input in case of an invalid image
        inputElement.value = null;
      };
    }
  };
  

  // console.log(formik)
  // console.log(domain)
  const today = new Date().toISOString().split("T")[0];

  // const [value, setValue] = useState(initialDate); // Initialize with the moment object
  const handleDateChange = (e, data) => {
    // console.log(date.format("iYYYY/iM/iD")); // Log the selected date in Hijri format
    formik.setFieldValue(data, e);
    // setValue(e?.format("iYYYY-iM-iD"));
  };
  console.log("formicvalues", formik.values);

  async function getAllCities(id) {
    try {
      let data = await getCities(id)
      console.log(data?.data?.data)
      setCities(data?.data?.data)

    } catch (error) {
      console.error(error)
    }

  }

  const handleRegionChange = (e) => {

    if (e.target.value === "Select") {
      // Handle the case when "Select" is chosen
      formik.setFieldValue('region', '');
      formik.setFieldValue('city', '');
      setCities([]); // Clear the cities
      return;
    }
    // console.log(e)
    // console.log(e.target.value)

    const regionId = JSON.parse(e.target.value);
    // console.log(regionId)
    formik.setFieldValue('region', regionId._id);
    formik.setFieldValue('city', '');
    getAllCities(regionId.areaId);

  };

  useEffect(() => {
    if (CharityProfile?.region?.areaId) {
      getAllCities(CharityProfile?.region?.areaId)
    }
  }, [])


  const getSelectedOptions = (selectedValues) => {
    return selectedValues.map((value) => {
      return domain.find(option => option.value === value);
    });
  };
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState({});





  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1  gap-4">
          {/* first section */}
          <div className="w-full ">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Main Information")}
            </p>
            <div className="pb-3 w-full _Charity">
              {/* first row  */}
              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%] ">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Organization name`)}
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter Name")}
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <p className="text-red-500">{formik.errors.name}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="domain"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Organization Field`)}
                  </label>
                  <Select
                    id="domain"
                    name="domain"
                    isMulti
                    options={domain}
                    placeholder={t("Select")}
                    className="mt-1 block w-full"
                    classNamePrefix="react-select"
                    // value={formik.values.domain}
                    value={getSelectedOptions(formik.values.domain)}

                    // onChange={(value) => formik.setFieldValue("domain", value)}
                    onChange={(value) => formik.setFieldValue("domain", value.map(option => option.value))}

                  // onBlur={() => formik.setFieldTouched('domain', true)}
                  />

                  {formik.touched.domain && formik.errors.domain ? (
                    <p className="text-red-500">{formik.errors.domain}</p>
                  ) : null}
                </div>
              </div>

              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%] w-[50">
                  <label
                    htmlFor="mission"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Mission`)}
                  </label>
                  <input
                    type="text"
                    name="mission"
                    id="mission"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter mission")}
                    {...formik.getFieldProps("mission")}
                  />

                  {formik.touched.mission && formik.errors.mission ? (
                    <p className="text-red-500">{formik.errors.mission}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="vision"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Vision`)}
                  </label>
                  <input
                    type="text"
                    name="vision"
                    id="vision"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter vision")}
                    {...formik.getFieldProps("vision")}
                  />
                  {formik.touched.vision && formik.errors.vision ? (
                    <p className="text-red-500">{formik.errors.vision}</p>
                  ) : null}
                </div>
              </div>

              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%] ">
                  <div className="flex items-center justify-between mb-2">
                    <label
                      htmlFor="objectives"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t(`Objectives`)}
                    </label>
                    <div
                      onClick={handleAddItem}
                      className="cursor-pointer  border px-2 w-fit text-center border-gray-300 rounded-md text-black"
                    >
                      {t("Add")}
                    </div>
                  </div>
                  {/* <InputValue onUpdate={handleUpdate}/> */}
                  {formik.values.objectives?.map((newItem, index) => {
                    return (
                      <div className="mt-1 border py-[1px] border-gray-300 rounded-md focus:ring-indigo-500 focus:outline-none  focus:border-indigo-500 flex item-center justify-center gap-1">
                        <input
                          type="text"
                          id="objectives"
                          className="mt-1 block py-1 w-full sm:text-sm pl-2 pr-2"
                          placeholder={t("Enter Objectives")}
                          // {...formik.getFieldProps('objectives')}
                          name={`objectives.${index}`}
                          value={newItem}
                          onChange={formik.handleChange}
                        />

                        {
                          formik.values.objectives.length > 1 && <button
                            onClick={() => handleRemoveItem(index)}
                            className="text-red-700 text-2xl h-full py-2 px-3 rounded "
                          >
                            -
                          </button>
                        }
                      </div>
                    );
                  })}
                  {formik.touched.objectives && formik.errors.objectives ? (
                    <p className="text-red-500">{formik.errors.objectives}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  {/* <label
                    htmlFor="dateOfEstablishment"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Date Of Establishment`)}
                  </label> */}
                  {/* <input type="date" name="dateOfEstablishment" id="dateOfEstablishment" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"  
                  {...formik.getFieldProps('dateOfEstablishment')}
                  min={today}

                /> */}
                  {/* <DatePicker
                    selected={formik?.values?.dateOfEstablishment}
                    locale="ar-EG"
                    onChange={(e) => {
                      handleDateChange(
                        e,
                        "dateOfEstablishment"
                      )
                      checkdiff();
                    }
                    }
                    calendar="hijri"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  /> */}
                  <HijriDatePicker
                    value={formik.values.dateOfEstablishment}
                    onChange={(e) => {
                      handleDateChange(
                        e,
                        "dateOfEstablishment"
                      )
                      checkdiff();
                    }
                    }
                    label="Date Of Establishment"
                    name={"dateOfEstablishment"}
                  />

                  {formik.touched.dateOfEstablishment &&
                    formik.errors.dateOfEstablishment ? (
                    <p className="text-red-500">
                      {formik.errors.dateOfEstablishment}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%]">
                  {/* <label
                    htmlFor="actualStartDateofOrganizationActivity"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Actual Start Date of Organization Activity`)}
                  </label> */}
                  {/* <input type="date" name="actualStartDateofOrganizationActivity" id="actualStartDateofOrganizationActivity" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter date")}
                  {...formik.getFieldProps('actualStartDateofOrganizationActivity')}
                  min={today}
                /> */}

                  {/* <DatePicker
                    selected={
                      formik?.values?.actualStartDateofOrganizationActivity
                    }
                    locale="ar-EG"
                    onChange={(e) => {
                      handleDateChange(
                        e,
                        "actualStartDateofOrganizationActivity"
                      )
                      checkdiff();
                    }
                    }
                    calendar="hijri"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  /> */}
                  <HijriDatePicker
                    value={formik.values.actualStartDateofOrganizationActivity}
                    onChange={(e) => {
                      handleDateChange(
                        e,
                        "actualStartDateofOrganizationActivity"
                      )
                      checkdiff();
                     }
                    }
                    label="Actual Start Date of Organization Activity"
                    name={"actualStartDateofOrganizationActivity"}
                  />

                  {formik.touched.actualStartDateofOrganizationActivity &&
                    formik.errors.actualStartDateofOrganizationActivity ? (
                    <p className="text-red-500">
                      {formik.errors.actualStartDateofOrganizationActivity}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="targetedAudience"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Target Audience`)}
                  </label>
                  <input
                    type="text"
                    name="targetedAudience"
                    id="targetedAudience"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter audience")}
                    {...formik.getFieldProps("targetedAudience")}
                  />
                  {formik.touched.targetedAudience &&
                    formik.errors.targetedAudience ? (
                    <p className="text-red-500">
                      {formik.errors.targetedAudience}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="copyOfTheOrganizationalStructure"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Copy Of Organization Structure`)}
                  </label>
                  <div className="flex">
                    {CharityProfile?.copyOfTheOrganizationalStructure && (
                      <a href={CharityProfile.copyOfTheOrganizationalStructure} target="_blank">
                        <img src={CharityProfile.copyOfTheOrganizationalStructure} width={40} />
                      </a>
                    )}
                    <input
                      type="file"
                      name="copyOfTheOrganizationalStructure"
                      id="copyOfTheOrganizationalStructure"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter mobile number"
                      // {...formik.getFieldProps('copyOfTheOrganizationalStructure')}
                      onChange={(e) =>
                        handleFileChange(e, "copyOfTheOrganizationalStructure")
                      }
                    />
                     {/* {file && <p>File selected: {file.name}</p>} */}
                  </div>
                  {errors.copyOfTheOrganizationalStructure && <p style={{ color: 'red' }}>{errors.copyOfTheOrganizationalStructure}</p>}
                  <p>
                    {formik.touched.copyOfTheOrganizationalStructure &&
                      formik.errors.copyOfTheOrganizationalStructure ? (
                      <p className="text-red-500">
                        {formik.errors.copyOfTheOrganizationalStructure}
                      </p>
                    ) : null}
                  </p>
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="participationLetter"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Participation letter from the organization`)}
                  </label>
                  <div className="flex">
                    {CharityProfile?.participationLetter && (
                      <a href={CharityProfile.participationLetter} target="_blank">
                        <img src={CharityProfile.participationLetter} width={40} />
                      </a>
                    )}

                    <input
                      type="file"
                      name="participationLetter"
                      id="participationLetter"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter mobile number"
                      // {...formik.getFieldProps('participationLetter')}
                      onChange={(e) =>
                        handleFileChange(e, "participationLetter")
                      }
                    />
                  </div>
                  {errors.participationLetter && <p style={{ color: 'red' }}>{errors.participationLetter}</p>}
                  <p>
                    {formik.touched.participationLetter &&
                      formik.errors.participationLetter ? (
                      <p className="text-red-500">
                        {formik.errors.participationLetter}
                      </p>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="companyProfileImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t(`Entity Profile Image`)}
                </label>
                <div className="flex">
                  {CharityProfile?.companyProfileImage && (
                    <a href={CharityProfile.companyProfileImage} target="_blank">
                      <img src={CharityProfile.companyProfileImage} width={40} />
                    </a>
                  )}
                  <input
                    type="file"
                    name="companyProfileImage"
                    id="companyProfileImage"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter mobile number"
                    // {...formik.getFieldProps('companyProfileImage')}
                    onChange={(e) => uploadImage(e, "companyProfileImage")}
                  />
                </div>

                <p>
                  {formik.touched.companyProfileImage &&
                    formik.errors.companyProfileImage ? (
                    <p className="text-red-500">
                      {formik.errors.companyProfileImage}
                    </p>
                  ) : null}
                </p>
              </div>
            </div>
          </div>

          {/* second section */}
          <div className="pb-3 _Charity">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Contact Information")}
            </p>
            <div className="mt-3">
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Region`)}
                  </label>
                  <select
                    type="text"
                    name="region"
                    id="region"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Select")}
                    onChange={(e) => handleRegionChange(e)}
                  // value={formik.values.region}
                  >
                    {
                      // console.log("Regions values ->" ,formik.values.region?.name)
                      // console.log("saudiRegions ->" ,saudiRegions )
                    }
                    <option>{t(`Select`)}</option>
                    {saudiRegions?.map((item) => (
                      // <option  value={JSON.stringify(item)} >
                      //   {i18n.language === 'en' ? item.name : item.nameArabic}
                      // </option>
                      <option value={JSON.stringify(item)} selected={item.name === CharityProfile?.region?.name}>
                        {i18n.language === 'en' ? item.name : item.nameArabic}
                      </option>

                    ))}
                    {/* <option value='Education'>Education</option>
                  <option value='Social'>Social</option>
                  <option value='alharm projects'>alharm projects</option> */}
                  </select>
                  {formik.touched.region && formik.errors.region ? (
                    <p className="text-red-500">{formik.errors.region}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`City`)}
                  </label>

                  <select
                    type="text"
                    name="city"
                    id="city"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Select")}
                    onChange={(e) => formik.setFieldValue("city", e.target.value)}
                    value={formik.values.city} // Ensure the selected value is controlled by Formik
                  >
                    <option value="" disabled>{t("Select")}</option> {/* Add value="" to make it the default empty option */}
                    {cities?.map((item) => (
                      <option key={item._id} value={item._id}>
                        {i18n.language === 'en' ? item.cityName : item.cityNameArabic}
                      </option>
                    ))}
                  </select>

                  {formik.touched.city && formik.errors.city ? (
                    <p className="text-red-500">{formik.errors.city}</p>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="mobileNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Mobile Number`)}
                  </label>
                  <div className="flex justif-center item-center">
                    <span
                      className={`mr-2 px-2 py-1.5 mt-1 bg-gray-500 text-white  ${fetchlocalstorage("language") == "ar"
                        ? "rounded-r-md"
                        : "rounded-l-md"
                        }`}
                    >
                      +966
                    </span>
                    <input
                      type="number" min="0"
                      name="mobileNumber"
                      id="mobileNumber"
                      className={`mt-1 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${fetchlocalstorage("language") == "ar"
                        ? "rounded-l-md"
                        : "rounded-r-md"
                        }`}
                      placeholder={t("Enter mobile number")}
                      {...formik.getFieldProps("mobileNumber")}
                    />
                  </div>
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <p className="text-red-500">{formik.errors.mobileNumber}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Email`)}
                  </label>
                  <input
                    type="email"
                    disabled
                    name="email"
                    id="email"
                    value={formik.values.email}
                    className="mt-1 bg-gray-300 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter Email")}
                  // {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <p className="text-red-500">{formik.errors.email}</p>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Website`)}
                  </label>
                  <input
                    type="text"
                    name="website"
                    id="website"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter website")}
                    {...formik.getFieldProps("website")}
                  />
                  {formik.touched.website && formik.errors.website ? (
                    <p className="text-red-500">{formik.errors.website}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="landLineNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Landline Number`)}
                  </label>
                  <div className="flex justif-center item-center">
                    <span
                      className={`mr-2 px-2 py-1.5 mt-1 bg-gray-500 text-white  ${fetchlocalstorage("language") == "ar"
                        ? "rounded-r-md"
                        : "rounded-l-md"
                        }`}
                    >
                      +966
                    </span>
                    <input
                      type="number" min="0"
                      name="landLineNumber"
                      id="landLineNumber"
                      className={`mt-1 block w-full px-3 py-2 border border-gray-300  shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${fetchlocalstorage("language") == "ar"
                        ? "rounded-l-md"
                        : "rounded-r-md"
                        }`}
                      placeholder={t("Enter landline number")}
                      {...formik.getFieldProps("landLineNumber")}
                    />
                  </div>
                  {formik.touched.landLineNumber &&
                    formik.errors.landLineNumber ? (
                    <p className="text-red-500">
                      {formik.errors.landLineNumber}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="district"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`District`)}
                  </label>
                  <input
                    type="text"
                    name="district"
                    id="district"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter district")}
                    {...formik.getFieldProps("district")}
                  />

                  {formik.touched.district && formik.errors.district ? (
                    <p className="text-red-500">{formik.errors.district}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="street"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Street`)}
                  </label>
                  <input
                    type="text"
                    name="street"
                    id="street"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter street")}
                    {...formik.getFieldProps("street")}
                  />

                  {formik.touched.street && formik.errors.street ? (
                    <p className="text-red-500">{formik.errors.street}</p>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Address`)}
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter address")}
                    {...formik.getFieldProps("address")}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <p className="text-red-500">{formik.errors.address}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="faxNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Fax number`)}
                  </label>
                  <input
                    type="number" min="0"
                    name="faxNumber"
                    id="faxNumber"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter fax number")}
                    {...formik.getFieldProps("faxNumber")}
                  />
                  {formik.touched.faxNumber && formik.errors.faxNumber ? (
                    <p className="text-red-500">{formik.errors.faxNumber}</p>
                  ) : null}
                </div>
              </div>
              <div className="mb-4 ">
                <label
                  htmlFor="googleMapLink"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t(`Google map: Location on google maps`)}
                </label>
                <input
                  type="text"
                  name="googleMapLink"
                  id="googleMapLink"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder={t("Enter link")}
                  {...formik.getFieldProps("googleMapLink")}
                />

                {formik.touched.googleMapLink && formik.errors.googleMapLink ? (
                  <p className="text-red-500">{formik.errors.googleMapLink}</p>
                ) : null}
              </div>
            </div>
          </div>

          {/* third section */}
          <div className="pb-3 _Charity">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("License Information")}
            </p>
            <div className="mt-3">
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="licenseNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`License Number`)}
                  </label>

                  <input
                    type="text"
                    name="licenseNumber"
                    id="licenseNumber"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter number (License Number)")}
                    {...formik.getFieldProps("licenseNumber")}
                  />

                  {formik.touched.licenseNumber &&
                    formik.errors.licenseNumber ? (
                    <p className="text-red-500">
                      {formik.errors.licenseNumber}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="licenseAuthority"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Licensing Entity`)}
                  </label>
                  <select
                    type="text"
                    name="licenseAuthority"
                    id="licenseAuthority"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Select")}
                    onChange={(e) =>
                      formik.setFieldValue("licenseAuthority", e.target.value)
                    }
                    value={formik.values.licenseAuthority}
                  >
                    <option>{<>{t("Select")}</>}</option>
                    {licensingAuthorities.map((item, i) => (
                      <option key={`asddf-${i}`} value={item}>
                        {t(item)}
                      </option>
                    ))}
                  </select>
                  {formik.values.licenseAuthority == "Others" ? (
                    <div>
                      <input
                        type={"text"}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e) => setInput(e.target.value)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {formik.touched.licenseAuthority &&
                    formik.errors.licenseAuthority ? (
                    <p className="text-red-500">
                      {formik.errors.licenseAuthority}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  {/* <label
                    htmlFor="licenseDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`License Established Date`)}
                  </label> */}
                  {/* <input type="date" name="licenseDate" id="licenseDate" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"  min={today} {...formik.getFieldProps('licenseDate')} /> */}

                  {/* <DatePicker
                    selected={formik?.values?.licenseDate}
                    locale="ar-EG"
                    onChange={(e) => handleDateChange(e, "licenseDate")}
                    calendar="hijri"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  /> */}
                  <HijriDatePicker
                    value={formik.values.licenseDate}
                    onChange={(e) => handleDateChange(e, "licenseDate")}
                    label="License Established Date"
                    name={"licenseDate"}
                  />

                  {formik.touched.licenseDate && formik.errors.licenseDate ? (
                    <p className="text-red-500">{formik.errors.licenseDate}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  {/* <label
                    htmlFor="licenseExpiryDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`License Expiry Date`)}
                  </label> */}
                  {/* <input type="date" name="licenseExpiryDate" id="licenseExpiryDate" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter date")}  min={today} {...formik.getFieldProps('licenseExpiryDate')} /> */}

                  {/* <DatePicker
                    selected={formik?.values?.licenseExpiryDate}
                    locale="ar-EG"
                    onChange={(e) => handleDateChange(e, "licenseExpiryDate")}
                    calendar="hijri"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  /> */}
                   <HijriDatePicker
                    value={formik.values.licenseExpiryDate}
                    onChange={(e) => handleDateChange(e, "licenseExpiryDate")}
                    label="License Expiry Date"
                    name={"licenseExpiryDate"}
                  />

                  {formik.touched.licenseExpiryDate &&
                    formik.errors.licenseExpiryDate ? (
                    <p className="text-red-500">
                      {formik.errors.licenseExpiryDate}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="organizationLicense"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Organization License`)}
                  </label>
                  <div className="flex">
                    {CharityProfile?.organizationLicense && (
                      <a href={CharityProfile.organizationLicense} target="_blank">
                        <img src={CharityProfile.organizationLicense} width={40} />
                      </a>
                    )}

                    <input
                      type="file"
                      name="organizationLicense"
                      id="organizationLicense"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) =>
                        handleFileChange(e, "organizationLicense")
                      }
                    />
                  </div>
                  {errors.organizationLicense && <p style={{ color: 'red' }}>{errors.organizationLicense}</p>}

                  {formik.touched.organizationLicense &&
                    formik.errors.organizationLicense ? (
                    <p className="text-red-500">
                      {formik.errors.organizationLicense}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="copyOfTheBoardOfDirectorsAppointment"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Formation of the Board of Directors file`)}
                  </label>
                  <div className="flex">
                    {CharityProfile?.copyOfTheBoardOfDirectorsAppointment && (
                      <a href={CharityProfile.copyOfTheBoardOfDirectorsAppointment} target="_blank">
                        <img src={CharityProfile.copyOfTheBoardOfDirectorsAppointment} width={40} />
                      </a>
                    )}
                    <input
                      type="file"
                      name="copyOfTheBoardOfDirectorsAppointment"
                      id="copyOfTheBoardOfDirectorsAppointment"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter mobile number"
                      //  {...formik.getFieldProps('copyOfTheBoardOfDirectorsAppointment')}
                      onChange={(e) =>
                        handleFileChange(e, "copyOfTheBoardOfDirectorsAppointment")
                      }
                    />
                  </div>
                  {errors.copyOfTheBoardOfDirectorsAppointment && <p style={{ color: 'red' }}>{errors.copyOfTheBoardOfDirectorsAppointment}</p>}

                  <p>
                    {formik.touched.copyOfTheBoardOfDirectorsAppointment &&
                      formik.errors.copyOfTheBoardOfDirectorsAppointment ? (
                      <p className="text-red-500">
                        {formik.errors.copyOfTheBoardOfDirectorsAppointment}
                      </p>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* fourth section */}
          <div className="pb-3 _Charity">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Administrative Information")}
            </p>
            <div className="mt-3">
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="numberOfBoardDirector"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Number of board of director's member`)}
                  </label>
                  <input
                    type="number" min="0"
                    name="numberOfBoardDirector"
                    id="numberOfBoardDirector"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter number")}
                    {...formik.getFieldProps("numberOfBoardDirector")}
                  />
                  {formik.touched.numberOfBoardDirector &&
                    formik.errors.numberOfBoardDirector ? (
                    <p className="text-red-500">
                      {formik.errors.numberOfBoardDirector}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="executiveManagerName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Project manager name`)}
                  </label>
                  <input
                    type="text"
                    name="executiveManagerName"
                    id="executiveManagerName"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter name")}
                    {...formik.getFieldProps("executiveManagerName")}
                  />

                  {formik.touched.executiveManagerName &&
                    formik.errors.executiveManagerName ? (
                    <p className="text-red-500">
                      {formik.errors.executiveManagerName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="executiveManagerNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Executive manager number`)}
                  </label>
                  <div className="flex justif-center item-center">
                    <span
                      className={`mr-2 px-2 py-1.5 mt-1 bg-gray-500 text-white ${fetchlocalstorage("language") == "ar"
                        ? "rounded-r-md"
                        : "rounded-l-md"
                        }`}
                    >
                      +966
                    </span>

                    <input
                      type="number" min="0"
                      name="executiveManagerNumber"
                      id="executiveManagerNumber"
                      className={`mt-1 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${fetchlocalstorage("language") == "ar"
                        ? "rounded-l-md"
                        : "rounded-r-md"
                        }`}
                      placeholder={t("Enter mobile number")}
                      {...formik.getFieldProps("executiveManagerNumber")}
                    />
                  </div>

                  {formik.touched.executiveManagerNumber &&
                    formik.errors.executiveManagerNumber ? (
                    <p className="text-red-500">
                      {formik.errors.executiveManagerNumber}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="agentName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Agent Name`)}
                  </label>
                  <input
                    type="text"
                    name="agentName"
                    id="agentName"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter name")}
                    {...formik.getFieldProps("agentName")}
                  />
                  <p>
                    {formik.touched.agentName && formik.errors.agentName ? (
                      <p className="text-red-500">{formik.errors.agentName}</p>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="agentMobileNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Agent Mobile Number`)}
                  </label>
                  <div className="flex justif-center item-center">
                    <span
                      className={`mr-2 px-2 py-1.5 mt-1 bg-gray-500 text-white  ${fetchlocalstorage("language") == "ar"
                        ? "rounded-r-md"
                        : "rounded-l-md"
                        }`}
                    >
                      +966
                    </span>

                    <input
                      type="number" min="0"
                      name="agentMobileNumber"
                      id="agentMobileNumber"
                      className={`mt-1 block w-full px-3 py-2 border border-gray-300  shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm  ${fetchlocalstorage("language") == "ar"
                        ? "rounded-l-md"
                        : "rounded-r-md"
                        }`}
                      placeholder={t("Enter mobile number")}
                      {...formik.getFieldProps("agentMobileNumber")}
                    />
                  </div>
                  <p>
                    {formik.touched.agentMobileNumber &&
                      formik.errors.agentMobileNumber ? (
                      <p className="text-red-500">
                        {formik.errors.agentMobileNumber}
                      </p>
                    ) : null}
                  </p>
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="agentEmail"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Agent Email`)}
                  </label>
                  <input
                    type="agentEmail"
                    name="agentEmail"
                    id="agentEmail"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter Email")}
                    {...formik.getFieldProps("agentEmail")}
                  />
                  <p>
                    {formik.touched.agentEmail && formik.errors.agentEmail ? (
                      <p className="text-red-500">{formik.errors.agentEmail}</p>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="letterOfAuthorization"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Letter Of Authorization`)}
                  </label>
                  <div className="flex">
                    {CharityProfile?.letterOfAuthorization && (
                      <a href={CharityProfile.letterOfAuthorization} target="_blank">
                        <img src={CharityProfile.letterOfAuthorization} width={40} />
                      </a>
                    )}

                    <input
                      type="file"
                      name="letterOfAuthorization"
                      id="letterOfAuthorization"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder={t("Enter mobile number")}
                         //  {...formik.getFieldProps('copyOfTheBoardOfDirectorsAppointment')}
                         onChange={(e) =>
                          handleFileChange(e, "letterOfAuthorization")
                        }
                      />
                    </div>
                    {errors.letterOfAuthorization && <p style={{ color: 'red' }}>{errors.letterOfAuthorization}</p>}

                  <p>
                    {formik.touched.letterOfAuthorization &&
                      formik.errors.letterOfAuthorization ? (
                      <p className="text-red-500">
                        {formik.errors.letterOfAuthorization}
                      </p>
                    ) : null}
                  </p>
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="copyOfTheBoardOfDirectorsDegree"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Copy of the Board of Directors Appointment`)}
                  </label>
                  <div className="flex">
                    {CharityProfile?.copyOfTheBoardOfDirectorsDegree && (
                      <a href={CharityProfile.copyOfTheBoardOfDirectorsDegree} target="_blank">
                        <img src={CharityProfile.copyOfTheBoardOfDirectorsDegree} width={40} />
                      </a>
                    )}

                    <input
                      type="file"
                      name="copyOfTheBoardOfDirectorsDegree"
                      id="copyOfTheBoardOfDirectorsDegree"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter mobile number"
                      // {...formik.getFieldProps('copyOfTheBoardOfDirectorsDegree')}
                      onChange={(e) =>
                        handleFileChange(e, "copyOfTheBoardOfDirectorsDegree")
                      }
                    />
                  </div>
                  {errors.copyOfTheBoardOfDirectorsDegree && <p style={{ color: 'red' }}>{errors.copyOfTheBoardOfDirectorsDegree}</p>}

                  <p>
                    {formik.touched.copyOfTheBoardOfDirectorsDegree &&
                      formik.errors.copyOfTheBoardOfDirectorsDegree ? (
                      <p className="text-red-500">
                        {formik.errors.copyOfTheBoardOfDirectorsDegree}
                      </p>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* fifth section */}
          <div className="mt-3 _Charity">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Banking Information")}
            </p>
            <div className="mt-3">
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="ibanNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`IBAN Number`)}
                  </label>
                  <div className="flex justif-center item-center">
                    <span
                      className={`mr-2 px-2 py-1.5 mt-1 bg-gray-500 text-white  ${fetchlocalstorage("language") == "ar"
                        ? "rounded-r-md"
                        : "rounded-l-md"
                        }`}
                    >
                      SA
                    </span>
                    <input
                      type="text"
                      name="ibanNumber"
                      id="ibanNumber"
                      className={`mt-1 block w-full px-3 py-2 border border-gray-300  shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${fetchlocalstorage("language") == "ar"
                        ? "rounded-l-md"
                        : "rounded-r-md"
                        }`}
                      placeholder={t("Enter IBAN")}
                      {...formik.getFieldProps("ibanNumber")}
                    />
                  </div>
                  {formik.touched.ibanNumber && formik.errors.ibanNumber ? (
                    <p className="text-red-500">{formik.errors.ibanNumber}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="bankName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Bank Name`)}
                  </label>
                  <select
                    type="text"
                    name="bankName"
                    id="bankName"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Select")}
                    onChange={(e) =>
                      formik.setFieldValue("bankName", e.target.value)
                    }
                    value={formik.values.bankName}
                  >
                    <option>{t(`Select`)}</option>
                    {saudiBanks.map((item, i) => (
                      <option key={`asddf-${i}`} value={item}>
                        {t(item)}
                      </option>
                    ))}
                    {/* <option value='Education'>Education</option>
                <option value='Social'>Social</option>
                <option value='alharm projects'>alharm projects</option> */}
                  </select>

                  {formik.touched.bankName && formik.errors.bankName ? (
                    <p className="text-red-500">{formik.errors.bankName}</p>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="updatedBankStatement"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Updated bank statement`)}
                  </label>
                  <div className="flex">
                    {CharityProfile?.updatedBankStatement && (
                      <a href={CharityProfile.updatedBankStatement} target="_blank">
                        <img src={CharityProfile.updatedBankStatement} width={40} />
                      </a>
                    )}

                    <input
                      type="file"
                      name="updatedBankStatement"
                      id="updatedBankStatement"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter mobile number"
                      onChange={(e) =>
                        handleFileChange(e, "updatedBankStatement")
                      }
                    />
                  </div>
                  {errors.updatedBankStatement && <p style={{ color: 'red' }}>{errors.updatedBankStatement}</p>}


                  <p>
                    {formik.touched.updatedBankStatement &&
                      formik.errors.updatedBankStatement ? (
                      <p className="text-red-500">
                        {formik.errors.updatedBankStatement}
                      </p>
                    ) : null}
                  </p>
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="copyOfBudgets"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Copy Of Budgets`)}
                  </label>
                  <div className="flex">
                    {CharityProfile?.copyOfBudgets && (
                      <a href={CharityProfile.copyOfBudgets} target="_blank">
                        <img src={CharityProfile.copyOfBudgets} width={40} />
                      </a>
                    )}

                    <input
                      type="file"
                      name="copyOfBudgets"
                      id="copyOfBudgets"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter mobile number"
                      onChange={(e) =>
                        handleFileChange(e, "copyOfBudgets")
                      }
                    />
                  </div>
                  {errors.copyOfBudgets && <p style={{ color: 'red' }}>{errors.copyOfBudgets}</p>}

                  <p>
                    {formik.touched.copyOfBudgets &&
                      formik.errors.copyOfBudgets ? (
                      <p className="text-red-500">
                        {formik.errors.copyOfBudgets}
                      </p>
                    ) : null}
                  </p>
                  {/* <p>
                    {
                      establishDiff>2 && <p>
                        {t("Copy of Budgets is required")}
                      </p>
                    }
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-1">
            <input
              type="checkbox"
              className=" checked:bg-green-500"
              name=""
              id=""
              checked={checked}
              onClick={() => setChecked(!checked)}
            />
            <p>{t("I confirm all information is accurate")}</p>
          </div>
        </div>

        {/* Form Submit Button */}
        <div className="mt-6 flex justify-between">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t(`Register Entity`)}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DemoOrganizationDetails;
