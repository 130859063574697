import React, { useEffect, useState } from 'react'
import OrganizationDetails from '../Components/Organization/Charity/OrganizationDetails'
import AgentDetails from '../Components/Organization/Charity/AgentDetails';
import Navbar from '../Components/Nav_Form/Navbar';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import { createCharityApi } from '../Api';
import DemoOrganizationDetails from '../Components/Organization/Charity/DemoOrganizationDetails'
import { useTranslation } from 'react-i18next';
import Header from '../Components/UI/Header';
import Footer from '../Components/Footer/Footer';

const Charity = () => {
  const [step, setStep] = useState(1);
  const [step1Data, setStep1Data] = useState({});
  const [step2Data, setStep2Data] = useState({});
  const [establishDiff, setEstablishDiff] = useState(0);
  const navigate = useNavigate()
  const location = useLocation();
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  console.log('step', step);
  const currentPath = useLocation().pathname
  // const handleStep1Submit = data => {
  //   setStep1Data(data);

  //   setStep(2);
  // };

  const handleStep1Submit = async data => {
    setStep1Data(data);
    console.log('inside payload', step1Data, data);
    let payload = { ...step1Data, ...data }
    try {
      setLoading(false)
      console.log('payload', payload.domain);
      if (payload?.ibanNumber) {

        payload.ibanNumber = "SA" + payload.ibanNumber
      }

      console.log('payload', payload)
      const res = await createCharityApi(payload);
      console.log('res', res);
      // console.log(res)
      if (res.status === 200 || res.status === 201) {

        toast.success(res?.data?.message, { id: "001" });
        //if donar created successfully update isProfile true for furter validation
        const data = JSON.parse(localStorage.getItem('userDetails'));
        data.isProfileCreated = true;
        localStorage.setItem('userDetails', JSON.stringify(data));
        setLoading(true)
        toast.success(t("Charity Created Successfully"))
        navigate("/organization-profile", { replace: true, state: { previousPath: currentPath } });
        // navigate('/thankyou',{replace:true});

      } else {
        // toast.error(res?.response?.data?.message, { id: "err501" });

        setLoading(true)
      }

    } catch (error) {
      toast.error(t(error?.response?.data?.message), { id: "err501" });
    }


  };



  return (
    <div>
      {/* <Navbar /> */}

      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>

      <div className="bg-white py-8 px-4  shadow-md rounded-lg my-5 mx-auto   max-w-4xl ">
        {/* Header Section */}
        <div className="text-center pb-8">
          <h1 className="text-2xl font-semibold">{t("Let's get you started")}</h1>
          <p className="text-gray-600">{t(`Enter the details to get going`)}</p>
        </div>

        {/* Stepped Section Indicators */}
        {/* <div className="flex justify-between text-center mb-6">
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${step === 1 ? "bg-blue-500 text-white" : "text-gray-600 bg-gray-300"}  rounded-full text-lg leading-loose`}>1</div>
          <span className="text-sm font-semibold text-gray-700 ml-2">{t(`Organisation Details`)}</span>
        </div>
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${step === 2 ? "bg-blue-500 text-white" : "text-gray-600 bg-gray-300"}  rounded-full text-lg leading-loose`}>2</div>
          <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Agent Details`)}</span>
        </div>
        
      </div> */}
        <DemoOrganizationDetails CharityProfile={step1Data} onSubmit={handleStep1Submit} setEstablishDiff={setEstablishDiff} establishDiff={establishDiff} />
        {/* { step==2?<AgentDetails onSubmit={handleStep2Submit} setStep={setStep} establishDiff={establishDiff} loading={loading}/>:""} */}

      </div>

      <Footer />

    </div>
  )
}

export default Charity