import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getALlBookingsApi } from '../../Api'
import { useTranslation } from 'react-i18next'

const Donations = ({bookings}) => {
  
  const { t } = useTranslation()
  
  return (
    <div>
        <div className='shadow-lg rounded-md'>
            <div className="  h-full z-10   overflow-x-auto " >
              <table className="w-full divide-x text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 bg-white  uppercase  border-b  ">
                  <tr>

                    <th scope="col" className="px-6 py-3">
                      {t('Project Name')}
                    </th>

                    <th scope="col" className="px-6 py-3">
                      {t('Amount')}
                    </th>
                    <th scope="col" className="px-6 py-3">
                     {t('Status')}
                    </th>
                    <th scope="col" className="px-6 py-3">
                     {t('My Contracts')}
                    </th>
                    

                  </tr>
                </thead>
                {bookings?.length ? <tbody >
                  {bookings?.map((item, id) =>
                    <tr key={`jasdfa-${id}`} className="bg-white" >
                      {/* <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">
                      <input type="checkbox" />
                    </td> */}
                      <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">
                        {item?.description}
                      </td>
                      <td className="px-6 py-4 truncate">
                        {item?.amount}
                      </td>
                      <td className="px-6 py-4">
                        {t(item?.status)}
                      </td>
                      <td className="px-6 py-4">
                          
                       { item?.myContract&& <a href={item?.myContract} target='_blank' className='cursor-pointer rounded-lg p-2 text-white bg-project_bg_image_color text-[lg]'>{t(`View Contract`)}</a>
                        }
                      </td>
                       
                       

                      {/* <td className="px-6 py-4">
                      {item.status}
                    </td> */}


                    </tr>
                  )}

                </tbody> : <div className='text-center my-2'>{t('No Data Available')}</div>}
              </table>
            </div>


          </div> 
    </div>
  )
}

export default Donations