
import React, { useEffect, useState } from 'react';
import Navbar from '../Nav_Form/Navbar';
import { FaPen } from "react-icons/fa";
import { getProfile } from '../../Api';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdDocument } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Loader from "react-js-loader";
import ProfileInfo from './ProfileInfo';
import DemoProfileInfo from './DemoProfileInfo'
import { fetchlocalstorage } from '../../HelperFunctions';
import Thankyou from './Thankyou';
import { useLocation } from 'react-router';


function Organizationprofile() {
  const [userInfo, setUserInfo] = useState({})
  const [showThankYou, setShowThankYou] = useState(true);
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location=useLocation()
  const [loading,setLoading] = useState(false)
console.log(location.pathname)
const previousPath = location.state?.previousPath;
console.log(previousPath);
  async function getProfileData() {
    let data = await getProfile() 
    setUserInfo(data?.data?.charity)
    setLoading(true)
    // console.log(data.data.charity)
  }
  useEffect(() => {
    getProfileData()
  }, [])
  const openLink = (link) => {
    console.log(link)
    window.open(link, '_blank');
  };


  useEffect(() => {
    const shouldShowThankYou = location.pathname === '/organization-profile' && location.state?.previousPath === '/charity';

    if (shouldShowThankYou) 
      setShowThankYou(true)
     else
     setShowThankYou(false)


     if (userInfo?.status === 'APPROVED') {
      setShowThankYou(false);
  }
  


      // const timer = setTimeout(() => {
      //   setShowThankYou(false);
      // }, 10000);

      // return () => clearTimeout(timer);
    
  }, [location]);

  return (
    // <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
    <div  >
      <Navbar />
      {/* <div className="ml-16 mt-8"><span className=''>Sync Products</span>/Product Listing</div> */}

      <div className={`flex justify-center items-center ${showThankYou ? "mt-10" : "mt-0"}`}>
  {showThankYou && <Thankyou />}
</div>


      <div className="bg-white p-2 shadow sm:rounded-lg xs:m-[1rem] sm:m-[5rem] md:m-[7rem]"  >
       {loading? <div className="  px-2 py-5 pb-20 sm:p-6">
          <div className=" ">
            <h4 className="leading-6 mb-[4rem] font-medium text-gray-900">
              {t('Profile Details')}
            </h4>
            <div className='flex  '>
              <div className='w-24 p-2 rounded  bg-project_bg_image_color text-white text-lg xs:hidden sm:block' style={{
                width: "80.556px",
                height: "80.556px",
                marginRight: '26px'
                // flex-shrink: 0;

              }}>  <div className=' border-2 w-full h-full bg-project_image_color text-white text-lg flex justify-center item-center' style={{
                borderRadius: "6px",
                border: "none"
              }}><h3 className='text-[3rem] text-white'>{userInfo?.name.charAt(0).toUpperCase()}</h3></div></div>
              <div className='w-3/4'>
                <div className='uppercase  flex'><p className={`text-black  font-bold mr-2  ${fetchlocalstorage("language")=="ar"?"ml-2":""}`} >{t(`Name`)} : </p><p>{userInfo?.name}</p></div>
                <div className='flex item-center  '><p className={`mr-2 text-black  font-bold ${fetchlocalstorage("language")=="ar"?"ml-4":""}`}><FaPhoneAlt /></p>     <p> {userInfo?.mobileNumber}</p></div>
                <div className='flex item-center  '><p className={`text-black  font-bold  mr-2 ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>{t(`Status`)}: </p>      <p className={`text-base ${userInfo?.status === 'PENDING' ? 'text-orange-500' : userInfo?.status === 'APPROVED' ? 'text-green-500' : 'text-red-500'}`}>
  
  {t(userInfo?.status)}
</p>
</div>
              </div>
              {(userInfo?.status === "APPROVED" || userInfo?.status === "RESEND") && <div className='flex items-center justify-end flex-grow ' onClick={() => navigate("/update-charity", { state: { userInfo } })}> {/* Edit button at end */}
                <span className='flex bg-gray-300 px-4 py-2 text-black	justify-center align-center   rounded  cursor-pointer'>
                  {/* <button className='ml-auto mr-3' >{t(`Edit`)}</button> */}
                  <button className='ml-auto mr-3' >{t(`Edit`)}</button>

                  <span><FaPen /></span>
                </span>
              </div> }
            </div>
          </div>
          {/* <div className="mt-5 border-t border-gray-200 pt-5">
            <dl>
              <dt className="text-sm font-medium text-gray-500">
                Organization
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                Appllore Technologies
              </dd>
              <dt className="text-sm font-medium text-gray-500">
                Phone
              </dt>
              <dd className="mt-1 text-sm text-gray-900">


              </dd>
            </dl>
          </div> */}
          <hr/>
              <DemoProfileInfo userInfo={userInfo}/>
               
          <hr />
          {/* <hr /> */}
          
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            {/* Placeholder for the participation letters */}
            {/* {[1, 2, 3, 4].map((item) => ( */}
            {/* <div  className="bg-gray-100 p-4 rounded-lg flex justify-center items-center">
                <span className="text-sm text-gray-500">Participation Letter</span>
                <div></div>
              </div> */}
            {/* ))} */}
            <div className="max-w-sm rounded overflow-hidden  ">
              <div className="  p-4 ">
                <div className="mb-4">
                  <div className="text-black font-semibold">{t(`Letter Of Authorization`)}:</div>
                </div>
                {userInfo?.letterOfAuthorization !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.letterOfAuthorization)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }

              </div>
            </div>
            
            <div className="max-w-sm rounded overflow-hidden  ">
              <div className="  p-4 ">
                <div className="mb-4">
                  <div className="text-black font-semibold">{t(`Copy Of Budgets`)}:</div>
                </div>
                {userInfo?.copyOfBudgets !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.copyOfBudgets)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }

              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ">
              <div className="  p-4 ">
                <div className="mb-4">
                  <div className="text-black font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap">{t("Copy of the Board of Directors Appointment")}:</div>
                </div>
                {userInfo?.copyOfTheBoardOfDirectorsAppointment !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.copyOfTheBoardOfDirectorsAppointment)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }

              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ">
              <div className="  p-4 ">
                <div className="mb-4">
                  <div className="text-black font-semibold">{t(`Copy Of Organization Structure`)}:</div>
                </div>
                {userInfo?.copyOfTheOrganizationalStructure !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.copyOfTheOrganizationalStructure)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }

              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ">
              <div className="  p-4 ">
                <div className="mb-4">
                  <div className="text-black font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap"> { t("Participation letter from the organization")}:</div>
                </div>
                {userInfo?.participationLetter !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.participationLetter)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }

              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ">
              <div className="  p-4 ">
                <div className="mb-4">
                  <div className="text-black font-semibold">{t(`Updated bank statement`)}:</div>
                </div>
                {userInfo?.updatedBankStatement !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.updatedBankStatement)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }

              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ">
              <div className="  p-4 ">
                <div className="mb-4">
                  <div className="text-black font-semibold">{t(`Organization License`)}:</div>
                </div>
                {userInfo?.organizationLicense !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.organizationLicense)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }

              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ">
              <div className="  p-4 ">
                <div className="mb-4">
                  <div className="text-black font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap">{t("Formation of the Board of Directors file")}:</div>

                </div>
                {userInfo?.copyOfTheBoardOfDirectorsDegree !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.copyOfTheBoardOfDirectorsDegree)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }

              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ">
              <div className="  p-4 ">
                <div className="mb-4">
                  <div className="text-black font-semibold">{t(`Company Profile Image`)}:</div>
                </div>
                {userInfo?.companyProfileImage !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.companyProfileImage)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }

              </div>
            </div>



          </div>
        </div>:<span> <Loader type="spinner-circle" bgColor={'black'} color={'black'} size={100} /></span>}
      </div>

    </div >
  );
}

export default Organizationprofile;



