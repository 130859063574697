import React from 'react'
import { useTranslation } from 'react-i18next'
const WorkshopModal = ({onClose,item}) => {
    const {t}=useTranslation();
    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white w-full max-w-md p-6 rounded-lg">
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                        aria-label="Close modal"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </button>
                </div>
                <h5>Workshop</h5>
                {/* <form onSubmit={formik.handleSubmit}> */}

                    <div className="mt-4">
                        <div>
                            {/* <label htmlFor="reportName">Report Name:</label>
            <input type="text" id="reportName" name="reportName" /> */}
                            <label htmlFor="uploadReportname" className="block text-sm font-medium text-gray-700">Notes</label>
                            {/* <input type="uploadReportname" name="uploadReportname" id="uploadReportname" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter Report Name"   /> */}
                            <div>{item?.notes|| t("No data available")}</div>
                            
                        </div>
                        

                        <div className="mt-6">
                            <button
                        onClick={onClose}
                                
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

                            >
                                Close
                            </button>
                        </div>
                    </div>
                {/* </form> */}
            </div>
        </div>
    )
}

export default WorkshopModal