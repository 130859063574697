import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProfileSectionOne = ({userInfo}) => {
  console.log(userInfo);
  const { t } = useTranslation();
  return (
    <div className="border-hidden border-project_image_color mr-2 border-2 rounded-md flex-1  ">
      <div className="p-2">
        <div className=" mb-2 flex justify-between ">
          <div className="text-black p-1 rounded-md border mr-2 w-[45%] px-2 ">
            {t(`Name`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 rounded-md border">
            {userInfo?.name || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className="mb-2  flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`License Number`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.licenseNumber || <>{t("No Data available")}</>}
          </div>
        </div>

        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Address`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.address || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Contact`)}:{" "}
          </div>
          <span className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.mobileNumber || <>{t("No Data available")}</>}
          </span>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Email`)} :{" "}
          </div>
          <div
            className="w-[45%] px-2 p-1 border rounded-md text-ellipsis	overflow-hidden"
            style={{ fontSize: "10vm" }}
          >
            {userInfo?.email || <>{t("No Data available")}</>}
          </div>
        </div>

        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Donation Fields`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md flex flex-wrap">
            {userInfo?.domain?.map((item,index) => (
              <span className="bg-gray-200 mr-2" key={index}>{item.name}</span>
            )) || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`License Authority`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.licenseAuthority || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`License Date`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {(userInfo?.licenseDate &&
              new Date(userInfo?.licenseDate).toLocaleDateString()) ||
              <>{t("No Data available")}</>}
          </div>
        </div>
       

        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Website`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {(userInfo?.website && (
              <Link to={userInfo?.website} target="_blank">
                {userInfo?.website}
              </Link>
            )) ||
              <>{t("No Data available")}</>}
          </div>
        </div>

        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Position`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.position || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`District`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.district || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`City`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.city || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Street`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.street || <>{t("No Data available")}</>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSectionOne;
