import React, { useState } from 'react'
import DonorDetails from '../Components/Donor/DonationForm/DonorDetails'
import AgentDetails from '../Components/Donor/DonationForm/AgentDetails'
import { useLocation, useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { createDonorApi, updateDonorApi } from '../Api';
import Navbar from '../Components/Nav_Form/Navbar';
import { useTranslation } from 'react-i18next';
import { fetchlocalstorage } from '../HelperFunctions';

const UpdateDonation = () => {
    const [step, setStep] = useState(1);
    const [step1Data, setStep1Data] = useState({});
    const [step2Data, setStep2Data] = useState({});
    const {t} = useTranslation()
    let location = useLocation()
    let { state } = location
    const [loading,setLoading] = useState(true)

    // console.log(state)

    const navigate = useNavigate()
    const handleStep1Submit = data => {
      setStep1Data(data);
      // console.log(data)
      setStep(2);
    };
  
    const handleStep2Submit = async data => {
      setStep2Data(data);
      let payload =  { ...step1Data, ...step2Data ,...data}
     
      try {
        setLoading(false)

        const res = await updateDonorApi(payload,state.userInfo._id);
        // console.log(res)
        if (res.status === 200 || res.status === 201) {
            
            toast.success(t(res?.data?.message), { id: "001" });
            //if donar created successfully update isProfile true for furter validation
            navigate("/donor-profile", { replace: true });
            setLoading(true)

        }else{
          setLoading(true)
        }
      } catch (error) { 
        toast.error(t(error?.response?.data?.message), { id: "err501" });
      }
  
   
    };
    const handleBack = () => {
      setStep(1);
    };
  
  return (
    <>
      <Navbar/>
    <div className="bg-white py-8 px-4  shadow-md rounded-lg my-5 mx-auto   max-w-4xl ">
      {/* Header Section */}
      <div className="text-center pb-8">
        <h1 className="text-2xl font-semibold">{t("Let's get you started")}</h1>
        <p className="text-gray-600">{t(`Enter the details to get going`)}</p>
      </div>

      {/* Stepped Section Indicators */}
      <div className="flex justify-between text-center mb-6">
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${step==1 ?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"}  rounded-full text-lg leading-loose  ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>1</div>
          <span className="text-sm font-semibold text-gray-700 ml-2">{t(`Donor Details`)}</span>
        </div>
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${step==2?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"} text-gray-600 rounded-full text-lg leading-loose ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>2</div>
          <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Agent Details`)}</span>
        </div>
        
      </div>

      {step==1?<DonorDetails onSubmit={handleStep1Submit} DonorProfile={state?.userInfo}/>:""}
      {step==2?<AgentDetails onSubmit={handleStep2Submit} DonorProfile={state?.userInfo} loading={loading} onBack={handleBack}/>:"" }
      </div>
    </>
  )
}

export default UpdateDonation