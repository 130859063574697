import * as yup from "yup";
 
// import { useTranslation } from 'react-i18next';
// const {t} = useTranslation()


export const signinValidationForOrg = yup.object({
  email: yup.string().email('Invalid email').required(`email is a required field`),
  password: yup.string().min(6, `password must be at least 6 characters`).required("هذا هو الحقل المطلوب")  
});


export const signupValidationForOrg = yup.object({
  email: yup.string().email('Invalid email').required("يجب إدخال البريد الإلكتروني"),
  password: yup.string().min(6, "كلمة المرور يجب الا تقل عن ٦ حروف و ارقام").required("هذا هو الحقل المطلوب")  
});

//Created for form vaidation but not working in RTL

export const organizationDetailsValidation = yup.object({
  name: yup.string().required('Name is required'),
  domain: yup.string().required('Domain is required'),
  mobileNumber: yup.number().positive().integer(),
  landLineNumber: yup.number().positive().integer(),
  faxNumber: yup.number().positive().integer(),
  ibanNumber: yup.number().positive().integer(),
  email: yup.string().email('Invalid email'),
  region :yup.array().min(1, 'Select at least one option').required('Region is required'),

  website: yup.string()
    .matches(
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
      'Invalid URL'
    )
    .required('Website URL is required'),  numberOfBoardDirector: yup.number().positive().integer(),
  bankName: yup.string(),
  address: yup.string().required("Address is required"),
  googleMapLink: yup.string(),
  dateOFestablishment: yup.date(),
  actualStartDateOfOrganizationActivity: yup.date(),
  mission: yup.string(),
  vision: yup.string(),
  objectives: yup.string(),
  targetedAudience: yup.string(),
  executiveManagerName: yup.string(),
  executiveManagerNumber: yup.number().positive().integer(),
  licenseNumber: yup.string(),
  licenseDate: yup.date(),
  
});
 
export const organisationCharityAgentValidation = yup.object({
 
  agentName: yup.string(),
  agentMobileNumber: yup.number().positive().integer(),
  agentEmail: yup.string().email('Invalid email'),
  participationLetter: yup.string(),
  letterOfAuthorization: yup.string(),
  updatedBankStatement: yup.string(),
  organizationLicense: yup.string(),
  copyOfBudgets: yup.string(),
  copyOfTheBoardOfDirectorsAppointment: yup.string(),
  copyOfTheBoardOfDirectorsDegree: yup.string(),
  copyOfTheOrganizationalStructure: yup.string(),
  companyProfileImage: yup.string(),
});
 