import React from "react";
import AboutContent from "./AboutContent";
import Goals from "./Goals";
import Itinerary from "./Itinerary";
import Requirements from "./Requirements";
import ProjectRequirements from "./ProjectRequirements";
import ProjectEvaluation from "./ProjectEvaluation";
import ProjectDomains from "./ProjectDomains";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";
const HeroSection = () => {
  const { t , i18n} = useTranslation();
  return (
    <div className="w-[100%]  bg-[#EEEDE5]  " style={{marginTop:'-250px'}}>
      <div className="w-[80%] mx-auto flex flex-col gap-24 relative z-80">
        <h3
          className={`text-6xl text-center text-white uppercase z-100 ${i18n.language === 'ar' ? 'cairo' : 'manrope'}`}
          style={{
            zIndex: "100",
          }}
        >
        {t("About1.about")}
        </h3>
        <div className="flex flex-col gap-10  mt-10 lg:mt-0">
          <img
            src="/images/aboutPageHeroSection.svg"
            // style={{
            //   zIndex: "100",
            // }}
          />
          <p className="text-[#084F679C] text-xl leading-8 font-medium">
          {t("About1.description")}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-center mt-12 gap-24">
        <AboutContent />
        <Goals />
        <Itinerary />
        <Requirements />
        <ProjectRequirements />
        <ProjectEvaluation />
        <ProjectDomains />
        <Footer />
      </div>
    </div>
  );
};

export default HeroSection;
