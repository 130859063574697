import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import React from "react";
import Footer from "../Footer/Footer";
import Header from "./Header";

const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = localStorage.getItem("language") || "en";

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      findUs : "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required(<>{t(`Name is a required field`)}</>),
      email: yup
        .string()
        .email("Invalid email")
        .required(<>{t(`Email is a required field`)}</>),
      phoneNumber: yup.string().required(<>{t(`Phone Number is required`)}</>),
      findUs : yup.string(),

    }),
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  return (
    <section className="w-full bg-[#eeece1]">
      <section className="bg-[#0A4D69] h-[50vh] flex flex-col  relative">
        <Header />
      </section>
      <div className="w-full  bg-[#EEEDE5]" style={{marginTop:'-300px'}}>
        <div className="w-full md:w-[80%] mx-auto flex flex-col gap-14 relative mt-20 max-md:mt-14 max-sm:mt-6">
          <h3
            className={`${i18n.language === 'ar' ? 'cairo' : 'manrope'} text-3xl md:text-6xl text-center text-white tracking-wide z-100`}
            style={{
              zIndex: "100",
            }}
          >
            {t("Contact1.Contact")}
          </h3>
          <div
            className="w-[90%] p-4 md:p-0 mx-auto py-10 md:py-20 bg-white rounded-3xl z-100"
            style={{ marginTop: "-20px"}}
          >
            <div className="w-full md:w-[90%] mx-auto flex flex-col md:flex-row justify-between items-center gap-12">
              <div className="w-full md:w-[50%] flex flex-col gap-8 md:gap-12">
                <div className="">
                  <h1 className="text-4xl md:text-5xl font-semibold mt-4 text-[#000000]">
                    {t("Contact1.Get in ")}
                    <span className="text-[#0A4D69]">{t("Contact1.Touch")}</span>
                  </h1>
                  <p className="text-[#084F679C] mt-4">{t("Contact1.contactText")}</p>
                </div>
                <form onSubmit={formik.handleSubmit} className="text-[#084F6799] flex flex-col gap-4">
                  <div className="mb-4 space-y-1">
                    <input
                      type="text"
                      {...formik.getFieldProps("name")}
                      placeholder={t("Contact1.Name *")}
                      name="name"
                      className="w-full p-3 border border-gray-300 placeholder:text-[#084F6799]"
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <p className="text-red-500">{formik.errors.name}</p>
                    ) : null}
                  </div>
                  <div className="mb-4 space-y-1">
                    <input
                      type="email"
                      {...formik.getFieldProps("email")}
                      placeholder={t("Contact1.Email *")}
                      name="email"
                      className="w-full p-3 border border-gray-300 placeholder:text-[#084F6799]"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <p className="text-red-500">{formik.errors.email}</p>
                    ) : null}
                  </div>
                  <div className="mb-4 space-y-1">
                    <input
                      type="number" min="0"
                      {...formik.getFieldProps("phoneNumber")}
                      placeholder={t("Contact1.Phone Number *")}
                      name="phoneNumber"
                      className="w-full p-3 border border-gray-300 placeholder:text-[#084F6799]"
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <p className="text-red-500">{formik.errors.phoneNumber}</p>
                    ) : null}
                  </div>
                  <div className="mb-4 space-y-1">
                    <input
                        type="text"
                        {...formik.getFieldProps("findUs")}
                        placeholder={t("Contact1.How did you find us?")}
                        name="findUs"
                        className="w-full p-3 border border-gray-300 placeholder:text-[#084F6799]"
                      />
                      {formik.touched.findUs && formik.errors.findUs ? (
                        <p className="text-red-500">{formik.errors.findUs}</p>
                      ) : null}
                  </div>

                  <button type="submit" className="w-full p-3 bg-[#0A4D69] font-bold uppercase text-base text-white">
                    {t("Contact1.Send")}
                  </button>
                </form>

                <div className="flex flex-col md:flex-row justify-between items-center gap-4 md:gap-4">
                  <div className="flex items-center gap-4">
                    <img src="/images/phone.svg" alt="img" className="w-6 h-6" />
                    <div className="flex flex-col gap-1">
                      <p className="text-[#0A4D69]">{t("Contact1.PHONE")}</p>
                      <p className="text-[#084F679C] text-sm">0539543215</p>
                    </div>
                  </div>
                  {/* <div className="flex items-center gap-4">
                    <img src="/images/phone.svg" alt="img" className="w-6 h-6" />
                    <div className="flex flex-col gap-1">
                      <p className="text-[#0A4D69]">{t("Contact1.FAX")}</p>
                      <p className="text-[#084F679C] text-sm">03 5432 1234</p>
                    </div>
                  </div> */}
                  <div className="flex items-center gap-4">
                    <img src="/images/phone.svg" alt="img" className="w-6 h-6" />
                    <div className="flex flex-col gap-1">
                      <p className="text-[#0A4D69]">{t("Contact1.EMAIL")}</p>
                      <p className="text-[#084F679C] text-sm">almoultqaa@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <img src="/images/gmap.svg" alt="map" className="w-full h-auto " />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-center mt-24 gap-24">
          <Footer />
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
