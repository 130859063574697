import axios from 'axios'

// export const backendUrl = 'http://localhost:9000/api'

export const backendUrl = 'https://al-moulqta-api.applore.in/api'

export const callAxios = async (method, route, body) => {
  const token = localStorage.getItem('token')
  if (method === 'get') {
    return await axios[method](`${backendUrl}/${route}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).catch(err => {
      console.log(err)
      if (err.response.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location.href = '/login'
      }
    })
  } else if (method === 'delete') {
    if (body) {
      return await axios[method](`${backendUrl}/${route}`, body, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).catch(err => {
        console.log(err)
        if (err.response.status === 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          window.location.href = '/login'
        }
      })
    } else {
      return await axios[method](`${backendUrl}/${route}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).catch(err => {
        console.log(err)
        if (err.response.status === 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          window.location.href = '/login'
        }
      })
    }
  } else {
    return await axios[method](`${backendUrl}/${route}`, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).catch(err => {
      console.log(err)
      if (err.response.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location.href = '/login'
      }
    })
  }
}
