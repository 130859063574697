import React, { useState } from 'react';
import { bookSlotsApi } from '../Api';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { fetchlocalstorage } from '../HelperFunctions';


const SlotModal = ({ onClose, slots,id, date }) => {
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [formData, setFormData] = useState({ name: '', email: '', description: '', agenda: '' ,attachment:'',user:"" });
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: { name: '', email: '', description: '', agenda: '' },
    validationSchema: yup.object({
      name: yup.string().required(<>{t('Name is required')}</>),
      email: yup.string().required('Email is required'),
      description: yup.string().required("Description is required"),
      agenda: yup.string().required("Agenda is required"),
      attachment:yup.string().required("Attachment is required"),
      user:yup.string()

    }),
    onSubmit: async (values) => {
       values.consultant = id
      values.date = date
      values.startTime = selectedSlot.startTime
      values.endTime = selectedSlot.endTime
      values.user = fetchlocalstorage("userDetails")&&JSON.parse(fetchlocalstorage("userDetails"))?._id
      console.log(values)
      // Close the modal after booking
      try {

        let res = await bookSlotsApi(values)
        // console.log(res)
        setShowBookingModal(false);
        toast.success(t(res?.data?.message))

        onClose(false);
      } catch (error) {
        console.log(error)
      }
    }
  })
 
  const [showBookingModal, setShowBookingModal] = useState(false);
   

  const uploadImage = async (e, fieldType) => {
    // Logic to upload the selected file
    if (e.target.files[0]) {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        // Send formData to your backend API for file upload
        let data = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`, formData, {
            headers: {
                "Content-Type": 'multipart/form-data',

            },
        });


        // Handle response
        formik.setFieldValue(fieldType, data.data.fileUrl)



    }
}


  const handleSlotClick = (slot) => {
    if (!slot.isBooked) {

      setSelectedSlot(slot);
      setShowBookingModal(true);
    }
  };

  // console.log(formik)

  // const handleInputChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleBooking = async () => {
  //   // Handle booking logic here, e.g., send form data and selected slot to server


  //   formik.values.consultant = id
  //   formik.values.date = date
  //   formik.values.startTime = selectedSlot.startTime
  //   formik.values.endTime = selectedSlot.endTime

  //   // Close the modal after booking
  //   try {

  //     let res = await bookSlotsApi(formik.values)
  //     // console.log(res)
  //     setShowBookingModal(false);
  //     // toast.success(res?.data?.message)

  //     onClose(false);
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className=''>
        <div className="bg-white w-full max-w-md p-4 rounded-lg overflow-y-auto ">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-gray-800">Available Slots</h2>
            <button
              onClick={() => onClose(false)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
              aria-label="Close modal"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div className="mt-4  flex flex-wrap gap-4">
            {slots.map((slot, index) => (
              <div
                key={index}
                className={`flex items-center justify-center hover:bg-gray-200 transition-all duration-300 hover:shadow-lg ${slot.isBooked ? "bg-gray-300" : "cursor-pointer"} py-2 px-4 border border-gray-400 rounded  ${selectedSlot === slot ? 'bg-gray-200' : ''
                  }`}
                onClick={() => handleSlotClick(slot)}
              >
                <span className="text-gray-600"> {slot.endTime} - {slot.startTime}</span>
              </div>
            ))}
          </div>
        </div>
        {showBookingModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white w-full max-w-md p-6 rounded-lg">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold text-gray-800">Book Slot</h2>
                <button
                  onClick={() => setShowBookingModal(false)}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                  aria-label="Close modal"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div className="mt-4">


                <form onSubmit={formik.handleSubmit}>
                  <div className='text-left'>
                     

                    <h3 className='mb-2'>Name</h3>
                     
                    <input
                      type="text"
                      name="name"
                      // value={formData.name}
                      // onChange={handleInputChange}
                      {...formik.getFieldProps('name')}
                      placeholder="Name"
                      className="w-full border border-gray-300 rounded-md py-2 px-3 mb-2"
                    />

                    {formik.touched.name && formik.errors.name ? (
                      <p className="text-red-500">{formik.errors.name}</p>
                    ) : null}
                  </div>
                  <div className='text-left'>
                     

                    <h3 className='mb-2'>Email</h3>
                    <input
                      type="email"
                      name="email"
                      // value={formData.email}
                      // onChange={handleInputChange}
                      {...formik.getFieldProps('email')}
                      placeholder="Email"
                      className="w-full border border-gray-300 rounded-md py-2 px-3 mb-2"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <p className="text-red-500">{formik.errors.email}</p>
                    ) : null}
                  </div>
                  <div className='text-left'>
                     

                    <h3 className='mb-2'>Description</h3>
                    <input
                      type="text"
                      name="description"
                      // value={formData.description}
                      // onChange={handleInputChange}
                      {...formik.getFieldProps('description')}
                      placeholder="Description"
                      className="w-full border border-gray-300 rounded-md py-2 px-3 mb-2"
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <p className="text-red-500">{formik.errors.description}</p>
                    ) : null}
                  </div>
                  <div className='text-left'>
                     

                    <h3 className='mb-2'>Agenda</h3>
                    <input
                      type="text"
                      name="agenda"
                      // value={formData.agenda}
                      // onChange={handleInputChange}
                      {...formik.getFieldProps('agenda')}
                      placeholder="Agenda"
                      className="w-full border border-gray-300 rounded-md py-2 px-3 mb-2"
                    />
                    {formik.touched.agenda && formik.errors.agenda ? (
                      <p className="text-red-500">{formik.errors.agenda}</p>
                    ) : null}
                  </div>
                  <div className='text-left mb-4'>
                     

                     <h3 className='mb-2'>Attachment</h3>
                    <input
                      type="file"
                      name="attachment"
                      // value={formData.agenda}
                      // onChange={handleInputChange}
                      onChange={(e) => uploadImage(e, "attachment")}
                      placeholder="Attachment"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                    />
                    {formik.touched.attachment && formik.errors.attachment ? (
                      <p className="text-red-500">{formik.errors.attachment}</p>
                    ) : null}
                  </div>
                  <div className="flex justify-between">
                    <input
                      type="text"
                      name="startTime"
                      value={selectedSlot?.startTime}
                      readOnly
                      className="w-5/12 border border-gray-300 rounded-md py-2 px-3 mb-2 mr-2"
                    />
                    <input
                      type="text"
                      name="endTime"
                      value={selectedSlot?.endTime}
                      readOnly
                      className="w-5/12 border border-gray-300 rounded-md py-2 px-3 mb-2"
                    />
                  </div>
                  <button
                    type="submit"

                    className="w-full bg-blue-500 text-white rounded-md py-2 px-3 hover:bg-blue-600 focus:outline-none"
                  >
                    Book Slot
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>

  )
}

export default SlotModal