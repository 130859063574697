import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { createAvailabilityApi } from '../../Api';
import { fetchlocalstorage } from '../../HelperFunctions';
import { useTranslation } from 'react-i18next';

const Availability = () => {
    const { t, i18n } = useTranslation()
    
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [selectedDays, setSelectedDays] = useState([]);
    const [defaultTime, setDefaultTime] = useState({ startTime: '', endTime: '', interval: 0 });
    const [daysWithTimes, setDaysWithTimes] = useState({});
    const [isDefaultTime, setIsDefaultTime] = useState(false);
    
    const handleDaySelection = (event) => {
      const updatedDaysWithTimes = { ...daysWithTimes };
        const value = event.target.value;
        setSelectedDays(prevSelectedDays =>
            prevSelectedDays.includes(value)
                ? prevSelectedDays.filter(day => day !== value)
                : [...prevSelectedDays, value]
        );
        

    };
 

    const handleDefaultTimeChange = (e) => {
        const { name, value } = e.target;
        setDefaultTime(prev => ({ ...prev, [name]: value }));
    };

    const applyDefaultTimeToSelectedDays = () => {
        if (!selectedDays.length ) {
            toast(t('Please select the days to set the default time'));
            return;
        }
        if(defaultTime?.startTime =="" || defaultTime?.endTime =="" || defaultTime.interval ==0)
          {
            toast(t("Please fill all the default values"))
            return
          }
        const updatedDaysWithTimes = { ...daysWithTimes };
        selectedDays.forEach(day => {
            if (!updatedDaysWithTimes[day]) {
                updatedDaysWithTimes[day] = [];
            }
            updatedDaysWithTimes[day] = [{ ...defaultTime }];
        });
        setDaysWithTimes(updatedDaysWithTimes);
        setIsDefaultTime(true);
    };

    const handleDayTimeChange = (day, index, field, value) => {
        const updatedDaysWithTimes = { ...daysWithTimes };
        updatedDaysWithTimes[day][index][field] = value;
        setDaysWithTimes(updatedDaysWithTimes);
    };

    const addTimeInterval = (day) => {
        const updatedDaysWithTimes = { ...daysWithTimes };
        updatedDaysWithTimes[day].push({ startTime: '', endTime: '', interval: 0 });
        setDaysWithTimes(updatedDaysWithTimes);
    };

    const removeTimeInterval = (day, index) => {
        const updatedDaysWithTimes = { ...daysWithTimes };
        updatedDaysWithTimes[day].splice(index, 1);
        setDaysWithTimes(updatedDaysWithTimes);
    };


    const handleSubmit = async ()=>{
        
        try {
            let domain = JSON.parse(fetchlocalstorage("userDetails"))
            console.log(domain)
            let res = await createAvailabilityApi({availability:daysWithTimes,domain:domain.domain})
            toast.success(t(res?.data?.message))
        } catch (error) {
            console.log(error)
        }
 
    }

    return (
        <div className=" mx-auto p-4">
            <div className="p-4">
                <div className="mb-4 w-full sm:w-[90%] lg:w-[50%]">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t('Select Days')}
                    </label>
                    <select
                        multiple
                        value={selectedDays}
                        onChange={handleDaySelection}
                        className="block appearance-none h-[10rem] w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:ring-0 focus:border-gray-300"
                    >
                        {daysOfWeek.map(day => (
                            <option key={day} value={day}>
                                {t(day)}
                            </option>
                        ))}
                    </select>
                </div>
                
                <div className="mb-4">
                    <h2 className="text-lg font-semibold ">{t('Default Time')}</h2>
                    <div className="flex space-x-2">
                        <input
                            type="time"
                            name="startTime"
                            disabled={!selectedDays.length}
                            value={defaultTime.startTime}
                            onChange={handleDefaultTimeChange}
                            className="border border-gray-300 w-[100%] lg:w-[10%]  rounded px-2 py-1"
                            placeholder="Start Time"
                        />
                        <input
                            type="time"
                            name="endTime"
                            disabled={!selectedDays.length}
                            value={defaultTime.endTime}
                            onChange={handleDefaultTimeChange}
                            className="border border-gray-300 w-[100%] lg:w-[10%]  rounded px-2 py-1"
                            placeholder="End Time"
                        />
                        <input
                            type="number" min="0"
                            name="interval"
                            disabled={!selectedDays.length}
                            value={defaultTime.interval}
                            onChange={handleDefaultTimeChange}
                            className="border border-gray-300 w-[100%] lg:w-[10%]  rounded px-2 py-1"
                            placeholder="Interval"
                        />
                        <button
                            onClick={applyDefaultTimeToSelectedDays}
                            className="bg-blue-500 text-white rounded px-4 py-2"
                        >
                            {t('Set Time')}
                        </button>
                    </div>
                </div>
                
                <div className="mt-4">
                    <h2 className="text-lg font-semibold">{t('Selected Days with Times')}:</h2>
                    {isDefaultTime && selectedDays.length > 0 ? (
                        selectedDays.map(day => (
                            <div key={day} className="mt-2">
                                <h5 className="font-bold mt-2">{day}</h5>
                                {daysWithTimes[day] && daysWithTimes[day].map((timeSlot, index) => (
                                    <div key={index} className="flex space-x-2 mb-2 items-center">
                                        <input
                                            type="time"
                                            value={timeSlot.startTime}
                                            onChange={(e) => handleDayTimeChange(day, index, 'startTime', e.target.value)}
                                            className="border border-gray-300 rounded px-2 py-1"
                                        />
                                        <input
                                            type="time"
                                            value={timeSlot.endTime}
                                            onChange={(e) => handleDayTimeChange(day, index, 'endTime', e.target.value)}
                                            className="border border-gray-300 rounded px-2 py-1"
                                        />
                                        <input
                                            type="number" min="0"
                                            value={timeSlot.interval}
                                            onChange={(e) => handleDayTimeChange(day, index, 'interval', e.target.value)}
                                            className="border border-gray-300 rounded px-2 py-1"
                                            placeholder="Interval (minutes)"
                                        />
                                        <button
                                            onClick={() => removeTimeInterval(day, index)}
                                            className="bg-red-500 text-white rounded px-2 py-1"
                                        >
                                            {t("Delete")}
                                        </button>
                                    </div>
                                ))}
                                <button
                                    onClick={() => addTimeInterval(day)}
                                    className="bg-green-500 text-white rounded px-4 py-2 mt-2"
                                >
                                    {t('Add Time')}
                                </button>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-600">{t('No days selected')}</p>
                    )}
                </div>
            <button className="bg-project_image_color text-white rounded px-4 py-2 mt-2" onClick={handleSubmit}>{t('Add Availability')}</button>
            </div>
        </div>
    );
};

export default Availability;
