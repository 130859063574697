import React, { useEffect, useRef, useState } from 'react'
import { getAllWorkShopApi } from '../Api'
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HiDotsVertical } from "react-icons/hi";
import Navbar from '../Components/Nav_Form/Navbar';
import Loader from "react-js-loader";
import WorkshopModal from '../Modal/WorkshopModal';
import { fetchlocalstorage } from '../HelperFunctions';
import Header from '../Components/UI/Header';
import Footer from '../Components/Footer/Footer';


const WorkshopList = () => {

  const dropdownRefs = useRef([]);
  const [openDropdown, setOpenDropdown] = useState({});
  // const [items,setItems] = useState([])
  const [project, setProject] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [nextPage, setNextPage] = useState(1)
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const { t } = useTranslation()
  const [Loading, setLoading] = useState(false)
  const modalRef = useRef(null);

// console.log(project)


  async function getAllWorkShop(params) {
    let { data } = await getAllWorkShopApi()
    // console.log(data)
    setLimit(data?.limit)
    setPage(data?.page)
    setTotal(data?.total)
    setTotalPages(data?.totalPages)
    setProject(data?.data)
    setLoading(true)
  }


  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };
  
  
  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true); // Open the modal when the button is clicked
  };


  const handleCloseModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false); // Close the modal
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
    if (dropdownRefs.current) {
      dropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setOpenDropdown(null);
        }
      });
    }
  };

  let paginationButton = () => {
    let arr = []
    for (let i = 1; i <= totalPages; i++) {
      arr.push(

        <button
          key={i}
          onClick={() => setNextPage(i)}
          className={`relative z-10 inline-flex items-center ${nextPage === i ? 'bg-indigo-600' : ''} px-4 py-2 text-sm font-semibold ${nextPage === i ? 'text-white ' : 'text-black'} focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
        >
          {i}
        </button>

      )

    }
    // console.log(arr)
    return arr
  }

  useEffect(() => {

    getAllWorkShop()

  }, [nextPage])
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {/* <Navbar /> */}
      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>
      <div className={`xs:p-2' sm:p-4 md:p-10 lg:p-16 mt-2 `} >
        {Loading ?
          <div className='shadow-lg rounded-md'>
            <div className="  h-full z-10   overflow-x-auto " >
              <table className="w-full divide-x text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 bg-white  uppercase  border-b  ">
                  <tr>
                    {/* <th scope="col" className="px-6 py-3">
                    <input type="checkbox" disabled />
                  </th> */}
                    {/* <th scope="col" className="px-6 py-3">
                    {t(`Project Name`)}
                  </th> */}
                    <th scope="col" className="px-6 py-3">
                      {t(`Title`)}
                    </th>

                    <th scope="col" className="px-6 py-3">
                      {t(`Description`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                     {t(`Start Date`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t(`End Date`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t(`Project Name`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t(`Actions`)}
                    </th>

                  </tr>
                </thead>
                {project?.length ? <tbody >
                  {project?.map((item, id) =>
                    <tr key={`jasdfa-${id}`} className="bg-white" >
                      {/* <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">
                      <input type="checkbox" />
                    </td> */}
                      <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">
                        {item?.title}
                      </td>
                      <td className="px-6 py-4">
                        {item?.description}
                      </td>

                      <td className="px-6 py-4">
                        {new Date(item.startDate).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4">
                        {item?.endDate && new Date(item?.endDate).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4">
                        {item?.project && item?.project?.projectName}
                      </td>
                      {/* <td className="px-6 py-4">
                      {item.status}
                    </td> */}
                      <td className="px-6 py-4 cursor-pointer z-10  ">


                        <li className="px-4  py-4 list-none text-center cursor-pointer  bg-white " >
                          <div  onClick={(e) => e.stopPropagation()}>
                            <HiDotsVertical   onClick={() => toggleDropdown(item._id)} />
                            {openDropdown === item._id && (
                              <div  className={`absolute  z-99  ${fetchlocalstorage("language")=="ar"?"left-[20px] sm:left-[82px]":"right-[20px] sm:right-[82px]"} mt-2 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg`} style={{zIndex:"99"}}>
                                <div className="py-4 w-40 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                   {/* <Link to="#" className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Edit</Link> */}
                                  <Link to="#" onClick={() => handleOpenModal(item)} className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">View Notes</Link>
                                 </div>
                              </div>
                            )}
                          </div>
                        </li>
                      </td>

                    </tr>
                  )}

                </tbody> : <div className='text-center my-2'>{t('No data available')}</div>}
              </table>
            </div>
            <div className='bg-whit   '>

              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                <div className="flex flex-1 justify-between sm:hidden">
                  <a
                    href="#"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    onClick={() => nextPage !== 1 && setNextPage((prev) => prev - 1)}
                  >
                    Previous
                  </a>
                  <a
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    disabled={nextPage == totalPages ? true : false}
                    // disabled={true}
                    onClick={() => setNextPage((prev) => prev + 1)}

                  >
                    Next
                  </a>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      {t('Showing')} <span className="font-medium">{limit * (page - 1) + 1}</span> {t('to')} <span className="font-medium">{limit * (page - 1) + project?.length}</span> {t('of')}{' '}
                      <span className="font-medium">{total}</span> {t('results')}
                    </p>
                  </div>
                  <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                      <a
                        href="#"
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        onClick={() => nextPage !== 1 && setNextPage((prev) => prev - 1)}

                      >
                        <span className="sr-only">{t('Previous')}</span>
                        <span className="h-5 w-5" aria-hidden="true" >{"<"}</span>
                      </a>
                      {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                      {paginationButton().map(item => item)
                      }

                      {/* <a
                    href="#"
                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    2
                  </a>
                  <a
                    href="#"
                    className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                  >
                    3
                  </a> */}

                      <a
                        href="#"
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        onClick={() => setNextPage((prev) => prev + 1)}

                      >
                        <span className="sr-only">{t('Next')}</span>
                        <span className="h-5 w-5" aria-hidden="true" >{">"}</span>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

          </div> : <span> <Loader type="spinner-circle" bgColor={'black'} color={'black'} size={100} /></span>}
      </div>

      {/* {isModalOpen && (
        <UploadReport onClose={handleCloseModal} id={selectedItem}/>
      )} */}

      <Footer />
      {isModalOpen && (
        <WorkshopModal onClose={handleCloseModal} item={selectedItem} />
      )}
    </div>
  )
}

export default WorkshopList