import React, { useEffect, useState } from 'react'
import Eventcausearea from '../Components/Eventcausearea';
import {Link} from 'react-router-dom';
import EventCause from '../Components/Event/EventCause';
import { getAllEventApi } from '../Api';
import       Aboutfootertoparea2 from "../Components/Aboutfootertoparea2" 
import { useTranslation } from 'react-i18next';
import { fetchlocalstorage } from '../HelperFunctions';

const Event = () => {
   const {t} = useTranslation()
  return (
    <div>
<section className="breadcrumb_area breadcrumb_overlay">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="breadcrumb_section">
                            <ul className="breadcrumb-list volunteer_breadcrumb">
                                <li className="bhas_border text-black"><Link to="/" className='text-black'>{t(`Home`)}</Link></li>
                                {/* <li><span className="active">{t(`Events`)}</span></li> */}
                                <li className={`${fetchlocalstorage('language') == "ar" ? "mr-4 !important" : ""}`}><span className="active">{t(`Events`)}</span></li>
                            </ul>
                            <div className="breadcrumb_title">
                                <h2 className='text-black'>{t(`Held Events`)}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <EventCause />
        <Aboutfootertoparea2/>
     </div>
  )
}

export default Event