import { useTranslation } from "react-i18next";
import HeroSection from "../AboutPage/HeroSection"
import Header from "./Header"

export default function AboutAboutArea() {
    const { t } = useTranslation();
    return (
        //about area start
        <section className='w-full bg-[#eeece1]'>
        <section className="bg-[#0A4D69] h-[50vh] flex flex-col  relative">
            <Header />
        </section>
        <HeroSection />
     
    </section>

    )
}
