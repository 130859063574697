import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const SupportModal = ({ onClose, showModal }) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [description, setDescription] = useState("");
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showSubcategoryDropdown, setShowSubcategoryDropdown] = useState(false);
  const [ document, setDocument] = useState("")

  const categories = {
    "Information Technology Services": [
      "selecting exceptional human resources for donors",
      "providing a database for charitable organizations",
      "Providing necessary information for establishing new institutions",
      "suggesting and providing volunteers",
      "Analyzing technical requirements for donors",
      "Financial planning for projects",
      "Providing specialized consultations in impactful and best grants",
      "Providing advice to donors on issues affecting their practices"
    ],
    "Executive and SupportServices"
    : [
      "Embracing a donor institution",
      "Managing grants for donors",
      "Representing the donor in the region",
      "studying grant requests. Assessing charitable organizations",
      "Providing ready-to-grant projects",
      "Developing existing projects and presenting them to donors",
      "Mediating in grant issues between donors and their partners",
      "Managing donor events Registering civil institutions and endowments",
      "Managing social media accounts for donors"
    ],
    "Maximizing Impact"
    : [
      "Conducting research and studies and disseminating their results to donors",
      "Providing specialized knowledge products in grants",
      "Organizing meetings and conferences on grant-related issues",
      "Coordinating partnerships between donors",
      "Attracting and motivating donors",
      "Training grant employees Issuing specialized journals and publications in grants and endowments",
      "Innovating new developmental products in grants",
      "Holding annual enrichment conferences",
      "Providing and suggesting partners (donors + implementers)",
      "Providing information and guidance on best practices in grants"
      
    ],
  };

  const closeModal = () => {
    setCategory("");
    setSubcategory("");
    setDescription("");
    onClose();
  };

  const toggleCategoryDropdown = () => {
    setShowCategoryDropdown(!showCategoryDropdown);
    setShowSubcategoryDropdown(false);
  };

  const toggleSubcategoryDropdown = () => {
    setShowSubcategoryDropdown(!showSubcategoryDropdown);
  };

  const handleCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);
    setShowCategoryDropdown(false);
    setSubcategory("");
  };

  const handleSubcategorySelect = (selectedSubcategory) => {
    setSubcategory(selectedSubcategory);
    setShowSubcategoryDropdown(false);
  };

//   const handleSubmit = () => {
//     console.log("Category:", category);
//     console.log("Subcategory:", subcategory);
//     console.log("Description:", description);
//     closeModal();
//   };

const handleSubmit = async () => {
    // Check if any of the required fields are empty
    if (!category.trim()) {
      alert(t("Please select a category"));
      return; // Exit the function early if category is empty
    }
    if (!subcategory.trim()) {
      alert(t("Please select a subcategory"));
      return; // Exit the function early if subcategory is empty
    }
    if (!description.trim()) {
      alert(t("Please select a description"));
      return; // Exit the function early if description is empty
    }
  
    const token = localStorage.getItem('token');
    console.log("Category:", category);
    console.log("Subcategory:", subcategory);
    console.log("Description:", description);
  
    // Prepare the payload outside of the try block
    const payload = {
      description: description,
      document, // Assuming 'document' is meant to be empty or replaced with actual value
      category: category,
      subCategory: subcategory,
    };
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ticket/createTicket`, payload, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
      });
  
      closeModal(); 
  
    } catch (error) {
      console.error('Error:', error);
     
    }
  };
  

  const uploadImage = async (e) => {
    // Logic to upload the selected file
    if (e.target.files[0]) {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        // Send formData to your backend API for file upload
        let data = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`, formData, {
            headers: {
                "Content-Type": 'multipart/form-data',

            },
        });


        // Handle response
       setDocument( data.data.fileUrl)



    }
}
 
  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "40px",
              }}
            >
              {t(`Create Ticket`)}
            </h2>

            <div style={{ marginBottom: "30px" }}>
              <label
                htmlFor="category"
                style={{ fontWeight: "semi-bold", fontSize: "20px" }}
              >
                {t(`Category`)}:
              </label>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  border: "1px solid black",
                  marginLeft: "86px",
                  borderRadius: "5px",
                }}
              >
                <input
                  type="text"
                  id="category"
                  value={category}
                  placeholder={t(`Enter Category`)}
                  onClick={toggleCategoryDropdown}
                  style={{ paddingRight: "20px" , paddingTop: "8px",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                width:"350px"}}
                  readOnly
                />
                <span
                  style={{
                    border: "black",
                    position: "absolute",
                    right: "5px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={toggleCategoryDropdown}
                >
                  &#9660;
                </span>
                {showCategoryDropdown && (
                  <ul
                    style={{
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      zIndex: 1,
                    }}
                  >
                    {Object.keys(categories).map((category) => (
                      <li
                        key={category}
                        style={{ padding: "5px", cursor: "pointer" }}
                        onClick={() => handleCategorySelect(category)}
                      >{t(`${category}`)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div style={{ marginBottom: "30px" }}>
              <label
                htmlFor="subcategory"
                style={{ fontWeight: "semi-bold", fontSize: "20px" }}
              >
                {t(`SubCategory`)}:
              </label>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  border: "1px solid black",
                  marginLeft: "50px",
                  borderRadius: "5px",
                }}
              >
                <input
                  type="text"
                  id="subcategory"
                  value={subcategory}
                  placeholder={t(`Enter SubCategory`)}
                  onClick={toggleSubcategoryDropdown}
                  style={{
                    paddingRight: "20px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingLeft: "10px",
                    width:"350px"
                  }}
                  readOnly
                />
                <span
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={toggleSubcategoryDropdown}
                >
                  &#9660;
                </span>
                {showSubcategoryDropdown && category && (
                  <ul
                    style={{
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      zIndex: 1,
                      maxHeight: "200px", // Set a maximum height for the dropdown menu
      overflowY: "auto",
                    }}
                  >
                    {categories[category].map((subcategory) => (
                      <li
                        key={subcategory}
                        style={{ padding: "5px", cursor: "pointer" }}
                        onClick={() => handleSubcategorySelect(subcategory)}
                      >
                        {t(`${subcategory}`)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="description"
                style={{ fontWeight: "semi-bold", fontSize: "20px" }}
              >
                {t(`Description`)}:
              </label>
              <textarea
                id="description"
                value={description}
                placeholder={t(`Enter Description`)}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  width: "100%",
                  height: "100px",
                  border: "1px solid black",
                  borderRadius: "5px",
                  marginTop: "20px",
                  paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingLeft: "10px",

                }}
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="uploadFile"
                style={{ fontWeight: "semi-bold", fontSize: "20px" }}
              >
                {t(`Document`)}:
              </label>
              <input
                type="file"
                id="uploadFile"
                style={{ marginTop: "10px" , marginLeft:"80px"}} 
                onChange={(e) =>uploadImage(e)} 
              />
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  padding: "5px 10px",
                  border: "none",
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
                onClick={handleSubmit}
              >
                {t(`Submit`)}
              </button>
              <button
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  padding: "5px 10px",
                  border: "none",
                  borderRadius: "3px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={closeModal}
              >
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SupportModal;
