import axios from "axios";
import toast from "react-hot-toast";
import Translations from "../utils/Translations";
let { REACT_APP_API_BASE_URL } = process.env;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Server responded with a status other than 2xx
      if (error.response.data?.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.error(<Translations text={error.response.data?.message}/>);
      }
    } else if (error.request) {
      // Request was made but no response was received (network error)
      toast.error(<Translations text={"Internet connection lost"}/>);
    } else {
      // Something else caused the error
      toast.error(<Translations text={"An unexpected error occurred"}/>);
    }
    return (error);
  }
);

export const postApiWithoutToken = (path, data) => {
  return axios.post(REACT_APP_API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  });
};

export const simpleApiWithoutToken=(path,data)=>{
  console.log('data',data);
  return axios.post(REACT_APP_API_BASE_URL+path,data);
}

export const putApiWithoutToken = (path, data) => {
  return axios.put(REACT_APP_API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postApiWithToken =async(path, data) => {
  const token = localStorage.getItem("token");
  console.log('data of postapi with token',data);
  const result=await axios.post(REACT_APP_API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  console.log('result',result);
  return result;
};

export const getApiWithToken = (path) => {
  const token = localStorage.getItem("token");

  return axios.get(REACT_APP_API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const imgUploadApi = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.post(REACT_APP_API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getApiWithoutToken = (path) => {
  
    return axios.get(REACT_APP_API_BASE_URL + path, {
      headers: {
        "Content-Type": "application/json",
      },
    });
 
 
};

export const putApiWithToken = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.put(REACT_APP_API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

// export const postLoginApi = (path, data) => {
//   // const token = localStorage.getItem("token");

//   return axios.post(API_BASE_URL + path, data, {
//     headers: {
//       "Content-Type": "application/json",
//       // Authorization: `Bearer ${token}`,
//     },
//   });
// };