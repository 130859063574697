import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Navbar from '../Components/Nav_Form/Navbar';
 import BasicDetailsForm from '../Components/CreateProject/BasicDetailsForm';
import TeamMemberForm from '../Components/CreateProject/TeamMemberForm';
import FinancialDetailForm from '../Components/CreateProject/FinancialDetailForm';
import toast from 'react-hot-toast';
import { creatProjectApi, updateReportApi } from '../Api';
import { useTranslation } from 'react-i18next';
import CreateProjectModal from '../Modal/CreateProjectModal';
import { fetchlocalstorage } from '../HelperFunctions';
// import { LoginValidation } from 'path_to_your_login_validation_file'; // Import your login validation schema here
// import { createProjectValidations } from '../Validations';
// import { createProjectInitialValues } from '../Validations/initialvalues';

const UpdateProject = () => {
    // const [errorState, setErrorState] = useState(false);
    // const navigate = useNavigate();
    const [isModalOpen,setIsModalOpen] = useState(false)

    const [step, setStep] = useState(1);
    const [step1Data, setStep1Data] = useState({});
    const [step2Data, setStep2Data] = useState({});
    const [step3Data, setStep3Data] = useState({});

    const {state} = useLocation()
    const {projectDetail} = state
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()


    // console.log(projectDetail)
 

    const navigate = useNavigate()
    const handleStep1Submit = data => {

        // console.log(data)
      setStep1Data((prev) => ({...prev,...data}));
 
      setStep(2);
    };
    const handleStep2Submit = data => {
        setStep2Data((prev)=>({...data,...step1Data}));
        
        setStep(3);
      };
  
    const handleStep3Submit = async data => {
      // console.log(step1Data)
      // console.log(step2Data)
      // console.log(data)
      let payload =  {...data,...step2Data }
      // console.log(payload)
      try {
        setLoading(false)

        const res = await updateReportApi(payload,projectDetail._id);
        // console.log(res)
        if (res.status === 200 || res.status === 201) {
            
            toast.success(t(res?.data?.message), { id: "001" });
              //if donar created successfully update isProfile true for furter validation
            
              navigate("/list-all-projects");
              setLoading(true)
        }else{
          setLoading(true)
        }
      } catch (error) { 
        toast.error(t(error?.response?.data?.message), { id: "err501" });
      }
  
   
    };

    const handleCloseModal = () => {
      setIsModalOpen(false); // Close the modal
   };
  
    return (
        <div>
            <Navbar />
            <div className="bg-white py-8 px-4 shadow-md rounded-lg my-5 mx-auto max-w-4xl">
                {/* Header Section */}
                <div className="text-center pb-8">
                    <h1 className="text-2xl font-semibold">{t(`Update Project`)}</h1>
                    <p className="text-gray-600">{t(`Enter the details to get going`)}</p>
                </div>

                {/* Stepped Section Indicators */}
                <div className="flex justify-between text-center mb-6">
                    <div className="w-1/2">
                        <div className={`inline-block w-8 h-8 ${step==1?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"}  rounded-full text-lg leading-loose ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>1</div>
                        <span className="text-sm font-semibold text-gray-700 ml-2">Basic Details</span>
                    </div>
                    <div className="w-1/2">
                        <div className={`inline-block w-8 h-8 ${step==2?"bg-blue-500 text-white":"bg-gray-300  text-gray-600"} rounded-full text-lg leading-loose ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>2</div>
                        <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Team Members`)}</span>
                    </div>

                    <div className="w-1/2">
                        <div className={`inline-block w-8 h-8 ${step==3?"bg-blue-500 text-white":"bg-gray-300  text-gray-600"} rounded-full text-lg leading-loose ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>3</div>
                        <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Financial Details`)}</span>
                    </div>
                </div>

                {/* Form */}
              { step==1?<BasicDetailsForm onSubmit={handleStep1Submit} projectDetail={projectDetail } setStep={setStep} step={step}/>:""}
              { step==2?<TeamMemberForm onSubmit={handleStep2Submit} projectDetail={projectDetail } setStep={setStep} step={step}/>:""}
              { step==3?<FinancialDetailForm projectDetail={projectDetail } onSubmit={handleStep3Submit}  setIsModalOpen = {setIsModalOpen} setStep3Data={setStep3Data} setStep={setStep} step={step} loading={loading}/>:""}
              {/* {isModalOpen && (
        <CreateProjectModal submitForm={()=>handleStep3Submit(step3Data)} onClose={handleCloseModal} loading={loading}/>
      )} */}
            </div>
        </div>
    );
}

export default UpdateProject;
