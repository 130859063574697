import React from "react";
import { useTranslation } from "react-i18next";
import { BiSolidDonateHeart } from "react-icons/bi";


const CategoryCard = ({ imageSrc, title, subtitle }) => (
  <article className="flex flex-col justify-center items-center w-full py-8 bg-white rounded-lg max-md:px-5">
    <img
      loading="lazy"
      src={imageSrc}
      alt={title}
      className="aspect-square w-[60px]"
    />
    <p className="mt-6 text-center font-semibold text-black px-2">{title}</p>
    {subtitle && <p className="mt-1 text-center">{subtitle}</p>}
  </article>
);

const ProjectDomains = () => {
  const { t , i18n} = useTranslation();

  const categories = [
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/5041635f57be39f76f6183aef434a0548ccb57104e67dc85b362d522fbdd5b94?apiKey=8d89067347d641b99b038352d46b6e1d&",
      title: `${t("About1.Health")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/5914451c801ed35ee3d97a2760fbe5214255789046af55de53d4fb5a053baa18?apiKey=8d89067347d641b99b038352d46b6e1d&",
      title: `${t("About1.Development and housing")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/afeecbd475cc7a927a2f9a2f607c2f0287519331c5443772f1d4f4dfa1991644?apiKey=8d89067347d641b99b038352d46b6e1d&",
      title: `${t("About1.Education and research")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3a1ac231c520750c756746df1c24c70d23211262f65f287779ec45cc2bd603c8?apiKey=8d89067347d641b99b038352d46b6e1d&",
      title: `${t("About1.Supporting Charitable Work")}`,
    },
    {
      imageSrc: "/images/mosque.svg",
      title: `${t("About1.Dawah and guidance")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/7f5215823088d2fa62e7600c007e7b0906d08f12b8dc5b2dae316a7e65c2fc1a?apiKey=8d89067347d641b99b038352d46b6e1d&",
      title: `${t("About1.Environment")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/2fbbe08bc852f8f080a51d2d8959f6048d79ef543fc5c9041e5225abb70e3e1d?apiKey=8d89067347d641b99b038352d46b6e1d&",
      title: `${t("About1.Culture and entertainment")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/28e03197a225e5d52839eed43853fe62423e8fd2449278778d711196fc95f672?apiKey=8d89067347d641b99b038352d46b6e1d&",
      title: `${t("About1.Support")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/bc6cad3a805f7f49f97590bdaae16e0791fd2a6a7c1a7404c39c5e42218822b9?apiKey=8d89067347d641b99b038352d46b6e1d&",
      title: `${t("About1.Social Services")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/61feaab221322733be6280b8d888d1d806d24124cfc39f6140c64a2739e6e94e?apiKey=8d89067347d641b99b038352d46b6e1d&",
      title: `${t("About1.Professional Associations")}`,
    },
  ];

  return (
    <section className="flex flex-col gap-8 w-full max-w-[80%]  ">
      <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
        <BiSolidDonateHeart size={54}/>
        <h1 className={`my-auto uppercase font-semibold max-md:max-w-full ${i18n.language === 'ar' ? 'cairo' : 'manrope'} `}>
          {t("About1.domains")}
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4">
        {categories.map((category) => (
          <CategoryCard
            key={category.title}
            imageSrc={category.imageSrc}
            title={category.title}
          />
        ))}
      </div>
    </section>
  );
};

export default ProjectDomains;
