import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { createProjectBasicDetailInitialValues, createProjectInitialValues } from '../../Validations/initialvalues';
import { createProjectBasicDetailValidations, createProjectValidations } from '../../Validations';
import { useLocation, useNavigate } from 'react-router';
import { callDomainForOrg } from '../../Api';
import Select from 'react-select';
import * as yup from "yup";

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import DatePicker from "@deskpro/react-datepicker-hijri";
import moment from "moment-hijri";
import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";
import CreateProjectModal from '../../Modal/CreateProjectModal';
import toast from 'react-hot-toast';
import CreatableSelect from "react-select/creatable";
const customComponents = {
  DropdownIndicator: null,
  Menu: () => null,
};

moment.locale("ar");

const   BasicDetailsForm = ({ onSubmit, projectDetail, setStep, step }) => {
    const hijriDate = moment().format("iYYYY/iM/iD");
    const initialDate = moment(hijriDate, "iYYYY/iM/iD");
    const [isModalOpen,setIsModalOpen] = useState(true)

    const [errorState, setErrorState] = useState(false);
    const [domain, setDomain] = useState([])
    const  {t , i18n} = useTranslation()
    const navigate = useNavigate();
    const {pathname} =  useLocation()

    const formik = useFormik({
        initialValues: {
            projectName: "",
            domain: "",//
            region:"",
            // projectType:"",
            projectJustification: "",
            projectOverview: "",
            projectObjectives: [],
            targetedAudience: "",
            startDate: initialDate,
            closeDate: initialDate, 
            remark: "",//
            rating: 0,//
            status:"DRAFT",
            expectedOutcomes: "",
            expectedImpact: "",
            creativeInnovativeAspect: "",
            projectManagerName: "",
            projectManagerSignature:"",
            projectImage:""
        
        },
        validationSchema: yup.object({
            projectName: yup.string().required(t("Project name is required")),
            domain: yup.string().required(t("Project Domain is required")),
            region: yup.array()
            // Specify that the array contains strings
            .min(1, t('Select at least one option')) // Ensu/re at least one item is selected
            .required(t('Region is required')),
            projectJustification: yup.string().required(t("Project Justification is required")),
            projectOverview: yup.string()
            .required(t("Project OverView is required"))
            .max(300, t("field must not exceed 300 characters")) ,
            projectObjectives: yup.array()
                        .max(8, <>{t("Maximum 8 Objectives allowed")}</>)
                        .required(<>{t("Project Objectives is required")}</>) ,
            targetedAudience: yup.string().required(t("Trageted Audience is required"))  ,
            startDate: yup.date().required(t("Starting date is required")),
            closeDate: yup.date().required(t("Closing date is required")),
            remark: yup.string(),
            rating: yup.number(),
            expectedOutcomes: yup.string().required(t("Expecred Outcomes is required")) ,
            expectedImpact: yup.string().required(t("Expected Impact is required")) ,
            creativeInnovativeAspect: yup.string().required(t("Creative innovative aspect is required")) ,
            // expectedImpact: yup.string().required("Expected Impact is required") ,
            // creativeInnovativeAspect: yup.string().required("Creative Innovative Aspect is required") ,
            
            projectManagerName: yup.string().required(t("Project manager name is required")),
            projectImage:yup.string().required(t("Project Manager Signature is reqired"))
            
          }),
        onSubmit: async (values) => {
            console.log('values',values);
            values.region = formik.values?.region&&formik.values?.region?.map((item)=>item.value)
            formik.values.startDate = values.startDate?.format("YYYY-MM-DD")
            // if(values.closeDate?.format("YYYY-MM-DD") < values.startDate?.format("YYYY-MM-DD")){
            //     toast.error(t('Closing Date Cannot be before Starting Date'))
            // }else{
            //     formik.values.closeDate = values.closeDate?.format("YYYY-MM-DD")
            // }
            
            onSubmit(values)
            formik.resetForm()
            console.log("submit values->",values)
            // Handle form submission here
        },
    });

    console.log(formik)

    const saudiRegions = [
        "Al Riyadh",
        "Eastern Region",
        "Mecca Al Mokarama",
        "Al Madina Al Moawra",
        "Al Qassim",
        "Assir",
        "Tabouk",
        "Hail",
        "Northern Borders",
        "Jazan",
        "Najran",
        "Al Baha",
        "Al Jouf"
    ];

    const saudiRegionsOptions = saudiRegions.map(region => ({
        value: region,
        label: region
    }));

    async function callDomainType(params) {
        try {

            const data = await callDomainForOrg()


            setDomain(data?.data?.domains)
        } catch (error) {
            console.log(error)
        }

    }

    // console.log(formik.values)
    useEffect(() => {
        callDomainType()
        if (projectDetail) {
            const filteredData = {};
            console.log("111 ",projectDetail)
            Object.keys(createProjectBasicDetailInitialValues).forEach(key => {
                if (projectDetail.hasOwnProperty(key)) {
                    filteredData[key] = projectDetail[key];
                }
                if (projectDetail[key] == null) {
                    filteredData[key] = '';
                    
                }
            });
            console.log(filteredData)

            // Set filtered data as form values
            formik.setValues(filteredData);
            // console.log("1111  ",filteredData)
            formik.setFieldValue("domain",`${filteredData?.domain?._id}`)
            formik.setFieldValue("region", filteredData?.region?.map((item) => ({ value: item, label: item })))
            // console.log("2222 ",formik.values)
            // Convert the UTC date string to a moment object
            // const momentDate = moment(filteredData?.startDate);

            // // Convert the moment object to a Hijri date moment object
            // const hijriMomentDate = momentDate.clone().locale('en')
        

            // console.log("startDate without object" , filteredData?.startDate)
            // console.log("startDate backend date formatted ->" , hijriMomentDate.format("iYYYY/iM/iD"))
            // console.log("startDate backend moment object ->" , hijriMomentDate)

            // const hijriDate = moment().format("iYYYY/iM/iD");
            // const initialDate = moment(hijriDate, "iYYYY/iM/iD");

            formik.setFieldValue("startDate", moment(filteredData?.startDate))
            formik.setFieldValue("closeDate", moment(filteredData?.closeDate))
            const objectivedata=filteredData.projectObjectives;
            formik.setFieldValue(
                "projectObjectives",
                objectivedata
            );

            // formik.setValues(projectDetail)
        }
    }, [])
    // console.log(formik)
    const uploadImage = async (e, fieldType) => {
        const selectedFile = e.target.files[0];
    
        if (selectedFile) {
          const isJpgOrPng = 
            selectedFile.type === 'image/jpeg' || 
            selectedFile.type === 'image/png';
          const isLessThan5MB = selectedFile.size < 5 * 1024 * 1024; // 5MB in bytes
      
          if (!isJpgOrPng) {
            alert('Only JPG and PNG files are allowed.');
            return;
          }
      
          if (!isLessThan5MB) {
            alert('File size should be less than 5MB.');
            return;
          }
      
          const image = new Image();
          image.src = URL.createObjectURL(selectedFile);
      
          image.onload = async () => {
            const { width, height } = image;
            const isAspectRatioValid = width === height;
      
            if (!isAspectRatioValid) {
              alert('The image must have a 1:1 aspect ratio (equal width and height).');
              return;
            }
      
            // If all validations pass, proceed with the upload
            const formData = new FormData();
            formData.append("file", selectedFile);
      
            try {
              let data = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
      
              // Handle response and update the form field
              formik.setFieldValue(fieldType, data?.data?.fileUrl);
            } catch (error) {
              alert('File upload failed. Please try again.');
            }
          };
      
          image.onerror = () => {
            alert('Invalid image file.');
          };
        }
      };

    // const [value, setValue] = useState(initialDate); // Initialize with the moment object
    const handleDateChange = (e,data) => {
        // console.log(date.format("iYYYY/iM/iD")); // Log the selected date in Hijri format
        formik.setFieldValue(data,e)
        // setValue(e?.format("iYYYY-iM-iD"));
    };
    console.log('formicvalues' , formik.values)

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
     };
    

    return (
        <div className={`${i18n.language === 'ar' ? 'cairo' : 'manrope'}`}>

            <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Column 1 */}
                    <div>
                        {/* Entity Name */}
                        <div className="mb-4">
                            <label htmlFor="projectName" className="block text-sm font-medium text-gray-700">{t(`Project Name`)}</label>
                            <input type="text" name="projectName" id="projectName" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter name")}
                                {...formik.getFieldProps('projectName')}
                            />
                            {formik.touched.projectName && formik.errors.projectName ? (
                                <p className="text-red-500">{formik.errors.projectName}</p>
                            ) : null}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="domain" className="block text-sm font-medium text-gray-700">{t(`Project Domain`)}</label>
                            <select type="text" name="domain" id="domain" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Select")} onChange={(e) => formik.setFieldValue('domain', e.target.value)}
                                value={formik.values.domain} >
                                <option >{t('Select')}</option>
                                {
                                    domain?.map((item) => <option key={item._id} value={item._id}>{item.name}</option>)

                                }</select>
                            {formik.touched.domain && formik.errors.domain ? (
                                <p className="text-red-500">{formik.errors.domain}</p>
                            ) : null}
                        </div>


                        <div className="mb-4">
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">{t(`Region`)}</label>
                            <Select
                                id="region"
                                name="region"
                                isMulti
                                options={saudiRegionsOptions}
                                className="mt-1 block w-full"
                                classNamePrefix="react-select"
                                value={formik.values.region}
                                onChange={value => formik.setFieldValue('region', value)}
                            // onBlur={() => formik.setFieldTouched('region', true)}
                            />

                            {formik.touched.region && formik.errors.region ? (
                                <p className="text-red-500">{formik.errors.region}</p>
                            ) : null}
                        </div>

                        <div className="mb-4">
                            <label htmlFor="projectJustification" className="block text-sm font-medium text-gray-700">{t(`Project Justification`)}</label>
                            <input type="text" name="projectJustification" id="projectJustification" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter Project Justification")}
                                {...formik.getFieldProps('projectJustification')}
                            />
                            {formik.touched.projectJustification && formik.errors.projectJustification ? (
                                <p className="text-red-500">{formik.errors.projectJustification}</p>
                            ) : null}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="projectOverview" className="block text-sm font-medium text-gray-700">{t(`Project Overview`)}</label>
                            <input type="text" name="projectOverview" id="projectOverview" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter project overview")}
                                {...formik.getFieldProps('projectOverview')}
                            />
                            {formik.touched.projectOverview && formik.errors.projectOverview ? (
                                <p className="text-red-500">{formik.errors.projectOverview}</p>
                            ) : null}
                        </div>
                         <div className="mb-4">
                            <label
                                htmlFor="projectObjectives"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t(`Project Objectives`)}
                            </label>
                            <CreatableSelect
                                id="projectObjectives"
                                name="projectObjectives"
                                isMulti
                                components={customComponents}
                                className="mt-1 block w-full"
                                classNamePrefix="react-select"
                                value={formik.values.projectObjectives}
                                onChange={(value) => formik.setFieldValue("projectObjectives", value)}
                            />
                            {formik.touched.projectObjectives && formik.errors.projectObjectives ? (
                                <p className="text-red-500">{formik.errors.projectObjectives}</p>
                            ) : null}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="targetedAudience" className="block text-sm font-medium text-gray-700">{t(`Targeted Audience`)}</label>
                            <input type="text" name="targetedAudience" id="targetedAudience" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter target audience")}
                                {...formik.getFieldProps('targetedAudience')}
                            />
                            {formik.touched.targetedAudience && formik.errors.targetedAudience ? (
                                <p className="text-red-500">{formik.errors.targetedAudience}</p>
                            ) : null}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="creativeInnovativeAspect" className="block text-sm font-medium text-gray-700">{t(`Creative And Innovative Aspect`)}</label>
                            <input type="text" name="creativeInnovativeAspect" id="creativeInnovativeAspect" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter creative innovative aspect")}
                                {...formik.getFieldProps('creativeInnovativeAspect')}
                            />
                            {formik.touched.creativeInnovativeAspect && formik.errors.creativeInnovativeAspect ? (
                                <p className="text-red-500">{formik.errors.creativeInnovativeAspect}</p>
                            ) : null}
                        </div>


                    </div>

                    {/* Column 2 */}
                    <div>

                        <div className="mb-4">
                            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">{t(`Starting Date`)}</label>
                            {/* <input type="date" name="startDate" id="startDate" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter starting date")}

                                {...formik.getFieldProps('startDate')}
                            /> */}
                            <DatePicker
                                selected={formik?.values?.startDate} 
                                locale="ar-EG"
                                onChange={(e)=>handleDateChange(e,'startDate')}
                                calendar="hijri"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                minDate={formik?.values?.startDate}
                            />
                            {formik.touched.startDate && formik.errors.startDate ? (
                                <p className="text-red-500">{formik.errors.startDate}</p>
                            ) : null}
                        </div>

                        <div className="mb-4">
                            <label htmlFor="closeDate" className="block text-sm font-medium text-gray-700">{t(`Closing Date`)}</label>
                            {/* <input type="date" name="closeDate" id="closeDate" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter closing date")}
                                {...formik.getFieldProps('closeDate')}
                            /> */}
                            <DatePicker
                                selected={formik?.values?.closeDate}
                                locale="ar-EG"
                                onChange={(e)=>handleDateChange(e,'closeDate')}
                                calendar="hijri"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                minDate={formik?.values?.startDate}
                            />
                            {formik.touched.closeDate && formik.errors.closeDate ? (
                                <p className="text-red-500">{formik.errors.closeDate}</p>
                            ) : null}
                        </div>
                        {/* <div className="mb-4">
                            <label htmlFor="remark" className="block text-sm font-medium text-gray-700">Remark</label>
                            <input type="text" name="remark" id="remark" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter remark"
                                {...formik.getFieldProps('remark')}
                            />
                            {formik.touched.remark && formik.errors.remark ? (
                                <p className="text-red-500">{formik.errors.remark}</p>
                            ) : null}
                        </div> */}
                        {/* <div className="mb-4">
                            <label htmlFor="rating" className="block text-sm font-medium text-gray-700">Rating</label>
                            <input type="number" name="rating" id="rating" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter rating"
                                {...formik.getFieldProps('rating')}
                            />
                            {formik.touched.rating && formik.errors.rating ? (
                                <p className="text-red-500">{formik.errors.rating}</p>
                            ) : null}
                        </div> */}

                        <div className="mb-4">
                            <label htmlFor="expectedOutcomes" className="block text-sm font-medium text-gray-700">{t(`Expected Outcomes`)}</label>
                            <input type="text" name="expectedOutcomes" id="expectedOutcomes" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter expected outcome")}
                                {...formik.getFieldProps('expectedOutcomes')}
                            />
                            {formik.touched.expectedOutcomes && formik.errors.expectedOutcomes ? (
                                <p className="text-red-500">{formik.errors.expectedOutcomes}</p>
                            ) : null}
                        </div>

                        <div className="mb-4">
                            <label htmlFor="expectedImpact" className="block text-sm font-medium text-gray-700">{t(`Expected Impact`)}</label>
                            <input type="text" name="expectedImpact" id="expectedImpact" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter expected impact")}
                                {...formik.getFieldProps('expectedImpact')}
                            />
                            {formik.touched.expectedImpact && formik.errors.expectedImpact ? (
                                <p className="text-red-500">{formik.errors.expectedImpact}</p>
                            ) : null}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="projectManagerName" className="block text-sm font-medium text-gray-700">{t(`Project Manager Name`)}</label>
                            <input type="text" name="projectManagerName" id="projectManagerName" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter project manager name")}
                                {...formik.getFieldProps('projectManagerName')}
                            />
                            {formik.touched.projectManagerName && formik.errors.projectManagerName ? (
                                <p className="text-red-500">{formik.errors.projectManagerName}</p>
                            ) : null}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="projectImage" className="block text-sm font-medium text-gray-700">{t(`Project Picture`)}</label>
                            <input type="file" name="projectImage" id="projectImage" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"  
                                onChange={(e) => uploadImage(e, "projectImage")}
                            />
                            {formik.touched.projectImage && formik.errors.projectImage ? (
                                <p className="text-red-500">{formik.errors.projectImage}</p>
                            ) : null}
                        </div>

                    </div>
                </div>

                {/* Form Submit Button */}
                <div className="mt-6 flex justify-between">
                    {/* {step>1&&<button onClick={()=>setStep((prev)=>prev-1)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Previous
                    </button>} */}
                    <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {t(`Next`)}
                    </button>
                </div>
            </form>
            {isModalOpen&&pathname!=="/update-project" && (
        <CreateProjectModal   onClose={handleCloseModal} />
      )}
        </div>
    )
}

export default BasicDetailsForm