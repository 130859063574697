import { Field, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  createProjectBasicDetailInitialValues,
  createProjectInitialValues,
} from "../../Validations/initialvalues";
import {
  createProjectBasicDetailValidations,
  createProjectValidations,
} from "../../Validations";
import { useLocation, useNavigate } from "react-router";
import { callDomainForOrg, getCities, getProfile, getRegions } from "../../Api";
import Select from "react-select";
import * as yup from "yup";

import axios from "axios";
import { useTranslation } from "react-i18next";
import DatePicker from "@deskpro/react-datepicker-hijri";
import moment from "moment-hijri";
import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";
import CreateProjectModal from "../../Modal/CreateProjectModal";
import i18n from "i18next";
import HijriDatePicker from "../HijriDatePicker";

moment.locale("ar");

const DemoBasicDetailsForm = ({ onSubmit, projectDetail }) => {
  console.log("projectDeatils in edit -----> ", projectDetail);

  const hijriDate = moment().format("iYYYY/iM/iD");
  const initialDate = moment(hijriDate, "iYYYY/iM/iD");
  const [isModalOpen, setIsModalOpen] = useState(true);

  const [errorState, setErrorState] = useState(false);
  const [domain, setDomain] = useState([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [dummy, setDummy] = useState(true);
  const [projectImpdummy, setProjectImpdummy] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  console.log("projectImpdummy", projectImpdummy, dummy);

  const formik = useFormik({
    initialValues: {
      projectName: "",
      domain: "", //
      region: userInfo?.region
        ? [
            {
              id: userInfo.region._id,
              label:
                i18n.language == "en"
                  ? userInfo.region.name
                  : userInfo.region?.nameArabic,
            },
          ]
        : [],
      city: userInfo?.city
        ? [
            {
              value: userInfo?.city?._id,
              label:
                i18n.language === "en"
                  ? userInfo.city.cityName
                  : userInfo.city.cityNameArabic,
            },
          ]
        : [],
      // projectType:"",
      projectOverview: "",
      projectJustification: [""],
      projectObjectives: [""],
      targetedAudience: "",
      startDate: initialDate,
      closeDate: initialDate,
      remark: "", //
      rating: 0, //
      status: "DRAFT",
      expectedOutcomes: "",
      expectedImpact: "",
      creativeInnovativeAspect: "",
      projectManagerName: "",
      projectManagerSignature: "",
      projectImage: "",
      teamMembersPartnerships: "",
      partnershipstype: "",
      teamMembersFulltime: "",
      teamMembersPartnerships: "",
      partnershipstype: "", //
      teamMembersPercentageOfEachPartner: "",
      teamMembersPerformanceMeasurement: [""], //
      teamMembersProjectImplementation: false,
      teamMembersLastProjectsReports: "", //
      teamMembersSupportingEntityStrategy: "",
      teamMembersFundingPercentage: "",
      financialDetails: [
        {
          item: "",
          price: 0,
          quantity: 0,
          vat: 0,
          totalprice: 0,
          beforeVat: 0,
          afterVat: 0,
        },
      ],
      // totalBeforeVat: 0,
      totalAfterVat: 0,

      financialDetailsItem: "",
      financialDetailsQuantity: "",
      financialDetailsPrice: "",
      totalBeforeVat: 0,
      // financialDetailsTotal: 0,
      // Financial:- Second
      plansActivity: "",
      plansPerformance: "",
      plansTimeline: "",
      teamMembersFundingSource: false,
      teamMembersVolunteer: "",
    },
    // validationSchema: yup.object({}), // Empty validation schema

    validationSchema: yup.object({

      // step 1 vaidation
      projectName: yup.string().required(t("Project name is required")),
      domain: yup.string().required(t("Project Domain is required")),
      region: yup
        .array()
        .min(1, t("Select at least one option")) 
        .required(t("Region is required")),
      projectJustification: yup.array().of(
        yup
          .string()
          .required(t("Project Justification is required"))
          .min(1, <>{t("Project Justification is required")}</>)
      ),
      projectOverview: yup
        .string()
        .required(t("Project overview is required"))
        .max(300, t("field must not exceed 300 characters")),
      projectObjectives: yup
        .array()
        .of(
          yup
            .string()
            .required(<>{t("Project objective is required")}</>)
            .min(1, <>{t("Project objective is required")}</>)
        )
        .max(8, <>{t("Maximum 8 objectives allowed")}</>),
      targetedAudience: yup
        .string()
        .required(t("Targeted audience is required")),
      projectImage: yup.string().required(t("Project picture is required")),

    //  step 2 validation
      expectedOutcomes: yup
        .string()
        .required(t("Expected outcomes is required")),
      expectedImpact: yup.string().required(t("Expected impact is required")),
      creativeInnovativeAspect: yup
      .string()
      .required(t("Creative innovative aspect is required")),
      startDate: yup
      .date()
      .required(t("Starting date is required"))
      .typeError(t("Invalid date")),
      closeDate: yup
        .date()
        .required(t("Closing date is required"))
        .typeError(t("Invalid date"))
        .min(
          yup.ref("startDate"),
          t("Close date must be grater than the start date")
        ),
      teamMembersProjectImplementation: yup
      .mixed()
      .oneOf([true, false, null])
      .required(t("Project Implementation is required")),
      partnershipstype: yup.string(),
      teamMembersPartnerships: yup.string(),
      partnershipstype: yup.string(), //
      teamMembersPercentageOfEachPartner: yup.number(),
      teamMembersFundingSource: yup
        .mixed()
        .oneOf([true, false, null])
        .required(t("Funding resource from other organization is required")),    
      remark: yup.string(),
      rating: yup.number(),
      // expectedImpact: yup.string().required("Expected Impact is required") ,
      // creativeInnovativeAspect: yup.string().required("Creative Innovative Aspect is required") ,

      // step 3 validation
      projectManagerName: yup
        .string()
        .required(t("Project manager name is required")),
      teamMembersFulltime: yup.string().required(t("Full time is required")),
      teamMembersVolunteer: yup.string().required(t("Volunteer is required")),
      // teamMembersPerformanceMeasurement: yup.string(),
      teamMembersPerformanceMeasurement: yup.array().of(yup.string()), //
      teamMembersLastProjectsReports: projectImpdummy
        ? yup.string()
        : yup.string().required(t("Old report is required")), //
      teamMembersSupportingEntityStrategy: yup.string(),
      teamMembersFundingPercentage: dummy
        ? yup.number().min(0)
        : yup.number().min(0).required(t("Funding percentage is required")),

      // step 4 validation
      financialDetails: yup.array().of(
        yup.object({
          item: yup.string().required(t("Required")),
          quantity: yup
            .number()
            .required(t("Required"))
            .positive(t("Must be positive"))
            .integer(t("Must be an integer")),
          price: yup
            .number()
            .required(t("Required"))
            .positive(t("Must be positive")),
          beforeVat: yup.number(),
          vat: yup.number(),
          totalprice: yup.number(),
        })
      ),
      financialDetailsItem: yup.string(),
      financialDetailsPrice: yup.number(), //
      financialDetailsQuantity: yup.number(),
      totalBeforeVat: yup.number(),
      totalAfterVat: yup.number(),

    //  step 5 validation
      plansActivity: yup.string().required(t("Plan activity is required")),
      plansTimeline: yup.string().required(t("Timeline is required")),
      plansPerformance: yup
        .string()
        .required(t("Plan performance is required")),
  
    }),
    onSubmit: async (values) => {
      console.log("asd",values)
      formik.values.startDate = values.startDate?.format("YYYY-MM-DD");
      let s = formik.values.projectObjectives.filter((item) => item !== "");

      formik.values.projectObjectives = s;

      let justArray = formik.values.projectJustification.filter(
        (item) => item !== ""
      );
      formik.values.projectJustification = justArray;

      // if(values.closeDate?.format("YYYY-MM-DD") < values.startDate?.format("YYYY-MM-DD")){
      //     toast.error(t('Closing Date Cannot be before Starting Date'))
      // }else{
      //     formik.values.closeDate = values.closeDate?.format("YYYY-MM-DD")
      // }

      onSubmit(values);
      formik.resetForm();
      console.log("submit values->", values);
      // Handle form submission here
    },
  });

  // const handleSubmit=async(e)=>{
  //   e.preventDefault();
  //   console.log("values1111111", formik.values);
  //   formik.values.startDate = formik.values.startDate?.format("YYYY-MM-DD");
  //   let s = formik.values.projectObjectives.filter((item) => item !== "");

  //   formik.values.projectObjectives = s;

  //   let justArray = formik.values.projectJustification.filter((item) => item!== "");
  //   formik.values.projectJustification = justArray;

  //   onSubmit(formik.values);
  //   formik.resetForm();
  //   console.log("submit values->", formik.values);
  //teamMembersFundingPercentage
  //teamMembersLastProjectsReports
  // }

  console.log("userInfo", userInfo?.city?._id, formik.values.city);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trigger validation
    const errors = await formik.validateForm();

    console.log("errors", errors);

    console.log("2222");

    if (Object.keys(errors).length === 0) {
      // If there are no errors, proceed with form submission
      formik.values.startDate = formik.values.startDate?.format("YYYY-MM-DD");
      let s = formik.values?.projectObjectives.filter((item) => item !== "");
      formik.values.projectObjectives = s;

      let justArray = formik.values.projectJustification.filter(
        (item) => item !== ""
      );

      formik.values.projectJustification = justArray;

      onSubmit(formik.values);
      formik.resetForm();
      console.log("submit values->", formik.values);
    } else {
      // If there are errors, set them to be displayed
      formik.setTouched(errors);
      formik.setErrors(errors);
    }
  };

  console.log("formik", formik.values.region, formik.values.city);

  const [saudiRegions, setSaudiRegions] = useState([]);
  const [saudiCity, setSaudiCities] = useState([]);

  const handleRegionChange = (selectedOptions) => {
    const selectedRegionIds = selectedOptions.map((option) => option.value);
    console.log("selectedRegionIds", selectedRegionIds);
    formik.setFieldValue("region", selectedRegionIds);
  };

  // const handleRegionCity = (selectedOptions) => {
  //   const selectedCityIds = selectedOptions.map((option) => option.label);
  //   setSelectedCity(selectedCityIds);
  //   console.log("selectedCityIds", selectedCityIds[0]);
  //   formik.setFieldValue("city", selectedCityIds);
  // };

  const handleRegionCity = (selectedOptions) => {
    formik.setFieldValue(
      "city",
      selectedOptions.map((option) => option.value)
    );
  };

  // console.log("selectedCity", selectedCity[0]);

  useEffect(() => {
    async function getAllRegions() {
      try {
        let data = await getRegions();
        setSaudiRegions(data?.data?.data);
      } catch (error) {
        console.error("Failed to fetch regions:", error);
      }
    }
    getAllRegions();
  }, []);

  useEffect(() => {
    async function getAllCitiesFunc() {
      console.log("userfsfsf", userInfo?.region?.areaId);
      try {
        if (userInfo?.region) {
          let data = await getCities(userInfo?.region?.areaId);
          console.log("saudiCities in createProjects -> ", data.data.data);
          setSaudiCities(data.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch Cities:", error);
      }
    }
    getAllCitiesFunc();
  }, [userInfo?.region, getCities]);

  console.log("saudiCities", saudiCity);

  const saudiRegionsOptions = saudiRegions.map((region) => ({
    value: region._id,
    label: i18n.language === "en" ? region?.name : region.nameArabic,
  }));

  const saudiCityOptions = saudiCity.map((city) => ({
    value: city?._id,
    label: i18n.language === "en" ? city?.cityName : city.cityNameArabic,
  }));

  console.log("saudiCityOptions-->", saudiCityOptions, formik.values.city);

  async function callDomainType(params) {
    try {
      const data = await callDomainForOrg();

      setDomain(data?.data?.domains);
    } catch (error) {
      console.log(error);
    }
  }

  // console.log(formik.values)
  useEffect(() => {
    callDomainType();
    console.log("projectDetail", projectDetail);
    if (projectDetail) {
      const filteredData = {};
      console.log("111 ", projectDetail);
      Object.keys(createProjectBasicDetailInitialValues).forEach((key) => {
        if (projectDetail.hasOwnProperty(key)) {
          filteredData[key] = projectDetail[key];
        }
        if (projectDetail[key] == null) {
          filteredData[key] = "";
        }
      });
      console.log("filteredDAta", filteredData);

      // Set filtered data as form values
      filteredData.financialDetails = filteredData?.financialDetails.map(
        (item) => ({
          ...item,
          vat: 0,
          totalprice: 0,
        })
      );
      formik.setValues(filteredData);
      // console.log("1111  ",filteredData)
      formik.setFieldValue("domain", `${filteredData?.domain?._id}`);
      formik.setFieldValue(
        "region",
        filteredData?.region?.map((item) => item?._id)
      );
      formik.setFieldValue(
        "city",
        filteredData?.city?.map((item) => item?._id)
      );
      // console.log("2222 ",formik.values)
      // Convert the UTC date string to a moment object
      // const momentDate = moment(filteredData?.startDate);

      // // Convert the moment object to a Hijri date moment object
      // const hijriMomentDate = momentDate.clone().locale('en')

      // console.log("startDate without object" , filteredData?.startDate)
      // console.log("startDate backend date formatted ->" , hijriMomentDate.format("iYYYY/iM/iD"))
      // console.log("startDate backend moment object ->" , hijriMomentDate)

      // const hijriDate = moment().format("iYYYY/iM/iD");
      // const initialDate = moment(hijriDate, "iYYYY/iM/iD");

      formik.setFieldValue("startDate", moment(filteredData?.startDate));
      formik.setFieldValue("closeDate", moment(filteredData?.closeDate));
      const objectivedata = filteredData.projectObjectives;
      formik.setFieldValue("projectObjectives", objectivedata);

      const justificationData = filteredData.projectJustification;
      formik.setFieldValue("projectJustification", justificationData);
      // formik.setValues(projectDetail)
    }
  }, []);

  const initialInput = {
    financialDetailsItem: "",
    financialDetailsQuantity: 0,
    financialDetailsPrice: 0,
  };

  const [formInput, setFormInput] = useState([initialInput]);

  const handleAdd = () => {
    setFormInput(formInput.concat(initialInput));
  };

  const handleAddItem = () => {
    formik.setFieldValue("financialDetails", [
      ...formik.values.financialDetails,
      { item: "", quantity: "", price: "",beforeVat:"",afterVat:"" },
    ]);
  };

  const handleRemoveItem = (index) => {
    const financialDetails = formik.values.financialDetails.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("financialDetails", financialDetails);
  };

  const handleAdd1Item = () => {
    // formik.setFieldValue("projectObjectives", ["", ...formik.values.projectObjectives]);
    if (formik.values.projectObjectives) {
      const s = formik.values.projectObjectives?.filter((item) => item !== "");

      formik.setFieldValue("projectObjectives", ["", ...s]);
    }
  };

  const handleRemove1Item = (index) => {
    const projectObjectives = formik.values.projectObjectives.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("projectObjectives", projectObjectives);
  };

  const handleAdd2Item = () => {
    // formik.setFieldValue("projectJustification", ["", ...formik.values.projectJustification]);
    if (formik.values.projectJustification) {
      const s = formik.values.projectJustification?.filter(
        (item) => item !== ""
      );

      formik.setFieldValue("projectJustification", ["", ...s]);
    }
  };

  const handleRemove2Item = (index) => {
    const projectJustification = formik.values.projectJustification.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("projectJustification", projectJustification);
  };

  const handleAddMembersPerformance = () => {
    // formik.setFieldValue("projectJustification", ["", ...formik.values.projectJustification]);
    if (formik.values.teamMembersPerformanceMeasurement) {
      const s = formik.values.teamMembersPerformanceMeasurement?.filter(
        (item) => item !== ""
      );

      formik.setFieldValue("teamMembersPerformanceMeasurement", ["", ...s]);
    }
  };
  const handleRemoveMembersPerformance = (index) => {
    const teamMembersPerformanceMeasurement =
      formik.values.teamMembersPerformanceMeasurement.filter(
        (_, i) => i !== index
      );
    formik.setFieldValue(
      "teamMembersPerformanceMeasurement",
      teamMembersPerformanceMeasurement
    );
  };

  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState({});

  const handleFileChange = async (event, fieldType) => {
    const selectedFile = event.target.files[0];
  
    if (selectedFile) {
      const isPdf = selectedFile.type === 'application/pdf';
      const isLessThan5MB = selectedFile.size < 5 * 1024 * 1024; // 5MB in bytes
  
      // Validate file type
      if (!isPdf) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldType]: 'Only PDF files are allowed.',
        }));
        setFiles((prevFiles) => ({
          ...prevFiles,
          [fieldType]: null,
        }));
        return;
      }
  
      // Validate file size
      if (!isLessThan5MB) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldType]: 'File size should be less than 5MB.',
        }));
        setFiles((prevFiles) => ({
          ...prevFiles,
          [fieldType]: null,
        }));
        return;
      }
  
      // Clear errors and set the file in state
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldType]: null,
      }));
      setFiles((prevFiles) => ({
        ...prevFiles,
        [fieldType]: selectedFile,
      }));
  
      // Create FormData and make the API request
      const formData = new FormData();
      formData.append("file", selectedFile);
  
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        // Update the field value with the uploaded file URL
        formik.setFieldValue(fieldType, response?.data?.fileUrl);
        
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldType]: 'File upload failed. Please try again.',
        }));
      }
    }
  };



  
  // console.log(formik)
  const uploadImage = async (e, fieldType) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const isJpgOrPng = 
        selectedFile.type === 'image/jpeg' || 
        selectedFile.type === 'image/png';
      const isLessThan5MB = selectedFile.size < 5 * 1024 * 1024; // 5MB in bytes
  
      if (!isJpgOrPng) {
        alert('Only JPG and PNG files are allowed.');
        return;
      }
  
      if (!isLessThan5MB) {
        alert('File size should be less than 5MB.');
        return;
      }
  
      const image = new Image();
      image.src = URL.createObjectURL(selectedFile);
  
      image.onload = async () => {
        const { width, height } = image;
        const isAspectRatioValid = width === height;
  
        if (!isAspectRatioValid) {
          alert('The image must have a 1:1 aspect ratio (equal width and height).');
          return;
        }
  
        // If all validations pass, proceed with the upload
        const formData = new FormData();
        formData.append("file", selectedFile);
  
        try {
          let data = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
  
          // Handle response and update the form field
          formik.setFieldValue(fieldType, data?.data?.fileUrl);
        } catch (error) {
          alert('File upload failed. Please try again.');
        }
      };
  
      image.onerror = () => {
        alert('Invalid image file.');
      };
    }
  };

  // const [value, setValue] = useState(initialDate); // Initialize with the moment object
  const handleDateChange = useCallback(
    (date, fieldName) => {
      formik.setFieldValue(fieldName, date);
    },
    [formik]
  );
  // console.log("formicvalues", formik.values);

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  // useEffect(() => {
  //   let totalbeforevat = formik.values.financialDetails.reduce(
  //     (acc, curr) => acc + curr.quantity * parseFloat(curr.price || 0),
  //     0
  //   );
  //   totalbeforevat = formik?.values?.teamMembersFundingPercentage
  //     ? totalbeforevat -
  //       (totalbeforevat * formik.values?.teamMembersFundingPercentage) / 100
  //     : totalbeforevat;


  //   const total = formik.values.financialDetails.reduce(
  //     (acc, curr) =>
  //       acc +
  //       (curr.quantity * parseFloat(curr.price || 0) * 0.15 +
  //         curr.quantity * parseFloat(curr.price || 0)),
  //     0
  //   );

  //   formik.setFieldValue(
  //     "totalAfterVat",
  //     (totalbeforevat * 1.15).toFixed(2)
  //   );
  //   formik.setFieldValue("totalBeforeVat", totalbeforevat.toFixed(2));
  // }, [formik.values.financialDetails]);

  useEffect(() => {
    let totalbeforevat = formik.values.financialDetails.reduce((acc, curr, index) => {
      if (parseFloat(curr.price) > 0) {
        const beforeVat = curr.quantity * parseFloat(curr.price);
        const vat = (curr.quantity * parseFloat(curr.price) * 0.15).toFixed(2);
        const afterVat = curr.quantity * parseFloat(curr.price) * 0.15 + curr.quantity * parseFloat(curr.price);
        formik.setFieldValue(`financialDetails.${index}.beforeVat`, beforeVat);
        formik.setFieldValue(`financialDetails.${index}.vat`, vat);
        formik.setFieldValue(`financialDetails.${index}.afterVat`, afterVat);
        return acc + beforeVat;
      } else {
        formik.setFieldValue(`financialDetails.${index}.beforeVat`, 0);
        formik.setFieldValue(`financialDetails.${index}.vat`, 0);
        formik.setFieldValue(`financialDetails.${index}.afterVat`, 0);
        return acc;
      }
    }, 0);
  
    totalbeforevat = formik?.values?.teamMembersFundingSource || formik?.values?.teamMembersPercentageOfEachPartner
      ? totalbeforevat -
        (totalbeforevat * ((formik?.values?.teamMembersFundingSource ? formik.values?.teamMembersFundingPercentage ?? 0 : 0)+(formik?.values?.teamMembersPercentageOfEachPartner??0))) / 100
      : totalbeforevat;
  
    formik.setFieldValue("totalAfterVat", (totalbeforevat * 1.15).toFixed(2));
    formik.setFieldValue("totalBeforeVat", totalbeforevat.toFixed(2));
  }, [
    formik.values.financialDetails,
    formik.values.teamMembersFundingPercentage,
    formik.values.teamMembersFundingSource,
    formik.values.teamMembersPercentageOfEachPartner,
  ]);
  

  async function getProfileData() {
    let data = await getProfile();
    console.log("data", data);
    setUserInfo(data?.data?.charity);
  }
  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    if (userInfo?.region && !formik.values.region.length) {
      const initialRegion = {
        value: userInfo.region._id,
        label:
          i18n.language == "en"
            ? userInfo.region.name
            : userInfo.region?.nameArabic,
      };
      formik.setFieldValue("region", [initialRegion.value]);
    }
  }, [userInfo, formik.values.region]);

  useEffect(() => {
    if (userInfo && userInfo?.city && !formik.values?.city?.length) {
      const initialRegion = {
        value: userInfo.city._id,
        label: userInfo.city.cityBane,
      };
      formik.setFieldValue("city", [initialRegion.value]);
    }
  }, [userInfo, formik.values?.city]);

  console.log(
    "formik?.values?.teamMembersFundingPercentage",
    formik?.values?.teamMembersFundingPercentage
  );
  const [steps, setSteps] = useState(1);
  const stepFields = {
    1: ['projectName', 'domain', 'region', 'projectJustification', 'projectOverview', 'projectObjectives', 'targetedAudience', 'projectImage'],
    2: ['expectedOutcomes', 'expectedImpact', 'creativeInnovativeAspect', 'startDate', 'closeDate', 'teamMembersProjectImplementation', 'partnershipstype', 'teamMembersPartnerships', 'teamMembersPercentageOfEachPartner', 'teamMembersFundingSource', 'remark', 'rating','teamMembersPerformanceMeasurement','teamMembersSupportingEntityStrategy', 'teamMembersFundingPercentage','teamMembersLastProjectsReports'],
    3: ['projectManagerName', 'teamMembersFulltime', 'teamMembersVolunteer'],
    4: ['financialDetails'],
    5: ['plansActivity', 'plansTimeline', 'plansPerformance'],
  };
  const handleBack = () => {
    setSteps(steps - 1);
  };
  
  const handleNext = async () => {
    const allErrors = await formik.validateForm();
  
    const currentStepFields = stepFields[steps];
  
    const currentStepErrors = Object.keys(allErrors)
      .filter((field) => currentStepFields.includes(field))
      .reduce((acc, field) => {
        acc[field] = allErrors[field];
        return acc;
      }, {});
  
    formik.setTouched(
      currentStepFields.reduce((acc, field) => {
        acc[field] = true;
        return acc;
      }, {})
    );
    formik.setErrors(currentStepErrors);
  
    if (Object.keys(currentStepErrors).length === 0) {
      setSteps(steps + 1);
    }
  };
  
  return (
    <div>
       <div className="flex justify-between text-center mb-6">
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${steps>=1 ?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"}  rounded-full text-lg leading-loose `}>1</div>
          <span className="text-sm font-semibold text-gray-400 ml-2">{t(`General Information`)}</span>
        </div>
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${steps>=2?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"} text-gray-600 rounded-full text-lg leading-loose`}>2</div>
          <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Detailed Information`)}</span>
        </div>
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${steps>=3?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"} text-gray-600 rounded-full text-lg leading-loose`}>3</div>
          <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Project Team`)}</span>
        </div>
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${steps>=4?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"} text-gray-600 rounded-full text-lg leading-loose`}>4</div>
          <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Detailed Project Budget`)}</span>
        </div>
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${steps>=5?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"} text-gray-600 rounded-full text-lg leading-loose`}>5</div>
          <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Project Timeline`)}</span>
        </div>

      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-4">
          {/* first section */}
          {steps === 1 && 
             <div className="w-full">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("General Information")}
            </p>
            <div className="pb-3 w-full">
              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%] ">
                  <label
                    htmlFor="domain"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Project Domain`)}
                  </label>
                  <select
                    type="text"
                    name="domain"
                    id="domain"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Select")}
                    onChange={(e) =>
                      formik.setFieldValue("domain", e.target.value)
                    }
                    value={formik.values.domain}
                  >
                    <option>{t("Select")}</option>
                    {domain?.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.domain && formik.errors.domain ? (
                    <p className="text-red-500">{formik.errors.domain}</p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="projectName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Project Name`)}
                  </label>
                  <input
                    type="text"
                    name="projectName"
                    id="projectName"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter name")}
                    {...formik.getFieldProps("projectName")}
                  />
                  {formik.touched.projectName && formik.errors.projectName ? (
                    <p className="text-red-500">{formik.errors.projectName}</p>
                  ) : null}
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%]">
                  <div className="flex items-center justify-between mb-3">
                    <label
                      htmlFor="projectJustification"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Project Justification")}
                    </label>
                    <div
                      onClick={handleAdd2Item}
                      className="cursor-pointer  border px-2 w-fit text-center border-gray-300 rounded-md text-black"
                    >
                      {t("Add")}
                    </div>
                  </div>
                  {formik.values.projectJustification?.map((newItem, index) => {
                    return (
                      <div className="mt-1 rounded-md focus:ring-indigo-500 focus:outline-none  focus:border-indigo-500 flex item-center justify-center gap-1">
                        <input
                          type="text"
                          id="projectJustification"
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder={t("Enter Project Justification")}
                          // {...formik.getFieldProps('projectJustification')}
                          name={`projectJustification.${index}`}
                          value={newItem}
                          onChange={formik.handleChange}
                        />

                        {formik.values.projectJustification.length > 1 && (
                          <button
                            onClick={() => handleRemove2Item(index)}
                            className="text-red-700 text-2xl h-full py-2 px-3 rounded "
                          >
                            -
                          </button>
                        )}
                      </div>
                    );
                  })}
                  {formik.touched.projectJustification &&
                  formik.errors.projectJustification ? (
                    <p className="text-red-500">
                      {formik.errors.projectJustification}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="projectOverview"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Project Overview`)}
                  </label>
                  <input
                    type="text"
                    name="projectOverview"
                    id="projectOverview"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter project overview")}
                    {...formik.getFieldProps("projectOverview")}
                  />
                  {formik.touched.projectOverview &&
                  formik.errors.projectOverview ? (
                    <p className="text-red-500">
                      {formik.errors.projectOverview}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%] ">
                  <div className="flex items-center justify-between mb-3">
                    <label
                      htmlFor="projectObjectives"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Project Objectives")}
                    </label>
                    <div
                      onClick={handleAdd1Item}
                      className="cursor-pointer  border px-2 w-fit text-center border-gray-300 rounded-md text-black"
                    >
                      {t("Add")}
                    </div>
                  </div>
                  {/* <InputValue onUpdate={handleUpdate}/> */}
                  {formik.values.projectObjectives?.map((newItem, index) => {
                    return (
                      <div className="mt-1 rounded-md focus:ring-indigo-500 focus:outline-none  focus:border-indigo-500 flex item-center justify-center gap-1">
                        <input
                          type="text"
                          id="projectObjectives"
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder={t("Enter Project Objectives")}
                          // {...formik.getFieldProps('projectObjectives')}
                          name={`projectObjectives.${index}`}
                          value={newItem}
                          onChange={formik.handleChange}
                        />

                        {formik.values.projectObjectives.length > 1 && (
                          <button
                            onClick={() => handleRemove1Item(index)}
                            className="text-red-700 text-2xl h-full py-2 px-3 rounded "
                          >
                            -
                          </button>
                        )}
                      </div>
                    );
                  })}
                  {formik.touched.projectObjectives &&
                  formik.errors.projectObjectives ? (
                    <p className="text-red-500">
                      {formik.errors.projectObjectives}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="targetedAudience"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Targeted Audience`)}
                  </label>
                  <input
                    type="text"
                    name="targetedAudience"
                    id="targetedAudience"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter target audience")}
                    {...formik.getFieldProps("targetedAudience")}
                  />
                  {formik.touched.targetedAudience &&
                  formik.errors.targetedAudience ? (
                    <p className="text-red-500">
                      {formik.errors.targetedAudience}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Region`)}
                  </label>
                  <Select
                    id="region"
                    name="region"
                    isMulti
                    options={saudiRegionsOptions}
                    className="mt-1 block w-full"
                    classNamePrefix="react-select"
                    placeholder={t("Region")}
                    value={
                      userInfo?.region
                        ? [
                            {
                              id: userInfo.region._id,
                              label:
                                i18n.language == "en"
                                  ? userInfo.region.name
                                  : userInfo.region?.nameArabic,
                            },
                          ]
                        : []
                    }
                    isDisabled={true}
                    onChange={handleRegionChange}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#f0f0f0",
                        cursor: "not-allowed",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                    }}
                  />

                  {formik.touched.region && formik.errors.region ? (
                    <p className="text-red-500">{formik.errors.region}</p>
                  ) : null}
                </div>

                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="projectImage"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Project Picture`)}
                  </label>
                  <div className="flex items-center">
                    {projectDetail?.projectImage && (
                      <img src={projectDetail?.projectImage} width={40} />
                    )}
                    <input
                      type="file"
                      name="projectImage"
                      id="projectImage"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => uploadImage(e, "projectImage")}
                    />
                  </div>

                  {formik.touched.projectImage && formik.errors.projectImage ? (
                    <p className="text-red-500">{formik.errors.projectImage}</p>
                  ) : null}
                </div>
              </div>

              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`City`)}
                  </label>
                  <Select
                    id="city"
                    name="city"
                    isMulti
                    options={saudiCityOptions}
                    className="mt-1 block w-full"
                    classNamePrefix="react-select"
                    placeholder={t("City")}
                    value={saudiCityOptions?.filter((option) =>
                      formik?.values?.city.includes(option.value)
                    )}
                    onChange={handleRegionCity}
                  />

                  {formik.touched.city && formik.errors.city ? (
                    <p className="text-red-500">{formik.errors.city}</p>
                  ) : null}
                </div>
              </div>
             </div>
            <div className="w-full flex justify-end">
              <button onClick={() => handleNext()}  className="inline-flex justify-center py-2 submitButtonLoader px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{t(`Next`)}</button>
            </div>
            </div>
          }

          {/* second section */}
          {steps === 2 && 
            <div className="w-full">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Detailed Information")}
            </p>
            <div className="pb-3 w-full">
              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="expectedOutcomes"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Expected Outcomes`)}
                  </label>
                  <input
                    type="text"
                    name="expectedOutcomes"
                    id="expectedOutcomes"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter expected outcome")}
                    {...formik.getFieldProps("expectedOutcomes")}
                  />
                  {formik.touched.expectedOutcomes &&
                  formik.errors.expectedOutcomes ? (
                    <p className="text-red-500">
                      {formik.errors.expectedOutcomes}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="expectedImpact"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Expected Impact`)}
                  </label>
                  <input
                    type="text"
                    name="expectedImpact"
                    id="expectedImpact"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter expected impact")}
                    {...formik.getFieldProps("expectedImpact")}
                  />
                  {formik.touched.expectedImpact &&
                  formik.errors.expectedImpact ? (
                    <p className="text-red-500">
                      {formik.errors.expectedImpact}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="creativeInnovativeAspect"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Creative And Innovative Aspect`)}
                  </label>
                  <input
                    type="text"
                    name="creativeInnovativeAspect"
                    id="creativeInnovativeAspect"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter creative innovative aspect")}
                    {...formik.getFieldProps("creativeInnovativeAspect")}
                  />
                  {formik.touched.creativeInnovativeAspect &&
                  formik.errors.creativeInnovativeAspect ? (
                    <p className="text-red-500">
                      {formik.errors.creativeInnovativeAspect}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="partnershipstype"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Team Members Partnerships`)}
                  </label>
                  <input
                    type="number" min="0"
                    name="teamMembersPartnerships"
                    id="teamMembersPartnerships"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter team members partnership")}
                    {...formik.getFieldProps("teamMembersPartnerships")}
                  />
                  {formik.touched.teamMembersPartnerships &&
                  formik.errors.teamMembersPartnerships ? (
                    <p className="text-red-500">
                      {formik.errors.teamMembersPartnerships}
                    </p>
                  ) : null}
                </div>
              </div>
              <formidiv className="flex w-full gap-10">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="partnershipstype"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Partnerships Type`)}
                  </label>
                  <input
                    type="text"
                    name="partnershipstype"
                    id="partnershipstype"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter partnership type")}
                    {...formik.getFieldProps("partnershipstype")}
                  />
                  {formik.touched.partnershipstype &&
                  formik.errors.partnershipstype ? (
                    <p className="text-red-500">
                      {formik.errors.partnershipstype}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="teamMembersPercentageOfEachPartner"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Percentage That Each Partner Covers`)}
                  </label>
                  <input
                    type="number" min="0"
                    name="teamMembersPercentageOfEachPartner"
                    id="teamMembersPercentageOfEachPartner"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter team members percentage")}
                    {...formik.getFieldProps(
                      "teamMembersPercentageOfEachPartner"
                    )}
                  />
                  {formik.touched.teamMembersPercentageOfEachPartner &&
                  formik.errors.teamMembersPercentageOfEachPartner ? (
                    <p className="text-red-500">
                      {formik.errors.teamMembersPercentageOfEachPartner}
                    </p>
                  ) : null}
                </div>
              </formidiv>
              <div className="flex w-full justify-between gap-10 my-4">
                <div className="w-[50%]">
                  <div className="flex items-center justify-between mb-3">
                    <label
                      htmlFor="teamMembersPerformanceMeasurement"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Performance Measurement")}
                    </label>
                    <div
                      onClick={handleAddMembersPerformance}
                      className="cursor-pointer  border px-2 w-fit text-center border-gray-300 rounded-md text-black"
                    >
                      {t("Add")}
                    </div>
                  </div>
                  {formik.values.teamMembersPerformanceMeasurement?.map(
                    (newItem, index) => {
                      return (
                        <div className="mt-1 rounded-md focus:ring-indigo-500 focus:outline-none  focus:border-indigo-500 flex item-center justify-center gap-1">
                          <input
                            type="text"
                            id="teamMembersPerformanceMeasurement"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder={t("Enter Performance Measurement")}
                            // {...formik.getFieldProps('teamMembersPerformanceMeasurement')}
                            name={`teamMembersPerformanceMeasurement.${index}`}
                            value={newItem}
                            onChange={formik.handleChange}
                          />

                          {formik.values.teamMembersPerformanceMeasurement
                            .length > 1 && (
                            <button
                              onClick={() =>
                                handleRemoveMembersPerformance(index)
                              }
                              className="text-red-700 text-2xl h-full py-2 px-3 rounded "
                            >
                              -
                            </button>
                          )}
                        </div>
                      );
                    }
                  )}
                  {formik.touched.teamMembersPerformanceMeasurement &&
                  formik.errors.teamMembersPerformanceMeasurement ? (
                    <p className="text-red-500">
                      {formik.errors.teamMembersPerformanceMeasurement}
                    </p>
                  ) : null}
                </div>

                <div className="mb-4 w-[50%]">
                  {/* <label
                    htmlFor="startDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Start Date`)}
                  </label> */}
                  {/* <input type="date" name="startDate" id="startDate" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter starting date")}

                                {...formik.getFieldProps('startDate')}
                            /> */}
                  {/* <DatePicker
                    selected={formik.values.startDate}
                    locale="ar-EG"
                    onChange={(date) => handleDateChange(date, "startDate")}
                    calendar="hijri"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onKeyDown={(e) => e.preventDefault()}
                    shouldCloseOnSelect={false}
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                      },
                    }}
                  /> */}
                  {/* <HijriDatePicker label={"Start Date"}/> */}
                  <HijriDatePicker
                    value={formik.values.startDate}
                    onChange={(date) => handleDateChange(date, "startDate")}
                    label="Start Date"
                    name={"startDate"}
                  />
                  {formik.touched.startDate && formik.errors.startDate ? (
                    <p className="text-red-500">{formik.errors.startDate}</p>
                  ) : null}
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className="mb-4 w-[50%]">
                  {/* <label
                    htmlFor="closeDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`End Date`)}
                  </label> */}
                  {/* <input type="date" name="closeDate" id="closeDate" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter closing date")}
                                    {...formik.getFieldProps('closeDate')}
                                /> */}
                  {/* <DatePicker
                    selected={formik?.values?.closeDate}
                    locale="ar-EG"
                    onChange={(e) => handleDateChange(e, "closeDate")}
                    calendar="hijri"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    minDate={moment(formik?.values?.startDate)
                      .add(1, "days")
                      .toDate()} // Set minDate to startDate + 1 day
                  /> */}
                  {/* <DatePicker
                    selected={formik.values.closeDate}
                    locale="ar-EG"
                    onChange={(date) => handleDateChange(date, "closeDate")}
                    calendar="hijri"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onKeyDown={(e) => e.preventDefault()}
                    shouldCloseOnSelect={false}
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                      },
                    }}
                    minDate={moment(formik?.values?.startDate)
                      .add(1, "days")
                      .toDate()} // Set minDate to startDate + 1 day
                  /> */}
                  <HijriDatePicker
                    value={formik.values.closeDate}
                    onChange={(date) => handleDateChange(date, "closeDate")}
                    label="End Date"
                    name={"closeDate"}
                    minDate={moment(new Date(formik?.values?.startDate))}
                  />
                  {formik.touched.closeDate && formik.errors.closeDate ? (
                    <p className="text-red-500">{formik.errors.closeDate}</p>
                  ) : null}
                </div>

                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="teamMembersProjectImplementation"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Project Implementation`)}
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio h-4 w-4 text-indigo-600"
                      name="teamMembersProjectImplementation"
                      value="true" // Radio button value should be a string
                      onChange={() => {
                        formik.setFieldValue(
                          "teamMembersProjectImplementation",
                          true
                        );
                        setProjectImpdummy(true);
                      }}
                      checked={
                        formik.values.teamMembersProjectImplementation === true
                      }
                    />
                    <span className="ml-2 text-gray-700">{t(`Yes`)}</span>
                  </label>
                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      className="form-radio h-4 w-4 text-indigo-600"
                      name="teamMembersProjectImplementation"
                      value="false" // Radio button value should be a string
                      onChange={() => {
                        formik.setFieldValue(
                          "teamMembersProjectImplementation",
                          false
                        );
                        setProjectImpdummy(false);
                      }}
                      checked={
                        formik.values.teamMembersProjectImplementation === false
                      }
                    />
                    <span className="ml-2 text-gray-700">{t(`No`)}</span>
                  </label>

                  {formik.touched.teamMembersProjectImplementation &&
                  formik.errors.teamMembersProjectImplementation ? (
                    <p className="text-red-500">
                      {formik.errors.teamMembersProjectImplementation}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="flex w-full gap-10 ">
                <div className="mb-4 w-[50%]">
                  <label
                    htmlFor="teamMembersSupportingEntityStrategy"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Supporting The Entity Strategy`)}
                  </label>
                  <input
                    type="text"
                    name="teamMembersSupportingEntityStrategy"
                    id="teamMembersSupportingEntityStrategy"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter supporting entity strategy")}
                    {...formik.getFieldProps(
                      "teamMembersSupportingEntityStrategy"
                    )}
                  />
                  {formik.touched.teamMembersSupportingEntityStrategy &&
                  formik.errors.teamMembersSupportingEntityStrategy ? (
                    <p className="text-red-500">
                      {formik.errors.teamMembersSupportingEntityStrategy}
                    </p>
                  ) : null}
                </div>

                <div className="flex flex-col w-[50%]">
                  <div className="mb-4">
                    <label
                      htmlFor="teamMembersFundingSource"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t(`Does the organization have another funding source ?`)}
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio h-4 w-4 text-indigo-600"
                        name="teamMembersFundingSource"
                        value="true" // Use string values for radio buttons
                        onChange={() => {
                          formik.setFieldValue(
                            "teamMembersFundingSource",
                            true
                          );
                          setDummy(true);
                        }}
                        checked={
                          formik.values.teamMembersFundingSource === true
                        }
                      />
                      <span className="ml-2 text-gray-700">{t(`Yes`)}</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <input
                        type="radio"
                        className="form-radio h-4 w-4 text-indigo-600"
                        name="teamMembersFundingSource"
                        value="false" // Use string values for radio buttons
                        onChange={() => {
                          formik.setFieldValue(
                            "teamMembersFundingSource",
                            false
                          );
                          setDummy(true);
                        }}
                        checked={
                          formik.values.teamMembersFundingSource === false
                        }
                      />
                      <span className="ml-2 text-gray-700">{t(`No`)}</span>
                    </label>

                    {formik.touched.teamMembersFundingSource &&
                    formik.errors.teamMembersFundingSource ? (
                      <p className="text-red-500">
                        {formik.errors.teamMembersFundingSource}
                      </p>
                    ) : null}
                  </div>

                  {formik.values.teamMembersFundingSource ? (
                    <div className="mb-4">
                      <label
                        htmlFor="teamMembersFundingPercentage"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("Funding Percentage")}
                      </label>
                      <input
                        type="number" min="0"
                        name="teamMembersFundingPercentage"
                        id="teamMembersFundingPercentage"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder={t("Enter Funding Percentage")}
                        {...formik.getFieldProps(
                          "teamMembersFundingPercentage"
                        )}
                      />
                      {formik.touched.teamMembersFundingPercentage &&
                      formik.errors.teamMembersFundingPercentage ? (
                        <p className="text-red-500">
                          {formik.errors.teamMembersFundingPercentage}
                        </p>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* <div className="mb-4">
                <div className="mb-4">
                  <label htmlFor="teamMembersLastProjectsReports" className="block text-sm font-medium text-gray-700">{t(`Old Reports`)} </label>
                  <input type="file" name="teamMembersLastProjectsReports" id="teamMembersLastProjectsReports" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter last project reports"
                    //  {...formik.getFieldProps('teamMembersLastProjectsReports')} 


                    onChange={(e) => uploadImage(e, "teamMembersLastProjectsReports")}
                  />


                </div>
                {formik.touched.teamMembersLastProjectsReports && formik.errors.teamMembersLastProjectsReports ? (
                  <p className="text-red-500">{formik.errors.teamMembersLastProjectsReports}</p>
                ) : null}
              </div> */}

              {formik.values.teamMembersProjectImplementation ? (
                <div className="mb-4">
                  <div className="mb-4">
                    <label
                      htmlFor="teamMembersLastProjectsReports"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t(`Old Reports`)}{" "}
                    </label>
                    <input
                      type="file"
                      name="teamMembersLastProjectsReports"
                      id="teamMembersLastProjectsReports"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter last project reports"
                      //  {...formik.getFieldProps('teamMembersLastProjectsReports')}

                      onChange={(e) =>
                        handleFileChange(e, "teamMembersLastProjectsReports")
                      }
                    />
                  </div>
                  {formik.touched.teamMembersLastProjectsReports &&
                  formik.errors.teamMembersLastProjectsReports ? (
                    <p className="text-red-500">
                      {formik.errors.teamMembersLastProjectsReports}
                    </p>
                  ) : null}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="w-full flex justify-between">
              <button onClick={() => handleBack()}  className="inline-flex justify-center py-2 submitButtonLoader px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{t(`Back`)}</button>
              <button onClick={() => handleNext()}  className="inline-flex justify-center py-2 submitButtonLoader px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{t(`Next`)}</button>
            </div>
            </div>
          }


          {/* third section */}
          {steps === 3 && 
            <div className="w-full">
              <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
                {t("Project Team")}
              </p>
              <div className="pb-3 w-full">
                <div className="flex w-full gap-10">
                  <div className="mb-4 w-[50%]">
                    <label
                      htmlFor="projectManagerName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t(`Project Manager Name`)}
                    </label>
                    <input
                      type="text"
                      name="projectManagerName"
                      id="projectManagerName"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder={t("Enter project manager name")}
                      {...formik.getFieldProps("projectManagerName")}
                    />
                    {formik.touched.projectManagerName &&
                    formik.errors.projectManagerName ? (
                      <p className="text-red-500">
                        {formik.errors.projectManagerName}
                      </p>
                    ) : null}
                  </div>
                  <div className="mb-4 w-[50%]">
                    <label
                      htmlFor="teamMembersFulltime"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t(`Full Time`)}
                    </label>
                    <input
                      type="number" min="0"
                      name="teamMembersFulltime"
                      id="teamMembersFulltime"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder={t("Enter Full Time")}
                      {...formik.getFieldProps("teamMembersFulltime")}
                    />
                    {formik.touched.teamMembersFulltime &&
                    formik.errors.teamMembersFulltime ? (
                      <p className="text-red-500">
                        {formik.errors.teamMembersFulltime}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="teamMembersVolunteer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t(`Volunteer`)}
                  </label>
                  <input
                    type="number" min="0"
                    name="teamMembersVolunteer"
                    id="teamMembersVolunteer"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={t("Enter volunteer")}
                    {...formik.getFieldProps("teamMembersVolunteer")}
                  />
                  {formik.touched.teamMembersVolunteer &&
                  formik.errors.teamMembersVolunteer ? (
                    <p className="text-red-500">
                      {formik.errors.teamMembersVolunteer}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="w-full flex justify-between">
              <button onClick={() => handleBack()}  className="inline-flex justify-center py-2 submitButtonLoader px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{t(`Back`)}</button>
              <button onClick={() => handleNext()}  className="inline-flex justify-center py-2 submitButtonLoader px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{t(`Next`)}</button>
            </div>
            </div>
          }

          {/* fourth section */}
          {steps === 4 && 
            <div className="w-full">
              <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
                {t("Detailed Project Budget")}
              </p>
              <div className="pb-3 w-full">
                <div>
                  {/* Mapping through the financialDetails array */}
                  {formik.values.financialDetails.map((newItem, index) => (
                    <div key={index} className="flex items-center gap-4">
                      {/* Item input field */}
                      <div>
                        <label htmlFor={`financialDetails.${index}.item`}>
                          {t(`Item`)}
                        </label>
                        <input
                          type="text"
                          name={`financialDetails.${index}.item`}
                          value={newItem.item}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {formik.errors.financialDetails?.[index]?.item &&
                        formik.touched.financialDetails?.[index]?.item ? (
                          <div className="text-red-500">
                            {formik.errors.financialDetails[index].item}
                          </div>
                        ) : null}
                      </div>
                      {/* Quantity input field */}
                      <div>
                        <label htmlFor={`financialDetails.${index}.quantity`}>
                          {t(`Quantity`)}
                        </label>
                        <input
                          type="number" min="0"
                          name={`financialDetails.${index}.quantity`}
                          value={newItem.quantity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {formik.errors.financialDetails?.[index]?.quantity &&
                        formik.touched.financialDetails?.[index]?.quantity ? (
                          <div className="text-red-500">
                            {formik.errors.financialDetails[index].quantity}
                          </div>
                        ) : null}
                      </div>
                      {/* Unit Price input field */}
                      <div>
                        <label htmlFor={`financialDetails.${index}.price`}>
                          {t(`Unit Price`)}
                        </label>
                        <input
                          type="number" min="0" 
                          name={`financialDetails.${index}.price`}
                          value={newItem.price}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {formik.errors.financialDetails?.[index]?.price &&
                        formik.touched.financialDetails?.[index]?.price ? (
                          <div className="text-red-500">
                            {formik.errors.financialDetails[index].price}
                          </div>
                        ) : null}
                      </div>

                      <div>
                        <label htmlFor={`financialDetails.${index}.beforeVat`}>
                          {t(`Before Vat`)}
                        </label>
                        <input
                          type="number" min="0"
                          name={`financialDetails.${index}.beforeVat`}
                          disabled
                          value={
                            formik.values.financialDetails[index].beforeVat || 0
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {/* {formik.errors.financialDetails?.[index]?.price && formik.touched.financialDetails?.[index]?.price ? (
                              <div className="text-red-500">{formik.errors.financialDetails[index].price}</div>
                          ) : null} */}
                      </div>

                      <div>
                        <label htmlFor={`financialDetails.${index}.vat`}>
                          {t(`Vat`)}
                        </label>
                        <input
                          type="number" min="0"
                          name={`financialDetails.${index}.vat`}
                          disabled
                          value={formik.values.financialDetails[index].vat || 0}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {formik.errors.financialDetails?.[index]?.vat &&
                        formik.touched.financialDetails?.[index]?.vat ? (
                          <div className="text-red-500">
                            {formik.errors.financialDetails[index].vat}
                          </div>
                        ) : null}
                      </div>

                      <div>
                        <label htmlFor={`financialDetails.${index}.afterVat`}>
                          {t(`After Vat`)}
                        </label>
                        <input
                          type="number" min="0"
                          disabled
                          name={`financialDetails.${index}.afterVat`}
                          value={
                            formik.values.financialDetails[index].afterVat || 0
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {formik.errors.financialDetails?.[index]?.afterVat &&
                        formik.touched.financialDetails?.[index]?.afterVat ? (
                          <div className="text-red-500">
                            {formik.errors.financialDetails[index].afterVat}
                          </div>
                        ) : null}
                      </div>
                      {/* Delete button */}
                      <div>
                        <button
                          type="button"
                          onClick={() => handleRemoveItem(index)}
                          className="mt-8 bg-red-700 text-white px-3 py-1 rounded"
                        >
                          {t("Delete")}
                        </button>
                      </div>
                    </div>
                  ))}
                  {/* Add Item button */}
                  <button
                    type="button"
                    onClick={handleAddItem}
                    className="mt-4 bg-green-700 text-white px-3 py-1 rounded"
                  >
                    {t(`Add Item`)}
                  </button>
                  <div className="flex  gap-2">
                    <div className="my-4 w-full">
                      <label
                        htmlFor="totalBeforeVat"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t(`Total Before Vat`)}
                      </label>
                      <input
                        type="number"
                        min="0"
                        name="totalBeforeVat"
                        id="totalBeforeVat"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        readOnly
                        // value={formik.values.financialDetails.reduce((acc,curr)=>acc + parseFloat(curr.price || 0), 0)}

                        value={formik.values.totalBeforeVat}
                      />
                      {formik.touched.totalBeforeVat &&
                      formik.errors.totalBeforeVat ? (
                        <p className="text-red-500">
                          {formik.errors.totalBeforeVat}
                        </p>
                      ) : null}
                    </div>
                    <div className="my-4 w-full">
                      <label
                        htmlFor="totalAfterVat"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t(`Total After Vat`)}
                      </label>
                      <input
                        type="number"
                        min="0"
                        name="totalAfterVat"
                        id="totalAfterVat"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        readOnly
                        // value={formik.values.financialDetails.reduce((acc,curr)=>acc + parseFloat(curr.price || 0), 0)}

                        value={formik.values.totalAfterVat}
                      />
                      {formik.touched.totalAfterVat &&
                      formik.errors.totalAfterVat ? (
                        <p className="text-red-500">
                          {formik.errors.totalAfterVat}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between">
              <button onClick={() => handleBack()}  className="inline-flex justify-center py-2 submitButtonLoader px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{t(`Back`)}</button>
              <button onClick={() => handleNext()}  className="inline-flex justify-center py-2 submitButtonLoader px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{t(`Next`)}</button>
            </div>
            </div>
          }
          
          {/* fifth section */}
          {steps === 5 &&
            <div className="w-full">
              <p className="text-2xl text-blue-900 mb-3 border-gray-300 border-b-2">
                {t("Project Timeline")}
              </p>
              <div className="pb-3 w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* Column 1 */}
                  <div>
                    {/* Entity Name */}

                    <div className="mb-4">
                      <label
                        htmlFor="plansActivity"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t(`Activities`)}
                      </label>
                      <input
                        type="text"
                        name="plansActivity"
                        id="plansActivity"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder={t("Enter activities")}
                        {...formik.getFieldProps("plansActivity")}
                      />
                      {formik.touched.plansActivity &&
                      formik.errors.plansActivity ? (
                        <p className="text-red-500">
                          {formik.errors.plansActivity}
                        </p>
                      ) : null}
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="plansPerformance"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t(`Performance`)}
                      </label>
                      <input
                        type="text"
                        name="plansPerformance"
                        id="plansPerformance"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder={t("Enter performance")}
                        {...formik.getFieldProps("plansPerformance")}
                      />
                      {formik.touched.plansPerformance &&
                      formik.errors.plansPerformance ? (
                        <p className="text-red-500">
                          {formik.errors.plansPerformance}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {/* Column 2 */}
                  <div>
                    <div className="mb-4">
                      <label
                        htmlFor="plansTimeline"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t(`Timeline`)}
                      </label>
                      <input
                        type="text"
                        name="plansTimeline"
                        id="plansTimeline"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder={t("Enter timeline")}
                        {...formik.getFieldProps("plansTimeline")}
                      />
                      {formik.touched.plansTimeline &&
                      formik.errors.plansTimeline ? (
                        <p className="text-red-500">
                          {formik.errors.plansTimeline}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between">
              <button onClick={() => handleBack()} className="inline-flex justify-center py-2 submitButtonLoader px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{t(`back`)}</button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t(`Upload The Project`)}
              </button>
            </div>
            </div>
          }

          {/* Column 2
          <div></div> */}
        </div>

        {/* Form Submit Button */}
        {/* <div className="mt-6 flex justify-between"> */}
          {/* {step>1&&<button onClick={()=>setStep((prev)=>prev-1)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Previous
                    </button>} */}

        {/* </div> */}
      </form>
      {isModalOpen && pathname !== "/update-project" && (
        <CreateProjectModal onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default DemoBasicDetailsForm;
