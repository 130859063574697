import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import 'tailwindcss/tailwind.css';

const msInSecond = 1000;
const msInMinute = 60 * 1000;
const msInAHour = 60 * msInMinute;
const msInADay = 24 * msInAHour;

const getPartsOfTimeDuration = duration => {
    const days = Math.max(Math.floor(duration / msInADay), 0);
    const hours = Math.max(Math.floor((duration % msInADay) / msInAHour), 0);
    const minutes = Math.max(Math.floor((duration % msInAHour) / msInMinute), 0);
    const seconds = Math.max(Math.floor((duration % msInMinute) / msInSecond), 0);

    return { days, hours, minutes, seconds };
};

const Timer = ({ endDateTime, startDate }) => {
    const [timeParts, setTimeParts] = useState(getPartsOfTimeDuration(endDateTime - startDate));
    const {t} = useTranslation()
    useEffect(() => {
        const interval = setInterval(() => {
            const timeDiff = endDateTime - Date.now();
            if (timeDiff <= 0) {
                clearInterval(interval);
                setTimeParts({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            } else {
                setTimeParts(getPartsOfTimeDuration(timeDiff));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [endDateTime]);

    return (
        <div className="count_down_box text-black flex flex-wrap justify-center">
            <div className="count_down   text-project_image_color bg-project-image-color rounded-lg p-2 mx-1 text-center">
                {timeParts.days}<span className="block text-xs">{t(`Days`)}</span>
            </div>
            <div className="count_down text-project_image_color bg-project-image-color rounded-lg p-2 mx-1 text-center">
                {timeParts.hours}<span className="block text-xs">{t(`Hours`)}</span>
            </div>
            <div className="count_down text-project_image_color bg-project-image-color rounded-lg p-2 mx-1 text-center">
                {timeParts.minutes}<span className="block text-xs">{t(`Minutes`)}</span>
            </div>
            <div className="count_down text-project_image_color bg-project-image-color rounded-lg p-2 mx-1 text-center">
                {timeParts.seconds}<span className="block text-xs">{t(`Seconds`)}</span>
            </div>
        </div>
    );
};

export default Timer;
