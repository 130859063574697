import * as React from 'react';
import moment from 'moment-hijri';
import { AdapterMomentHijri } from '@mui/x-date-pickers/AdapterMomentHijri';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import useTheme from '@mui/system/useTheme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

export default function HijriDatePicker({ 
    value,
    label,
    onChange,
    name,
    minDate = moment('1356-01-01', 'iYYYY-iMM-iDD'), // Default minimum date in Hijri
    maxDate = moment('1499-12-29', 'iYYYY-iMM-iDD'), // Default maximum date in Hijri
    }) {
    const existingTheme = useTheme();
    const theme = React.useMemo(
        () => createTheme({ direction: 'rtl' }, existingTheme),
        [existingTheme],
    );
    const { t, i18n } = useTranslation();
    return (
        <ThemeProvider theme={theme}>
            <div>
                <label
                    className="block text-sm font-medium text-gray-700"
                >
                    {t(`${label}`)}
                </label>
                <LocalizationProvider dateAdapter={AdapterMomentHijri}>
                    <DatePicker
                        value={value}
                        onChange={onChange}

                        // label="Date Picker"
                        // moment-hijri support dates between 1356-01-01 and 1499-12-29 H (1937-03-14 and 2076-11-26)
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </LocalizationProvider>
            </div>
        </ThemeProvider>
    );
}
