import React, { useEffect, useState } from 'react'
import { getALlBookingsApi } from '../../Api'
import { Link } from 'react-router-dom'
import ViewPdfModal from '../../Modal/ViewPdfModal'
import { useTranslation } from 'react-i18next'

const Reserve = ({reserve}) => {
  const [isModalOpen,setIsModalOpen] = useState(false)
  const [causeDetail,setCauseDetail] = useState({})
  const [amount,setAmount] = useState(0)
  const [donationStatus,setDonationStatus] = useState("Donation")

  function createPayment(item) {
    item._id = item?.project?._id
    item.projectName = item?.project?.projectName
    setCauseDetail(item)
    setIsModalOpen(true)
    setAmount(item?.amount)
    
  }
    
  const { t } = useTranslation()

    return (
      <div>
          <div className='shadow-lg rounded-md'>
              <div className="  h-full z-10   overflow-x-auto " >
                <table className="w-full divide-x text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 bg-white  uppercase  border-b  ">
                    <tr>
                    <th scope="col" className="px-6 py-3">
                      {t('Project Name')}
                    </th>

                    <th scope="col" className="px-6 py-3">
                      {t('Amount')}
                    </th>
                    <th scope="col" className="px-6 py-3">
                     {t('Status')}
                    </th>

  
                    </tr>
                  </thead>
                  {reserve?.length ? <tbody >
                    {reserve?.map((item, id) =>
                      <tr key={`jasdfa-${id}`} className="bg-white" >
                        {/* <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">
                        <input type="checkbox" />
                      </td> */}
                        <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">
                        {item?.description}
                      </td>
                      <td className="px-6 py-4 truncate">
                        {item?.amount}
                      </td>
                      <td className="px-6 py-4">
                        {t(item?.status)}
                      </td>
                      <td>
                        <button className='btn bg-project_bg_image_color p-2 text-white' onClick={()=>createPayment(item)}>{t('Donate')}</button>
                      </td>
  
  
                      </tr>
                    )}
  
                  </tbody> : <div className='text-center my-2'>{t('No data available')}</div>}
                </table>
              </div>
  
  
            </div> 
            {isModalOpen&&<ViewPdfModal onClose={setIsModalOpen} causeDetail = {causeDetail} amount={amount} donationStatus={donationStatus}/>}

      </div>
    )
}

export default Reserve