import { useRef, useState, useEffect } from 'react';
import Navbar from '../Components/Nav_Form/Navbar'
import { HiDotsVertical } from "react-icons/hi";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { createDonorApi, getAllProjectApi, getAnalyticsApi, updateReportApi } from '../Api';
import UploadReport from '../Modal/UploadReport';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Loader from "react-js-loader";
import { fetchlocalstorage } from '../HelperFunctions';
import UploadProjectAttatchment from '../Modal/UploadProjectAttatchment';
import ViewNotes from './ViewNotes'
import Header from '../Components/UI/Header';
import Footer from '../Components/Footer/Footer';



const ProjectsList = ({userInfo}) => {
  console.log({userInfo});

  const dropdownRefs = useRef([]);
  const [openDropdown, setOpenDropdown] = useState({});
  // const [items,setItems] = useState([])
  const [project, setProject] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [filter, setFilter] = useState('')
  const [analytics, setAnalytics] = useState([])
  const [nextPage, setNextPage] = useState(1)
  const navigate = useNavigate()
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const { t, i18n } = useTranslation()
  const [Loading, setLoading] = useState(false)
  const modalRef = useRef(null);
  const [uploadStatus, statusUploadStatus] = useState("")
  const { pathname } = useLocation()
  console.log(pathname)
  const [reloadPage, setReloadPage] = useState(false)
  const [charityInfo, setCharityInfo] = useState({})

  // if (typeof window !== 'undefined') {
  //   let getUserInfo = localStorage.getItem("userDetails");
  //   setCharityInfo(JSON.parse(getUserInfo));
  // }
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      let getUserInfo = localStorage.getItem("charityinfo");
      setCharityInfo(JSON.parse(getUserInfo));
    }
  }, [])


  // useEffect(() => {
  //   const handleClickOutside = (event, index) => {
  //     if (dropdownRefs.current[index] && !dropdownRefs.current[index].contains(event.target)) {
  //       setOpenDropdown(prevState => ({
  //         ...prevState,
  //         [index]: false
  //       }));
  //     }
  //   };


  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const toggleDropdown = (index) => {
    console.log(openDropdown)
    setOpenDropdown(openDropdown === index ? null : index);
  };
  async function getAllProjects() {
    let { data } = await getAllProjectApi(nextPage, 10, filter);
    // console.log(data?.data[0]._id)
    setLimit(data?.limit)
    setPage(data?.page)
    setTotal(data?.total)
    setTotalPages(data?.totalPages)
    setProject(data?.data)
    setLoading(true)
    // setItems(data)

  }

  const getAllAnalytics = async () => {
    let { data } = await getAnalyticsApi();
      // console.log(data)
    setAnalytics(data?.data)

  }
  useEffect(() => {

    getAllAnalytics()

  }, [])

  useEffect(() => {
    getAllProjects()
  }, [nextPage, reloadPage, filter])

  const handleOpenModal = (itemId, upload) => {
    setSelectedItemId(itemId);
    setIsModalOpen(true); // Open the modal when the button is clicked
    statusUploadStatus(upload)
  };


  const handleCloseModal = () => {
    setSelectedItemId(null);
    setIsModalOpen(false); // Close the modal
  };

  // const items = [
  //   { id: 0,  projectName: 'Back End Developer', domain: 'Engineering', projectProgress: 'Full-time',  projectGoal: 'Remote' , projectStatus:"Pending"},
  //   { id: 10,  projectName: 'Back End Developer', domainType: 'Engineering', projectProgress: 'Full-time',  projectGoal: 'Remote' , projectStatus:"Pending"},
  //   {id: 20,  projectName: 'Back End Developer', domainType: 'Engineering', projectProgress: 'Full-time',  projectGoal: 'Remote' , projectStatus:"Pending"},
  //   {id: 30,  projectName: 'Back End Developer', domainType: 'Engineering', projectProgress: 'Full-time',  projectGoal: 'Remote' , projectStatus:"Pending"},
  //   {id: 440,  projectName: 'Back End Developer', domainType: 'Engineering', projectProgress: 'Full-time',  projectGoal: 'Remote' , projectStatus:"Pending"}
  // ]

  let paginationButton = () => {
    let arr = []
    for (let i = 1; i <= totalPages; i++) {
      arr.push(

        <button
          key={i}
          onClick={() => setNextPage(i)}
          className={`relative z-10 inline-flex items-center ${nextPage === i ? 'bg-indigo-600' : ''} px-4 py-2 text-sm font-semibold ${nextPage === i ? 'text-white ' : 'text-black'} focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
        >
          {i}
        </button>

      )

    }
    // console.log(arr)
    return arr
  }
  // console.log(nextPage)
  async function sendForApproval(id, status) {


    try {
      let payload = { status }
      const res = await updateReportApi(payload, id);
      // console.log(res)
      setReloadPage(!reloadPage)
      if (res.status === 200 || res.status === 201) {

        if (res.status === 200 || res.status === 201) {

          toast.success(t("Project send for approval"), { id: "001" });
          //if donar created successfully update isProfile true for furter validation

        }

      }
    } catch (error) {
      toast.error(t(error?.response?.data?.message), { id: "err501" });
    }


  }
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
    if (dropdownRefs.current) {
      dropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          console.log(dropdownRefs)
          setOpenDropdown(null);
        }
      });
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div>

      {/* <Navbar /> */}

      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>

      <div className={`xs:p-2 sm:p-4 md:p-10 lg:p-16 mt-2 `} >
        {Loading ? <div>
          {pathname == "/my-analytics" && <button className='p-2 rounded text-white
           shadow bg-[#0b4d68] mb-2' onClick={() => setFilter('')}>{t("All Projects")}</button>}
          {pathname == "/my-analytics" && <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  gap-2'>
            {analytics.length > 0 ? analytics?.map(item => <div className=' p-2 rounded shadow-lg w-[12rem] sm:w-[14rem] bg-[#0b4d68] text-white flex justify-between' onClick={() => setFilter(item?.status)}>

              <div>
                <div className='text-sm'>{t(item?.status)}</div>
                <div>{item?.count}</div>

              </div>

              <div className="bg-gray-100 rounded-full h-12 w-12 my-2 flex items-center justify-center mr-4" style={{ backgroundColor: "rgb(82, 168, 181)" }}>
                <span className="text-black text-lg font-bold">{item?.status.charAt(0).toUpperCase()}</span>
              </div>

            </div>) : ("")}

          </div>}
          {pathname != "/my-analytics" && <div className='flex justify-between my-4'>
            {/* <input className="d-none border  shadow-sm rounded-lg	p-1 w-32 sm:w-40" type='text' placeholder='Search ' /> */}
            <div></div>
            <div className='flex gap-2'>
             {!charityInfo?.region?.isProjectBlocked && <div>
                <Link to="/create-project" className='border border-transparent shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 px-4 py-1 rounded-lg'>
                  {t(`Create Project`)}
                </Link>
              </div>}

              <div>
                <Link to={"https://drive.google.com/file/d/1iZnBHMkWZR6KQ0NppEDqQFIlkJjls4e2/view"} className='border border-transparent shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 px-4 py-1 rounded-lg' target='_blank'>
                  {t('Project Evaluation Standards')}
                </Link>
              </div>

            </div>
          </div>}
          <div className='shadow-lg rounded-md  '>

            <div className="  h-full  mt-4  overflow-x-auto " >
              <table className="w-full divide-x text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 bg-white  uppercase  border-b  ">
                  <tr>
                    {/* <th scope="col" className="px-6 py-3">
                    <input type="checkbox" disabled />
                  </th> */}
                  <th scope="col" className="px-6 py-3">
                      {t(`Id`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t(`Project Name`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t(`Region`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t(`Domain Type`)}
                    </th>

                    <th scope="col" className="px-6 py-3">
                      {t(`Project goal`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t(`PROJECT STATUS`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t(`DONATION STATUS`)}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t(`Actions`)}
                    </th>
                  </tr>
                </thead>
                <tbody >
                  {project?.length ? project?.map((item, id) =>{
                    console.log('item00',item);
                    return <tr key={`jasdfa-${id}`} className="bg-white" >
                      {/* <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">
                      <input type="checkbox" />
                    </td> */}
                      <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap">
                        {item?._id}
                      </td>
                      <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">
                        {item.projectName}
                      </td>
                      <td scope="row" className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap">
                        {item.region?.map(el => i18n.language == "en" ? el?.name : el?.nameArabic).join(', ')}
                      </td>
                      <td className="px-6 py-4">
                        {item?.domain?.name}
                      </td>

                      <td className="px-6 py-4">
                        {item.totalAfterVat}
                      </td>
                      <td className="px-6 py-4">
                        {t(item.status)}
                      </td>
                      <td className="px-6 py-4">
                        {/* {
                          item.status != "LIVE" ? ("") : (item?.raised == 0 ? (t("Awaiting Donation")) :  (item?.financialDetailsTotal == item?.raised ? t("Complete Donation") : t("Partial Donation") ))
                        } */}
                        {
                          item.status != "LIVE" ? ("") : t(item?.donationStatus)
                        }
                      </td>
                      <td className="px-6 py-4 cursor-pointer ">


                        <li className="px-4 py-4 list-none w-4 h-4	 text-center cursor-pointer  " >
                          <div onClick={(e) => e.stopPropagation()}>
                            <div className="">
                              <HiDotsVertical onClick={() => toggleDropdown(item._id)} />
                            </div>
                            {openDropdown === item._id && (
                              <div className={`absolute  z-50	  mt-2 origin-top-right  ${fetchlocalstorage("language") == "ar" ? "left-[20px] sm:left-[82px]" : "right-[20px] sm:right-[82px]"} bg-white border border-gray-200 rounded-md shadow-lg`}>
                                <div className="py-4 w-40 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <Link to={{ pathname: "/project-detail" }} state={{ id: item._id, name: item?.domain?.name }} className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" >{t(`View`)}</Link>

                                  {/* {item?.status == "DRAFT" || item?.status == "INITIAL_NOTES" || item?.status == "EXPERT_COMMENTED" || item?.status == "EXPERT_COMMENTED" || item?.status == "WORKSHOP_COMPLETED" ? <Link to="/update-project" state={{ projectDetail: item }} className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{t(`Edit`)}</Link> : ""} */}

                                  {!charityInfo?.region?.isProjectBlocked && (
                                    (item?.status == "DRAFT" || item?.status == "INITIAL_NOTES" || item?.status == "EXPERT_COMMENTED" || item?.status == "EXPERT_COMMENTED" || item?.status == "WORKSHOP_COMPLETED") ? (
                                      <Link
                                        to="/update-project"
                                        state={{ projectDetail: item }}
                                        className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem"
                                      >
                                        {t(`Edit`)}
                                      </Link>
                                    ) : null
                                  )}

                                  <Link to="#" onClick={() => handleOpenModal(item._id, "Report")} className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{t(`Upload Report`)}</Link>

                                  {item?.status == "DRAFT" || item?.status == "INITIAL_NOTES" || item?.status == "EXPERT_COMMENTED" || item?.status == "UNDER_MATURATION" || item?.status == "WORKSHOP_COMPLETED" ? <Link to="#" onClick={() => sendForApproval(item._id, item?.status == "DRAFT" ? "NEW_PROJECT" : item?.status == "EXPERT_COMMENTED"  ? "UNDER_MATURATION" : item?.status == "WORKSHOP_COMPLETED" ? "READY_FOR_EVALUTION": "UNDER_REVIEW")} className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{t(`Send For Approval`)} </Link> : ""
                                  }
                                  {
                                    item?.status == "LIVE" ? 
                                    <Link to='#' onClick={() => sendForApproval(item._id, "COMPLETED")} className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" >{t(`Complete donation`)}</Link> : ""
                                  }
                                  {item?.status == "COMPLETED" ?

                                    <Link to="#" onClick={() => handleOpenModal(item._id, "Attatchment")} className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{t(`Upload Attatchment`)}</Link>
                                    : ""
                                  }
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                      </td>
                    </tr>
}) : <div className='my-2 text-center'>{t('No data available')}</div>}

                </tbody>
              </table>
            </div>
            <div className='bg-whit   '>

              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                <div className="flex flex-1 justify-between sm:hidden">
                  <a
                    href="#"

                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    onClick={() => nextPage !== 1 && setNextPage((prev) => prev - 1)}
                  >
                    {t('Previous')}
                  </a>
                  <a
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    disabled={nextPage == totalPages ? true : false}
                    // disabled={true}
                    onClick={() => nextPage !== totalPages && setNextPage((prev) => prev + 1)}

                  >
                    {t('Next')}
                  </a>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      {t('Showing')} <span className="font-medium">{limit * (page - 1) + 1}</span> {t('to')} <span className="font-medium">{limit * (page - 1) + project?.length}</span> {t('of')}{' '}
                      <span className="font-medium">{total}</span> {t('results')}
                    </p>
                  </div>
                  <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                      <Link
                        to="#"
                        className={`relative inline-flex items-center ${fetchlocalstorage("language") == "ar" ? "rounded-r-md" : "rounded-l-md"} px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                        onClick={() => nextPage !== 1 && setNextPage((prev) => prev - 1)}

                      >
                        <span className="sr-only">{t('Previous')}</span>
                        <span className="h-5 w-5" aria-hidden="true" >{"<"}</span>
                      </Link>
                      {paginationButton().map(item => item)
                      }


                      <a
                        href="#"
                        className={`relative inline-flex items-center ${fetchlocalstorage("language") == "ar" ? "rounded-l-md" : "rounded-r-md"} px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                        onClick={() => nextPage !== totalPages && setNextPage((prev) => prev + 1)}

                      >
                        <span className="sr-only">{t(`Next`)}</span>
                        <span className="h-5 w-5" aria-hidden="true" >{">"}</span>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : <span> <Loader type="spinner-circle" bgColor={'black'} color={'black'} size={100} /></span>}
      </div>

      <Footer />

      {isModalOpen && (
        uploadStatus == "Report" ? <UploadReport onClose={handleCloseModal} id={selectedItemId} /> : <UploadProjectAttatchment onClose={handleCloseModal} id={selectedItemId} />
      )}
    </div>
  )
}

export default ProjectsList 