// ** MUI Imports
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IoIosArrowDropdownCircle } from "react-icons/io";

// ** Icon Imports
// import Icon from 'src/@core/components/icon'
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { t } from "i18next";

const ViewNotes = ({ projectComments, selectedInput }) => {
  const [filterList, setFilterList] = useState([]);
  let selectedKey = selectedInput.split(".");

  console.log("projectComments inside ViewNotes", projectComments);
  console.log("selectedInput in AdminPanel", selectedInput);

  // debugger
  useEffect(() => {
    let filterComments = [];

    if (selectedKey?.length > 1) {
      filterComments = projectComments?.filter(
        (el) =>
          el?.financialDetails?.[selectedKey[1]]?.[selectedKey[2]]?.note
            ?.length > 0
      );
      setFilterList(filterComments);
    } else {
      filterComments = projectComments?.filter(
        (el) => el?.[selectedInput]?.note?.length > 0
      );
      setFilterList(filterComments);
    }
  }, [projectComments]);

  // console.log(filterComments);

  return (
    <Box sx={{ padding: "20px" }}>
      {filterList?.length > 0 ? (
        filterList.map((item) => (
          <Accordion>
            <AccordionSummary
              id="panel-header-1"
              aria-controls="panel-content-1"
              // expandIcon={<Icon fontSize='1.25rem' icon='tabler:chevron-down' className='text-[1.25rem]' />}
              expandIcon={
                <IoIosArrowDropdownCircle className="text-[1.25rem]" />
              }
            >
              <Typography>{item?.createdBy?.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {selectedKey?.length > 1 ? (
                <>
                  {" "}
                  <Typography sx={{ color: "text.secondary" }}>
                    {t("Notes")} :{" "}
                    {
                      item?.financialDetails?.[selectedKey[1]]?.[selectedKey[2]]
                        ?.note
                    }
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {t("Recomendation")} :{" "}
                    {
                      item?.financialDetails?.[selectedKey[1]]?.[selectedKey[2]]
                        ?.recommendation
                    }
                  </Typography>
                </>
              ) : (
                <>
                  <Typography sx={{ color: "text.secondary" }}>
                    {t("Notes")}: {item?.[selectedInput]?.note}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {t("Recomendation")} :{" "}
                    {item?.[selectedInput]?.recommendation}
                  </Typography>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <div>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {t("No Notes found")}
            </div>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default ViewNotes;
