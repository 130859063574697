export const signinByOrgInitialValue = {
  email: "",
  password: "",
  // role:"CHARITY"

}
export const signUpByOrgInitialValue = {
 
  email: "",
  password: "",
  // role:"CHARITY"

}

export const organizationDetailsInitialValues = {
  name: '',
  domain: [],
  countryCode: '+966',
  agentName:'',
  agentEmail:'',
  agentMobileNumber:'',
  mobileNumber: '',
  landLineNumber: '',
  faxNumber: '',
  ibanNumber: '',
  email: '',
  website: '',
  numberOfBoardDirector: "",
  bankName: '',
  address: '',
  companyProfileImage:'',
  copyOfBudgets:'',
  updatedBankStatement:'',
  copyOfTheBoardOfDirectorsDegree:'',
  googleMapLink: '',
  dateOfEstablishment: '',
  organizationLicense: "",
  actualStartDateOfOrganizationActivity: '',
  mission: '',
  vision: '',
  objectives: '',
  targetedAudience: '',
  executiveManagerName: '',
  executiveManagerNumber: '',
  licenseNumber: '',
  licenseAuthority: "",
  licenseDate: '',
  licenseExpiryDate: "",
  region: '',
  accountNumber: "",
  registrationCertificateNumber: "",
  city: "",
  district: "",
  street: "",
  letterOfAuthorization:'',
  copyOfTheBoardOfDirectorsAppointment:'',
  participationLetter: '',
  copyOfTheOrganizationalStructure: '',
};

export const organisationCharityAgentInitialValue = {
  agentName: '',
  agentMobileNumber: '',
  agentEmail: '',
  participationLetter: '',
  letterOfAuthorization: '',
  updatedBankStatement: '',
  // organizationLicense: '',
  copyOfBudgets: '',
  copyOfTheBoardOfDirectorsAppointment: '',
  copyOfTheBoardOfDirectorsDegree: '',
  copyOfTheOrganizationalStructure: '',
  companyProfileImage: '',
}