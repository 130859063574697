import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import Loader from "react-js-loader";

const root = ReactDOM.createRoot(document.getElementById('root'));

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: localStorage.getItem('i18nextLng') || 'ar',
    fallbackLng: "en",
    whitelist: ["en", "ar"],
    detection: {
      order: ["localStorage", "htmlTag", "path", "cookie", "subdomain"],
      caches: ["localStorage"],
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/lang/{{lng}}.json",
    },
  });
root.render(
  // <React.StrictMode>
  <Suspense fallback={<div className='h-full flex item-center justify-center'>  <span className='submitButtonLoader mt-10'> <Loader type="spinner-circle" bgColor={'black'} color={'black'}  size={100} /></span>

  </div>}>

    <App />
    </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
