import React from 'react';
import { useTranslation } from 'react-i18next'; // Importing useTranslation from react-i18next

const Thankyou = () => {
  const { t } = useTranslation(); // Destructuring t from useTranslation

  return (
    <div className="flex justify-center items-center">
      <p className="text-2xl font-bold text-green-700">
        {t('Thank You for Registering, Your Form Is Under Revision')}
      </p>
    </div>
  );
};

export default Thankyou;
