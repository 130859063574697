import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

//----------------------------------------------------------------------------------------------------------------------------------------
import Causeareadetails from '../Components/Causes/Causeareadetails';
import Aboutfootertoparea2 from '../Components/Aboutfootertoparea2';
import { useTranslation } from 'react-i18next';
import Header from '../Components/UI/Header';
import Footer from '../Components/Footer/Footer';

//----------------------------------------------------------------------------------------------------------------------------------------

export default function Causes() {
    const [causeDetails, setCauseDetails] = useState([])
    let { state } = useLocation()

    useEffect(() => {
        setCauseDetails(state?.item)

    }, [])

    const { t } = useTranslation()


    return (
        //breadcrumb area start
        <section className='w-[100%]'>
            <section className="bg-[#0A4D69] h-[40vh] flex flex-col z-40 relative">
                <Header />
            </section>
            <section className="absolute top-[20vh] z-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="breadcrumb_section">
                                <ul className="breadcrumb-list volunteer_breadcrumb">
                                    <li className="bhas_border ml-2 text-black"><Link to={"/"} className='text-black'>{t('Home')}</Link></li>
                                    <li><span className="active">{t('Project Details')}</span></li>
                                </ul>
                                <div className="breadcrumb_title">
                                    <h2 className='text-white'>{state?.item?.projectName}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Causeareadetails causeDetail={causeDetails} />

            {/* <Aboutfootertoparea2 /> */}

            <Footer />

        </section>

        //breadcrumb area end
    )
}
