import * as React from "react";
import { useTranslation } from "react-i18next";
const ServiceCard = ({ title, services }) => (
  <section className="flexflex-col grow pb-6 w-full text-lg tracking-normal leading-8 text-black bg-white rounded-lg shadow-sm max-md:mt-8 max-md:max-w-full">
    <header className="justify-center w-full h-32 flex items-center p-5 text-2xl font-medium tracking-wide leading-8 text-white uppercase bg-[#0A4D69] rounded-lg max-md:max-w-full text-center">
      {title}
    </header>
    <ul className="mx-11 list-disc  mt-14 max-md:mx-2.5 max-md:mt-10">
      {services.map((service, index) => (
        <li key={index} className={index > 0 ? "mt-6" : " "}>
          {service}
        </li>
      ))}
    </ul>
  </section>
);

function Services() {
  const { t } = useTranslation();
  const serviceData = [
    {
      title: `${t("DonorService.Maximizing Impact")}`,
      services: [
        `${t("DonorService.Impact1")}`,
        `${t("DonorService.Impact2")}`,
        `${t("DonorService.Impact3")}`,
        `${t("DonorService.Impact4")}`,
        `${t("DonorService.Impact5")}`,
        `${t("DonorService.Impact6")}`,
        `${t("DonorService.Impact7")}`,
        `${t("DonorService.Impact8")}`,
        `${t("DonorService.Impact9")}`,
        `${t("DonorService.Impact10")}`,
        `${t("DonorService.Impact11")}`,
      ],
    },
    {
      title: `${t("DonorService.Executive and Support Services")}`,
      services: [
        `${t("DonorService.Support1")}`,
        `${t("DonorService.Support2")}`,
        `${t("DonorService.Support3")}`,
        `${t("DonorService.Support4")}`,
        `${t("DonorService.Support5")}`,
        `${t("DonorService.Support6")}`,
        `${t("DonorService.Support7")}`,
        `${t("DonorService.Support8")}`,
        `${t("DonorService.Support9")}`,
        `${t("DonorService.Support10")}`,
        `${t("DonorService.Support11")}`,
      ],
    },
    {
      title: `${t("DonorService.Information Services")}`,
      services: [
        `${t("DonorService.It1")}`,
        `${t("DonorService.It2")}`,
        `${t("DonorService.It3")}`,
        `${t("DonorService.It4")}`,
        `${t("DonorService.It5")}`,
        `${t("DonorService.It6")}`,
        `${t("DonorService.It7")}`,
        `${t("DonorService.It8")}`,
      ],
    },
  ];

  return (
    <main>
      <div className="flex w-full gap-5 max-md:flex-col max-md:gap-0">
        {serviceData.map((data, index) => (
          <div
            key={index}
            className={`flex flex-col w-[33%] ${
              index > 0 ? "ml-5" : ""
            } max-md:ml-0 max-md:w-full`}
          >
            <ServiceCard title={data.title} services={data.services} />
          </div>
        ))}
      </div>
    </main>
  );
}

export default Services;
