import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const ProjectGuide = () => {
  const { t  , i18n} = useTranslation();
  


  return (
    <div className="w-[100%] my-16">
      <div className="w-11/12 lg:w-[62%] mx-auto flex flex-col gap-8 items-center  text-[#084F67] py-8">
        <h2 
        style={{
          color:"#084F67"
        }}
        className={`text-4xl text-center font-light leading-[3.5rem] ${i18n.language == 'ar' ? "cairo" : "manrope"}`}>
        {t("Home1.projectGuide")}
        </h2>
       <Link to="/project-guide">
       <button
     
     className="bg-[#EDC76F] text-[#013C4A] px-8 py-3 rounded-3xl w-fit font-medium flex items-center gap-2
     "
   >
   {t("Home1.projectGuideButton")}
     <span className="text-sm">
       <FaArrowRight />
     </span>
   </button>
       </Link>
      </div>
    </div>
  );
};

export default ProjectGuide;
