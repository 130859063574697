import React from 'react'

import { useState, useEffect } from 'react'
// import Timer from '../TimeCountDown';
import Timer from '../Timer'
import { Link, useNavigate } from 'react-router-dom'
import { callDomainForOrg, getAllEventApi } from '../../Api'
import Loader from "react-js-loader";
import { fetchlocalstorage } from '../../HelperFunctions'
import { useTranslation } from 'react-i18next'

export default function EventCause() {
  const date = new Date()
  const navigate = useNavigate()

  // console.log(date)
 

  // console.log(date.setDate(date.getDate() + 1))
  const [filter, setFilter] = useState('')

  //pagination
  //pagination
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [nextPage, setNextPage] = useState(1)
  const {t} = useTranslation()
  // const [projects, setProjects] = useState([])

  // useEffect(() => {
  //   setProjects([])

  //   const filtered =
  //     filter === '' ? causes : causes.filter(c => c.category.includes(filter))
  //   setProjects(filtered)
  // }, [filter])

  const [events, setEvents] = useState([])
  const [domain, setDomain] = useState([])
  let [Loading, setLoading] = useState(false)

  async function callEvents() {
    const { data } = await getAllEventApi(filter, nextPage, limit)
    // console.log(data.data)
    setLimit(data?.limit)
    setPage(data?.page)
    setTotal(data?.total)
    setTotalPages(data?.totalPages)
    console.log(data?.data)
    setEvents(data?.data)
    setLoading(true)
  }

  async function getAllDomain() {
    let { data } = await callDomainForOrg()

    // console.log(data)
    setDomain(data?.domains)

  }
  let paginationButton = () => {
    let arr = []
    for (let i = 1; i <= totalPages; i++) {
      arr.push(

        <button
          key={i}
          onClick={() => setNextPage(i)}
          className={`relative z-10 inline-flex items-center ${nextPage === i ? 'bg-indigo-600' : ''} px-4 py-2 text-sm font-semibold ${nextPage === i ? 'text-white ' : 'text-black'} focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
        >
          {i}
        </button>

      )

    }
    // console.log(arr)
    return arr
  }

  const project_image_color = "rgb(82, 168, 181)";
  const project_bg_image_color = "rgb(8, 131, 149)";

  useEffect(() => {

    callEvents()
    getAllDomain()

  }, [filter])

  return (
    //Recent causes area start
    <section className='event_causes_area  pt-90 pb-85'>
      {Loading ? <div className=' xs:mx-4 sm:mx-10 lg:mx-32  '>
        <div className='row'>
          <div className='col-xxl-12 text-center'>
            <div className='cause_button_wrapper mb-25'>
              <nav>
                <div className='nav tab_buttons' id='nav-tab' role='tablist'>
                  <button

                    className={`nav-link ${filter === '' ? 'active' : ''}`}
                    onClick={() => setFilter("")}
                  >
                    {t('All')}
                  </button>
                  {domain && domain?.map((item, i) => <button
                    key={`asa23-${i}`}
                    className={`nav-link ${filter === '' ? 'active' : ''}`}
                    onClick={() => setFilter(item?._id)}
                  >
                    {item?.name}
                  </button>)
                  }

                  {/* <button
                    className={`nav-link ${filter === 'water' ? 'active' : ''}`}
                    onClick={() => setFilter('water')}
                  >
                    Water
                  </button>
                  <button
                    className={`nav-link ${
                      filter === 'education' ? 'active' : ''
                    }`}
                    onClick={() => setFilter('education')}
                  >
                    Education
                  </button>
                  <button
                    className={`nav-link ${
                      filter === 'medical' ? 'active' : ''
                    }`}
                    onClick={() => setFilter('medical')}
                  >
                    Medical
                  </button>
                  <button
                    className={`nav-link ${filter === 'food' ? 'active' : ''}`}
                    onClick={() => setFilter('food')}
                  >
                    Food
                  </button> */}
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className='tab-content ' id='nav-tabContent'>
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div className=' md:block '>
                    {events?.length ? events.map((item, i) => (
                        <div className='w-full   p-2' key={`event-${i}`}>
                            <div className='bg-white rounded-lg shadow-lg p-4 transform transition duration-500 hover:scale-105 hover:shadow-2xl' style={{ backgroundColor: project_bg_image_color }}>
                                <div className='flex justify-between items-center mb-4' style={{ borderBottom: "2px dashed #ddd", paddingBottom: "10px" }}>
                                    <h5 className='text-xl font-bold text-black mr-2'> {t(`Event Name`) }:</h5>
                                    <p className='text-black truncate'>{item.name}</p>
                                </div>
                                <div className='lg:flex'>
                                    <div className='text-center text-md-start mb-4 flex-1'>
                                        <div className='ml-2'>
                                            <div style={{ marginLeft: 10, marginTop: 10 }}>
                                                <p className='mb-1 text-black' style={{ display: "flex", alignItems: "center" }}><span className={`w-[240px] mr-2 ${fetchlocalstorage("language")=='ar'? 'text-right':""}`}>{t(`Description`)}:</span> <span className='truncate'>{item.description}</span></p>
                                                <p className='mb-1 text-black' style={{ display: "flex", alignItems: "center" }}><span className={`w-[240px] mr-2 ${fetchlocalstorage("language")=='ar'? 'text-right':""}`}>{t(`Organizer Name`)}:</span> <span className='truncate'>{item.organizerName}</span></p>
                                                <p className='mb-1 text-black' style={{ display: "flex", alignItems: "center" }}><span className={`w-[240px] mr-2 ${fetchlocalstorage("language")=='ar'? 'text-right':""}`}>{t(`Email`)}:</span><span className='truncate text-ellipsis overflow-hidden'>{item.email}</span></p>
                                                <p className='mb-1 text-black' style={{ display: "flex", alignItems: "center" }}><span className={`w-[240px] mr-2 ${fetchlocalstorage("language")=='ar'? 'text-right':""}`}>{t(`Max Number Of People`)}:</span> <span className='truncate'>{item.maxNumberOfPeople}</span></p>
                                                <p className='mb-1 text-black' style={{ display: "flex", alignItems: "center" }}><span className={`w-[240px] mr-2 ${fetchlocalstorage("language")=='ar'? 'text-right':""}`}>{item?.status == "LIVE" ? t(`Link`) : t(`Address`)} :</span> {item?.status == "LIVE" ? <Link to={item.link} target='_blank' className='text-blue-200 truncate'>{item.link}</Link> : <span className='truncate'>{item.address}</span>}</p>
                                                <p className='mb-1 text-black' style={{ display: "flex", alignItems: "center" }}><span className={`w-[240px] mr-2 ${fetchlocalstorage("language")=='ar'? 'text-right':""}`}>{t(`Start Date`)}:</span> {new Date(item.startDate).toLocaleDateString()}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 flex items-center justify-center'>
                                        <div>
                                            <Timer endDateTime={new Date(item?.endDate).getTime()} startDate={new Date(item?.startDate).getTime()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                        : <div className='text-center my-4'>{t('No data available')}</div>
                    }
                </div>
            </div>
            <div className='bg-white'>
                <div className="flex items-center justify-between border-gray-200 bg-gray-200 px-4 py-3 sm:px-6">
                    <div className="flex flex-1 justify-between sm:hidden">
                        <a
                            href="#"
                            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                            onClick={() => nextPage !== 1 && setNextPage((prev) => prev - 1)}
                        >
                            {t('Previous')}
                        </a>
                        <a
                            href="#"
                            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                            disabled={nextPage == totalPages ? true : false}
                            onClick={() => nextPage !== totalPages && setNextPage((prev) => prev + 1)}
                        >
                            {t('Next')}
                        </a>
                    </div>
                    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                            <p className="text-sm text-gray-700">
                                {t('Showing')} <span className="font-medium">{limit * (page - 1) + 1}</span> {t('to')} <span className="font-medium">{limit * (page - 1) + events?.length}</span> {t('of')}{' '}
                                <span className="font-medium">{total}</span> {t('results')}
                            </p>
                        </div>
                        <div>
                            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                <a
                                    href="#"
                                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    onClick={() => nextPage == 1 && setNextPage((prev) => prev - 1)}
                                >
                                    <span className="sr-only">{t('Previous')}</span>
                                    <span className="h-5 w-5" aria-hidden="true">{"<"}</span>
                                </a>
                                {paginationButton().map(item => item)}
                                <a
                                    href="#"
                                    disabled={nextPage == totalPages ? true : false}
                                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    onClick={() => nextPage !== totalPages && setNextPage((prev) => prev + 1)}
                                >
                                    <span className="sr-only">{t('Next')}</span>
                                    <span className="h-5 w-5" aria-hidden="true">{">"}</span>
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className='row'>
          <div className='cause_button text-center mt-10 mb-30'>
            <Link href='/event' className='cause_btn g_btn to_right1 rad-30'>
              Load More <i className='far fa-plus'></i> <span></span>
            </Link>
          </div>
        </div> */}
      </div> : <span> <Loader type="spinner-circle" bgColor={'black'} color={'black'} size={100} /></span>}
    </section>
    //Recent causes area end
  )
}

//<Timer endDateTime={item.date} />
