import React from "react";
import { useTranslation } from "react-i18next";

const InfoCard = ({ imageSrc, text }) => (
  <div className="flex items-center grow gap-1 justify-center p-5 w-full text-lg leading-7 text-sky-900 bg-white rounded-lg max-md:flex-wrap max-md:mt-10">
    <div className=" max-w-[350px] flex items-center justify-center gap-1">
      <img
        loading="lazy"
        src={imageSrc}
        alt=""
        className="shrink-0 my-auto aspect-square w-[62px]"
      />
      <p className="text-sm" >{text}</p>
    </div>
  </div>
);

const Requirements = () => {
  const { t, i18n } = useTranslation();
  const infoCards = [
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/fbd64f4d614d646dd3c3e428b3c01fa19772066e6aed5a8ce9ed9ee96e5b41be?apiKey=8d89067347d641b99b038352d46b6e1d&",
      text: `${t("About1.charity1")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/1c11f7ba6b52ba7e17704fa55fb5d1309255332083ad15ce1127ffc0037086d0?apiKey=8d89067347d641b99b038352d46b6e1d&",
      text: `${t("About1.charity2")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/bcb7a0f9e86626f7da1b95bbdcf84f63697a699c7d13abdbe8511e141b9ce656?apiKey=8d89067347d641b99b038352d46b6e1d&",
      text: `${t("About1.charity3")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/42387def6dece5d71815f1d9d138c4d55e45f3f1ceb5aecd7cd0de4d0e29f6d5?apiKey=8d89067347d641b99b038352d46b6e1d&",
      text: `${t("About1.charity4")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/4cb428a8223bedefa663bdb354c59946ad6a6b87274c0ec179a9242e06291ac5?apiKey=8d89067347d641b99b038352d46b6e1d&",
      text: `${t("About1.charity5")}`,
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/0939bf4f1098796cf1134f64e56e8fbeb8f0eb51698b62af2f8100521dc2149d?apiKey=8d89067347d641b99b038352d46b6e1d&",
      text: `${t("About1.charity6")}`,
    },
  ];

  return (
    <section className="flex flex-col gap-8 w-full max-w-[80%]  ">
      <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
        <img
          loading="lazy"
          src="/images/icons/charity-requirements.svg"
          className="shrink-0 aspect-square w-[52px]"
          alt=""
        />
        <h1
          className={`my-auto uppercase  font-semibold max-md:max-w-full ${
            i18n.language === "ar" ? "cairo" : "manrope"
          } `}
        >
          {t("About1.charityRequirements")}
        </h1>
      </div>
      <div className="flex flex-col flex-wrap justify-centercontent-center">
        <div className="px-5 w-full max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {infoCards.slice(0, 3).map((card, index) => (
              <div
                key={index}
                className={`flex flex-col w-[33%] ${
                  index > 0 ? "ml-5" : ""
                } max-md:ml-0 max-md:w-full`}
              >
                <InfoCard imageSrc={card.imageSrc} text={card.text} />
              </div>
            ))}
          </div>
        </div>
        <div className="px-5 mt-11 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {infoCards.slice(3).map((card, index) => (
              <div
                key={index}
                className={`flex flex-col w-[33%] ${
                  index > 0 ? "ml-5" : ""
                } max-md:ml-0 max-md:w-full`}
              >
                <InfoCard imageSrc={card.imageSrc} text={card.text} />
              </div>
            ))}
          </div>
        </div>
        <div className="flex gap-1 justify-between items-center self-center p-5 mt-11 text-lg leading-7 text-sky-900 bg-white rounded-lg max-md:flex-wrap max-md:mt-10">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/902e26f5d6e243855066e1904a53757c18e9f6b5556f8dd573d01e3e23c249b4?apiKey=8d89067347d641b99b038352d46b6e1d&"
            alt=""
            className="shrink-0 my-auto aspect-square w-[52px]"
          />
          <p>{t("About1.charity7")}</p>
        </div>
      </div>
    </section>
  );
};

export default Requirements;
