import React, { useState } from "react";
import ChatLog from "./ChatLog";
import { useTranslation } from "react-i18next";
import { db, fb_messages } from "../../firebase";

import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
  updateDoc,
  doc,
  and,
} from "@firebase/firestore";

const ChatContent = ({ selectedChat, setNewUsers }) => {
  const [msg, setMsg] = useState("");

  const {t}=useTranslation();
  let getUserInfo = localStorage.getItem("userDetails");
  getUserInfo = JSON.parse(getUserInfo);

  const id = getUserInfo._id;

  const [userId, setUserId] = useState(getUserInfo._id);

  const handleSendMsg = async (e) => {
    e.preventDefault();
    const message = msg.trim();
    if (message.length) {
      try {
        const data = await addDoc(collection(db, fb_messages), {
          message: message,
          sender: userId,
          receiver: selectedChat?._id,
          timestamp: new Date(),
        });
        console.log(data);

        const userRef = collection(db, "users");
        const userQuery = query(
          userRef,
          and(
            where("newMessage", "==", true),
            where("receiver", "==", selectedChat?._id),
            where("sender", "==", userId)
          )
        );
        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) {
          // User not found, add new user
          await addDoc(userRef, {
            sender: userId,
            receiver: selectedChat?._id,
            timestamp: new Date(),
            newMessage: true,
          });
        } else {
          // User found, update user
          const userDoc = userSnapshot.docs[0];
          await updateDoc(userDoc.ref, {
            newMessage: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setMsg("");
  };

  return (
    <>
      {selectedChat && (
        <div className="w-full px-5 flex flex-col justify-between">
          <div className="flex flex-col mt-5">
            <ChatLog selectedChat={selectedChat} setNewUsers={setNewUsers} />
          </div>
          <form
            onSubmit={handleSendMsg}
            className="py-5 flex justify-between gap-8 "
          >
            <input
              className="w-full bg-gray-300 py-2 px-3 rounded-xl"
              type="text"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              placeholder={t('type your message here...')}
            />
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-xl"
            >
              {t('Send')}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default ChatContent;
