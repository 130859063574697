import React from "react";
import { useTranslation } from "react-i18next";

const ProjectGuideCard = ({ page , setpage }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full py-12 lg:py-32 bg-[#eeece1] rounded-lg overflow-hidden" style={{ zIndex: '999' }}>
      <div className="w-11/12 mx-auto flex flex-col items-center gap-10 lg:gap-16">
        <h3
          className="text-4xl text-center text-[#0A4E68] tracking-wide z-100"
          style={{
            zIndex: "100",
          }}>
          {t(`${page}.FIRST EDITION OF ALMOULTQA GUIDE`)}
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <AchievementCard
            icon={<i aria-hidden="true" className="fas fa-user-graduate "></i>}
            title={t(`${page}.Education and training field`)}
            desc={t(`${page}.Education and training field description`)}
            value="83"
            t={t}
            page={page}
            
          />
          <AchievementCard
            icon={<i aria-hidden="true" className="fas fa-hand-holding-heart mt-2"></i>}
            title={t(`${page}.The social and awareness field`)}
            desc={t(`${page}.The social and awareness field description`)}
            value="83"
            t={t}
            page={page}
          />
          <AchievementCard
            icon={<i aria-hidden="true" className="fas fa-mosque mt-6"></i>}
            title={t(`${page}.Projects of the Haram and visitors to the city`)}
            desc={t(`${page}.Projects of the Haram and visitors to the city description`)}
            value="79"
            t={t}
            page={page}
          />
          <AchievementCard
            icon={<i aria-hidden="true" className="fas fa-book mt-2"></i>}
            title={t(`${page}.Forum Guide`)}
            desc={t(`${page}.Forum Guide description`)}
            value="22"
            t={t}
            page={page}
          />
          <AchievementCard
            icon={<i aria-hidden="true" className="fas fa-stethoscope mt-6"></i>}
            title={t(`${page}.Health and environmental field`)}
            desc={t(`${page}.Health and environmental field description`)}
            value="12"
            t={t}
            page={page}
          />
          <AchievementCard
            icon={<i aria-hidden="true" className="fas fa-chart-line mt-6"></i>}
            title={t(`${page}.Development field`)}
            desc={t(`${page}.Development field description`)}
            value="1,120,000"
            t={t}
            page={page}
          />
        </div>
        <div onClick={() => setpage(page === 'ProjectGuide2' ? 'ProjectGuide' : 'ProjectGuide2')} className="justify-center px-4 py-2 text-lg font-semibold text-white bg-sky-900 rounded-[40px]">
         {page === 'ProjectGuide' ? t('SECOND PAGE'): t('FIRST PAGE')} 
        </div>
      </div>
    </div>
  );
};

const AchievementCard = ({ icon, title, desc, value, t, page }) => (
  <div className="flex ProjectGuide flex-col justify-center items-center p-2.5 text-lg font-semibold bg-white rounded-3xl max-w-[499px] text-sky-900 text-opacity-60">
    {icon}
    <div className="self-stretch mt-7 text-2xl leading-7 text-center text-sky-900 max-md:max-w-full">
      {title}
    </div>
    <div className="self-stretch mt-4 leading-6 text-center max-md:max-w-full">
      {desc}
    </div>
    <div className="justify-center px-4 py-2 mt-7 text-white bg-sky-900 rounded-[40px] max-md:px-5">
      {t(`${page}.Browse the field`)}
    </div>
    <div className="mt-12 text-base leading-6 text-center underline max-md:mt-10">
      {t(`${page}.Quick browse`)}
    </div>
  </div>
);

export default ProjectGuideCard;
