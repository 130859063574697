import React from 'react'
import Header from './Header'
import HeroSection from '../DonorServices/HeroSection'

const DonorService = () => {
  return (
    <section className='w-full bg-[#eeece1]'>
    <section className="bg-[#0A4D69] h-[50vh] flex flex-col  relative">
        <Header />
    </section>
    <HeroSection />
 
</section>
  )
}

export default DonorService