import React from "react";
import { useTranslation } from "react-i18next";

const ListItem = ({ number, text }) => (
  <div className="flex gap-5  mt-6 ml-0 max-md:flex-wrap p-4 max-md:mt-10">
    <div className="justify-center items-center px-5 py-3.5 w-14 h-14 text-4xl leading-7 text-white whitespace-nowrap bg-sky-900 rounded-3xl  ">
      {number}
    </div>
    <p className="my-auto text-xl md:text-2xl lg:text-3xl leading-7 text-slate-500 max-md:max-w-full  ">
      {text}
    </p>
  </div>
);

const ProjectRequirements = () => {
  const { t , i18n } = useTranslation();

  const listItems = [
    {
      number: 1,
      text: `${t("About1.project1")}`,
    },
    {
      number: 2,
      text: `${t("About1.project2")}`,
    },
    { number: 3, text: `${t("About1.project3")}` },
    {
      number: 4,
      text: `${t("About1.project4")}`,
    },
    {
      number: 5,
      text: `${t("About1.project5")}`,
    },
  ];

  return (
    <section className="flex flex-col gap-8 w-full max-w-[80%]  ">
      <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
        <img
          loading="lazy"
          src="/images/icons/project-requirements.svg"
          className="shrink-0 aspect-square w-[52px]"
          alt=""
        />
        <h1 className={`my-auto uppercase text-4xl lg:text-4xl font-semibold max-md:max-w-full  ${i18n.language === 'ar' ? 'cairo' : 'manrope'}`}>
          {t("About1.projectRequirements")}
        </h1>
      </div>
      <div className="flex flex-col items-startp-5 md:p-10 bg-white rounded-lg">
        {listItems.map((item, index) => (
          <ListItem key={index} number={item.number} text={item.text} />
        ))}
      </div>
    </section>
  );
};

export default ProjectRequirements;
