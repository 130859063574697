
export const getToken = () => localStorage.getItem("token");
export const setToken = (token) => localStorage.setItem("token", token);
export const removeToken = () => localStorage.removeItem("token");
export const isAuth = () => Boolean(getToken());

export const logOut = () => {
  // Clear user data from localStorage or session storage
  localStorage.removeItem('userDetails'); // Assuming you store user data in localStorage
  localStorage.removeItem('token'); // Remove the authentication token
  localStorage.removeItem('charityinfo'); // Remove the authentication token
  // Redirect to the login page or perform any other action needed after logout
  // window.location.href = '/'; // Redirect to the login page
};

export const fetchlocalstorage = (value) => {
  let res = localStorage.getItem(value)
  
  return res && res
}