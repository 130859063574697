import React from 'react'
import Aboutfootertoparea2 from "../Components/Aboutfootertoparea2"
import Navbar from '../Components/Nav_Form/Navbar'
import Availability from '../Components/Consultant/Availability'
import Header from '../Components/UI/Header'
import Footer from '../Components/Footer/Footer'

const ConsultantAvailability = () => {
  return (
    <div>
      {/* <Navbar/> */}
      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>

      <Availability />

      {/* <Aboutfootertoparea2 /> */}
      <Footer />

    </div>
  )
}

export default ConsultantAvailability