import React, { useEffect, useState } from 'react'
import { getProfile } from '../../Api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FaPen } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdDocument } from "react-icons/io";
import Navbar from '../Nav_Form/Navbar';
import { fetchlocalstorage } from '../../HelperFunctions';
import Footer2 from '../Aboutfootertoparea2';
import Header from '../UI/Header';
import Footer from '../Footer/Footer';

const ConsultantProfile = () => {
    const [userInfo, setUserInfo] = useState({})
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    async function getProfileData() {
        let data = await getProfile()
        console.log("consultantProfileData->", data?.data?.data)
        setUserInfo(data?.data?.data)

        setLoading(true)
        // console.log(data.data.charity)
    }
    useEffect(() => {
        getProfileData()
    }, [])
    const openLink = (link) => {
        console.log(link)
        window.open(link, '_blank');
    };

    return (
        <div >
            {/* <Navbar /> */}

            <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
                <Header />
            </section>

            <div className="bg-white p-4 sm:rounded-lg xs:m-[1rem] sm:m-[5rem] md:m-[7rem] shadow-lg border">
                <div>
                    <h4 className="leading-6 mb-[4rem] font-medium text-gray-900">
                        {t('Profile Details')}
                    </h4>
                    <div className='flex flex-col md:flex-row gap-4'>
                        <div className='max-w-[500px] rounded-lg overflow-hidden'>
                            <img src={userInfo?.profileImage} className='w-[100%]'/>
                        </div>
                        <div className='w-3/4'>
                            <div className='uppercase  flex'><p className={`text-black  font-bold mr-2  ${fetchlocalstorage("language") == "ar" ? "ml-2" : ""}`} >{t(`Name`)} : </p><p>{userInfo?.name}</p></div>
                            <div className='uppercase  flex'><p className={`text-black  font-bold mr-2  ${fetchlocalstorage("language") == "ar" ? "ml-2" : ""}`} >{t(`Email`)} : </p><p>{userInfo?.email}</p></div>
                            
                            <div className='flex item-center  '><p className={`text-black  font-bold  mr-2 ${fetchlocalstorage("language") == "ar" ? "ml-2" : ""}`}>{t(`Bio`)}: </p>      <p>{userInfo?.bio}</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ConsultantProfile
