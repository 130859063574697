import React from "react";
import AboutContent from "./AboutContent";

import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";
const HeroSection = () => {
  const { t , i18n } = useTranslation();
  return (
    <div className="w-[100%]  bg-[#EEEDE5]  " style={{marginTop:'-300px'}}>
      <div className="w-[80%] mx-auto flex flex-col gap-14 relative z-80 mt-20 max-md:mt-16 max-sm:mt-8">
        <h3
          className={`text-6xl text-center text-white uppercase tracking-wide z-100 ${i18n.language === 'ar' ? 'cairo' : 'manrope'} `}
          style={{
            zIndex: "100",
          }}
        >
        {t("DonorService.Donor Services Unit")}
        </h3>
        <div className="flex flex-col gap-10  ">
          <img
            src="/images/donor-service-hero.png"
            style={{
              zIndex: "100",
            }}
          />
         
        </div>
      </div>
      <div className="w-full flex flex-col items-center mt-12 gap-24">
        <AboutContent />
        <Footer />
      </div>
    </div>
  );
};

export default HeroSection;
