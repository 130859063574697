import { useNavigate } from "react-router-dom";
import { isAuth } from "../HelperFunctions/index";
import { getProfile } from "../Api";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function PRouteForOrg({ children }) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const { t } = useTranslation()

  let getProfileData = async () => {
    let data = await getProfile();
    if (data?.data?.charity?.status !== "APPROVED") {
      toast(t('Your profile is not approved yet'));
      navigate("/organization-profile");
    }
    localStorage.setItem("charityinfo",JSON.stringify(data?.data?.charity) )
    setUserInfo(data?.data?.charity);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  // useEffect(() => {
   
  // }, [userInfo, navigate]);

  return userInfo.status === "APPROVED" ? children : null;
}
