import * as React from "react";
import { useTranslation } from "react-i18next";
const GoalSection = ({ title, goals }) => (
  <section className="mt-14 w-full max-md:mt-10 max-md:max-w-full">
    <p className="text-xl font-medium text-[#084F67] uppercase" style={{
        color:"#084F67"
    }} >{title}</p>
    <ul className="mt-6 px-8 list-decimal w-full text-2xl font-light leading-10 text-[#084F679C] text-opacity-60 max-md:max-w-full">
      {goals.map((goal, index) => (
        <li key={index}>{goal}</li>
      ))}
    </ul>
  </section>
);

function Goals() {
  const { t , i18n } = useTranslation();
  const commonGoals = [
   `${t("About1.commonGoalOne")}`,
   `${t("About1.commonGoalTwo")}`,
  ];

  const grantingEntitiesGoals = [
    `${t("About1.grantingGoalOne")}`,
    `${t("About1.grantingGoalTwo")}`,
    `${t("About1.grantingGoalThree")}`,
    `${t("About1.grantingGoalFour")}`,
  ];

  const receivingEntitiesGoals = [
    `${t("About1.receivingGoalOne")}`,
    `${t("About1.receivingGoalTwo")}`,
    `${t("About1.receivingGoalThree")}`,
    `${t("About1.receivingGoalFour")}`,
    `${t("About1.receivingGoalFive")}`,
    `${t("About1.receivingGoalSix")}`,,
    `${t("About1.receivingGoalSeven")}`,
  ];

  return (
    <div className="flex flex-col text-xl font-medium text-[#013C4A] w-[80%]  ">
      <div className="flex gap-2.5  text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase font-light  max-md:flex-wrap">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/9669b202306cf70a5c93511f0a0adedae68b180f9053d20954008ee2682bd9be?apiKey=8d89067347d641b99b038352d46b6e1d&"
          className="shrink-0 aspect-square w-[52px]"
          alt=""
        />
        <h1 className={` ${i18n.language === 'ar' ? 'cairo' : 'manrope'} my-auto max-md:max-w-full font-semibold`}>{t("About1.goals")}</h1>
      </div>

      <GoalSection title={t("About1.commonGoals")} goals={commonGoals} />
      <GoalSection
        title={t("About1.grantingGoals")}
        goals={grantingEntitiesGoals}
      />
      <GoalSection
        title={t("About1.receivingGoals")}
        goals={receivingEntitiesGoals}
      />
    </div>
  );
}

export default Goals;
