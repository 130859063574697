import React from 'react'
import { useTranslation } from 'react-i18next';
import { IoMdDocument } from "react-icons/io";


const BookingModal = ({onClose,item}) => {
    console.log("item    ",item)
    const {t} = useTranslation()
    const openLink = (link) => {
        console.log(link)
        window.open(link, '_blank');
      };
    
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex items-center justify-center">
    <div className="bg-white w-full max-w-md p-6 rounded-lg">
        <div className="flex justify-end">
            <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                aria-label="Close modal"
            >
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </button>
        </div>
        <h4 className='text-xxl'>Workshop</h4>
        {/* <form onSubmit={formik.handleSubmit}> */}

            <div className="mt-4">
                <div>
                    {/* <label htmlFor="reportName">Report Name:</label>
    <input type="text" id="reportName" name="reportName" /> */}
                    <label htmlFor="uploadReportname" className="block text-sm font-medium text-gray-700 text-xl mb-1" >Description</label>
                    {/* <input type="uploadReportname" name="uploadReportname" id="uploadReportname" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter Report Name"   /> */}
                    <div>{item?.description|| t("No data available")}</div>
                    
                </div>

                <div className='mb-4'>
                    {/* <label htmlFor="reportName">Report Name:</label>
    <input type="text" id="reportName" name="reportName" /> */}
                    <label htmlFor="uploadReportname" className="block text-sm font-medium text-gray-700 text-xl mb-1">Agenda</label>
                    {/* <input type="uploadReportname" name="uploadReportname" id="uploadReportname" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter Report Name"   /> */}
                    <div>{item?.agenda|| t("No data available")}</div>
                    
                </div>
                <div>
                {item?.attachment ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(item?.attachment)}>
                  <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View Attatchment`)}</button>

                  {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                     </svg> */}
                </div> :
                  <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                 </svg> */}
                  </div>
                }
                    
                </div>
                

                <div className="mt-6">
                    <button
                onClick={onClose}
                        
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

                    >
                        Close
                    </button>
                </div>
            </div>
        {/* </form> */}
    </div>
</div>
  )
}

export default BookingModal