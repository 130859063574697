import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDX8oWemb-Cr4SvjCbqIpn5fJshy-LYmrs",
  authDomain: "al-moulqta.firebaseapp.com",
  projectId: "al-moulqta",
  storageBucket: "al-moulqta.appspot.com",
  messagingSenderId: "26409363580",
  appId: "1:26409363580:web:d19a55e0daab78371ec1f9"
};
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)

export const fb_messages = 'messages'
