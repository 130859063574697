import { useEffect, useState } from "react";
import React from "react";
import Navbar from "../Nav_Form/Navbar";
import ChatSidebar from "./ChatSidebar";
import { db } from "../../firebase";
import { and, collection, onSnapshot, query, where } from "@firebase/firestore";
import { isAuth } from "../../HelperFunctions";
import toast from "react-hot-toast";
import { callAxios } from "../../axios";
import ChatContent from "./ChatContent";
import { useTranslation } from "react-i18next";
import Header from "../UI/Header";
const Chat = () => {
  const [userStatus, setUserStatus] = useState("online");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedChat, setSelectedChat] = useState({});
  const { t } = useTranslation()
  let getAuth = isAuth();

  console.log(selectedChat, "chat");

  let getUserInfo = localStorage.getItem("userDetails");
  getUserInfo = JSON.parse(getUserInfo);

  const [userId, setUserId] = useState(getUserInfo._id);

  const getUsers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `user/getCharityUserChat?search=${search}`
      );
      console.log(data);
      setUsers([...data?.data]);
      setNewUsers([...data?.data]);
      //   setTotalPages(data?.totalPages)
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(t(error?.response?.data?.message) || t("Something went wrong"));
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
    // dispatch(fetchChatsContacts())
  }, [search]);

  useEffect(() => {
    if (users.length === 0) return setNewUsers([]);
    const newMessageUsers = onSnapshot(
      query(
        collection(db, "users"),
        and(where("newMessage", "==", true), where("receiver", "==", userId))
      ),

      (snapshot) => {
        let newUsers = [];
        snapshot.docs.map((doc) => {
          newUsers.push(doc.data());
        });
        console.log(newUsers);

        const messageIds = new Set(newUsers.map((user) => user.sender));
        console.log(messageIds);

        const usersWithMessages = users
          .filter((user) => messageIds.has(user._id))
          // .map((user) => {
          //     if (user._id == selectedChat?._id) {
          //         return {
          //             ...user, newMessage: false
          //         }
          //     } else {
          //         return {
          //             ...user, newMessage: true
          //         }
          //     }
          // })
          .map((user) => ({ ...user, newMessage: true }));

        const usersWithoutMessages = users
          .filter((user) => !messageIds.has(user._id))
          .map((user) => ({ ...user, newMessage: false }));

        console.log(usersWithMessages);
        console.log(usersWithoutMessages);

        // Concatenate the two arrays
        const sortedUsersArray = [
          ...usersWithMessages,
          ...usersWithoutMessages,
        ];

        console.log(sortedUsersArray);

        if (usersWithMessages?.length > 0) {
          setNewUsers(sortedUsersArray);
        }
      }
    );

    return () => {
      newMessageUsers();
    };
  }, [users]);

  return (
    <>
      {/* <Navbar /> */}
      <section className="bg-[#0A4D69]  flex flex-col  relative">
        <Header />
      </section>
      {/* component */}
      {/* This is an example component */}
      <div className="container mx-auto mt-16 shadow-lg rounded-lg">
        {/* Chatting */}
        <div className="flex flex-row justify-between bg-white">
          {/* chat list */}
          <ChatSidebar
            search={search}
            setSearch={setSearch}
            users={newUsers}
            setSelectedChat={setSelectedChat}
          />
          {/* end chat list */}
          {/* message */}
          <ChatContent selectedChat={selectedChat} setNewUsers={setNewUsers} />
        </div>
      </div>
    </>
  );
};

export default Chat;
