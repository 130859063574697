import React, { useEffect, useState } from 'react';
import Aboutfootertoparea2 from "../Components/Aboutfootertoparea2";
import Navbar from '../Components/Nav_Form/Navbar';
import { getALlBookingsApi } from '../Api'; 
import { Link } from 'react-router-dom';
import Loader from "react-js-loader"; 

import moment from "moment-hijri";
import HijriDatePicker from '../Components/HijriDatePicker';

import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';

import { HiDotsVertical } from "react-icons/hi";
import { fetchlocalstorage } from '../HelperFunctions';
import BookingModal from '../Modal/BookingModal';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/UI/Header';

moment.locale("ar");

const MyBookings = () => {
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState("");
  const [Loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [openDropdown, setOpenDropdown] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleChange = (e) => {
    setFilter(e.target.value);
  };

  const hijriDate = moment().format("iYYYY/iM/iD");
  const initialDate = moment(hijriDate, "iYYYY/iM/iD");
  const [value, setValue] = useState(initialDate); // Initialize with the moment object

  const handleDateChange = (date) => {
    setValue(date);
    setFilter(date.format("iYYYY/iM/iD"));
  };

  const getAllBookings = async () => {
    setLoading(false);
    try {
      // Convert Hijri date to Gregorian date before making the API call
      const gregorianFilter = filter ? moment(filter, "iYYYY/iM/iD").format("YYYY-MM-DD") : "";
      let res = await getALlBookingsApi(gregorianFilter);
      setBookings(res?.data?.data);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true); // Open the modal when the button is clicked
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false); // Close the modal
  };

  useEffect(() => {
    getAllBookings();
  }, [filter]);

  return (
    <div>
      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>

      {Loading ?
      <div className={`xs:p-2 sm:p-4 md:p-10 lg:p-16 mt-2`} >
        <div className='flex justify-between my-4'>
          <HijriDatePicker
            value={value}
            onChange={handleDateChange}
            label="Date"
          />
          <button onClick={() => setFilter("")} className='btn border shadow rounded-lg bg-project_bg_image_color text-white text-md'>
            {t('All bookings')}
          </button>
        </div>

        <div>
          <div className='shadow-lg rounded-md'>
            <div className="h-full z-10 overflow-x-auto">
              <table className="w-full divide-x text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 bg-white uppercase border-b">
                  <tr>
                    <th scope="col" className="px-6 py-3">{t('Name')}</th>
                    <th scope="col" className="px-6 py-3">{t('Email')}</th>
                    <th scope="col" className="px-6 py-3">{t('Start Time')}</th>
                    <th scope="col" className="px-6 py-3">{t('End Time')}</th>
                    <th scope="col" className="px-6 py-3">{t('Date')}</th>
                    <th scope="col" className="px-6 py-3">{t('Video call link')}</th>
                    <th scope="col" className="px-6 py-3">{t('ACTIONS')}</th>
                  </tr>
                </thead>
                {bookings?.length ? 
                <tbody>
                  {bookings?.map((item, id) =>
                    <tr key={`booking-${id}`} className="bg-white">
                      <td className="px-6 py-4 font-medium uppercase text-gray-900 whitespace-nowrap text-green-500">{item?.name}</td>
                      <td className="px-6 py-4 truncate">{item?.email}</td>
                      <td className="px-6 py-4">{item?.startTime}</td>
                      <td className="px-6 py-4">{item?.endTime}</td>
                      <td className="px-6 py-4">{new Date(item.date).toLocaleDateString()}</td>
                      <td className="px-6 py-4 truncate">
                        <Link to={item?.videoCallLink} target="_blank">{item?.videoCallLink}</Link>
                      </td>
                      <td className="px-6 py-4 cursor-pointer z-10">
                        <li className="px-4 py-4 list-none text-center cursor-pointer bg-white">
                          <div onClick={(e) => e.stopPropagation()}>
                            <HiDotsVertical onClick={() => toggleDropdown(item._id)} />
                            {openDropdown === item._id && (
                              <div className={`absolute z-99 ${fetchlocalstorage("language") === "ar" ? "left-[20px] sm:left-[82px]" : "right-[20px] sm:right-[82px]"} mt-2 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg`} style={{ zIndex: "99" }}>
                                <div className="py-4 w-40" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <Link to="#" onClick={() => { handleOpenModal(item); toggleDropdown(item._id) }} className="block no-underline px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Booking details</Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                      </td>
                    </tr>
                  )}
                </tbody> : <div className='text-center my-2'>{t('No Data Available')}</div>}
              </table>
            </div>
          </div>
        </div>
      </div>
      : <span> <Loader type="spinner-circle" bgColor={'black'} color={'black'} size={100} /></span>}
      <Footer />
      {isModalOpen && (
        <BookingModal onClose={handleCloseModal} item={selectedItem} />
      )}
    </div>
  );
}

export default MyBookings;
