import React from "react";
import Services from "./Services";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AboutContent = () => {
  const { t , i18n } = useTranslation();
  return (
    <section className="flex flex-col gap-12 max-w-[80%]  ">
      <div className="w-full flex flex-col gap-4">
        <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
          <img
            loading="lazy"
            src="/images/icons/org-structure.svg"
            className="shrink-0 aspect-square w-[52px]"
            alt=""
          />
          <h1 className={`my-auto font-medium max-md:max-w-full  ${i18n.language === 'ar' ? 'cairo' : 'manrope'} `}>
            {t("DonorService.Organizational  Structure")}
          </h1>
        </div>
        <article className={` w-full text-xl font-normal leading-10 text-[#084F679C] text-opacity-60 max-md:max-w-full  ${i18n.language === 'ar' ? 'cairo' : 'manrope'}  `}>
          <p>{t("DonorService.Structure Description")}</p>
        </article>
      </div>
      <div className="w-full flex flex-col gap-4">
        <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
          <img
            loading="lazy"
            src="/images/icons/unit-description.svg"
            className="shrink-0 aspect-square w-[52px]"
            alt=""
          />
          <h1 className={`my-auto font-medium max-md:max-w-full  ${i18n.language === 'ar' ? 'cairo' : 'manrope'}  `}>
            {t("DonorService.Description of  the unit")}
          </h1>
        </div>
        <article className={` w-full text-xl font-normal leading-10 text-[#084F679C] text-opacity-60 max-md:max-w-full  ${i18n.language === 'ar' ? 'cairo' : 'manrope'}  `}>
          <p>{t("DonorService.unitDescription")}</p>
        </article>
      </div>
      <div className="w-full flex flex-col gap-4">
        <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
          <img
            loading="lazy"
            src="/images/icons/target.svg"
            className="shrink-0 aspect-square w-[52px]"
            alt=""
          />
          <h1 className={`my-auto font-medium max-md:max-w-full   ${i18n.language === 'ar' ? 'cairo' : 'manrope'}  `}>
            {t("DonorService.Target Beneficiaries")}
          </h1>
        </div>
        <ul className="mt-6 px-8 list-decimal w-full text-xl font-normal leading-10 text-[#084F679C] text-opacity-60 max-md:max-w-full">
          <li>{t("DonorService.target1")}</li>
          <li>{t("DonorService.target2")}</li>
          <li>{t("DonorService.target3")}</li>
          <li>{t("DonorService.target4")}</li>
          <li>{t("DonorService.target5")}</li>
          <li>{t("DonorService.target6")}</li>
          <li>{t("DonorService.target7")}</li>
          <li>{t("DonorService.target8")}</li>
        </ul>
      </div>
      <div className="w-full flex flex-col gap-4">
        <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
          <img
            loading="lazy"
            src="/images/icons/donor-areas.svg"
            className="shrink-0 aspect-square w-[52px]"
            alt=""
          />
          <h1 className={`my-auto font-medium max-md:max-w-full  ${i18n.language === 'ar' ? 'cairo' : 'manrope'}  `}>
            {t("DonorService.AREAS OF DONOR SERVICES")}
          </h1>
        </div>
        <div className="w-full flex flex-col gap-6">
          <div className="w-full flex flex-col gap-4">
            <p className="text-[#0e5b6f]text-xl font-medium uppercase">
              {t("DonorService.Information Services Sector")}
            </p>
            <article className=" w-full text-xl font-normal leading-10 text-[#084F679C] text-opacity-60 max-md:max-w-full">
              <p>{t("DonorService.ITDescription")}</p>
            </article>
          </div>
          <div className="w-full flex flex-col gap-4">
            <p className="text-[#0e5b6f]text-xl font-medium uppercase">
              {t("DonorService.Executive and Support Services Sector")}
            </p>
            <article className=" w-full text-xl font-normal leading-10 text-[#084F679C] text-opacity-60 max-md:max-w-full">
              <p>{t("DonorService.serviceDesc")}</p>
            </article>
          </div>
          <div className="w-full flex flex-col gap-4">
            <p className="text-[#0e5b6f]text-xl font-medium uppercase">
              {t("DonorService.Impact Maximization Sector")}
            </p>
            <article className=" w-full text-xl font-normal leading-10 text-[#084F679C] text-opacity-60 max-md:max-w-full">
              <p>{t("DonorService.impactDesc")}</p>
            </article>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-8">
        <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
          <h1 className={`my-auto font-medium max-md:max-w-fulluppercase  ${i18n.language === 'ar' ? 'cairo' : 'manrope'} `}>
            {t("DonorService.KEY DONOR SERVICES")}
          </h1>
        </div>
        <Services />
      </div>
      <div className="w-full flex justify-center items-center">
        {
          !localStorage.getItem("token") && 
          <Link to={'/login'}>
          <button className="py-4 px-8 rounded-fullbg-[#0A4D69] text-white text-lg font-semibold bg-[#0A4D69] rounded-full" >{t('Sign in as Donor')}</button>
          </Link>
        }
        
      </div>
    </section>
  );
};

export default AboutContent;
