import React from "react";

// Swiper components, modules and styles
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";

const MediaCenter = () => {
  const { t, i18n } = useTranslation();

  const slides = [
    {
      image: "/images/largeMediaImg.jpg",
    },
    {
      image: "/images/largeMediaImg.jpg",
    },
  ];

  const swiperRef = React.useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="py-4 mb-12">
      <div className="w-[90%] mx-auto flex flex-col gap-12">
        <h2
          className={`${
            i18n.language == "ar" ? "cairo" : "manrope"
          }text-[#0A4E68] text-4xl text-center font-medium tracking-wide`}
        >
          {t("Home1.MEDIA CENTER")}
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* left section */}
          <div className="flex flex-col justify-between gap-4">
            <div className="flex flex-col md:flex-row gap-8 items-center rounded-lg overflow-hidden bg-white shadow-lg h-auto min-h-[200px]">
              <img
                src="/images/aboutPageHeroSection.svg"
                className="h-[100%] w-full lg:w-[200px] object-cover"
              />
              <div className="flex flex-col gap-2 w-full lg:w-[60%] p-2">
                <p className="text-[#013C4A] font-medium text-sm">
                  {t("Home1.smallMedia1Title")}
                </p>
                <p className="text-[#084F67] text-xs leading-5 font-light tracking-wide">
                  {t("Home1.smallMedia1Desc")}
                </p>
              </div>
            </div>
            <div className="flex  flex-col md:flex-row gap-8 items-center rounded-lg overflow-hidden bg-white shadow-lg h-auto min-h-[200px]">
              <img
                src="/images/smallMedia2Img.JPG"
                className="h-[100%] w-full lg:w-[200px] object-cover"
              />
              <div className="flex flex-col gap-2 w-full lg:w-[60%] p-2">
                <p className="text-[#013C4A] font-medium text-sm">
                  {t("Home1.smallMedia2Title")}
                </p>
                <p className="text-[#084F67] text-xs leading-5 font-light tracking-wide">
                  {t("Home1.smallMedia2Desc")}
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row  gap-8 items-center rounded-lg overflow-hidden bg-white shadow-lg h-auto min-h-[200px]">
              <img
                src="/images/smallMedia3Img.jpeg"
                className="h-[100%] w-full lg:w-[200px] object-cover"
              />
              <div className="flex flex-col gap-2 w-full lg:w-[60%] p-2">
                <p className="text-[#013C4A] font-medium text-sm">
                  {t("Home1.smallMedia3Title")}
                </p>
                <p className="text-[#084F67] text-xs leading-5 font-light tracking-wide">
                  {t("Home1.smallMedia3Desc")}
                </p>
              </div>
            </div>
          </div>

          {/* right section */}
          <div className="bg-white flex flex-col gap-6 pb-8 rounded-b-lg shadow-lg">
            <Swiper
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              pagination={{ type: "bullets", clickable: true }}
              loop={true}
              modules={[Autoplay, Navigation, Pagination]}
              className="w-[100%]"
              ref={swiperRef}
            >
              {slides?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="flex flex-col items-center">
                    <div className="w-[100%] h-[400px]">
                      <img
                        src={slide?.image}
                        alt="img"
                        className="object-cover h-[100%] w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-6 justify-between px-8 mt-4">
                      <p className="text-[#013C4A] font-medium text-sm">
                        {t("Home1.largeMediaTitle")}
                      </p>
                      <p className="text-[#084F67] text-xs leading-5 font-light tracking-wide">
                        {t("Home1.largeMediaDesc")}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <button
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-black rounded-full p-2 shadow-lg z-10"
                onClick={handlePrev}
              >
                <IoIosArrowBack size={24} />
              </button>
              <button
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-black rounded-full p-2 shadow-lg z-10"
                onClick={handleNext}
              >
                <IoIosArrowForward size={24} />
              </button>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaCenter;
