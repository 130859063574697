import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { donotAgentDetailsInitialValue } from '../../../Validations/Donor/initialValues';
import { donotAgentDetailsValidationSchema } from '../../../Validations/Donor';
import Loader from "react-js-loader";
import { useTranslation } from 'react-i18next';
import * as yup from "yup";
import { fetchlocalstorage } from '../../../HelperFunctions';

const AgentDetails = ({ onSubmit, DonorProfile, loading, onBack }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      ...donotAgentDetailsInitialValue,
      confirmAccuracy: false,
    },
    validationSchema: yup.object({
      agentName: yup.string(),
      agentMobileNumber: yup.number().typeError('Agent mobile number must be a number'),
      agentEmail: yup.string().email('Invalid agent email'),
      confirmAccuracy: yup.bool().oneOf([true], 'You must confirm the information is accurate'),
    }),
    onSubmit: async (values) => {
      onSubmit(values);
      // Handle form submission here
    },
  });

  useEffect(() => {
    if (DonorProfile) {
      const filteredData = {};

      Object.keys(donotAgentDetailsInitialValue).forEach(key => {
        if (DonorProfile.hasOwnProperty(key)) {
          filteredData[key] = DonorProfile[key];
          if (DonorProfile[key] == null) {
            filteredData[key] = '';
          }
        }
      });

      // Set filtered data as form values
      formik.setValues({ ...filteredData, confirmAccuracy: false });
    }
  }, [DonorProfile]);
  useEffect(()=>{
    const storedFormData = sessionStorage.getItem('donorFormData2');
    if (storedFormData) {
      const parsedData = JSON.parse(storedFormData);
      formik.setValues(parsedData);
      // formik.setFieldValue("domain", parsedData?.domain?.map((item) => ({ value: item?._id, label: item?.name })))
    }
  },[])
  const onBackPress = () =>{
    sessionStorage.setItem('donorFormData2', JSON.stringify(formik?.values));
    onBack();

  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="mb-4">
              <label htmlFor="agentName" className="block text-sm font-medium text-gray-700">{t(`Name`)}</label>
              <input type="text" name="agentName" id="agentName" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter name")}
                {...formik.getFieldProps('agentName')}
              />
              {formik.touched.agentName && formik.errors.agentName ? (
                <p className="text-red-500">{formik.errors.agentName}</p>
              ) : null}
            </div>

            <div className="mb-4">
              <label htmlFor="agentEmail" className="block text-sm font-medium text-gray-700">{t(`Email`)}</label>
              <input type="agentEmail" name="agentEmail" id="agentEmail" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter email")}    {...formik.getFieldProps('agentEmail')}
              />
              {formik.touched.agentEmail && formik.errors.agentEmail ? (
                <p className="text-red-500">{formik.errors.agentEmail}</p>
              ) : null}
            </div>
          </div>

          <div>
            <div className="mb-4">
              <label htmlFor="agentMobileNumber" className="block text-sm font-medium text-gray-700">{t(`Mobile Number`)}</label>
              <div className='flex justify-center items-center'>
                <span className={`mr-2 px-2 py-1.5 mt-1 bg-gray-500 text-white  ${fetchlocalstorage("language") === "ar" ? "rounded-r-md" : "rounded-l-md"}`} >+966</span>
                <input type="number" min="0" name="agentMobileNumber" id="agentMobileNumber" className={`mt-1 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm  ${fetchlocalstorage("language") === "ar" ? "rounded-l-md" : "rounded-r-md"}`} placeholder={t("Enter mobile number")} {...formik.getFieldProps('agentMobileNumber')}
                />
              </div>
              {formik.touched.agentMobileNumber && formik.errors.agentMobileNumber ? (
                <p className="text-red-500">{formik.errors.agentMobileNumber}</p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            <input type="checkbox" name="confirmAccuracy" className="mr-2 leading-tight" {...formik.getFieldProps('confirmAccuracy')} />
            {t("I confirm all information is accurate")}
          </label>
          {formik.touched.confirmAccuracy && formik.errors.confirmAccuracy ? (
            <p className="text-red-500">{formik.errors.confirmAccuracy}</p>
          ) : null}
        </div>
        

        <div className='flex justify-between'>
            <div className="mt-6">
                  <button type="button" onClick={onBackPress} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {t(`Back`)}
                  </button>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="inline-flex justify-center py-2 submitButtonLoader px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={!formik.values.confirmAccuracy}
              >
                {t("Submit")}
              </button>
            </div>
        </div>
      </form>
    </div>
  );
};

export default AgentDetails;
