
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next'
import Loader from "react-js-loader";
import { useNavigate } from 'react-router';
import ViewNotes from '../../pages/ViewNotes';

const CommentDetailModal = ({onClose , projectComments , selectedInput}) => {
    const [rememberMe, setRememberMe] = useState(false)
    const { t } = useTranslation()
    const navigate = useNavigate()

    console.log("projectComments in Modal->" , projectComments)
    console.log("selectedInput in Modal->" , selectedInput)

    return (
        <div>
            <div className="fixed inset-0 z-50 overflow-auto  bg-gray-800 bg-opacity-50 flex items-center justify-center">
                <div className="bg-white w-full mx-2 sm:w-[50%] p-6  rounded-lg">

                    {/* <form onSubmit={formik.handleSubmit}> */}
                    <div className="flex justify-end w-full ">
                        <button
                            onClick={onClose}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                            aria-label="Close modal"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                ></path>
                            </svg>
                        </button>
                    </div>

                    <div className='flex flex-col gap-8'>
                        <ViewNotes projectComments={projectComments} selectedInput={selectedInput} />
                    </div>

                    <button type='button' className='bg-green-500 text-white px-4 py-2 rounded-md '
                        onClick={onClose}>
                        {t('Close')}
                    </button>
                    {/* </form> */}
                </div>
            </div>
        </div>
    )
}

export default CommentDetailModal
