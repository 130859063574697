import { Link } from "react-router-dom";

//----------------------------------------------------------------------------------------------------------------------------------------

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Header from "./Header";
import { FaArrowRight } from "react-icons/fa6";
import axios from "axios";
import Achivements from "../HomePage/Achivements";
import MediaCenter from "../MediaCenter/MediaCenter";
import HomeImageBanner from "../HomePage/HomeImageBanner";
import ProjectGuide from "../ProjectGuide/ProjectGuide";
import SupervisiorySection from "../HomePage/SupervisiorySection";
import Footer from "../Footer/Footer";
import MatururedProjects from "../HomePage/MaturedProjects";
import { backendUrl } from "../../axios";
//----------------------------------------------------------------------------------------------------------------------------------------

//MAIN HOMEPAGE COMPONENT - CONTAINING  MAKE DONATION AND EXPORE CAUSES TAB
// NESTED COMPONENTS ARE USED IN END REQUIRED FOR HOMEPAGE DISPLAY ITEMS

export default function SliderArea() {
  const { t } = useTranslation();

  let [language, setLanguage] = useState("ar");

  useEffect(() => {
    const lang = localStorage.getItem("language");
    setLanguage(lang ? lang : "ar");
  }, [localStorage.getItem("language")]);


  const [domains, setdomains] = useState([])
  const [selectedDomain, setSelectedDomain] = useState('')
  const [domainsCard, setdomainsCard] = useState([])

  const handleClick = (domain) => {
    setSelectedDomain(domain);
  };


  useEffect(() => {
    axios.get(`${backendUrl}/domain/getAllDomains`).then((res) => {

      console.log("res", res.data.domains)
      setdomains(res.data.domains)
      setSelectedDomain('')
    })
  }, [])

  useEffect(() => {
    axios.get(`${backendUrl}/project/getAllLiveProjects?domain=${selectedDomain}&page=&limit=`).then((res) => {
      console.log("res", { res })
      setdomainsCard(res.data.data)
    })
  }, [selectedDomain])


  const { i18n } = useTranslation()

  return (
    <div className="w-full bg-[#eeece1]">

      <div className=" bg-cover h-[85vh] max-h-[800px] rounded-b-[40px] flex flex-col "
        style={{
          backgroundImage: "url('/images/homeBanner.svg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
       >
        <Header />

        {/* Main Heading Sectin */}
        <div className="text-white flex flex-col gap-8 w-[80%] mx-auto my-auto">

          <div className="flex flex-col gap-4">
            <p  className="font-bold text-4xl md:text-6xl lg:leading-[5.5rem] lg:max-w-[800px]"  >{t('Home1.title')}</p>

          </div>

          {/* <p className="text-5xl font-bold">Supporting Charities <br></br> <span className="font-normal text-5xl">and Donors for a better <br></br> tomorrow.</span></p> */}
          <p className="w-[100%] max-w-[500px] text-lg font-light leading-8 text-justify">{t("Home1.description")}</p>

          <Link to={'/opportunity'}> <button className="bg-[#EDC76F] text-[#013C4A] px-8 py-3 rounded-3xl w-fit font-bold flex items-center gap-2
        ">{t("Home1.givingOpportunities")} <span className="text-sm"><FaArrowRight /></span></button>
          </Link>

        </div>

      </div>

      {/* OverView Sections */}

      <div className=" w-full my-12 py-8">
        <div className="w-[80%] mx-auto py-8 flex flex-col items-center gap-8 justify-between ">

          <h3 className={`text-4xl text-center font-medium text-[#0A4E68] ${i18n.language == 'ar' ? "cairo" : "manrope"}`}>{t("Home1.overviewTitle")}</h3>

          <p className="w-[100%] md:w-[100%]  max-w-[800px] text-center text-lg font-semibold leading-9 text-[#084F679C]">{t("Home1.overviewDescription")} </p>

          <Link to={'/about'}>
            <button className="bg-[#EDC76F] text-[#013C4A] px-8 py-3 rounded-3xl w-fit font-medium flex items-center gap-2
        ">{t("Home1.overviewButton")}<span className="text-sm"><FaArrowRight /></span></button>
          </Link>



        </div>
      </div>

      {/* Prince Message */}

      <div className="w-full my-12">
        <div className="h-[300px] bg-[#0A4D69] text-white flex p-4 flex-col gap-4 items-center justify-center text-center w-[86%] mx-auto rounded-3xl">
          <p className="text-2xl md:text-3xl italic font-[100]">"{t("Home1.quote")}"</p>
          <p className="text-3xl md:text-4xl font-medium tracking-wide leading-10">{t("Home1.quoteAuthor")}</p>
        </div>
      </div>

      <Achivements />

      {/* Latest Projects */}

      <MatururedProjects domains={domains} selectedDomain={selectedDomain} domainsCard={domainsCard} handleClick={handleClick} />

      {/* Media Center */}
      <MediaCenter />

      {/* Image Banner */}

      <HomeImageBanner />

      <ProjectGuide />

      <SupervisiorySection />

      {/* Footer */}
      <Footer />



    </div>
  );
}
