import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from "yup";
import { updateReportApi } from '../Api';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const UploadReport = ({ onClose ,id}) => {
    const [reportName, setReportName] = useState('');
    const [reportFile, setReportFile] = useState(null);
     //   const handleSubmit = () => {
    //     // Handle form submission here
    //     console.log('Report Name:', reportName);
    //     console.log('Report File:', reportFile);
    //     // Add your submission logic here, e.g., sending data to backend
    //     // After submission, close the modal
    //     onClose();
    //   };
const {t}=useTranslation()

    const formik = useFormik({
        initialValues: {
            uploadReportname: "",
            uploadReportUrl: ""
        },
        validationSchema: yup.object({
            uploadReportname: yup.string().required('Report Name is required'),
            uploadReportUrl: yup.string().required('Report Url is required'),
        }),
        onSubmit: async (values) => {
            try {
                // console.log(values)
                let res = await updateReportApi(values,id)
                if (res.status === 200 || res.status === 201) {
          
                    toast.success(t(res?.data?.message), { id: "001" });
                      //if donar created successfully update isProfile true for furter validation
                      onClose()
                      formik.resetForm()
                     
                }
            } catch (error) {
                
            }
            // Handle form submission here
        },
    });


    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState({});
    const handleFileChange = async (event, fieldType) => {
      const inputElement = event.target;
      const selectedFile = inputElement.files[0];
    
      if (selectedFile) {
        const isPdf = selectedFile.type === 'application/pdf';
        const isLessThan5MB = selectedFile.size < 5 * 1024 * 1024; // 5MB in bytes
    
        // Validate file type
        if (!isPdf) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldType]: 'Only PDF files are allowed.',
          }));
          setFiles((prevFiles) => ({
            ...prevFiles,
            [fieldType]: null,
          }));
    
          // Clear the file input
          inputElement.value = null;
          return;
        }
    
        // Validate file size
        if (!isLessThan5MB) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldType]: 'File size should be less than 5MB.',
          }));
          setFiles((prevFiles) => ({
            ...prevFiles,
            [fieldType]: null,
          }));
    
          // Clear the file input
          inputElement.value = null;
          return;
        }
    
        // Clear errors and set the file in state
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldType]: null,
        }));
        setFiles((prevFiles) => ({
          ...prevFiles,
          [fieldType]: selectedFile,
        }));
    
        // Create FormData and make the API request
        const formData = new FormData();
        formData.append("file", selectedFile);
    
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
    
          // Update the field value with the uploaded file URL
          formik.setFieldValue(fieldType, response?.data?.fileUrl);
    
        } catch (error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldType]: 'File upload failed. Please try again.',
          }));
          // Clear the file input if the upload fails
          inputElement.value = null;
        }
      }
    };
    

    const uploadImage = async (e, fieldType) => {
      const inputElement = e.target;
      const selectedFile = inputElement.files[0];
    
      if (selectedFile) {
        const isJpgOrPng =
          selectedFile.type === 'image/jpeg' ||
          selectedFile.type === 'image/png';
        const isLessThan5MB = selectedFile.size < 5 * 1024 * 1024; // 5MB in bytes
    
        if (!isJpgOrPng) {
          alert('Only JPG and PNG files are allowed.');
          // Clear the file input
          inputElement.value = null;
          return;
        }
    
        if (!isLessThan5MB) {
          alert('File size should be less than 5MB.');
          // Clear the file input
          inputElement.value = null;
          return;
        }
    
        const image = new Image();
        image.src = URL.createObjectURL(selectedFile);
    
        image.onload = async () => {
          const { width, height } = image;
          const isAspectRatioValid = width === height;
    
          if (!isAspectRatioValid) {
            alert('The image must have a 1:1 aspect ratio (equal width and height).');
            // Clear the file input
            inputElement.value = null;
            return;
          }
    
          // If all validations pass, proceed with the upload
          const formData = new FormData();
          formData.append("file", selectedFile);
    
          try {
            let data = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
    
            // Handle response and update the form field
            formik.setFieldValue(fieldType, data?.data?.fileUrl);
          } catch (error) {
            alert('File upload failed. Please try again.');
            // Clear the file input if the upload fails
            inputElement.value = null;
          }
        };
    
        image.onerror = () => {
          alert('Invalid image file.');
          // Clear the file input in case of an invalid image
          inputElement.value = null;
        };
      }
    };
    

       
    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white w-full max-w-md p-6 rounded-lg">
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                        aria-label="Close modal"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </button>
                </div>
                <h5>Upload any report</h5>
                <form onSubmit={formik.handleSubmit}>

                <div className="mt-4">
                    <div>
                        {/* <label htmlFor="reportName">Report Name:</label>
            <input type="text" id="reportName" name="reportName" /> */}
                        <label htmlFor="uploadReportname" className="block text-sm font-medium text-gray-700">Report Name</label>
                        <input type="uploadReportname" name="uploadReportname" id="uploadReportname" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter Report Name" {...formik.getFieldProps('uploadReportname')} />
                        {formik.touched.uploadReportname && formik.errors.uploadReportname ? (
                            <p className="text-red-500">{formik.errors.uploadReportname}</p>
                        ) : null}

                    </div>
                    <div className="mb-4">
                        <label htmlFor="uploadReportUrl" className="block text-sm font-medium text-gray-700">Upload report</label>
                        <input type="file" name="uploadReportUrl" id="uploadReportUrl" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Select file"
                            // {...formik.getFieldProps('uploadReportUrl')}
                            onChange={(e) => handleFileChange(e, "uploadReportUrl")}


                        />

                        {formik.touched.uploadReportUrl && formik.errors.uploadReportUrl ? (
                            <p className="text-red-500">{formik.errors.uploadReportUrl}</p>
                        ) : null}

                    </div>

                    <div className="mt-6">
                    <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        
                    >
                        Next
                    </button>
                </div>
                </div>
                </form>
            </div>
        </div>
    );
};

export default UploadReport
    ;
