import React, { useEffect, useState } from 'react'
import Aboutfootertoparea2 from "../Components/Aboutfootertoparea2"
import Navbar from '../Components/Nav_Form/Navbar'
import success from "../assets/img/success.svg"
import { useLocation, useNavigate } from 'react-router-dom';
import { paymentCallbackApi } from '../Api';
import { useTranslation } from 'react-i18next';
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
const PaymentSuccess = () => {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState({});
  const navigate = useNavigate()
  const {t} = useTranslation()
  async function makePayment(data){
    try {
      
      let res = await paymentCallbackApi(data)
      console.log(res)
    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    console.log(params)
    makePayment(params)
    setQueryParams(params);
  }, [location.search]);
  return (
    <div>
        <Navbar/>
        <div className='flex justify-center item center py-20'>
            <div>
                <div className='flex justify-center item center'>

                {
                  queryParams.status=="failed"?<div className='flex gap-2 item-center'>

                    <RxCross2 className='text-red-700 mt-2 text-[2rem]'/>    <span className='mt-3'>  {t(`Your Payment is Failed`)}</span>
                  </div>:<div className='flex gap-2 item-center'>

<TiTick className='text-green-700 mt-2 text-[2rem]'/>    <span className='mt-3'>  {t(`Your Payment is successfully completed`)}</span>
</div>

               } </div>
                <button  className='bg-project_bg_image_color rounded text-white p-2 mt-4' onClick={()=>navigate("/my-donations")}>{t(`Go To Home`)} </button>
            </div>
        </div>

<Aboutfootertoparea2 />

    </div>
  )
}

export default PaymentSuccess