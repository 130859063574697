import * as yup from "yup";

export const createProjectBasicDetailValidations = yup.object({
  projectName: yup.string().required("Project name is required"),
  domain: yup.string().required("Project Domain is required"),
  region: yup.array()
  // Specify that the array contains strings
  .min(1, 'Select at least one option') // Ensu/re at least one item is selected
  .required('Region is required'),
  projectJustification: yup.string().required("Project Justification is required"),
  projectOverview: yup.string().required("Project OverView is required") ,
  projectObjectives: yup.string().required("Project Objective is required") ,
  targetedAudience: yup.string().required("Trageted Audience is required")  ,
  startDate: yup.date().required("Starting date is required"),
  closeDate: yup.date().required("Closing date is required"),
  remark: yup.string(),
  rating: yup.number(),
  expectedOutcomes: yup.string().required("Expecred Outcomes is required") ,
  expectedImpact: yup.string().required("Expected Impact is required") ,
  creativeInnovativeAspect: yup.string().required("Creative innovative aspect is required") ,
  // expectedImpact: yup.string().required("Expected Impact is required") ,
  // creativeInnovativeAspect: yup.string().required("Creative Innovative Aspect is required") ,
  
  projectManagerName: yup.string().required("Project manager name is required"),
  projectManagerSignature:yup.string().required("Project Manager Signature is reqired")
  
});
export const createProjectTeamMemberValidations = yup.object({
 
  //Team form
  teamMembersFulltime: yup.string(),
  teamMembersVolunteer: yup.string(),//
  teamMembersPartnerships: yup.string(),
  partnershipstype: yup.string(),//
  teamMembersPercentageOfEachPartner: yup.number(),
  teamMembersPerformanceMeasurement: yup.string(),//
  teamMembersProjectImplementation: yup.boolean(),
  teamMembersLastProjectsReports: yup.string(),//
  teamMembersSupportingEntityStrategy: yup.string(),
  teamMembersFundingPercentage: yup.number().required("Funding Percentage is required"),
  
});
export const createProjectFinancialDetailValidations = yup.object({
  
    // Financial form
    financialDetails: yup.array().of(
      yup.object({
        item: yup.string().required('Required'),
        quantity: yup.number().required('Required').positive('Must be positive').integer('Must be an integer'),
        price: yup.number().required('Required').positive('Must be positive')
      })
    ),
    financialDetailsItem: yup.string(),
    financialDetailsPrice: yup.number(),//
    financialDetailsQuantity: yup.number(),
    financialDetailsTotal: yup.number(),
    plansActivity: yup.string(),
    plansTimeline: yup.string(),
    plansPerformance: yup.string()
  
});

