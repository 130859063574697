import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Footer from "../Footer/Footer";
import  moment from "moment";
import { backendUrl } from "../../axios";


const HeldEventsFilter = () => {
    const { t } = useTranslation();
    const [domains, setDomains] = useState([]);
    const [selectedTab, setSelectedTab] = useState("");
    const [events, setEvents] = useState([]);

    function getTimeDifference(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        // Calculate the difference in milliseconds
        const difference = endDate - startDate;
      
        // Calculate days, hours, minutes, and seconds
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      
        return {
          days,
          hours,
          minutes,
          seconds
        };
      }

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await axios.get(`${backendUrl}/domain/getAllDomains`);
                setDomains([{ _id: "", name: "ALL" }, ...response.data.domains]);
                setSelectedTab("");
            } catch (error) {
                console.error("Error fetching domains:", error);
            }
        };
        fetchDomains();
    }, []);

    useEffect(() => {
        const getAllApprovedEvents = async () => {
            try {
                const response = await axios.get(`${backendUrl}/event/getAllApprovedEvents?domain=${selectedTab}&page=1&limit=10`);
                setEvents(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };
        getAllApprovedEvents();
    }, [selectedTab]);

    return (
        <div className="w-[100%] absolute top-[30vh]  bg-[#EEEDE5]">
            <div className="container mx-auto p-4 rounded-lg" style={{ zIndex: '999' }}>
                <div className="flex flex-wrap justify-center gap-3 mb-8">
                    {domains.map((domain) => (
                        <button
                            key={domain._id}
                            onClick={() => setSelectedTab(domain._id)}
                            className={`p-2 h-12 lg:p-3 lg:text-2xl text-2xl leading-8 text-center capitalize rounded-lg border border-sky-900 border-solid ${selectedTab === domain._id ? 'bg-sky-900 text-white' : 'text-sky-900'}`}
                        >
                            {t(domain.name)}
                        </button>
                    ))}
                </div>

                <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
    {events.length > 0 ? (
        events.map((event, index) => (
            <div key={index} className="flex flex-col justify-center self-stretch px-10 py-5 bg-white rounded-lg shadow-sm max-md:px-5">
                <div className="justify-center py-5 text-2xl leading-7 uppercase border-b border-solid border-slate-500 border-opacity-40 text-cyan-950 max-md:max-w-full max-md:text-xl">
                    {t(event.name)}
                </div>
                <div className="flex gap-5 justify-between mt-6 w-full max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col text-2xl text-sky-900 text-opacity-60 w-[90%] max-md:max-w-full max-md:text-lg">
                        <div className="flex gap-5 capitalize leading-[131%] max-md:flex-wrap">
                            <div className="font-medium w-[240px] ">{t('Description')}:</div>
                            <div className="max-md:max-w-full text-right" style={{ fontSize: '24px' }}>{event.description}</div>
                        </div>
                        <div className="flex gap-5 mt-4 capitalize leading-[131%] max-md:flex-wrap">
                            <div className="font-medium w-[240px] ">{t('Organizer Name')}:</div>
                            <div className="max-md:max-w-full">{event.organizerName}</div>
                        </div>
                        <div className="flex gap-5 mt-4 max-md:flex-wrap">
                            <div className="font-medium w-[240px] capitalize leading-[131%] ">{t('Email')}:</div>
                            <div className="leading-8 lowercase max-md:max-w-full">
                                {event.email}
                            </div>
                        </div>
                        <div className="flex gap-5 mt-4 max-md:flex-wrap">
                            <div className="capitalize leading-[131%] w-[240px]">
                                <span className="font-medium ">{t('Max Number Of People')}:</span>
                            </div>
                            <div className="leading-8 lowercase max-md:max-w-full">
                                {event.maxNumberOfPeople}
                            </div>
                        </div>
                        <div className="flex gap-5 mt-4 capitalize leading-[131%] max-md:flex-wrap">
                            <div className="font-medium w-[240px] ">{t("Address")}:</div>
                            <div className="max-md:max-w-full">{event.address}</div>
                        </div>
                        <div className="flex gap-5 mt-4 capitalize leading-[131%] max-md:flex-wrap">
                            <div className="font-medium w-[240px] ">{t('Start Date')}:</div>
                            <div className="max-md:max-w-full">{moment(event.startDate).format("DD MMM YYYY hh:mm a ")}</div>
                        </div>
                    </div>
                    <div className="flex gap-5 justify-between pr-20 my-auto capitalize max-md:flex-wrap">
                        <div className="flex flex-col">
                            <div className="text-4xl font-extrabold leading-8 text-center text-sky-900 max-md:text-2xl">{getTimeDifference(event.startDate,event.endDate)?.days}</div>
                            <div className="self-center mt-3.5 text-2xl leading-8 text-sky-900 text-opacity-60 max-md:text-lg">{t('Days')}</div>
                        </div>
                        <div className="flex flex-col">
                            <div className="text-4xl font-extrabold leading-8 text-center text-sky-900 max-md:text-2xl">{getTimeDifference(event.startDate,event.endDate)?.hours}</div>
                            <div className="self-center mt-3.5 text-2xl leading-8 text-sky-900 text-opacity-60 max-md:text-lg">{t('Hours')}</div>
                        </div>
                        <div className="flex flex-col whitespace-nowrap">
                            <div className="text-4xl font-extrabold leading-8 text-center text-sky-900 max-md:text-2xl">{getTimeDifference(event.startDate,event.endDate)?.minutes}</div>
                            <div className="mt-3.5 text-2xl leading-8 text-sky-900 text-opacity-60 max-md:text-lg">{t('Minutes')}</div>
                        </div>
                        <div className="flex flex-col whitespace-nowrap">
                            <div className="text-4xl font-extrabold leading-8 text-center text-sky-900 max-md:text-2xl">{getTimeDifference(event.startDate,event.endDate)?.seconds}</div>
                            <div className="mt-3.5 text-2xl leading-8 text-sky-900 text-opacity-60 max-md:text-lg">{t('Seconds')}</div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    ) : (
        <div className="text-center text-2xl text-gray-500 max-md:text-xl">{t("No events available")}</div>
    )}
</div>

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HeldEventsFilter;
