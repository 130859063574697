import React, { useEffect, useState } from 'react'
import OrganizationDetails from '../Components/Organization/Charity/OrganizationDetails'
import AgentDetails from '../Components/Organization/Charity/AgentDetails';
import Navbar from '../Components/Nav_Form/Navbar';
import DemoOrganizationDetails from '../Components/Organization/Charity/DemoOrganizationDetails'
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import { createCharityApi, updateCharityApi } from '../Api';
import { useTranslation } from 'react-i18next';
import { fetchlocalstorage } from '../HelperFunctions';
import { FaOtter } from 'react-icons/fa6';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/UI/Header';

const UpdateCharity = () => {
    const [step, setStep] = useState(1);
    const [step1Data, setStep1Data] = useState({});
    const [step2Data, setStep2Data] = useState({});
    const navigate = useNavigate()
    const { t } = useTranslation()
    let location = useLocation()
    let { state } = location;
    //   
    console.log('state', state.userInfo);
    const s = state.userInfo.objectives.map(item => ({
        label: item,
        value: item
    }));

    //const y=state.userInfo?.domain.map(item=>item.value);
    //console.log('y',y);
    //state.userInfo.domain=y;


    const [loading, setLoading] = useState(true)
    console.log('state', state);

    // console.log(state)

    // const handleStep1Submit = data => {
    //     setStep1Data(data);
    //     // console.log(data)

    //     setStep(2);
    // };

    const handleStep1Submit = async data => {

        setStep2Data(data);
        let payload = { ...step1Data, ...data, status: "PENDING" }
        console.log(payload)
        try {
            // console.log(
            //     payload
            // )
            setLoading(false)
            // if (payload.domain) {
            //     payload.domain = payload.domain.map(item => item.value);
            // }
            // if (payload.objectives) {
            //     payload.objectives = payload.objectives.map((item) => item.value)
            // }


            const res = await updateCharityApi(payload, state?.userInfo._id);
            // console.log(res)
            if (res.status === 200 || res.status === 201) {

                toast.success(t(res?.data?.message), { id: "001" });
                //if donar created successfully update isProfile true for furter validation
                toast.success(t("Charity Updated Successfully"))
                navigate("/organization-profile", { replace: true });
                setLoading(true)
            } else {
                setLoading(true)
            }
        } catch (error) {
            toast.error(t(error?.response?.data?.message), { id: "err501" });
        }


    };



    return (
        <div>
            {/* <Navbar /> */}

            <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
                <Header />
            </section>
            <div className="bg-white py-8 px-4  shadow-md rounded-lg my-5 mx-auto max-w-4xl ">
                {/* Header Section */}
                <div className="text-center pb-8">
                    <p className="text-[#1f3a8a] text-2xl font-semibold">{t("Let's get you started")}</p>
                    <p className="text-gray-600">{t(`Enter the details to get going`)}</p>
                </div>

                {/* Stepped Section Indicators */}
                <div className="flex justify-between text-center mb-6">
                    {/* <div className="w-1/2">
                        <div className={`inline-block w-8 h-8 ${step==1 ?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"}  rounded-full text-lg leading-loose  ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>1</div>
                        <span className="text-sm font-semibold text-gray-700 ml-2">{t(`Organisation Details`)}</span>
                    </div> */}
                    {/* <div className="w-1/2">
                        <div className={`inline-block w-8 h-8 ${step==2?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"} text-gray-600 rounded-full text-lg leading-loose ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>2</div>
                        <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Agent Details`)}</span>
                    </div> */}

                </div>

                <DemoOrganizationDetails onSubmit={handleStep1Submit} CharityProfile={state?.userInfo} />
                {/* <GeneralInformationDetails/> */}
                {/* {step == 2 ? <AgentDetails onSubmit={handleStep2Submit} CharityProfile={state?.userInfo} loading={loading}/> : ""} */}

            </div>

            <Footer />
        </div>

    )
}

export default UpdateCharity