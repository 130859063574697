import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const MobileMenu = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const modalRef = useRef(null);
  const { t } = useTranslation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };



  const fetchlocalstorage = (key) => {
    return localStorage.getItem(key);
  };

  return (
    <div
      ref={modalRef}
      className={`hamburger-menu  menu-bar relative info-bar d-inline-block width-[200px] ${fetchlocalstorage("language") === "ar" ? "mr-2" : "ml-5"}`}
      style={{ zIndex: 120 }}
    >
      <button
        onClick={toggleDropdown}
        className="hamburger-btn open-mobile-menu"
      >
        <i className="fal fa-bars"></i>
      </button>
      {isDropdownOpen && (
        <div
          className={`dropdown-menu absolute ${fetchlocalstorage("language") === "en" ? "right-[14px]" : "left-[3px] w-[200px]"}`}
          style={{ zIndex: 9999 }}
        >
          <span className="xs:block xl:hidden">
            <Link to="/" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Home")}</Link>
            <Link to="/about" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("About")}</Link>
            <Link to="/about-numbers" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Navbar.aboutNumbers")}</Link>
            <Link to="/opportunity" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Navbar.givingOppurtinities")}</Link>
            <Link to="/event" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Events")}</Link>
            <Link to="/faq" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Faq")}</Link>
            <Link to="/contact" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Contact")}</Link>
            {/* <Link to="/login" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Login Donor")}</Link> */}
            {/* <Link to="/signin-by-org" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Login organization")}</Link> */}
            {/* <Link to="/organization-profile" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("My Profile")}</Link> */}
            {/* <Link to="/donor-profile" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("My Profile")}</Link> */}
            {/* <Link to="/my-bookings" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("My Booking")}</Link> */}
            {/* <Link to="/my-availability" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("My Availability")}</Link> */}
            {/* <Link to="/consultant-profile" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("My Profile")}</Link> */}
            {/* <Link to="/chat" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Chats")}</Link> */}
            {/* <Link to="/get-all-workshop" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("My Workshop")}</Link> */}
            {/* <Link to="/my-donations" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("My Donations")}</Link> */}
            {/* <Link to="/my-analytics" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("My Analytics")}</Link> */}
            <Link to="/login"  className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t("Login")}</Link>
          </span>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
