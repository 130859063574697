import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { donorDetailInitialValues } from '../../../Validations/Donor/initialValues';
import { donorDetailsValidationSchema } from '../../../Validations/Donor';
import { callDomainForOrg } from '../../../Api';
import { useTranslation } from 'react-i18next';
import * as yup from "yup";
import Select from 'react-select';
import { fetchlocalstorage } from '../../../HelperFunctions';

const DonorDetails = ({ onSubmit, DonorProfile }) => {
  const [domain, setDomain] = useState([])
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: donorDetailInitialValues,
    validationSchema: yup.object({
      name: yup.string().required(t('Name is required')),
      domain: yup.array()
        .min(1, t('Select at least one option'))
        .required(t('Donation field is required')),
      mobileNumber: yup
        .number()
        .typeError(t('Mobile number must be a number'))
        .required(t('Mobile number is required')),
      landLineNumber: yup.number().typeError(t('Land number must be a number')),
      faxNumber: yup.number().typeError(t('Fax number must be a number')),
      email: yup.string().email(t('Invalid email')).required(t('Email is required')),
      entityType: yup.string(),
      licenseNumber: yup.string().required(t("License Number is required")),
      licenseAuthority: yup.string(),
      address: yup.string().required(t('Address is required')),
      googleMapLink: yup.string(),
      mission: yup.string(),
      vision: yup.string(),
      objectives: yup.string(),
      executiveManagerName: yup.string(),
      executiveManagerNumber: yup.number().typeError(t('Executive manager number must be a number')),
      registrationCertificateNumber: yup.string().required(t("Certificate Number is required")),
      city: yup.string().required(t("City is required")),
      district: yup.string().required(t("District is required")),
      street: yup.string().required(t("Street is required")),
      position: yup.string().required(t("Position is required"))
    }),
    onSubmit: async (values) => {
      // values.domain = formik.values?.domain && formik.values?.domain?.map((item) => item.value)
      onSubmit(values)
      // Save form values to session storage on submit
      sessionStorage.setItem('donorFormData1', JSON.stringify(values));
    },
  });

  async function callDomainType() {
    try {
      const data = await callDomainForOrg()
      const newData = data?.data?.domains.map(domain => ({
        value: domain?._id,
        label: domain?.name
      }));

      setDomain(newData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    callDomainType()

    // Load form data from session storage if available
    const storedFormData = sessionStorage.getItem('donorFormData1');
    if (storedFormData) {
      const parsedData = JSON.parse(storedFormData);
      formik.setValues(parsedData);
      // formik.setFieldValue("domain", parsedData?.domain?.map((item) => ({ value: item?._id, label: item?.name })))
    }

    // Set email from localStorage
    let user = JSON.parse(localStorage.getItem("userDetails"))
    formik.setFieldValue("email", user.email)

    if (DonorProfile) {
      const filteredData = {};
      Object.keys(donorDetailInitialValues).forEach(key => {
        if (DonorProfile.hasOwnProperty(key)) {
          filteredData[key] = DonorProfile[key];
          if (DonorProfile[key] == null) {
            filteredData[key] = '';
          }
        }
      });

      formik.setValues(filteredData);
      // formik.setFieldValue("domain", filteredData?.domain?.map((item) => ({ value: item?._id, label: item?.name })))
    }

  }, [])

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Column 1 */}
          <div>
            {/* Entity Name */}
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t(`Entity Name`)}</label>
              <input type="text" name="name" id="name" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter Name")}  {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name ? (
                <p className="text-red-500">{formik.errors.name}</p>
              ) : null}
            </div>
            {/* More fields similar to the above */}
            <div className="mb-4">
              <label htmlFor="domain" className="block text-sm font-medium text-gray-700">{t('Donation field')}</label>
              {/* <select type="text" name="domain" id="domain" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="select"
                onChange={(e) => formik.setFieldValue('domain', e.target.value)}
                value={formik.values.domain} >
                <option >Select</option>
                {
                  domain.map((item) => <option key={item._id} value={item._id}>{item.name}</option>)

                } */}
              {/* <option value='Education'>Education</option>
                <option value='Social'>Social</option>
                <option value='alharm projects'>alharm projects</option> */}

              {/* </select> */}
              {/* {formik.touched.domain && formik.errors.domain ? (

                <p className="text-red-500">{formik.errors.domain}</p>
              ) : null} */}
              <Select
                id="domain"
                name="domain"
                isMulti
                options={domain}
                className="mt-1 block w-full"
                classNamePrefix="react-select"
                value={formik.values.domain}
                onChange={value => { formik.setFieldValue('domain', (value)) }}
              // onBlur={() => formik.setFieldTouched('domain', true)}
              />
              {formik.touched.domain && formik.errors.domain ? (
                <p className="text-red-500">{formik.errors.domain}</p>
              ) : null}



            </div>

            <div className="mb-4">
              <label htmlFor="mobileNumber" className="block text-sm font-medium text-gray-700">{t(`Mobile Number`)}</label>
              <div className='flex justif-center item-center'>
                <span className={`mr-2 px-2 py-1.5 mt-1 bg-gray-500 text-white  ${fetchlocalstorage("language") == "ar" ? "rounded-r-md" : "rounded-l-md"}`}>+966</span>
                <input type="number" min="0" name="mobileNumber" id="mobileNumber" className={`mt-1 block w-full px-3 py-2 border border-gray-300  shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm  ${fetchlocalstorage("language") == "ar" ? "rounded-l-md" : "rounded-r-md"}`} placeholder={t("Enter mobile number")}  {...formik.getFieldProps('mobileNumber')}
                />
              </div>
              {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                <p className="text-red-500">{formik.errors.mobileNumber}</p>
              ) : null}
            </div>

            <div className="mb-4">
              <label htmlFor="landLineNumber" className="block text-sm font-medium text-gray-700">{t("Landline Number")}</label>
              <div className='flex justif-center item-center'>
                <span className={`mr-2 px-2 py-1.5 mt-1 bg-gray-500 text-white   ${fetchlocalstorage("language") == "ar" ? "rounded-r-md" : "rounded-l-md"}`}>+966</span>
                <input type="number" min="0" name="landLineNumber" id="landLineNumber" className={`mt-1 block w-full px-3 py-2 border border-gray-300  shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm  ${fetchlocalstorage("language") == "ar" ? "rounded-l-md" : "rounded-r-md"}`} placeholder={t("Enter landline number")}  {...formik.getFieldProps('landLineNumber')} />
              </div>
              {formik.touched.landLineNumber && formik.errors.landLineNumber ? (
                <p className="text-red-500">{formik.errors.landLineNumber}</p>
              ) : null}
            </div>

            <div className="mb-4">
              <label htmlFor="faxNumber" className="block text-sm font-medium text-gray-700">{t(`Fax number`)}</label>
              <input type="number" min="0" name="faxNumber" id="faxNumber" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter fax number")} {...formik.getFieldProps('faxNumber')}
              />
              {formik.touched.faxNumber && formik.errors.faxNumber ? (
                <p className="text-red-500">{formik.errors.faxNumber}</p>
              ) : null}
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t(`Email`)}</label>
              <input type="email" name="email" disabled id="email" className="mt-1 bg-gray-300 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter Email")} {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-500">{formik.errors.email}</p>
              ) : null}
            </div>

            <div className="mb-4">
              <label htmlFor="entityType" className="block text-sm font-medium text-gray-700">{t(`Entity Type`)}</label>
              <input type="text" name="entityType" id="entityType" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter entity type")} {...formik.getFieldProps('entityType')}
              />
              {formik.touched.entityType && formik.errors.entityType ? (
                <p className="text-red-500">{formik.errors.entityType}</p>
              ) : null}
            </div>

            <div className="mb-4">
              <label htmlFor="licenseNumber" className="block text-sm font-medium text-gray-700">{t(`License Number`)}</label>
              <input type="string" name="licenseNumber" id="licenseNumber" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter licence number")}{...formik.getFieldProps('licenseNumber')}
              />
              {formik.touched.licenseNumber && formik.errors.licenseNumber ? (
                <p className="text-red-500">{formik.errors.licenseNumber}</p>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="licenseAuthority" className="block text-sm font-medium text-gray-700">{t(`Licensing Authority`)}</label>
              <input type="text" name="licenseAuthority" id="licenseAuthority" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter Authority")} {...formik.getFieldProps('licenseAuthority')}
              />
              {formik.touched.licenseAuthority && formik.errors.licenseAuthority ? (
                <p className="text-red-500">{formik.errors.licenseAuthority}</p>
              ) : null}
            </div>
            {/* More fields similar to the above */}
            <div className="mb-4">
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">{t(`Address`)}</label>
              <input type="text" name="address" id="address" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter address")} {...formik.getFieldProps('address')}
              />
              {formik.touched.address && formik.errors.address ? (
                <p className="text-red-500">{formik.errors.address}</p>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="googleMapLink" className="block text-sm font-medium text-gray-700">{t("Google map Link")}</label>
              <input type="text" name="googleMapLink" id="googleMapLink" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter link")} {...formik.getFieldProps('googleMapLink')}
              />
              {formik.touched.googleMapLink && formik.errors.googleMapLink ? (
                <p className="text-red-500">{formik.errors.googleMapLink}</p>
              ) : null}
            </div>

          </div>

          {/* Column 2 */}
          <div>
            {/* License Authority */}



            <div className="mb-4">
              <label htmlFor="mission" className="block text-sm font-medium text-gray-700">{t(`Mission`)}</label>
              <input type="text" name="mission" id="mission" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter mission")}  {...formik.getFieldProps('mission')}
              />
              {formik.touched.mission && formik.errors.mission ? (
                <p className="text-red-500">{formik.errors.mission}</p>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="vision" className="block text-sm font-medium text-gray-700">{t(`Vision`)}</label>
              <input type="text" name="vision" id="vision" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter vission")} {...formik.getFieldProps('vision')}
              />
              {formik.touched.vision && formik.errors.vision ? (
                <p className="text-red-500">{formik.errors.vision}</p>
              ) : null}
            </div>

            <div className="mb-4">
              <label htmlFor="objectives" className="block text-sm font-medium text-gray-700">{t(`Objectives`)}</label>
              <input type="text" name="objectives" id="objectives" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter objective")} {...formik.getFieldProps('objectives')}
              />
              {formik.touched.objectives && formik.errors.objectives ? (
                <p className="text-red-500">{formik.errors.objectives}</p>
              ) : null}
            </div>

            <div className="mb-4">
              <label htmlFor="executiveManagerName" className="block text-sm font-medium text-gray-700">{t(`Executive manager name`)}</label>
              <input type="text" name="executiveManagerName" id="executiveManagerName" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter executive manager name")} {...formik.getFieldProps('executiveManagerName')}
              />
              {formik.touched.executiveManagerName && formik.errors.executiveManagerName ? (
                <p className="text-red-500">{formik.errors.executiveManagerName}</p>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="executiveManagerNumber" className="block text-sm font-medium text-gray-700">{t(`Executive Manager Number`)}</label>
              <div className='flex justif-center item-center'>
                <span className={`mr-2 px-2 py-1.5 mt-1 bg-gray-500 text-white   ${fetchlocalstorage("language") == "ar" ? "rounded-r-md" : "rounded-l-md"}`}>+966</span>

                <input type="number" min="0" name="executiveManagerNumber"
                  id="executiveManagerNumber" className={`mt-1 block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm  ${fetchlocalstorage("language") == "ar" ? "rounded-l-md" : "rounded-r-md"}`} placeholder={t("Enter executive manager mobile number")} {...formik.getFieldProps('executiveManagerNumber')}
                />
              </div>
              {formik.touched.executiveManagerNumber && formik.errors.executiveManagerNumber ? (
                <p className="text-red-500">{formik.errors.executiveManagerNumber}</p>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="registrationCertificateNumber" className="block text-sm font-medium text-gray-700">{t(`Registration Certificate Number (000) issued by
(Issuing Authority)`)}</label>

              <input type="text" name="registrationCertificateNumber"
                id="registrationCertificateNumber" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter Registration Certificate Number")} {...formik.getFieldProps('registrationCertificateNumber')}
              />
              {formik.touched.registrationCertificateNumber && formik.errors.registrationCertificateNumber ? (
                <p className="text-red-500">{formik.errors.registrationCertificateNumber}</p>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700">{t(`City`)}</label>

              <input type="text" name="city"
                id="city" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter City ")} {...formik.getFieldProps('city')}
              />
              {formik.touched.city && formik.errors.city ? (
                <p className="text-red-500">{formik.errors.city}</p>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="district" className="block text-sm font-medium text-gray-700">{t(`District`)}</label>

              <input type="text" name="district"
                id="district" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter District")} {...formik.getFieldProps('district')}
              />
              {formik.touched.district && formik.errors.district ? (
                <p className="text-red-500">{formik.errors.district}</p>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="street" className="block text-sm font-medium text-gray-700">{t(`Street`)}</label>

              <input type="text" name="street"
                id="street" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter Street")} {...formik.getFieldProps('street')}
              />
              {formik.touched.street && formik.errors.street ? (
                <p className="text-red-500">{formik.errors.street}</p>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="position" className="block text-sm font-medium text-gray-700">{t(`Position Referred to as "the First Party.`)}</label>

              <input type="text" name="position"
                id="position" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={t("Enter Position")} {...formik.getFieldProps('position')}
              />
              {formik.touched.position && formik.errors.position ? (
                <p className="text-red-500">{formik.errors.position}</p>
              ) : null}
            </div>

          </div>

        </div>

        {/* Form Submit Button */}
        <div className="mt-6">
          <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            {t(`Next`)}
          </button>
        </div>
      </form>

    </div>
  )
}

export default DonorDetails