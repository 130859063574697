import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { callDomainForOrg, getAllProjectsApi } from "../Api";
import Loader from "react-js-loader";
import { useTranslation } from "react-i18next";
import { Color5 } from "../assets/img/colorbg";
import { fetchlocalstorage } from "../HelperFunctions";

export default function CauseRecentCause() {
  const navigate = useNavigate();

  const [filter, setFilter] = useState("");
  const [projects, setProjects] = useState([]);
  const [domain, setDomain] = useState([]);

  //pagination
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const { t } = useTranslation();
  let [Loading, setLoading] = useState(false);

  async function getAllCauses() {
    let { data } = await getAllProjectsApi(filter, nextPage, limit);
    // console.log(data)
    setLimit(data?.limit);
    setPage(data?.page);
    setTotal(data?.total);
    setTotalPages(data?.totalPages);
    setProjects(data?.data);
    setLoading(true);
  }

  async function getAllDomain() {
    let { data } = await callDomainForOrg();
    // console.log(data)
    setDomain(data?.domains);
  }

  let paginationButton = () => {
    let arr = [];
    for (let i = 1; i <= totalPages; i++) {
      arr.push(
        <button
          key={i}
          onClick={() => setNextPage(i)}
          className={`relative z-10 inline-flex items-center ${
            nextPage === i ? "bg-indigo-600" : ""
          } px-4 py-2 text-sm font-semibold ${
            nextPage === i ? "text-white " : "text-black"
          } focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
        >
          {i}
        </button>
      );
    }
    // console.log(arr)
    return arr;
  };

  useEffect(() => {
    // setProjects([]);

    // const filtered = filter==="" ? causes : causes.filter(c=>c.category.includes(filter));
    // setProjects(filtered);

    getAllCauses();
    getAllDomain();
  }, [filter, nextPage]);
  return (
    //Recent causes area start
    <section className="causes_area gray-bg pt-90 pb-85">
      {Loading ? (
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 text-center">
              <div className="cause_button_wrapper mb-25">
                <nav>
                  <div className="nav tab_buttons" id="nav-tab" role="tablist">
                    <button
                      className={`nav-link ${filter === "" ? "active" : ""}`}
                      onClick={() => setFilter("")}
                    >
                      {t("All")}
                    </button>
                    {domain &&
                      domain?.map((item, i) => (
                        <button
                          key={`asdfa1s-${i}`}
                          className={`nav-link ${
                            filter === "" ? "active" : ""
                          }`}
                          onClick={() => setFilter(item?._id)}
                        >
                          {item?.name}
                        </button>
                      ))}
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="row">
                {projects?.length ? (
                  projects?.map((item, i) => (
                    <div
                      className="col-xxl-4 col-xl-4 col-lg-4 col-md-6"
                      onClick={() =>
                        navigate("/causes-details", { state: { item } })
                      }
                      key={`asdfas-${i}`}
                    >
                      <div className="single_cause mb-30">
                        <div className="single_cause_img_wrapper">
                          <div className="single_cause_img w_img img_effect_white">
                            <Link href="/causes-details">
                              <img
                                src={item?.projectImage || Color5}
                                alt="img"
                                className="h-[300px]"
                              />
                            </Link>
                          </div>
                          <div className="progress cause_progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: `${
                                  (item?.raised / item?.financialDetailsTotal) *
                                  100
                                }%`,
                              }}
                              aria-valuenow="88"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <span>
                                {(item?.raised / item?.financialDetailsTotal) *
                                  100}
                                %
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="single_cause_content">
                          <div className="single_cause">
                            <span className="sub_title">
                              {item?.domain?.name}
                            </span>
                            <h3 className="title">
                              <Link to="/causes-details">
                                {item?.projectName}
                              </Link>
                            </h3>
                          </div>
                          <div className="single_cause_meta">
                            <div className="single_meta d-inline-block">
                              <span className="meta_text clr_theme1">
                                <i className="fal fa-globe"></i>
                                {t("Goal")}
                              </span>
                              <span className="meta_price clr_theme1">
                                ﷼ {item?.financialDetailsTotal}
                              </span>
                            </div>
                            <div className="single_meta d-inline-block">
                              <span className="meta_text clr_theme2">
                                <i className="fal fa-users"></i> {t("Raised")}
                              </span>
                              <span className="meta_price clr_theme2">
                                ﷼ {item.raised || 0}
                              </span>
                            </div>
                            <div
                              className={`single_meta d-inline-block  ${
                                fetchlocalstorage("language") == "ar"
                                  ? "mr-[46px] !important"
                                  : ""
                              } `}
                            >
                              <span className="meta_text clr_theme3">
                                <i className="fal fa-reply"></i> {t("To Go")}
                              </span>
                              <span className="meta_price clr_theme3">
                                {" "}
                                ﷼{" "}
                                {Math.floor(
                                  item.financialDetailsTotal - item.raised
                                ) || 0}
                              </span>
                            </div>
                          </div>
                          {/* <div className='mt-4'>

                                                <Link to={"a"} className='btn border bg-project_bg_image_color'>Evaluation Report</Link>
                                            </div> */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center my-4">
                    {t("No data available")}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="row">
                    <div className="cause_button text-center mt-10 mb-30">
                        <Link href="/cause" className="cause_btn g_btn to_right1 rad-30">Load More <i className="far fa-plus"></i> <span></span></Link>
                    </div>
                </div> */}
          <div className="bg-whit   ">
            <div className="flex items-center justify-between  border-gray-200 bg-white px-4 py-3 sm:px-6">
              <div className="flex flex-1 justify-between sm:hidden">
                <a
                  href="#"
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  onClick={() =>
                    nextPage !== 1 && setNextPage((prev) => prev - 1)
                  }
                >
                  {t("Previous")}
                </a>
                <a
                  href="#"
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  disabled={nextPage == totalPages ? true : false}
                  // disabled={true}
                  onClick={() =>
                    nextPage !== totalPages && setNextPage((prev) => prev + 1)
                  }
                >
                  {t("Next")}
                </a>
              </div>
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    {t(`Showing`)}{" "}
                    <span className="font-medium">
                      {limit * (page - 1) + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {limit * (page - 1) + projects?.length}
                    </span>{" "}
                    of <span className="font-medium">{total}</span>{" "}
                    {t(`results`)}
                  </p>
                </div>
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <a
                      href="#"
                      className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={() =>
                        nextPage == 1 && setNextPage((prev) => prev - 1)
                      }
                    >
                      <span className="sr-only">{t("Previous")}</span>
                      <span className="h-5 w-5" aria-hidden="true">
                        {"<"}
                      </span>
                    </a>

                    {paginationButton().map((item) => item)}

                    <a
                      href="#"
                      disabled={nextPage == totalPages ? true : false}
                      className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={() =>
                        nextPage !== totalPages &&
                        setNextPage((prev) => prev + 1)
                      }
                    >
                      <span className="sr-only">{t("Next")}</span>
                      <span className="h-5 w-5" aria-hidden="true">
                        {">"}
                      </span>
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <span>
          {" "}
          <Loader
            type="spinner-circle"
            bgColor={"black"}
            color={"black"}
            size={100}
          />
        </span>
      )}
    </section>
    //Recent causes area end
  );
}
