export function flattenProjectComments(projectComments) {
    return projectComments.map(item => {
        const flattened = {};

        if (item.createdBy?.name) {
            flattened.createdBy = item.createdBy.name;
        }

        if (item.projectName?.note) {
            flattened.projectNameNote = item.projectName.note;
        }
        if (item.projectName?.recommendation) {
            flattened.projectNameRecommendation = item.projectName.recommendation;
        }

        if (item.domain?.note) {
            flattened.domainNote = item.domain.note;
        }
        if (item.domain?.recommendation) {
            flattened.domainRecommendation = item.domain.recommendation;
        }

        if (item.projectJustification?.note) {
            flattened.projectJustificationNote = item.projectJustification.note;
        }
        if (item.projectJustification?.recommendation) {
            flattened.projectJustificationRecommendation = item.projectJustification.recommendation;
        }

        // Add similar conditions for other fields...

        if (item.financialDetails) {
            flattened.financialDetails = item.financialDetails.map(detail => {
                const financialDetail = {};
                if (detail.item?.note) {
                    financialDetail.itemNote = detail.item.note;
                }
                if (detail.item?.recommendation) {
                    financialDetail.itemRecommendation = detail.item.recommendation;
                }
                if (detail.price?.note) {
                    financialDetail.priceNote = detail.price.note;
                }
                if (detail.price?.recommendation) {
                    financialDetail.priceRecommendation = detail.price.recommendation;
                }
                if (detail.quantity?.note) {
                    financialDetail.quantityNote = detail.quantity.note;
                }
                if (detail.quantity?.recommendation) {
                    financialDetail.quantityRecommendation = detail.quantity.recommendation;
                }
                return financialDetail;
            }).filter(detail => Object.keys(detail).length > 0); // Filter out empty details
        }

        return flattened;
    }).filter(item => Object.keys(item).length > 0); // Filter out empty items
}
