import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ProfileSectionTwo = ({userInfo}) => {
    console.log(userInfo)
const {t} = useTranslation()
  return (
    <div className="border-hidden  border-project_image_color mr-2 border-2 rounded-md flex-1 ">
      <div className="p-2">
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Agent Name`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.agentName || <>{t("No Data available")}</>}
          </div>
        </div>

        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Agent Mobile Number`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.agentMobileNumber || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Agent Email`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md text-ellipsis	overflow-hidden">
            {userInfo?.agentEmail || <>{t("No Data available")}</>}
          </div>
        </div>

        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Executive Manager Name`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.executiveManagerName || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Executive Manager Number`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.executiveManagerNumber || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Fax number`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.faxNumber || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Google map Link`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {(userInfo?.googleMapLink && (
              <Link to={userInfo?.googleMapLink} target="_blank">
                {userInfo?.googleMapLink}
              </Link>
            )) ||
              <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Entity Type`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.entityType || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Mission`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.mission || <>{t("No Data available")}</>}
          </div>
        </div>

        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Objectives`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.objectives || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Vission`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.vission || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Registration Certificate Number`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.registrationCertificateNumber || <>{t("No Data available")}</>}
          </div>
        </div>
        <div className=" mb-2 flex justify-between">
          <div className="text-black p-1 border rounded-md mr-2 w-[45%] px-2">
            {t(`Targeted Audience`)}:{" "}
          </div>
          <div className="w-[45%] px-2 p-1 border rounded-md">
            {userInfo?.targetedAudience || <>{t("No Data available")}</>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSectionTwo;
