import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Color1, Color4, Color6, Color8 } from '../../assets/img/colorbg'
import { fetchlocalstorage } from '../../HelperFunctions'
import toast from 'react-hot-toast'
import ViewPdfModal from '../../Modal/ViewPdfModal'
import { useTranslation } from 'react-i18next'
import { getProjectEvaluationApi } from '../../Api'
//----------------------------------------------------------------------------------------------------------------------------------------

export default function CauseDetailsArea({ causeDetail }) {
  // console.log(causeDetail)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [amount, setAmount] = useState('')
  const [donationStatus, setDonationStatus] = useState("Donation")
  const [arbitratorName,setArbitratorName] = useState("")
  const [rating, setRating] = useState()
  const [arbitrator, setArbitrator] = useState([])
  const { t } = useTranslation()
  const navigate = useNavigate()
  // console.log(causeDetail)

  let projectLiveCriteria = [
    { name: "Project Idea", description: "The idea is clear and distinct, elevating the project to higher levels.", points: 5 },
    { name: "Project Goals", description: "The more the goals focus on the final beneficiary, the higher the chance of earning the full 10 points.", points: 10 },
    { name: "Reasons for Choosing the Project", description: "The more the reasons stem from a real need based on accurate and correct information, the higher the score.", points: 5 },
    { name: "Project Compatibility with the Entity's Specialty", description: "This project directly aligns with the entity's specialty.", points: 10 },
    { name: "Quality and Completeness of the Project Implementation Plan", points: 40 },
    { name: "Level of Creativity and Innovation Used in the Project", points: 10 },
    { name: "Support for the Entity's Strategy by the Project", points: 10 },
    { name: "Capability of the Entity and Team to Implement the Project", points: 15 },
    { name: "Realism of the Expected Results from Project Implementation", points: 5 },
    { name: "Realism of the Expected Impact from Project Implementation", points: 5 },
    { name: "Need of the Target Group for the Project", points: 10 },
    { name: "Existence of a Mechanism for Measuring and Evaluating the Project", points: 10 },
    { name: "Achieving Partnerships and Integration", description: "Each partnership or integration earns (10) points, provided the scope of work and limits of authority for each partner are clear.", points: 20 },
    { name: "Existence of a Project Manager", points: 3 },
    { name: "Existence of a Work Team", description: "The team should consist of several members or committees according to the size of the project and not be less than 3 workers.", points: 2 },
    { name: "Presence of Volunteers in the Project", description: "The more volunteers relative to the total workers, the higher the project's chance of earning the full score.", points: 10 },
    { name: "Financial Cost of the Project", description: "The more detailed, clear, and accurately calculated the financial cost, the higher the project's chance of earning the full score.", points: 15 },
    { name: "Feasibility of Implementation Even Without Financial Support for the Project", description: "Breaking down the project into time, geographical, technical, or other phases.", points: 15 }
  ];
  function donateFunc(status) {
    if(amount<=0){
      toast.error(t('Please enter amount'))
      return
    }
    if(!fetchlocalstorage("token")){
      toast(t('Please login to donate'))
      navigate("/login",{state:{donate:true,item:causeDetail}})

    }

    setDonationStatus(status)
    setIsModalOpen(true)

  }

  async function getProjectRatig() {
    let res = await getProjectEvaluationApi(causeDetail?._id)
    setArbitrator(res?.data?.data)
    // setRating(res?.data?.data[0]?.rating)
    // setArbitratorName(res?.data?.data[0]?.createdBy?.name)

  }

  useEffect(() => {
    if (causeDetail?._id) {

      getProjectRatig()
    }
  }, [causeDetail])
   
  return (
    // cause_details_area start
    <div className='cause_details_area pt-120 pb-80'>
      <div className='container'>
        <div className='row'>
          <div className='col-xxl-8 col-xl-8 col-lg-7'>
            <div className='single_details_wrapper mb-40'>
              <div className='single_details_img'>
                {/* <img src='/img/causes/cause_sbig.jpg' alt='img' /> */}
                <img src={causeDetail?.projectImage || Color6} alt='img' />
              </div>
              <div className='single_details_content'>
                <div className='single_donation_content single_border pb-45 mb-45'>
                  <div className='section_title'>
                    <Link href='#' className='sub_title_details line_h2_2'>
                      {causeDetail?.domain?.name}
                    </Link>
                  </div>
                  <h4 className='details_title'>
                    {causeDetail?.projectName}
                  </h4>
                  {/* <p>
                    generous actions or donations to aid the poor, ill, or
                    needy: to devote one&apos;s life to charity. something given
                    to a \person or persons in need; alms: She asked for work,
                    not charity. a charitable act or work. Thank you so much for
                    visiting my page, something I never thought I would be
                    setting up. Having fought stage IV cancer for almost two
                    years, I have ran out of options which makes my diagnosis
                    terminal{' '}
                  </p> */}
                  <div className='feature_progress_wrapper mb-25 mt-35'>
                    <div className='progress feature_progress'>
                      <div
                        className='progress-bar'
                        role='progressbar'
                        data-width='5%'
                        aria-valuenow='30'
                        aria-valuemin='0'
                        aria-valuemax='100'
                        style={{ width: `${(((causeDetail?.raised) / (causeDetail?.financialDetailsTotal)) * 100)}%` }}
                      ></div>
                    </div>
                  </div>
                  <div className='single_cause_meta'>


                    <div className='single_meta feature_meta d-inline-block'>
                      <span className='meta_text clr_theme3'>
                        <i className='fal fa-reply'></i>{t(`To Go`)}
                      </span>
                      <span className='meta_price single_price clr_theme3'>
                        ﷼ {(causeDetail?.financialDetailsTotal - causeDetail?.raised) || 0}
                      </span>
                    </div>
                    <div className='single_meta feature_meta feature_border d-inline-block'>
                      <span className='meta_text clr_theme2'>
                        <i className='fal fa-users'></i> {t('Raised')}
                      </span>
                      <span className='meta_price single_price clr_theme2'>
                      ﷼ {causeDetail?.raised || 0}
                      </span>
                    </div>
                    <div className='single_meta feature_meta feature_border d-inline-block'>
                      <span className='meta_text clr_theme1'>
                        <i className='fal fa-globe'></i> {t(`Goal`)}
                      </span>
                      <span className='meta_price single_price clr_theme1'>
                        ﷼ {causeDetail?.financialDetailsTotal}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='my-4'>
                  <h2 className='text-[2rem] mb-4'>{t(`ARBITRATOR`)}</h2>
                  <p className='flex gap-2'>
                    {arbitrator?.length ? arbitrator?.map((item =>
                      <button className='p-2 shadow rounded text-white bg-project_bg_image_color' onClick={() =>{ setRating(item?.rating);setArbitratorName(item?.createdBy?.name)}}>{item?.createdBy.name}</button>
                    )):t("No Details")}
                  </p>

                </div>
                <div className='my-4'>

                  <h2 className='text-[2rem] mb-4'>{t('Objective')}: </h2>
                  <p>
                    {causeDetail?.projectObjectives}
                  </p>
                </div>
                {/* <p className='mb-30'>
                  When I was 21 I had just finished my second year at Uni
                  studying to be a primary school teacher, which is a career I
                  have always dreamed of achieving. However, during my summer
                  break in August 2017, my world turned upside down when I was
                  diagnosed with an incredibly rare and aggressive cancer called
                  Alveolar Rhabdomyosarcoma, stage 4.
                </p>
                <p className='mb-45'>
                  The symptoms were so minimal (agonising back and leg pain) it
                  had made its way around my full body by the time I was
                  diagnosed. Since then I have undergone over 20 cycles of
                  chemotherapy, 50 sessions of radiotherapy and numerous
                  operations to try and stabilise my disease.
                </p> */}

                {/* <div className='row mb-45'>
                  <div className='col-6 w_img'>
                    <img src={Color8} alt='img' />
                  </div>
                  <div className='col-6 w_img'>
                     <img src={Color8} alt='img' />
                  </div>
                </div>   */}

                {/* <p className='mb-45'>
                  I have always dreamed of achieving. However, during my summer
                  break in August 2017, my world turned upside down when I was
                  diagnosed with an incredibly rare and aggressive cancer called
                  Alveolar Rhabdomyosarcoma, stage 4. The symptoms were so
                  minimal (agonising back and leg pain) it had made its way
                  around my full body time I was diagnosed. Since then I have
                  undergone over 20 cycles of chemotherapy, 50 sessions of
                  radiotherapy and numerous operations to try and stabilise my
                  disease.
                </p>   */}

                {/* <div className='single_person_quote'>
                  <i className='icofont-speech-comments'></i>
                  <div className='person_image'>
                    <Link href='/volunteer-details'>
                      <img src='/img/causes/cause_person.jpg' alt='img' />
                    </Link>
                  </div>
                  <div className='person_quote_text'>
                    <p>
                      After endless hours and incredibly late nights of
                      research, we have found that the USA have a trial drug
                      available which works alongside my current chemotherapy.
                    </p>
                    <div className='person_designation'>
                      <h5 className='person_nam'>
                        <Link href='#'>Anarkoli Disco Cali</Link>
                      </h5>
                      <span className='person_surname'>
                        Founder,Anarkoli Co.
                      </span>
                    </div>
                  </div>
                </div>   */}
                {/* <p className='single_border pb-45 mb-50'>
                  The symptoms were so minimal (agonising back and leg pain) it
                  had made its way around my full body by the time I was
                  diagnosed. Since then I have undergone over 20 cycles of
                  chemotherapy, 50 sessions of radio therapy and numerous
                  operations to try and stabilise my disease. We have now been
                  told that I have stopped responding to the few drugs available
                  for Sarcoma. After endless hours and incredibly late nights of
                  research, we have found that the USA have a trial drug
                  available which works alongside.
                </p>   */}

                <div className='single_donation_input single_border mb-50 pb-30'>
                  <div className='donation_submit_wrapper mb-20'>
                    <div className='donation_submit_box'>
                      <input type='text' value={amount} onChange={(e) => { e.target.value > ((causeDetail?.financialDetailsTotal - causeDetail?.raised)) ? toast.error(t("Amount can not be greater then To go")) : setAmount(e.target.value) }} placeholder='0' />
                      <button type='submit'>﷼</button>
                    </div>
                  </div>
                  <div className='flex gap-2 items-center' >

                    {JSON.parse(fetchlocalstorage("userDetails"))?.role !== "CHARITY" && <div className='submit_info_button'>
                      <button
                        onClick={() => donateFunc("Reserve")}
                        className='g_btn hbtn_1 to_right1 i_left rad-30'
                      >
                        <i className='fal fa-heart'></i> {t(`Reserve`)}
                        <span></span>
                      </button>
                    </div>}
                    {JSON.parse(fetchlocalstorage("userDetails"))?.role !== "CHARITY" && <div className='submit_info_button'>
                      <button
                        onClick={() => donateFunc("Donation")}

                        className='g_btn btn_secondary hbtn_1 to_right1 i_left rad-30'
                      >
                        <i className='fal fa-heart'></i> {t(`Donate`)}
                        <span></span>
                      </button>
                    </div>}
                  </div>

                </div>
                <div className='single_donation_payment single_border mb-50 pb-30'>
                  <h5 className='single_title mb-0'>{t('Payment Method')}</h5>
                  <div className='payment_icons'>
                    <Link href='#'>
                      <i className='icofont-amazon'></i>
                    </Link>
                    <Link href='#'>
                      <i className='icofont-paypal'></i>
                    </Link>
                    <Link href='#'>
                      <i className='icofont-visa'></i>
                    </Link>
                    <Link href='#'>
                      <i className='icofont-cash-on-delivery'></i>
                    </Link>
                    <Link href='#'>
                      <i className='icofont-google-wallet-alt-3'></i>
                    </Link>
                  </div>
                </div>
                {/* <div className='single_donation_info single_border mb-50 pb-45'>
                  <h5 className='single_title'>Personal Information</h5>
                  <form action='#'>
                    <div className='input_info_wrapper'>
                      <div className='input_info_name info_input'>
                        <input type='text' placeholder='Enter full name' />
                        <i className='fal fa-user'></i>
                      </div>
                      <div className='input_info_email info_input'>
                        <input type='email' placeholder='Enter email' />
                        <i className='fal fa-envelope'></i>
                      </div>
                    </div>
                  </form>
                  <div className='submit_info_wrapper d-sm-inline-flex'>
                    <div className='donation_submit_wrapper'>
                      <div className='donation_submit_box w_208'>
                        <button type='submit'>Donation</button>
                        <input type='text' placeholder='$100' />
                      </div>
                    </div>
                    <div className='submit_info_button'>
                      <Link
                        href='/cause'
                        className='g_btn hbtn_1 to_right1 i_left rad-30'
                      >
                        <i className='fal fa-heart'></i> Explore Causes
                        <span></span>
                      </Link>
                    </div>
                  </div>
                </div> */}
                {/* <div className='page_pagination_withimg'>
                  <Link
                    href='/causes-details'
                    className='img_pagination img_pagination_left'
                  >
                   
                    <div className='left_text'>
                      <span className='sub_pagination'>{t('Prev Cause')}</span>
                      <h5 className='pagination_title'>World Water Charity</h5>
                    </div>
                  </Link>
                  <Link
                    href='/causes-details'
                    className='img_pagination img_pagination_right'
                  >
                    <div className='right_text text-sm-end'>
                      <span className='sub_pagination'>{t('Next Cause')}</span>
                      <h5 className='pagination_title'>Helping poor people</h5>
                    </div>
                     
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className='col-xxl-4 col-xl-4 col-lg-5'>
            <div className='single_sidebar_wrapper pl-15 mb-40'>
           {  rating? <div className='single_widget has_border person_widget p-4 text-center mb-40 '>
                {/* <div className='widget_person_img'>
                  <Link href='/volunteer-details'>
                    <img src='/img/causes/person_main.jpg' alt='img' />
                  </Link>
                  <span className='check_sign'>
                    <i className='fal fa-check'></i>
                  </span>
                </div>
                <div className='person_designation widget_mb25'>
                  <h5 className='person_nam f_size24'>
                    <Link href='/volunteer-details'>MR. Muhammad Ali</Link>
                  </h5>
                  <span className='person_surname'>Founder,Anarkoli Co.</span>
                </div>
                <div className='feature_buttons widget_buttons'>
                  <Link
                    to='/donation'
                    className='g_btn hbtn_1 to_right1 i_left rad-30'
                  >
                    <i className='fal fa-heart'></i>Donate Now<span></span>
                  </Link>
                  <Link href='#' className='reply_btn theme-2'>
                    <i className='fal fa-reply'></i>
                  </Link>
                </div> */}
                <h1 className='text-xl'>Evaluation: </h1>
                <h1 className='text-xl'> Arbitrator: {arbitratorName}</h1>
                {projectLiveCriteria.map(item =>
                  <div className='text-start mb-2'>
                    <div>
                      {/* <h1 className='text-lg'>Project name</h1> */}
                      <p className='text-black'>{item?.name}</p>
                    </div>
                    <div >
                      {/* <h1 className='text-lg'>Project details</h1> */}
                      <p>{item?.description}</p>
                    </div>
                    <div className="single_cause_img_wrapper flex justify-between mt-4">
                      <span>0</span>
                      <div className="progress cause_progress w-full">
                        <div className="progress-bar bg-green" role="progressbar" style={{ width: `${(rating&&rating[item?.name]/item?.points)*100||"10"}%` }} aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"><span>{rating&&rating[item?.name] || 0}</span></div>
                      </div>
                      <span>
                        {item.points}
                      </span>

                    </div>

                  </div>
                )}

              </div>:""}

              {/* <div className='single_widget has_border post_widget mb-40'>
                <div className='single_widget_title'>
                  <h4 className='widget_title_text has_border'>Donors Post</h4>
                </div>
                <div className='donor_post_wrapper'>
                  <div className='single_donor_post'>
                    <div className='donar_post_img'>
                      <Link href='/volunteer-details'>
                        <img src='/img/causes/donar1.jpg' alt='img' />
                      </Link>
                    </div>
                    <div className='donar_post_content'>
                      <h5 className='donar_name'>
                        <Link href='/volunteer-details'>Nilmarendra Max</Link>
                      </h5>
                      <div className='donar_meta'>
                        <Link href='#' className='donar_amount theme-1 sep'>
                          $900
                        </Link>
                        <Link href='#' className='donar_date theme-2'>
                          <i className='fal fa-calendar-alt'></i> February 20,
                          2022
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='single_donor_post'>
                    <div className='donar_post_img'>
                      <Link href='/volunteer-details'>
                        <img src='/img/causes/donar2.jpg' alt='img' />
                      </Link>
                    </div>
                    <div className='donar_post_content'>
                      <h5 className='donar_name'>
                        <Link href='/volunteer-details'>Miranda H. Hilix</Link>
                      </h5>
                      <div className='donar_meta'>
                        <Link href='#' className='donar_amount theme-1 sep'>
                          $500
                        </Link>
                        <Link href='#' className='donar_date theme-2'>
                          <i className='fal fa-calendar-alt'></i> February 20,
                          2022
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='single_donor_post'>
                    <div className='donar_post_img'>
                      <Link href='/volunteer-details'>
                        <img src='/img/causes/donar3.jpg' alt='img' />
                      </Link>
                    </div>
                    <div className='donar_post_content'>
                      <h5 className='donar_name'>
                        <Link href='/volunteer-details'>Anayatulla Abbasi</Link>
                      </h5>
                      <div className='donar_meta'>
                        <Link href='#' className='donar_amount theme-1 sep'>
                          $900
                        </Link>
                        <Link href='#' className='donar_date theme-2'>
                          <i className='fal fa-calendar-alt'></i> February 20,
                          2022
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='single_donor_post'>
                    <div className='donar_post_img'>
                      <Link href='/volunteer-details'>
                        <img src='/img/causes/donar4.jpg' alt='img' />
                      </Link>
                    </div>
                    <div className='donar_post_content'>
                      <h5 className='donar_name'>
                        <Link href='/volunteer-details'>Ahmadullah Fokir</Link>
                      </h5>
                      <div className='donar_meta'>
                        <Link href='#' className='donar_amount theme-1 sep'>
                          $300
                        </Link>
                        <Link href='#' className='donar_date theme-2'>
                          <i className='fal fa-calendar-alt'></i> February 20,
                          2022
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='single_donor_post'>
                    <div className='donar_post_img'>
                      <Link href='/volunteer-details'>
                        <img src='/img/causes/donar5.jpg' alt='img' />
                      </Link>
                    </div>
                    <div className='donar_post_content'>
                      <h5 className='donar_name'>
                        <Link href='/volunteer-details'>Ahmed Riad</Link>
                      </h5>
                      <div className='donar_meta'>
                        <Link href='#' className='donar_amount theme-1 sep'>
                          $500
                        </Link>
                        <Link href='#' className='donar_date theme-2'>
                          <i className='fal fa-calendar-alt'></i> February 20,
                          2022
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='single_donor_post'>
                    <div className='donar_post_img'>
                      <Link href='/volunteer-details'>
                        <img src='/img/causes/donar6.jpg' alt='img' />
                      </Link>
                    </div>
                    <div className='donar_post_content'>
                      <h5 className='donar_name'>
                        <Link href='/volunteer-details'>Andreu R.</Link>
                      </h5>
                      <div className='donar_meta'>
                        <Link href='#' className='donar_amount theme-1 sep'>
                          $500
                        </Link>
                        <Link href='#' className='donar_date theme-2'>
                          <i className='fal fa-calendar-alt'></i> February 20,
                          2022
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <ViewPdfModal onClose={setIsModalOpen} causeDetail={causeDetail} amount={amount} donationStatus={donationStatus} />}
    </div>
    // cause_details_area end
  )
}
