import React from 'react'
import { useTranslation } from 'react-i18next';

const SupervisiorySection = () => {
    const { t , i18n } = useTranslation();
    return (
        <div className='w-[100%] my-28 mb-32'>
            <div className='w-[90%] md:w-[80%] lg:w-[90%] mx-auto flex flex-col gap-24'>

                {/* first section */}
                <div className='flex flex-col gap-16 items-center'>
                    <h2 className={`${i18n.language == 'ar' ? "cairo" : "manrope"} text-4xl md:text-4xl text-center text-[#0A4E68] font-medium tracking-wide`}>{t("Home1.SUPERVISORY AUTHORITIES")}</h2>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 justify-between items-center w-full'>
                        <img src='/images/s1First.svg' alt='img' className='w-[200px] mx-auto'/>
                        <img src='/images/s1Two.svg' alt='img' className='w-[200px] mx-auto'/>
                        <img src='/images/s1Three.svg' alt='img' className='w-[200px] scale-[1.6] mx-auto'/>
                    </div>
                </div>

                {/* second section */}
                <div className='flex flex-col gap-16 items-center'>
                    <h2 className={` ${i18n.language == 'ar' ? "cairo" : "manrope"} text-4xl md:text-4xl text-center text-[#0A4E68] font-medium tracking-wide`}>{t("Home1.SPONSORING AUTHORITIES")}</h2>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 justify-between items-center w-full'>
                        <img src='/images/s2First.svg' alt='img' className='w-[200px] scale-[1.5] mx-auto mb-8 sm:mb-0'/>
                        <img src='/images/s2Two.svg' alt='img' className='w-[200px] scale-[1.5] mx-auto mb-8 sm:mb-0'/>
                        <img src='/images/s2Three.svg' alt='img' className='w-[200px] mx-auto mb-8 sm:mb-0'/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SupervisiorySection
