import React, { useEffect, useState } from 'react'
import Aboutfootertoparea2 from "../Components/Aboutfootertoparea2"
import Navbar from '../Components/Nav_Form/Navbar'
import { getALlBookingsApi, getALlDoationsApi, getALlReserveApi } from '../Api'
import { Link } from 'react-router-dom'
import Loader from "react-js-loader";
import Donations from '../Components/Bookings/Donations'
import Reserve from '../Components/Bookings/Reserve'
import { fetchlocalstorage } from '../HelperFunctions'
import { useTranslation } from 'react-i18next'

const MyDonations = () => {
  const [filter, setFilter] = useState("")
  const [status, setStatus] = useState("Donation")
  const [Loading, setLoading] = useState(false)
  const [bookings, setBookings] = useState([])
  const [reserve, setReserve] = useState([])
  const { t } = useTranslation()

  const handleChange = (e) => {
    setFilter(e.target.value)
  }


  const getAllBookings = async () => {
    setLoading(false)
    try {

      let res = await getALlDoationsApi(filter)
      setBookings(res?.data?.data)
      setLoading(true)
    } catch (error) {
      console.log(error)
    }
  }

  const getAllreserve = async () => {
    setLoading(false)
    try {

      let res = await getALlReserveApi(filter)
      setReserve(res?.data?.data)
      setLoading(true)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllBookings()
    getAllreserve()
  }, [filter])

  return (
    <div>

      <Navbar />
      {Loading ?
        <div className={`xs:p-2 sm:p-4 md:p-10 lg:p-16 mt-2 `} >
          <div className='flex justify-between my-4'>
            {/* <input className=" border  shadow-sm rounded-lg	p-1 w-32 sm:w-40" type='date' onChange={handleChange} placeholder='Search ' /> */}
            <div></div>

            <button onClick={() => setFilter("")} className='btn border shadow rounded-lg bg-project_bg_image_color text-white text-md  '>
              {t('All')}
            </button>
          </div>
          <div className='mb-4'>
            <button className={`btn   shadow bg-green-300 text-white  ${fetchlocalstorage("language") == "en" ? "mr-2" : "ml-2"} ${status == "Donation" ? "bg-green-800" : ""}`} onClick={() => { setStatus("Donation"); setFilter("") }}>{t('Donations')}</button>
            <button className={`btn  shadow bg-green-300 text-white ${status == "Donation" ? "" : "bg-green-800"}`} onClick={() => { setStatus("Reserve"); setFilter("") }}>{t('Reserve')}</button>
          </div>

          {
            status == "Donation" ?

              <Donations bookings={bookings} />
              :
              <Reserve reserve={reserve} />
          }

        </div>
        : <span> <Loader type="spinner-circle" bgColor={'black'} color={'black'} size={100} /></span>}
      <Aboutfootertoparea2 />

    </div>
  )
}

export default MyDonations