import React from 'react'
import Footer from '../Footer/Footer'
import Header from './Header'
import OpportunityList from '../AboutPage/OpportunityList'
import { useTranslation } from 'react-i18next'

const Givingopportunity = () => {
  const { t , i18n} = useTranslation();

  return (
    <section className='w-full '>
    <section className="bg-[#0A4D69] h-[50vh] flex flex-col  relative">
        <Header />
    </section>
    <div className="w-[100%]  bg-[#EEEDE5]  " style={{marginTop:'-300px'}}>
        <div className="w-[90%] mx-auto flex flex-col gap-14 relative mt-20 max-md:mt-16 max-sm:mt-8">
            <h3
                className={`  ${i18n.language === 'ar' ? 'cairo' : 'manrope'} uppercase text-4xl font-bold lg:font-normal lg:text-6xl text-center text-white tracking-wide z-100`}
                style={{
                    zIndex: "100",
                }}
            >
                {t('Giving Opportunities')}
            </h3>



        </div>

      <div className=''>
    
      <OpportunityList />
      </div>
    </div>

</section>
  )
}

export default Givingopportunity