import React from "react";
import { useTranslation } from "react-i18next";

const AboutNumberAchivements = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full py-12 lg:py-32 bg-white rounded-lg overflow-hidden"  >
      <div className="w-11/12 mx-auto flex flex-col items-center gap-10 lg:gap-28">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8" >
          <AchievementCard
            icon="/images/chain.svg"
            title={t("Home1.Participating Charitable Organization")}
            value="83"
          />
          <AchievementCard
            icon="/images/bookmark.svg"
            title={t("Home1.Submitted Projects")}
            value="83"
          />
          <AchievementCard
            icon="/images/hamburger.svg"
            title={t("Home1.Matured and Well-studied Projects")}
            value="79"
          />
          <AchievementCard
            icon="/images/people.svg"
            title={t("Home1.Experts and Evaluators")}
            value="22"
          />
          <AchievementCard
            icon="/images/bookmark.svg"
            title={t("Home1.Workshops")}
            value="12"
          />
          <AchievementCard
            icon="/images/people.svg"
            title={t("Home1.Beneficiaries")}
            value="1,120,000"
          />
          <AchievementCard
            icon="/images/dollarBitcoin.svg"
            title={t("Home1.Cost of Well-studied Projects")}
            value={`96,076,982 ${t("SAR")}`}
          />
          <AchievementCard
            icon="/images/worker.svg"
            title={t("Home1.Workers")}
            value="1,153"
          />
          <AchievementCard
            icon="/images/dollarBitcoin.svg"
            title={t("Home1.Cost of Signed Projects")}
            value={`20,640,017 ${t("SAR")}`}

          />
        </div>
      </div>
    </div>
  );
};

const AchievementCard = ({ icon, title, value }) => (
  <div className="flex flex-col items-center gap-2 p-4  rounded-lg text-center">
    <img src={icon} className="w-12 h-12" alt={title} />
    <p className="text-xl font-light text-black manrope">{title}</p>
    <p className="text-4xl font-bold text-[#134560]">{value}</p>
  </div>
);

export default AboutNumberAchivements;
