import React from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";

const MatururedProjects = ({
  domains,
  selectedDomain,
  domainsCard,
  handleClick,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  // Settings for React Slick slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Hide default arrows since we will use custom ones
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full bg-[#4f7e8f] text-white py-24 mt-28 mb-12 Projects">
      <div className="w-[85%] mx-auto flex flex-col justify-between items-center gap-8">
        <h3
          className={`text-4xl text-center font-medium leading-9 tracking-wide text-white ${
            i18n.language == "ar" ? "cairo" : "manrope"
          }`}
        >
          {t("Home1.LATEST MATURED PROJECTS")}
        </h3>

        <div className="flex flex-wrap gap-4 justify-center md:justify-between w-[80%] mt-10">
          <div className="group flex flex-col" onClick={() => handleClick("")}>
            <p className="hover:cursor-pointer">All</p>
            <p
              className={`bg-white h-[2px] transition-all duration-300 ${
                selectedDomain === ""
                  ? "w-[100%]"
                  : "w-[0px] group-hover:w-[100%]"
              }`}
            ></p>
          </div>
          {domains.map((dom, i) => (
            <div
              className="group flex flex-col"
              key={i}
              onClick={() => handleClick(dom._id)}
            >
              <p className="hover:cursor-pointer">{dom.name}</p>
              <p
                className={`bg-white h-[2px] transition-all duration-300 ${
                  selectedDomain === dom._id
                    ? "w-[100%]"
                    : "w-[0px] group-hover:w-[100%]"
                }`}
              ></p>
            </div>
          ))}
        </div>

        {window.innerWidth > 768 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 scrollbar-custom lg:grid-cols-3 gap-8 mt-2 pb-8">
            {domainsCard.map((dom, i) => (
              <div
                key={i}
                className="bg-[#FFFFFF] rounded-2xl shadow-lg overflow-hidden px-4 py-4 flex flex-col gap-4 hover:cursor-pointer"
                onClick={() =>
                  navigate("/causes-details", { state: { item:dom } })
                }
              >
                <div className="relative w-full">
                  <img
                    style={{
                      height: "200px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={dom.projectImage}
                    alt="Project"
                  />

                  <div className="absolute h-32 -bottom-0 flex flex-col justify-between py-2.5 text-sm text-center text-white bg-emerald-300 w-[68px] rounded-[32px_32px_0px_0px]">
                    <div className="justify-center self-center px-2 py-2 whitespace-nowrap rounded-3xl border text-xs border-white border-solid leading-[143%]">
                    {
                      ((dom?.raised/dom?.financialDetailsTotal
                      )*100).toFixed(1)
                    }%
                    </div>
                    <h2 className="mt-3 w-full leading-5 text-white whitespace-break-spaces">
                    {dom?.domain?.name}
                    </h2>
                  </div>
                </div>
                <h3 className="text-[#16192C] mt-2 text-sm font-semibold">
                  {dom.projectName}
                </h3>
                <p className="text-[#425466] text-xs font-medium">
                  {dom.projectJustification}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <Slider {...sliderSettings}>
            {domainsCard.map((dom, i) => (
              <div
                key={i}
                className="bg-[#FFFFFF] w-full rounded-2xl shadow-lg  px-4 py-4 flex flex-col gap-4 hover:cursor-pointer"
              >
               <div className="relative w-full">
                  <img
                    style={{
                      height: "200px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={dom.projectImage}
                    alt="Project"
                  />

                  <div className="absolute h-32 -bottom-0 flex flex-col justify-between py-2.5 text-sm text-center text-white bg-emerald-300 w-[68px] rounded-[32px_32px_0px_0px]">
                    <div className="justify-center self-center px-2 py-2 whitespace-nowrap rounded-3xl border text-xs border-white border-solid leading-[143%]">
                    {
                      ((dom?.raised/dom?.financialDetailsTotal
                      )*100).toFixed(1)
                    }%
                    </div>
                    <h2 className="mt-3 w-full leading-5 text-white whitespace-break-spaces">
                    {dom?.domain?.name}
                    </h2>
                  </div>
                </div>
                <h3 className="text-[#16192C] mt-2 text-sm font-semibold">
                  {dom.projectName}
                </h3>
                <p className="text-[#425466] text-xs font-medium">
                  {dom.projectJustification}
                </p>
              </div>
            ))}
          </Slider>
        )}

        <Link to={"/opportunity"}>
          <button className="bg-[#FFFFFFCC] text-[#013C4A] px-8 py-3 rounded-3xl w-fit font-medium flex items-center gap-2">
            {t("Home1.For More Projects")}
            <span className="text-sm">
              <FaArrowRight className="text-[#013C4A]" />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MatururedProjects;
