export const signinByDonorInitialValue = {
    email:"",
    password:"",
    role:"DONOR"
     
}
export const signUpByDonorInitialValue = {
    email:"",
    password:"",
    role:"DONOR"
     
}


export const donorDetailInitialValues = {
  name: '',
  domain: '',
  countryCode:'+966',
  mobileNumber: '',
  landLineNumber: '',
  faxNumber: '',
  email: '',
  // status: 'PENDING',
  entityType: '',
  licenseNumber: '',
  licenseAuthority: '',
  address: '',
  googleMapLink: '',
  mission: '',
  vision: '',
  objectives: '',
  executiveManagerName: '',
  executiveManagerNumber: '',
  registrationCertificateNumber: "",
  city: "",
  district: "",
  street: "",
  position:""

};

export const donotAgentDetailsInitialValue = {
  agentName: '',
  agentMobileNumber: '',
  agentEmail: '',
}
