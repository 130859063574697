import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { fetchlocalstorage } from "../../HelperFunctions";
import moment from "moment-hijri";


import { IoMdDocument } from "react-icons/io";

const DemoProfileInfo = ({ userInfo }) => {
    console.log(userInfo)
    const {t,i18n} = useTranslation()
    console.log(i18n.language)
    const [step, setStep] = useState(1);
    const openLink = (link) => {
        console.log(link)
        window.open(link, '_blank');
      };

console.log(i18n.language==="en"?userInfo?.region?.name:userInfo?.region?.nameArabic)

  return (
    <div>
      <div className=" xs:p-2 mx-auto mt-10 lg:w-[80%]  flex flex-col flex-wrap">
        {/* first section */}
        <div className="w-full">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
                {t("General information")}
            </p>
            <div className="pb-3 w-full">
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1  flex ">
                        <div className="text-black px-2 mt-1">
                            {t(`Organization name`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 rounded-md border">
                            {userInfo?.name ||<>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Organization Field`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.domain?.map((item) => (
                            <span className="bg-gray-200 mr-2">{item.name}</span>
                            )) || <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Mission`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.mission || <>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Vision`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.vission || <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Objectives`)}:{" "}
                        </div>
                        {
                            userInfo?.objectives.map((item)=>{
                                return (<div className=" px-2 p-1 border rounded-md">
                                            {item || <>{t("No data available")}</>}
                                        </div>)
                            })
                        }
                    </div>
                    <div className=" mb-2 w-[50%] h-fit flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Date Of Establishment`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                        {(userInfo?.dateOfEstablishment &&
                                moment(userInfo?.dateOfEstablishment).format('iYYYY/iM/iD')) ||
                                <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                            <div className="text-black p-1  px-2">
                                {t(`Actual Start Date of Organization Activity`)}:{" "}
                            </div>
                            <div className=" px-2 p-1 border rounded-md">
                                {userInfo?.actualStartDateofOrganizationActivity && moment(userInfo?.actualStartDateofOrganizationActivity).format('iYYYY/iM/iD') || <>{t("No data available")}</>}
                            </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Targeted Audience`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.targetedAudience || <>{t("No data available")}</>}
                        </div>
                    </div>
                    
                </div>
                <div className="flex w-full gap-10">
                   <div className="  w-[50%]  ">
                        <div className="mb-4">
                        <div className="text-black font-semibold">{t(`Copy Of Organization Structure`)}:</div>
                        </div>
                        {userInfo?.copyOfTheOrganizationalStructure !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.copyOfTheOrganizationalStructure)}>
                        <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                        {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                            </svg> */}
                        </div> :
                        <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                            <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                            {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                        </svg> */}
                        </div>
                        }

                    </div>
                    <div className="  w-[50%]  ">
                        <div className="mb-4">
                        <div className="text-black font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap"> { t("Participation letter from the organization")}:</div>
                        </div>
                        {userInfo?.participationLetter !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.participationLetter)}>
                        <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                        {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                            </svg> */}
                        </div> :
                        <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                            <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                            {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                        </svg> */}
                        </div>
                        }

                     </div>
                </div>
                <div className="   ">
                        <div className="mb-4">
                        <div className="text-black font-semibold">{t(`Company Profile Image`)}:</div>
                        </div>
                        {userInfo?.companyProfileImage !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.companyProfileImage)}>
                        <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                        {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                            </svg> */}
                        </div> :
                        <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                            <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                            {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                        </svg> */}
                        </div>
                        }

                </div>
            </div>

        </div>
        {/* second section */}
        <div className="w-full">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
               {t("Contact Information")}
            </p>
            <div className="pb-3 w-full">
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                            <div className="text-black p-1  px-2">
                                {t(`Region`)}:{" "}
                            </div>
                            <div className=" px-2 p-1 border rounded-md">
                                {i18n.language==="en"?userInfo?.region?.name:userInfo?.region?.nameArabic || <>{t("No data available")}</>}
                            </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`City`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {i18n.language==="en"?userInfo?.city?.cityName:userInfo?.city?.cityNameArabic || <>{t("No data available")}</>}
                        </div>
                    </div>
         

                </div>
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Mobile Number`)}:{" "}
                        </div>
                        <span className=" px-2 p-1 border rounded-md">
                            {userInfo?.mobileNumber || <>{t("No data available")}</>}
                        </span>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Email`)}:{" "}
                        </div>
                        <div
                            className=" px-2 p-1 border rounded-md text-ellipsis	overflow-hidden"
                            style={{ fontSize: "10vm" }}
                        >
                            {userInfo?.email || <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Website`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {(userInfo?.website && (
                            <Link to={userInfo?.website} target="_blank">
                                {userInfo?.website}
                            </Link>
                            )) ||
                            <>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Landline Number`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {(userInfo?.landLineNumber && (
                            <Link to={userInfo?.landLineNumber} target="_blank">
                                {userInfo?.landLineNumber}
                            </Link>
                            )) ||
                            <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`District`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.district || <>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Street`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.street || <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Address`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.address || <>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Fax number`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.faxNumber || <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className=" mb-2 flex-col gap-1 flex justify-around">
        <div className="text-black p-1  px-2">
            {t(`Google map Link`)}:{" "}
        </div>
        <div className=" px-2 p-1 border rounded-md break-words">
            {(userInfo?.googleMapLink && (
            <Link to={userInfo?.googleMapLink} target="_blank">
                {userInfo?.googleMapLink}
            </Link>
            )) ||
            <>{t("No data available")}</>}
        </div>
                </div>
            </div>
        </div>
        <div className="w-full">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("License Information")}
            </p>
            <div className="mt-3">
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t('License Number')}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.licenseNumber || <>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Licensing Authority`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {t(userInfo?.licenseAuthority) || <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`License Established Date`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {(userInfo?.licenseDate &&
                            moment(userInfo?.licenseDate).format('iYYYY/iM/iD')) ||
                            <>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`License Expiry Date`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {(userInfo?.licenseExpiryDate &&
                            moment(userInfo?.licenseExpiryDate).format('iYYYY/iM/iD')) ||
                            <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full gap-10">
                    <div className="  w-[50%]  ">
                    <div className="mb-4">
                    <div className="text-black font-semibold">{t(`Organization License`)}:</div>
                    </div>
                    {userInfo?.organizationLicense !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.organizationLicense)}>
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                        </svg> */}
                    </div> :
                    <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                        <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                        {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                    </svg> */}
                    </div>
                    }

                    </div>
                    <div className="  w-[50%]  ">
                      <div className="mb-4">
                  <div className="text-black font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap">{t("Formation of the Board of Directors file")}:</div>

                        </div>
                        {userInfo?.copyOfTheBoardOfDirectorsAppointment !== "" ? <div className=" border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.copyOfTheBoardOfDirectorsAppointment)}>
                        <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                        {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                            </svg> */}
                        </div> :
                        <div className=" border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                            <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                            {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                        </svg> */}
                        </div>
                        }

                    </div>
                </div>
                

            </div>

        </div>
        <div className="w-full">
           <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Administrative Information")}
            </p>
            <div className="mt-3">
                <div className="flex gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Number of board of director's member`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.numberOfBoardDirector || <>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`CEO Name`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.executiveManagerName || <>{t("No data available")}</>}
                        </div>
                    </div>

                </div>
                <div className="flex gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Executive manager number`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.executiveManagerNumber || <>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Agent Name`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.agentName || <>{t("No data available")}</>}
                        </div>
                    </div>

                </div>
                <div className="flex gap-10">
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                            {t(`Agent Mobile Number`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md">
                            {userInfo?.agentMobileNumber || <>{t("No data available")}</>}
                        </div>
                    </div>
                    <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                        <div className="text-black p-1  px-2">
                        {t(`Agent Email`)}:{" "}
                        </div>
                        <div className=" px-2 p-1 border rounded-md text-ellipsis	overflow-hidden">
                        {userInfo?.agentEmail || <>{t("No data available")}</>}
                        </div>
                    </div>
                </div>
                <div className="flex gap-10">
                    <div className="  w-[50%]  ">
                            <div className="mb-4">
                            <div className="text-black font-semibold">{t(`Letter Of Authorization`)}:</div>
                            </div>
                            {userInfo?.letterOfAuthorization !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.letterOfAuthorization)}>
                            <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                            {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                                </svg> */}
                            </div> :
                            <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                                <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                                {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                            </svg> */}
                            </div>
                            }

                    </div>
                    <div className="  w-[50%]  ">
                        <div className="mb-4">
                        <div className="text-black font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap">{t("Copy of the Board of Directors Appointment")}:</div>
                        </div>
                        {userInfo?.copyOfTheBoardOfDirectorsDegree !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.copyOfTheBoardOfDirectorsDegree)}>
                        <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                        {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                            </svg> */}
                        </div> :
                        <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                            <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                            {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                        </svg> */}
                        </div>
                        }

                    </div>
                </div>



            </div>
        </div>
        <div className="w-full">
            <p className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Banking Information")}
            </p>
            <div className="mt-3">
              <div className="flex gap-10">
                <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                    <div className="text-black p-1  px-2">
                        {t(`IBAN Number`)}:{" "}
                    </div>
                    <div className=" px-2 p-1 border rounded-md">
                        {userInfo?.ibanNumber || <>{t("No data available")}</>}
                    </div>
                </div>
                <div className=" mb-2 w-[50%] flex-col gap-1 flex justify-around">
                    <div className="text-black p-1  px-2">
                        {t(`Bank Name`)}:{" "}
                    </div>
                    <div className=" px-2 p-1 border rounded-md">
                        {t(userInfo?.bankName) || <>{t("No data available")}</>}
                    </div>
                </div>

              </div>
              <div className="flex gap-10">
                <div className="  w-[50%]  ">
                        <div className="mb-4">
                        <div className="text-black font-semibold">{t(`Updated bank statement`)}:</div>
                        </div>
                        {userInfo?.updatedBankStatement !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.updatedBankStatement)}>
                        <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                        {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                            </svg> */}
                        </div> :
                        <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                            <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                            {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                        </svg> */}
                        </div>
                        }

                </div>
                <div className="  w-[50%]  ">
                    <div className="mb-4">
                    <div className="text-black font-semibold">{t(`Copy Of Budgets`)}:</div>
                    </div>
                    {userInfo?.copyOfBudgets !== "" ? <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" onClick={() => openLink(userInfo?.copyOfBudgets)}>
                    <span className='mr-2'> <IoMdDocument /></span> <button>{t(`View document`)}</button>

                    {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                        </svg> */}
                    </div> :
                    <div className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4" >
                        <span className='mr-2'> <IoMdDocument /></span> <button>{t(`No document`)}</button>

                        {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                    </svg> */}
                    </div>
                    }

                </div>
              </div>

            </div>

        </div>
      </div>
    </div>
  );
};

export default DemoProfileInfo;
