import React, { useState, useEffect } from "react";
import { db, fb_messages } from "../../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  and,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
} from "@firebase/firestore";
const ChatLog = ({ selectedChat, setNewUsers }) => {
  const [allMessages, setAllMessages] = useState([]);
  let getUserInfo = localStorage.getItem("userDetails");
  getUserInfo = JSON.parse(getUserInfo);

  const id = getUserInfo._id;
  const [userId, setUserId] = useState(id);

  useEffect(() => {
    console.log(allMessages);
  }, [allMessages.length]);

  useEffect(() => {
    console.log(selectedChat);

    if (Object.keys(selectedChat).length === 0) {
      return;
    }

    const q = query(
      collection(db, fb_messages),
      and(
        where("sender", "in", [id, selectedChat?._id]),
        where("receiver", "in", [id, selectedChat?._id])
      )
      // orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log(snapshot.docs);

      let messages = [];

      snapshot.docs.map((doc) => {
        messages.push(doc.data());
      });
      const messagesWithTimestamp = messages.map((ob) => {
        const ts = new Date(ob?.timestamp?.seconds * 1000);
        return { ...ob, timestamp: ts };
      });

      const sortedMessages = messagesWithTimestamp.sort(
        (a, b) => a?.timestamp - b?.timestamp
      );

      setAllMessages(sortedMessages);
      // setAllMessages(messages.map(ob => {
      //     const ts = new Date(ob.timestamp.seconds * 1000)
      //     return { ...ob, timestamp: ts }
      // }))
    });
    updateUserNewMessage();

    return () => {
      unsubscribe();
    };
  }, [selectedChat]);

  const updateUserNewMessage = async () => {
    if (!selectedChat) return;

    try {
      const userRef = collection(db, "users");
      const userQuery = query(
        userRef,
        and(where("newMessage", "==", true), where("receiver", "==", userId))
      );
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        // User not found, add new user
        await addDoc(userRef, {
          sender: userId,
          receiver: selectedChat?._id,
          timestamp: new Date(),
          newMessage: false,
        });
        // setNewUsers((prev) => prev.map((el) => {
        //     if (el?.id == selectedChat?.id) {
        //         return {
        //             ...el,
        //             newMessage: false
        //         }
        //     } else return el
        // }))
      } else {
        // User found, update user
        const userDoc = userSnapshot.docs[0];
        await updateDoc(userDoc.ref, {
          newMessage: false,
        });
      }
      if (selectedChat?.newMessage == true) {
        setNewUsers((prev) =>
          prev.map((el) => {
            if (el?.id == selectedChat?.id) {
              return {
                ...el,
                newMessage: false,
              };
            } else return el;
          })
        );
      }
    } catch (error) {
      console.log(error);
      //   toastMessage("Failed to update user's new message status", "error");
    }
  };

  const formattedChatData = () => {
    const formattedChatLog = allMessages.map((ob) => {
      return {
        senderId: ob.sender,
        messages: [
          {
            time: ob.timestamp,
            msg: ob.message,
            feedback: null,
          },
        ],
      };
    });
    return formattedChatLog;
  };

  const renderChats = () => {
    return formattedChatData().map((item, index) => {
      const isSender = item.senderId === userId;
      const name = isSender ? getUserInfo?.name : selectedChat?.name;
      return (
        <>
          {item.messages.map((chat, index, { length }) => {
            return (
              <div
                className={`flex ${
                  isSender
                    ? "justify-end bg-blue-400 mr-2 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl"
                    : "justify-start bg-gray-400 ml-2 rounded-br-3xl rounded-tr-3xl rounded-tl-xl"
                } mb-4 `}
              >
                <div
                  className={`${
                    isSender
                      ? "mr-2 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl"
                      : "ml-2 rounded-br-3xl rounded-tr-3xl rounded-tl-xl"
                  }  py-3 px-4   text-white`}
                >
                  {chat?.msg}
                </div>
              </div>
            );
          })}
        </>
      );
    });
  };

  return <>{renderChats()}</>;
};

export default ChatLog;
